import errors from '../configs/errors-front';
import clsx from 'clsx';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { WithClassName, WithError } from '../withs';
import String from '../atoms/String';

export function Error({ className, error, subject }: ErrorProps) {
    const { t } = useTrans();
    error = error || {};
    const errorKeys = [`${error['errorType']}_${(error['data'] || {})['type']}`, error['errorType'], 'unknown'];
    const errorFormatter = errors[errorKeys.find((k) => errors[k])];
    const {
        title,
        message,
        detail,
        retry = true,
        contact = true,
    } = 'function' === typeof errorFormatter ? errorFormatter(error) : errorFormatter;

    return (
        <div className={clsx('w-full', className)}>
            <div className={'w-full items-center text-center mt-6 bg-red-400 rounded p-4'}>
                <h1 className={'font-go-text text-white font-bold text-3xl mb-2'}>{title}</h1>
                <div className={'font-go-text text-white text-xl mb-4'}>{message}</div>
                {!!detail && <String className={'font-go-text text-white'}>{detail}</String>}
            </div>
            {retry && <div className={'font-go-text text-center mt-4'}>{t('error_please_retry_later')}</div>}
            {contact && (
                <div className={'font-go-text text-center mt-4'}>
                    {t('error_contact_us')}{' '}
                    <a
                        className={'font-go-text text-blue-500 hover:text-blue-700 underline cursor-pointer'}
                        href={`mailto:${t('contact', { ns: 'email' })}?subject=${subject}`}
                    >
                        {t('contact', { ns: 'email' })}
                    </a>
                </div>
            )}
        </div>
    );
}

export interface ErrorProps extends WithClassName, Required<WithError<any>> {
    subject?: string;
}

export default Error;
