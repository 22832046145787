import clsx from 'clsx';
import SectionTitle from '../../atoms/SectionTitle';
import { module_section_variant } from '../../types';
import SectionSubtitle from '../../atoms/SectionSubtitle';

export function BasicModuleSection({
    children,
    className,
    containerClassName,
    subtitle,
    subtitleStyling,
    title,
    titleStyling,
    ...props
}: BasicModuleSectionProps) {
    return (
        <div className={clsx('w-full pt-8 pb-4', containerClassName)}>
            <SectionTitle
                title={title}
                titleStyling={titleStyling}
                className={clsx(!props.bottomImage ? 'mb-6 sm:mb-12' : 'mb-0')}
                {...props}
            />
            <SectionSubtitle subtitle={subtitle} subtitleStyling={subtitleStyling} className={'mb-4 sm:mb-6'} />
            <div className={clsx('w-full', className)}>{children}</div>
        </div>
    );
}

export type BasicModuleSectionProps = module_section_variant;

export default BasicModuleSection;
