import clsx from 'clsx';
import Text from './Text';
import useStyledText from '../hooks/useStyledText';
import { flag } from '@gotombola/types/lib/types/base';
import { WithClassName, WithSubtitle } from '@gotombola/react-types/lib/withs';

const sizes = {
    smalltext: 'text-sm',
    text: 'text-md',
    subtitle: 'text-lg',
};

export function SectionSubtitle({
    className,
    bold = false,
    center = true,
    subtitle: rawSubtitle,
    subtitleStyling,
    basic = false,
    size = 'subtitle',
}: SectionSubtitleProps) {
    const [subtitle, subtitleClassName] = useStyledText(rawSubtitle, subtitleStyling, undefined, 'sectionSubtitle');
    if (!subtitle) return null;
    return (
        <Text
            bold={bold}
            center={center}
            value={subtitle}
            className={clsx(sizes[size], className, subtitleClassName)}
            basic={basic}
        />
    );
}

export interface SectionSubtitleProps extends WithClassName, WithSubtitle {
    subtitleStyling?: string;
    basic?: flag;
    bold?: flag;
    center?: flag;
    size?: string;
}

export default SectionSubtitle;
