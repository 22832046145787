import { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { WithClassName } from '../withs';
import { trackCompleteRegistration, trackLead } from '../services/tracking';
import StaticIcon from '../atoms/StaticIcon';
import QRCode from '../atoms/QRCode';

export function OnboardingCongratulation({ className, data }: OnboardingCongratulationProps) {
    const { t } = useTrans();
    const history = useHistory();
    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(data.publicPageShortUrl);
    }, []);
    const handleCopyQrCodeUrl = useCallback(() => {
        navigator.clipboard.writeText(data.publicQrCodeUrl);
    }, []);
    const handleCopyFlyerUrl = useCallback(() => {
        navigator.clipboard.writeText(data.publicFlyerUrl);
    }, []);
    const keywordText =
        data.keywords &&
        data.keywords.items &&
        data.keywords.items.length &&
        data.keywords.items[0].code &&
        data.keywords.items[0].code.startsWith('GOTOMBOLA') &&
        `${data.keywords.items[0].code.slice(0, 9)} ${data.keywords.items[0].code.slice(9)}`;
    const handleSms = useCallback(() => {
        history.push('sms:32321&body=' + encodeURIComponent(keywordText));
    }, [history, data, keywordText]);
    const onContactClick = useCallback(() => {
        trackCompleteRegistration();
    }, []);

    useEffect(() => {
        trackLead();
    }, []);

    return (
        <div className={clsx('w-full', className)}>
            <div className={'w-full flex flex-col items-center sm:px-4'}>
                <div className={'w-full flex flex-col items-center text-black sm:p-8'}>
                    <div className={'h-16 w-16 bg-gray-200 p-4 rounded-full flex items-center justify-center'}>
                        <StaticIcon type={'success'} className={'object-contain'} />
                    </div>
                    <div className={'font-go-text text-2xl sm:text-5xl font-bold mt-6 text-center'}>
                        {t('onboarding_congratulations_title')}
                    </div>
                    <div className={'font-go-text sm:text-2xl font-bold mt-1 text-center'}>
                        {t('onboarding_congratulations_subtitle')}
                    </div>
                </div>
                <div className={'w-full'}>
                    <div className={'w-full flex flex-col justify-center items-center p-4 h-full mb-4'}>
                        <div className={'flex flex-col items-center justify-center rounded-lg bg-gray-100'}>
                            <QRCode target={data.publicPageShortUrl} className={'w-32 sm:w-56'} />
                        </div>
                    </div>
                    <div className={'w-full flex justify-between sm:p-4 h-full'}>
                        <div className={'w-full space-y-6 flex flex-col'}>
                            <div>
                                <div className={'font-go-text text-lg text-gray-700'}>
                                    {t('onboarding_congratulations_site_label')}
                                </div>
                                <div className={'flex'}>
                                    <input
                                        disabled
                                        id={'gameLink'}
                                        className={
                                            'font-go-text p-2 rounded-l-lg border-t border-b border-l border-gray-200 bg-white w-full'
                                        }
                                        value={data.publicPageShortUrl}
                                    />
                                    <button
                                        className={
                                            'font-go-button bg-blue-500 text-white w-10 rounded-r-lg flex items-center justify-center'
                                        }
                                        onClick={handleCopy}
                                    >
                                        <StaticIcon type={'link'} />
                                    </button>
                                </div>
                            </div>
                            {!!keywordText && (
                                <div className={'w-full'}>
                                    <div className={'font-go-text text-lg text-gray-700'}>
                                        {t('onboarding_congratulations_sms_label')}
                                    </div>
                                    <div className={'w-full flex'}>
                                        <input
                                            disabled
                                            className={
                                                'font-go-text p-2 rounded-l-lg border-t border-b border-l border-gray-200 bg-white w-full'
                                            }
                                            value={t('onboarding_sms', { keywordText: keywordText })}
                                        />
                                        <button
                                            className={
                                                'font-go-button bg-blue-500 text-white w-10 rounded-r-lg flex items-center justify-center'
                                            }
                                            onClick={handleSms}
                                        >
                                            <StaticIcon type={'send'} />
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div>
                                <div className={'font-go-text text-lg text-gray-700'}>
                                    {t('onboarding_congratulations_qrcode_label')}
                                </div>
                                <div className={'flex'}>
                                    <input
                                        disabled
                                        id={'gameLink'}
                                        className={
                                            'font-go-text p-2 rounded-l-lg border-t border-b border-l border-gray-200 bg-white w-full'
                                        }
                                        value={data.publicQrCodeUrl}
                                    />
                                    <button
                                        className={
                                            'font-go-button bg-blue-500 text-white w-10 rounded-r-lg flex items-center justify-center'
                                        }
                                        onClick={handleCopyQrCodeUrl}
                                    >
                                        <StaticIcon type={'link'} />
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div className={'font-go-text text-lg text-gray-700'}>
                                    {t('onboarding_congratulations_flyer_label')}
                                </div>
                                <div className={'flex'}>
                                    <input
                                        disabled
                                        id={'gameLink'}
                                        className={
                                            'font-go-text p-2 rounded-l-lg border-t border-b border-l border-gray-200 bg-white w-full'
                                        }
                                        value={data.publicFlyerUrl}
                                    />
                                    <button
                                        className={
                                            'font-go-button bg-blue-500 text-white w-10 rounded-r-lg flex items-center justify-center'
                                        }
                                        onClick={handleCopyFlyerUrl}
                                    >
                                        <StaticIcon type={'link'} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a
                    className={'font-go-button bg-blue-500 text-white rounded-lg px-10 py-4 mt-16 mb-12'}
                    onClick={onContactClick}
                    href={`mailto:${t('contact', { ns: 'email' })}?subject=${t('subject_activation', { ns: 'email' })}`}
                >
                    {t('onboarding_congratulations_contact')}
                </a>
            </div>
        </div>
    );
}

export interface OnboardingCongratulationProps extends WithClassName {
    data: any;
}

export default OnboardingCongratulation;
