import { createContext } from 'react';
import { sellergroup_context_value } from '../types';
import createDefaultSellergroupContextValue from '../utils/createDefaultSellergroupContextValue';

export const SellergroupContext = createContext<sellergroup_context_value>(createDefaultSellergroupContextValue());
// noinspection JSUnusedGlobalSymbols
export const SellergroupContextProvider = SellergroupContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const SellergroupContextConsumer = SellergroupContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default SellergroupContext;
