import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./title/StandardTitleTypeModule')),
};

export function TitleTypeModule(props: TitleTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type TitleTypeModuleProps = TypeModuleProps;

export default TitleTypeModule;
