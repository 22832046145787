import { useCallback } from 'react';
import clsx from 'clsx';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import useVals from '../hooks/useVals';

const styles = {
    defaults: {
        size: 'md',
    },
    sizes: {
        xs: 'border-1 h-2 w-2',
        sm: 'border-2 h-6 w-6',
        md: 'border-2 h-10 w-10',
        lg: 'border-4 h-14 w-14',
    },
};

const palettes = {
    standard: [
        { value: '#FFFFFF', label: 'White', priority: 1 },
        { value: '#C0C0C0', label: 'Silver' },
        { value: '#808080', label: 'Gray' },
        { value: '#000000', label: 'Black' },
        { value: '#FF0000', label: 'Red' },
        { value: '#800000', label: 'Maroon' },
        { value: '#FFFF00', label: 'Yellow' },
        { value: '#808000', label: 'Olive' },
        { value: '#00FF00', label: 'Lime' },
        { value: '#008000', label: 'Green' },
        { value: '#00FFFF', label: 'Aqua' },
        { value: '#008080', label: 'Teal' },
        { value: '#0000FF', label: 'Blue' },
        { value: '#000080', label: 'Navy' },
        { value: '#FF00FF', label: 'Fuchsia' },
        { value: '#800080', label: 'Purple' },
        { value: '#ff80ed', label: 'Bleu' },
        { value: '#a0d2eb', label: 'Ice Cold' },
        { value: '#e5eaf5', label: 'Freeze Purple' },
        { value: '#d0bdf4', label: 'Medium Purple' },
        { value: '#8458B3', label: 'Purple Pain' },
        { value: '#a28089', label: 'Heavy Purple' },
    ],
};
export function ColorDataField({
    placeholder,
    onChange,
    emptyAllowed = true,
    palette = 'standard',
    values = undefined,
    size,
    allData,
    ...props
}: ColorDataFieldProps) {
    const { t } = useTrans();
    const selectedValues = useVals(
        values,
        palettes,
        palette,
        'standard',
        t,
        emptyAllowed,
        'data_field_color_empty_label',
    );

    const handleClick = useCallback(
        (value) => (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            onChange && onChange({ target: { value } });
        },
        [onChange],
    );

    return (
        <div className={'flex flex-wrap gap-2'}>
            {selectedValues.map(({ value }: any, index: number) => (
                <div
                    key={index}
                    className={clsx(
                        'rounded-sm hover:border-go-primary cursor-pointer shadow shadow-3xl',
                        styles.sizes[size || styles.defaults.size] || false,
                        !value && 'border-dotted',
                        value === props.value ? 'border-go-primary' : !value ? '' : 'border-transparent',
                    )}
                    style={{ backgroundColor: value }}
                    onClick={handleClick(value)}
                />
            ))}
        </div>
    );
}

export interface ColorDataFieldProps {
    name: string;
    onChange: Function;
    value: any;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    emptyAllowed?: boolean;
    values?: any[];
    palette?: string;
    allData?: any;
    register: Function;
    control: Function;
    size?: string;
}

// noinspection JSUnusedGlobalSymbols
export default ColorDataField;
