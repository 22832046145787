import { createContext } from 'react';
import { partner_context_value } from '../types';
import createDefaultPartnerContextValue from '../utils/createDefaultPartnerContextValue';

export const PartnerContext = createContext<partner_context_value>(createDefaultPartnerContextValue());
// noinspection JSUnusedGlobalSymbols
export const PartnerContextProvider = PartnerContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const PartnerContextConsumer = PartnerContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default PartnerContext;
