import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from '@tsparticles/engine';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        fpsLimit: 120,
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            bounce: {
                vertical: {
                    value: {
                        min: 0.75,
                        max: 0.85,
                    },
                },
            },
            color: {
                value: [
                    '#3998D0',
                    '#2EB6AF',
                    '#A9BD33',
                    '#FEC73B',
                    '#F89930',
                    '#F45623',
                    '#D62E32',
                    '#EB586E',
                    '#9952CF',
                ],
            },
            number: {
                value: 0,
            },
            destroy: {
                mode: 'split',
                split: {
                    count: 2,
                    factor: {
                        value: {
                            min: 1.1,
                            max: 2,
                        },
                    },
                    rate: {
                        value: {
                            min: 2,
                            max: 3,
                        },
                    },
                },
            },
            opacity: {
                value: 0.8,
            },
            size: {
                value: {
                    min: 10,
                    max: 20,
                },
            },
            move: {
                enable: true,
                gravity: {
                    enable: true,
                    maxSpeed: 50,
                },
                speed: {
                    min: 10,
                    max: 20,
                },
                direction: 'none',
                random: false,
                straight: false,
                outModes: {
                    bottom: 'split',
                    default: 'bounce',
                    top: 'none',
                },
                trail: {
                    enable: false,
                    fill: {
                        color: '#fff',
                    },
                    length: 3,
                },
            },
        },
        detectRetina: true,
        emitters: {
            direction: 'top',
            life: {
                count: 0,
                duration: 0.15,
                delay: 3,
            },
            rate: {
                delay: 0.1,
                quantity: 5,
            },
            size: {
                width: 0,
                height: 0,
            },
        },
    };
};
