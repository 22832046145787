import clsx from 'clsx';
import { class_name } from '../types';
import { WithChildren, WithClassName } from '../withs';
import StaticLogo from '../atoms/StaticLogo';

export function CentralAllInOneLayout({ children, childrenClassName, className }: CentralAllInOneLayoutProps) {
    return (
        <div className={clsx('w-full', className)}>
            <div className={'flex justify-center items-center fixed bg-white h-20 w-screen p-6 space-x-6 z-10'}>
                <StaticLogo type={'gotombola-small-2'} className={'w-44'} />
            </div>
            <div className={'h-16 mb-4'} />
            <div className={clsx('flex flex-col items-center', childrenClassName)}>{children || ''}</div>
        </div>
    );
}

export interface CentralAllInOneLayoutProps extends WithChildren, WithClassName {
    childrenClassName?: class_name;
}

export default CentralAllInOneLayout;
