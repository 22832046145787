import { WithImage, WithText, WithTitle } from '../withs';
import Img from '../atoms/Img';
import String from '../atoms/String';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function Bullet({ image, title, text }: BulletProps) {
    const { mediaShapeClass } = useStyling();

    return (
        <div className={'text-center max-w-[260px] sm:max-w-[200px] mx-auto mb-4 sm:mb-0'}>
            {!!image?.url && (
                <Img
                    {...image}
                    className={clsx('object-contain w-32 h-32 mx-auto mb-4', mediaShapeClass)}
                    alt={image.alt || 'image item'}
                    maxSize={250}
                />
            )}
            {!!title && <String className={'font-go-text font-bold text-go-primary'}>{title}</String>}
            {!!text && <String className={'font-go-text font-light'}>{text}</String>}
        </div>
    );
}

export interface BulletProps extends WithImage, WithTitle, WithText {}

export default Bullet;
