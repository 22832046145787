import useTenant from '@gotombola/react-contexts-type/lib/hooks/useTenant';
import { WithAlt, WithType } from '../withs';
import StaticImg, { StaticImgProps } from './StaticImg';

export function StaticIcon({ alt, type, format = 'svg', ...props }: StaticIconProps) {
    const tenant = useTenant();

    return <StaticImg src={`tenants/${tenant}/images/icons/${type}.${format}`} alt={alt || type} {...props} />;
}

export interface StaticIconProps extends WithAlt, Omit<StaticImgProps, 'src' | 'alt'>, Required<WithType> {
    format?: string;
}

export default StaticIcon;
