import CampaignFooter from '../../../molecules/footers/CampaignFooter';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardFooterCampaignModule({ campaign, ...rest }: StandardFooterCampaignModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <CampaignFooter {...props} campaign={campaign} />
        </ModuleContainer>
    );
}

export interface StandardFooterCampaignModuleProps {
    campaign: any;
}

export default StandardFooterCampaignModule;
