import { useMemo } from 'react';

const defaultTag = 'primary';

export function useTypePropertyAsTaggedList(
    property: string,
    mapper: Function,
    type: string,
    doc: any,
    sort?: string,
    tag?: string,
) {
    return useMemo(() => {
        if (!doc || !doc[property] || !doc[property].items || !doc[property].items.length) return [];
        const xx = doc[property].items.map(mapper).filter((x: any) => {
            return (
                !!x &&
                (undefined === tag ? !x.tags || !x.tags?.length || x.tags?.includes(defaultTag) : x.tags?.includes(tag))
            );
        });
        sort && xx.sort((a, b) => (a[sort] > b[sort] ? 1 : a[sort] < b[sort] ? -1 : 0));
        return xx;
    }, [doc, property, type, sort, tag]);
}

// noinspection JSUnusedGlobalSymbols
export default useTypePropertyAsTaggedList;
