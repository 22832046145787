import { createContext } from 'react';
import { sponsoring_context_value } from '../types';
import createDefaultSponsoringContextValue from '../utils/createDefaultSponsoringContextValue';

export const SponsoringContext = createContext<sponsoring_context_value>(createDefaultSponsoringContextValue());
// noinspection JSUnusedGlobalSymbols
export const SponsoringContextProvider = SponsoringContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const SponsoringContextConsumer = SponsoringContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default SponsoringContext;
