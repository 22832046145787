import { useCallback, useMemo, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { storage, decoded_token, user_context_value, user } from '@gotombola/react-contexts/lib/types';

export function useBuiltUserContextValue({
    storage,
    prefix,
    forcedUser,
}: {
    storage?: storage;
    prefix?: string;
    forcedUser?: user;
}) {
    const storageKeyFactory = useCallback((k: string) => `${prefix}_${k}`, [prefix]);
    const fetchUserFromLocalStorage = useCallback(
        () => forcedUser || storage?.getItem(storageKeyFactory('user')),
        [forcedUser, storage, storageKeyFactory],
    );
    const [user, setUser] = useState<user | undefined>(forcedUser || fetchUserFromLocalStorage);

    const enrichedSetUser = useCallback(
        (user) => {
            storage?.setItem(storageKeyFactory('user'), user);
            setUser(user);
        },
        [storage, setUser],
    );

    const getCurrentTokens = useCallback(() => {
        const u = fetchUserFromLocalStorage();
        return u
            ? {
                  token: u.token,
                  refreshToken: u.refreshToken,
              }
            : {};
    }, [fetchUserFromLocalStorage]) as any;

    const refreshUser = useCallback(async () => {
        // @todo
    }, []) as any;

    const setCurrentTokens = useCallback(
        async (tokens: any) => {
            const u = (await jwtDecode(tokens.token)) as decoded_token;
            await enrichedSetUser({ ...u, token: tokens.token, refreshToken: tokens.refreshToken });
            await refreshUser();
        },
        [enrichedSetUser, refreshUser],
    ) as any;

    const onLogout = useCallback(async () => {
        await enrichedSetUser(undefined);
    }, [enrichedSetUser]) as any;

    const userContextProviderValue: any = useMemo<user_context_value>(
        () => ({
            user: fetchUserFromLocalStorage(),
            setUser: enrichedSetUser,
            getTokens: getCurrentTokens,
            setTokens: setCurrentTokens,
            logout: onLogout,
            refreshUser,
        }),
        [fetchUserFromLocalStorage, user, enrichedSetUser, setCurrentTokens, getCurrentTokens, onLogout, refreshUser],
    );

    /*
    const refreshTokens = useCallback(
        async (refreshToken: string) => {
            // @todo
            return {};
        },
        [],
    );
     */

    return userContextProviderValue;
}

// noinspection JSUnusedGlobalSymbols
export default useBuiltUserContextValue;
