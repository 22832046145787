import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import AlertAlertBannerModule from '../../../modules/alert-banners/AlertAlertBannerModule';
import { useMemo } from 'react';

export function StandardAlertBannerSellergroupModule({
    sellergroup,
    threshold = 10,
    ...rest
}: StandardAlertBannerSellergroupModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('sellergroup', sellergroup);
    const text = useMemo(() => {
        return sellergroup?.statPaidTickets >= threshold
            ? t(`sellergroup_alert_banner_text_tickets_sold${sellergroup?.main ? '_main' : ''}`, {
                  tickets: `**${sellergroup?.statPaidTickets}**`,
                  name: `**${sellergroup?.name}**`,
              })
            : t(`sellergroup_alert_banner_text_supported_by${sellergroup?.main ? '_main' : ''}`, {
                  name: `**${sellergroup?.name}**`,
              });
    }, [t, sellergroup?.name, sellergroup?.statPaidTickets, sellergroup?.main]);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <AlertAlertBannerModule {...props} text={text} color={'custom'} shape={'square'} textAlign={'center'} />
        </ModuleContainer>
    );
}

export interface StandardAlertBannerSellergroupModuleProps {
    sellergroup: any;
    game?: any;
    threshold?: number;
}

export default StandardAlertBannerSellergroupModule;
