import { WithClassName, WithText } from '../../withs';
import Text from '../../atoms/Text';

export function StandardParagraphModule({ className, text }: StandardParagraphModuleProps) {
    return (
        <Text light className={className}>
            {text || ''}
        </Text>
    );
}

export interface StandardParagraphModuleProps extends WithClassName, WithText {}

export default StandardParagraphModule;
