import TypeSponsors, { TypeSponsorsProps } from '../../../organisms/type/TypeSponsors';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { GameModuleProps } from '@gotombola/react-types/lib/types';
import LightSponsoringImage from '../../../atoms/LightSponsoringImage';

const properties = ['logoImage', 'mainImage'];

export function LightLogosSponsorsGameModule({ ...rest }: LightLogosSponsorsGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    if (!props.game?.sponsorings?.items?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeSponsors
                {...props}
                type={'game'}
                items={props.game?.sponsorings.items}
                property={properties}
                component={LightSponsoringImage}
                defaultMsVariant={'basic'}
            />
        </ModuleContainer>
    );
}

export interface LightLogosSponsorsGameModuleProps extends Omit<TypeSponsorsProps, 'type'>, GameModuleProps {}

export default LightLogosSponsorsGameModule;
