import loadable from '@loadable/component';
import { CommonModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./content/StandardContentCommonModule')),
};

export function ContentCommonModule(props: ContentCommonModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ContentCommonModuleProps = CommonModuleProps;

export default ContentCommonModule;
