import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    pdf: loadable(() => import('./terms/PdfTermsTypeModule')),
};

export function TermsTypeModule(props: TermsTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'pdf'} />;
}

export type TermsTypeModuleProps = TypeModuleProps;

export default TermsTypeModule;
