import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./servicePricing/StandardServicePricingPartnerModule')),
};

export function ServicePricingPartnerModule(props: ServicePricingPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ServicePricingPartnerModuleProps = PartnerModuleProps;

export default ServicePricingPartnerModule;
