import { WithItems, WithTitle } from '../withs';
import useTypeTranslation from '../hooks/useTypeTranslation';
import BoxIcon from '../atoms/BoxIcon';
import Items from '../atoms/Items';
import ModuleSection from './ModuleSection';

const defaultItems = [];

export function IconsBlock({ items = defaultItems, ...props }: IconsBlockProps) {
    const { t } = useTypeTranslation('game');

    return (
        <ModuleSection
            {...props}
            defaultTitle={t('game_social_networks_label')}
            className={'flex justify-center space-x-4'}
        >
            <Items items={items} component={BoxIcon} />
        </ModuleSection>
    );
}

export interface IconsBlockProps
    extends WithItems<{ url?: string; icon?: string; className?: string; flatIcon?: string }>,
        WithTitle {}

export default IconsBlock;
