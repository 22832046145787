import { dynamic_page_body_item_config } from '../types';
import { WithClassName, WithIndex, WithNopadding } from '../withs';

export function UnknownModule({ className }: UnknownModuleProps) {
    return <div className={className}>UNKNOWN MODULE</div>;
}

export interface UnknownModuleProps extends WithClassName, WithIndex<any>, WithNopadding {
    config: dynamic_page_body_item_config;
}

export default UnknownModule;
