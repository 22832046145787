import { WithText } from '../withs';
import String from '../atoms/String';
import StaticIcon from '../atoms/StaticIcon';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import clsx from 'clsx';

export function ListItem({ text }: ListItemProps) {
    const { blockShapeClass } = useStyling();

    return (
        <div
            className={clsx(
                'flex flex-col sm:flex-row items-center border p-4 space-y-4 sm:space-x-4 sm:space-y-0',
                blockShapeClass,
            )}
        >
            <div className={'flex items-center'}>
                <StaticIcon type={'check'} />
            </div>
            <div className={'flex items-center text-center sm:text-left'}>
                {!!text && <String className={'font-go-text text-xl font-light'}>{text}</String>}
            </div>
        </div>
    );
}

export type ListItemProps = WithText;

// noinspection JSUnusedGlobalSymbols
export default ListItem;
