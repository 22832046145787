import GameBeneficiaries from '../../../organisms/GameBeneficiaries';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardBeneficiariesGameModule({ game, ...rest }: StandardBeneficiariesGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    if (game?.beneficiaries?.items?.length !== 1) return null;

    return (
        <ModuleContainer {...zprops}>
            <GameBeneficiaries {...props} game={game} />
        </ModuleContainer>
    );
}

export interface StandardBeneficiariesGameModuleProps {
    game: any;
}

export default StandardBeneficiariesGameModule;
