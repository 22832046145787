import loadable from '@loadable/component';
import { SponsorModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./header/StandardHeaderSponsorModule')),
};

export function HeaderSponsorModule(props: HeaderSponsorModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type HeaderSponsorModuleProps = SponsorModuleProps;

export default HeaderSponsorModule;
