import GalleryBadges, { GalleryBadgesProps } from '../../../organisms/GalleryBadges';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any) => !!doc?.badges?.items?.length;

export function StandardBadgesTypeModule({ typeType, ...rest }: StandardBadgesTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    if (!doc) return null;

    return (
        <ModuleContainer {...zprops}>
            <GalleryBadges size={'2xl'} {...props} items={doc.badges.items} />
        </ModuleContainer>
    );
}

export interface StandardBadgesTypeModuleProps extends Omit<GalleryBadgesProps, 'items'> {
    doc: any;
    typeType: string;
}

export default StandardBadgesTypeModule;
