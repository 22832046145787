import { useMemo } from 'react';
import useGameLabels from '@gotombola/react-game/lib/hooks/useGameLabels';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import ListLabelItem from '../../../molecules/labels/ListLabelItem';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypeListing, { TypeListingProps } from '../../../organisms/type/TypeListing';

const listProps = {
    defaultMsVariant: 'basic',
};

export function ListLabelsGameModule({ title: forcedTitle, ...rest }: ListLabelsGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', props.game);

    const title = useMemo(() => forcedTitle || t('game_labels_label'), [forcedTitle, t]);
    const labels = useGameLabels(props.game);
    if (!labels?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeListing
                title={title}
                {...props}
                doc={props.game}
                type={'game'}
                items={labels}
                itemType={'label'}
                itemComponent={ListLabelItem}
                listProps={listProps}
            />
        </ModuleContainer>
    );
}

export interface ListLabelsGameModuleProps extends Omit<TypeListingProps, 'type'> {
    game?: any;
}

export default ListLabelsGameModule;
