import clsx from 'clsx';
import { prize } from '../types';
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { WithClassName } from '../withs';
import PrizeItem from './PrizeItem';

const defaultItems: any[] = [];

export function PrizeList({ className, items = defaultItems, onDragEnd, removeItem, setItems }: PrizesListProps) {
    return (
        <DragDropContext onDragEnd={onDragEnd as (result: DropResult, provided: ResponderProvided) => void}>
            <Droppable droppableId={'prize'}>
                {(provided: any) => (
                    <div
                        className={clsx('flex flex-col space-y-4 pt-6', className)}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {items.map((item, index) => {
                            const setItem = (editedItem: any) => {
                                const newItems = items.map((item, i) => {
                                    if (i !== index) {
                                        return item;
                                    } else {
                                        return editedItem;
                                    }
                                });
                                setItems(newItems);
                            };
                            const removeSpecificItem = () => {
                                removeItem(index);
                            };
                            return (
                                <Draggable
                                    key={`prize-${item.id || index}`}
                                    draggableId={`prize-${item.id || index}`}
                                    index={index}
                                >
                                    {(provided: any) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps}>
                                            <PrizeItem
                                                {...item}
                                                index={index}
                                                setItem={setItem}
                                                removeItem={removeSpecificItem}
                                                {...provided.dragHandleProps}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export interface PrizesListProps extends WithClassName {
    items: prize[];
    setItems: Function;
    removeItem: Function;
    onDragEnd: Function;
}

export default PrizeList;
