import { edit_mode_context_value } from '../types';

export function createDefaultEditModeContextValue(): edit_mode_context_value {
    return {
        view: 'display',
        enabled: false,
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        register: () => ({}),
        watch: () => undefined,
        control: () => undefined,
    };
}

export default createDefaultEditModeContextValue;
