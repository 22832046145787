import DynamicPageBodyItems, { DynamicPageBodyItemsProps } from '../../../organisms/DynamicPageBodyItems';
import ModuleContainer from '../../../organisms/ModuleContainer';
import { WithZoneCustomization } from '../../../withs';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardDynamicPageBodyCommonModule({ ...rest }: StandardDynamicPageBodyCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <DynamicPageBodyItems {...props} />
        </ModuleContainer>
    );
}

export interface StandardDynamicPageBodyCommonModuleProps extends DynamicPageBodyItemsProps, WithZoneCustomization {}

export default StandardDynamicPageBodyCommonModule;
