import StaticIcon, { StaticIconProps } from './StaticIcon';
import { WithUrl } from '@gotombola/react-types/lib/withs';

export function SocialSmallIcon({ type, url, ...props }: SocialSmallIconProps) {
    const content = <StaticIcon type={`socials/${type}`} {...props} />;

    if (url)
        return (
            <a href={url} target={'_blank'} rel='noreferrer'>
                {content}
            </a>
        );

    return content;
}

export interface SocialSmallIconProps extends StaticIconProps, WithUrl {}

// noinspection JSUnusedGlobalSymbols
export default SocialSmallIcon;
