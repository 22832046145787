import clsx from 'clsx';
import { ModuleProps } from '../../types';
import { WithItems } from '../../withs';
import GalleryItem from '../../atoms/GalleryItem';

const sizes = {
    xs: 'grid-cols-1',
    sm: 'grid-cols-1',
    md: 'md:grid-cols-2 grid-cols-1 gap-2 ',
    lg: 'md:grid-cols-3 grid-cols-1 gap-2 ',
    xl: 'md:grid-cols-4 grid-cols-1 gap-2 ',
    '2xl': 'md:grid-cols-6 grid-cols-1 gap-2 ',
};

export function GalleryItemsModule({ items = [], size = 'md', ...props }: GalleryItemsModuleProps) {
    return (
        <div className={clsx('grid max-w-full', sizes[size || ''] || sizes['md'])}>
            {(items || []).map((item: any, index: number) => (
                <GalleryItem {...item} key={index} {...props} />
            ))}
        </div>
    );
}

export interface GalleryItemsModuleProps extends ModuleProps, WithItems {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    contentClassName?: string;
}

export default GalleryItemsModule;
