import GenericLocaleSwitcher, { GenericLocaleSwitcherProps } from './GenericLocaleSwitcher';

export function OrganizationLocaleSwitcher({ organization, ...props }: OrganizationLocaleSwitcherProps) {
    return <GenericLocaleSwitcher item={organization} versionKey={'organizationVersions'} {...props} />;
}

export interface OrganizationLocaleSwitcherProps extends Omit<GenericLocaleSwitcherProps, 'item' | 'versionKey'> {
    organization: any;
}

export default OrganizationLocaleSwitcher;
