import { WithTitle } from '@gotombola/react-types';
import Ranking from '../../../organisms/type/Ranking';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import { useCallback, useMemo } from 'react';
import censureContent from '@gotombola/censure';
import useQueryString from '../../../hooks/useQueryString';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import ModuleContainer from '../../../organisms/ModuleContainer';

export function StandardGodfathersRankingGameModule({
    game,
    period,
    variable = 'paidTickets',
    ...rest
}: StandardGodfathersRankingGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const qs = useQueryString();
    const property = useMemo(() => {
        const suffix = variable.slice(0, 1).toUpperCase() + variable.slice(1);
        if ('current' === period) return `statCurrentPeriod${suffix}`;
        if ('previous' === period) return `statPreviousPeriod${suffix}`;
        return;
    }, [period, variable]);
    const computeTitle = useCallback(
        (x: any) => {
            if (!!x?.lastName || !!x?.firstName)
                return `${x?.lastName || ''}${!!x?.lastName && !!x?.firstName ? ' ' : ''}${x?.firstName || ''}`;
            return censureContent(x?.email || x?.phone, x?.email ? 'email' : 'phone', true);
        },
        [censureContent],
    );

    if (!game.godfathersRankingsEnabled && !qs.has('f:godfathersrankings')) return null;

    return (
        <ModuleContainer {...zprops}>
            <Ranking
                title={t('godfathers_ranking_game_title')}
                items={game?.amountRankedGodfathers?.items}
                property={property}
                computeTitle={computeTitle}
                game={game}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface StandardGodfathersRankingGameModuleProps extends WithTitle {
    game: any;
    typeType: string;
    defaultFilter?: string;
    unit?: string;
    defaultSort?: string;
    period?: string;
    variable?: string;
}

export default StandardGodfathersRankingGameModule;
