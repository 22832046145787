import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import SponsorTemplate, { SponsorTemplateProps } from './SponsorTemplate';

export function DynamicSponsorPublicTemplate(props: DynamicSponsorPublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <SponsorTemplate {...props} sponsor={context.doc} />;
}

export type DynamicSponsorPublicTemplateProps = Omit<SponsorTemplateProps, 'sponsor'>;

export default DynamicSponsorPublicTemplate;
