import ModuleContainer from '../../organisms/ModuleContainer';
import PushModule from '../../modules/PushModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function PushCommonModule({ config, ...rest }: PushCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <PushModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface PushCommonModuleProps {
    config: any;
}

export default PushCommonModule;
