import GalleryItemsModule from '../modules/items/GalleryItemsModule';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { useMemo } from 'react';
import CenteredHeadingModule from '../modules/headings/CenteredHeadingModule';
import { WithTitle } from '../withs';

export function PartnerFeatures({ title }: PartnerFeaturesProps) {
    const { t } = useTrans();
    const items = useMemo(
        () => [
            {
                icon: 'laptop',
                title: t('partner_features_minisite_title'),
                text: t('partner_features_minisite_text'),
            },
            {
                icon: 'qrcode',
                title: t('partner_features_qrcode_title'),
                text: t('partner_features_qrcode_text'),
            },
            {
                icon: 'phone-with-hand',
                title: t('partner_features_draw_title'),
                text: t('partner_features_draw_text'),
            },
            {
                icon: 'ticket',
                title: t('partner_features_ticket_title'),
                text: t('partner_features_ticket_text'),
            },
            {
                icon: 'chart-grow',
                title: t('partner_features_sale_title'),
                text: t('partner_features_sale_text'),
            },
            {
                icon: 'opposite-arrows',
                title: t('partner_features_payment_title'),
                text: t('partner_features_payment_text'),
            },
        ],
        [t],
    );

    return (
        <div className={'flex flex-col justify-between items-center'}>
            <CenteredHeadingModule title={title || t('partner_features_title')} />
            <GalleryItemsModule items={items} size={'lg'} className={'m-4'} contentClassName={'text-center gap-y-6'} />
            {/* <Button label={t('partner_features_btn_label')} /> */}
        </div>
    );
}

export type PartnerFeaturesProps = WithTitle;

// noinspection JSUnusedGlobalSymbols
export default PartnerFeatures;
