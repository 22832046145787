function prefixifyRouteMap(a: any, { path }: { path: string }) {
    return (
        Object.entries(a).reduce((acc, [k, v]: [string, any]) => {
            acc[k] = Object.entries(v).reduce((acc2, [kk, vv]) => {
                acc2[kk] = `${path === '/' ? '' : path}${vv}`;
                return acc2;
            }, {});
            return acc;
        }, {}) || {}
    );
}
function prefixifyRoutes(a: any[], { path }: { path: string }) {
    const prefix = `${path === '/' ? '' : path}`;
    return (
        a.reduce((acc, x) => {
            let aa: any;
            if ('string' === typeof x) aa = x;
            else if (!x.path) aa = { ...x };
            else aa = { ...x, path: `${prefix}${x.path}` };
            if ('string' !== typeof aa && aa && aa.redirect && 'string' === typeof aa.redirect)
                aa.redirect = `${prefix}${aa.redirect}`;
            acc.push(aa);
            return acc;
        }, [] as any[]) || []
    );
}

export function createRoutesBuilder(x: any[]) {
    const { prepare, configure, build, find, ssr } = (x || []).reduce(
        (acc: any, { mod, ...m }: any) => {
            const y = mod.routes;
            y.prepare && acc['prepare'].push([y.prepare, m]);
            y.configure && acc['configure'].push([y.configure, m]);
            y.build && acc['build'].push([y.build, m]);
            y.find && acc['find'].push([y.find, m]);
            y.ssr && acc['ssr'].push([y.ssr, m]);
            return acc;
        },
        { prepare: [], configure: [], build: [], find: [], ssr: [] } as any,
    );
    return {
        prepare: prepare.length
            ? async ({ req, res, data }: any, options: any, mode: any) => {
                  return (
                      prepare.reduce(async (acc: any, [item]: [any, any]) => {
                          const a = await acc;
                          return item({ req, res, data: a }, options, mode);
                      }, data) || data
                  );
              }
            : undefined,
        configure: configure.length
            ? (data: any, options: any, mode: any) => {
                  return configure.reduce(
                      (acc: any, [item, m]: [any, any]) => {
                          const z = item(data, options, mode);
                          return {
                              routeMap: { ...(acc.routeMap || {}), ...prefixifyRouteMap(z.routeMap || {}, m) },
                              routeList: [...(acc.routeList || []), ...prefixifyRoutes(z.routeList || [], m)],
                              extraRouteList: [
                                  ...(acc.extraRouteList || []),
                                  ...prefixifyRoutes(z.extraRouteList || [], m),
                              ],
                              pages: { ...(acc.pages || {}), ...(z.pages || {}) },
                          };
                      },
                      { routeMap: {}, routeList: [], extraRouteList: [], pages: {} } as any,
                  );
              }
            : undefined,
        build: build.length
            ? (ctx: any, options: any, mode: any) => {
                  return build.reduce((acc: any, [item, m]: [any, any]) => {
                      return [...acc, ...prefixifyRoutes(item(ctx, options, mode) || [], m)];
                  }, [] as any[]);
              }
            : undefined,
        find: find.length
            ? async ({ req, res, routes }: any, options: any, mode: any) => {
                  return (
                      find.reduce(async (acc: any, [item]: [any, any]) => {
                          const a = await acc;
                          if (a) return a;
                          return item({ req, res, routes }, options, mode);
                      }, undefined as any) || undefined
                  );
              }
            : undefined,
        ssr: ssr.length
            ? async (x: any, options: any, mode: any) => {
                  return (
                      ssr.reduce(async (acc: any, [item]: [any]) => {
                          const a = await acc;
                          return item({ ...x, ssp: { ...x.ssp, ...a } }, options, mode);
                      }, {} as any) || x.ssp
                  );
              }
            : undefined,
    };
}

// noinspection JSUnusedGlobalSymbols
export default createRoutesBuilder;
