import {
    WithChecked,
    WithClassName,
    WithDefaultValue,
    WithDisabled,
    WithError,
    WithName,
    WithOnChange,
    WithRequired,
    WithTitle,
} from '../../withs';
import { useCallback, useState } from 'react';
import { class_name } from '@gotombola/types/lib/types';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Text from '../Text';

export function CheckboxField({
    className,
    title,
    name,
    onChange,
    inputClassName,
    labelClassName,
    defaultValue,
    required = false,
    nolabel = false,
    nohelper = false,
    noplaceholder = false,
    ...props
}: CheckboxFieldProps) {
    const [value, setValue] = useState<boolean>(!!defaultValue);
    const { blockShapeClass } = useStyling();
    const handleChange = useCallback(
        (e: any) => {
            setValue(!value);
            onChange?.({ target: { value: !value, name } });
        },
        [onChange, name, value],
    );
    const handleClick = useCallback(
        (e: any) => {
            setValue(!value);
            onChange?.({ target: { value: !value, name } });
        },
        [setValue, value, name, onChange],
    );
    return (
        <div
            className={clsx(
                'flex items-center space-x-2 w-full py-2 px-3 cursor-pointer border hover:bg-go-choice-primary hover:border-go-choice-secondary',
                required ? 'border-gray-500' : 'border-gray-300',
                blockShapeClass,
                className,
            )}
            onClick={handleClick}
        >
            <input
                type={'checkbox'}
                className={clsx('w-10 h-10 cursor-pointer mr-1', inputClassName, blockShapeClass)}
                name={name}
                checked={value}
                onChange={handleChange}
                required={required}
                {...props}
            />
            <Text light className={clsx('flex-1 cursor-pointer text-left', labelClassName)}>
                {title}
            </Text>
        </div>
    );
}

export interface CheckboxFieldProps
    extends WithChecked,
        WithClassName,
        WithDisabled,
        WithError,
        WithName,
        WithDefaultValue,
        WithOnChange,
        WithRequired,
        WithTitle {
    inputClassName?: class_name;
    labelClassName?: class_name;
    nolabel?: boolean;
    nohelper?: boolean;
    noplaceholder?: boolean;
}

// noinspection JSUnusedGlobalSymbols
export default CheckboxField;
