import loadable from '@loadable/component';
import { ModuleProps } from '../types';
import ModuleMode from '../organisms/ModuleMode';

const modes = {
    page: loadable(() => import('./headers/PageHeaderModule')),
};

export function HeaderModule(props: HeaderModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'page'} />;
}

export type HeaderModuleProps = ModuleProps;

export default HeaderModule;
