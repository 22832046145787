import { useMemo } from 'react';
import usePublicPageDefinition from './usePublicPageDefinition';

export function useDynamicPublicPage(
    doc?: any,
    type?: string,
    defs?: any,
    extra?: any,
    defaults: any = {},
    layout?: string,
) {
    const config = usePublicPageDefinition(
        doc?.publicPageDefinition,
        type || 'page',
        doc?.code,
        defs || {},
        defaults,
        doc,
    );
    const context = useMemo(() => {
        let d = config.doc ? { ...config.doc, ...(doc || {}) } : doc || {};
        d = config.forcedDoc || (config.docOverride ? { ...d, ...config.docOverride } : d);
        return { doc: d, type, page: `${(type || '').replace(/_page$/, '')}`, ...(layout ? { layout } : {}), ...extra };
    }, [doc, config, type, extra, layout]);
    return useMemo(() => ({ config, context }), [config, context]);
}

// noinspection JSUnusedGlobalSymbols
export default useDynamicPublicPage;
