import loadable from '@loadable/component';
import { SellergroupModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./mobileCallToAction/StandardMobileCallToActionSellergroupModule')),
};

export function MobileCallToActionSellergroupModule(props: MobileCallToActionSellergroupModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type MobileCallToActionSellergroupModuleProps = SellergroupModuleProps;

export default MobileCallToActionSellergroupModule;
