import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./featuredBunches/StandardFeaturedBunchesGameModule')),
};

export function FeaturedBunchesGameModule(props: FeaturedBunchesGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type FeaturedBunchesGameModuleProps = GameModuleProps;

export default FeaturedBunchesGameModule;
