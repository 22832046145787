import { image } from '../types';
import { WithAlt, WithClassName, WithLazy, WithNativeOnClick, WithUrl } from '../withs';
import useImageUrls from '../hooks/useImageUrls';

export function Img({ alt, className, lazy = true, onClick, onError, ...props }: ImgProps) {
    const { url, mobileUrl, tabletUrl } = useImageUrls(props);

    if (!url) return null;

    const loadingProps: { loading?: 'lazy' | 'eager' } = lazy ? { loading: 'lazy' } : {};

    if (((!!mobileUrl || !!tabletUrl) && mobileUrl !== url) || tabletUrl !== url) {
        return (
            <picture onClick={onClick}>
                {mobileUrl && <source media='(max-width: 600px)' srcSet={mobileUrl} />}
                {tabletUrl && <source media='(max-width: 960px)' srcSet={tabletUrl} />}
                <img alt={alt} src={url} className={className} {...loadingProps} onError={onError as any} />
            </picture>
        );
    }

    return (
        <img
            alt={alt}
            src={url}
            className={className}
            onClick={onClick as any}
            {...loadingProps}
            onError={onError as any}
        />
    );
}

export interface ImgProps extends Required<WithAlt>, WithClassName, WithLazy, WithNativeOnClick, Required<WithUrl> {
    maxMode?: string;
    tablet?: image;
    mobile?: image;
    onError?: Function;
    maxSize?: number;
    maxSizeMobile?: number;
    maxSizeTablet?: number;
}

// noinspection JSUnusedGlobalSymbols
export default Img;
