import clsx from 'clsx';
import { WithChildren, WithClassName } from '@gotombola/react-types/lib/withs';
import { children } from '@gotombola/react-types/lib/types';

export function FlipCard({ className, children, backChildren }: FlipCardProps) {
    if (!children) children = backChildren;
    if (!backChildren) return children ? <>{children}</> : null;

    return (
        <div className={clsx('cursor-pointer group perspective', className)}>
            <div className={'relative preserve-3d group-hover:my-rotate-y-180 w-full h-full duration-1000'}>
                <div className={'absolute backface-hidden w-full h-full'}>{children}</div>
                <div className={'absolute my-rotate-y-180 backface-hidden w-full h-full overflow-hidden'}>
                    {backChildren}
                </div>
            </div>
        </div>
    );
}

export interface FlipCardProps extends WithClassName, WithChildren {
    backChildren?: children;
}

export default FlipCard;
