import useTypeUrl from '../../../hooks/useTypeUrl';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import TypeListing, { TypeListingProps } from '../../../organisms/type/TypeListing';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

const listProps = {
    defaultMsVariant: 'bordered',
};

export function ListBunchesGameModule({ ...rest }: ListBunchesGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', props.game);
    const isRunning = 'RUNNING' === props.game?.status;
    const buyUrl = useTypeUrl('game', props.game, 'buy');

    if (!props.game?.rankedBunches?.items?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeListing
                title={t(`game_prices_label`, { count: props.game?.rankedBunches.items.length })}
                {...props}
                doc={props.game}
                type={'game'}
                items={props.game?.rankedBunches.items}
                target={isRunning ? buyUrl : undefined}
                sponsorings={props.game?.sponsorings}
                listProps={listProps}
                itemType={'bunch'}
            />
        </ModuleContainer>
    );
}

export interface ListBunchesGameModuleProps extends Omit<TypeListingProps, 'type'> {
    game?: any;
}

export default ListBunchesGameModule;
