import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./files/StandardFilesGameModule')),
};

export function FilesGameModule(props: FilesGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type FilesGameModuleProps = GameModuleProps;

export default FilesGameModule;
