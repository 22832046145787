import TypeMiniSocialNetworks, { TypeMiniSocialNetworksProps } from '../../../organisms/type/TypeMiniSocialNetworks';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardMiniSocialNetworksTypeModule({ typeType, ...rest }: StandardMiniSocialNetworksTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <TypeMiniSocialNetworks {...props} type={typeType} />
        </ModuleContainer>
    );
}

export interface StandardMiniSocialNetworksTypeModuleProps extends Omit<TypeMiniSocialNetworksProps, 'type'> {
    typeType: string;
}

export default StandardMiniSocialNetworksTypeModule;
