import clsx from 'clsx';
import { WithChildren, WithClassName } from '../withs';

export function FooterModuleGroup({ children, className }: FooterModuleGroupProps) {
    return (
        <footer id={'footer'} className={clsx('w-full', className)}>
            {children || ''}
        </footer>
    );
}

export interface FooterModuleGroupProps extends WithChildren, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default FooterModuleGroup;
