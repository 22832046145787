import { useMemo } from 'react';
import {
    WithAutoFocus,
    WithDefaultValue,
    WithDisabled,
    WithError,
    WithName,
    WithNativeOnChange,
    WithRequired,
    WithTitle,
} from '../../withs';
import TextField from './TextField';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import Text from '../Text';

export function EmailField({ defaultValue, ...props }: EmailFieldProps) {
    const { t } = useTrans();
    const isEmailValid = useMemo(() => !!defaultValue && /^.+@.+\..+$/.test(defaultValue), [defaultValue]);

    return (
        <div>
            <TextField {...props} defaultValue={defaultValue} type={'email'} pattern={'.+@...+'} />
            {!!defaultValue && !isEmailValid && (
                <Text className={'text-red-500'}>{t('field_email_invalid_error')}</Text>
            )}
        </div>
    );
}

export interface EmailFieldProps
    extends WithAutoFocus,
        WithDefaultValue<string>,
        WithDisabled,
        WithError,
        WithName,
        WithNativeOnChange,
        WithRequired,
        WithTitle {}

export default EmailField;
