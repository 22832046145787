import { useCallback, useState } from 'react';
import clsx from 'clsx';
import Modal2 from '../molecules/Modal2';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import StaticIcon from '../atoms/StaticIcon';
import { camelcase } from '@ohoareau/string';

const ModuleDataField = (props: ModuleDataFieldProps) => {
    const { name, onChange, value, content, optionUnmarshall } = props;
    const [opened, setOpened] = useState<boolean>(false);
    const { t } = useTrans();

    const enabled = undefined === value ? true : Boolean(value);

    const handleToggle = useCallback(
        (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            onChange && onChange({ target: { value: !enabled } });
        },
        [onChange, enabled],
    );

    const handleClickToggleConfig = useCallback(
        (e: any) => {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            setOpened(!opened);
        },
        [setOpened, opened],
    );

    const handleClickConfirm = useCallback(
        (v: any) => (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            onChange &&
                onChange({
                    target: {
                        value: optionUnmarshall(
                            v,
                            !(value.find((x: any) => x.value === v) || { enabled: false, value: v }).enabled,
                            value,
                        ),
                    },
                });
            setOpened(false);
        },
        [onChange, optionUnmarshall, value, setOpened],
    );

    const hasSettings = !!content;

    return (
        <>
            <div className={'flex flex-wrap gap-2'}>
                <div className='flex w-full'>
                    <div
                        className={clsx(
                            'font-normal text-md px-4 py-2 flex items-center w-full hover:bg-go-secondary cursor-pointer',
                            hasSettings && 'rounded-l-sm',
                            !hasSettings && 'rounded-sm',
                            enabled ? 'bg-go-primary text-white' : 'line-through bg-gray-100',
                        )}
                        onClick={handleToggle}
                    >
                        {t([`module_${name}_label`, camelcase(name)])}
                    </div>
                    {hasSettings && (
                        <div
                            className={clsx(
                                'font-normal text-white text-md px-4 py-2 flex items-center w-fit rounded-r-sm bg-gray-300 hover:bg-go-secondary cursor-pointer border-l-0',
                            )}
                            onClick={(e) => handleClickToggleConfig(e)}
                        >
                            <StaticIcon type={'sliders-solid'} className={'w-6'} />
                        </div>
                    )}
                </div>
            </div>
            <Modal2
                opened={opened}
                onClose={handleClickToggleConfig}
                title={''}
                buttonLabel={'Enregistrer'}
                buttonTarget={handleClickConfirm}
            >
                @todo
            </Modal2>
        </>
    );
};

type ModuleDataFieldProps = {
    name: string;
    onChange: Function;
    value: any;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    optionUnmarshall: Function;
    content?: any;
    allData?: any;
    register: Function;
    control: Function;
};

export default ModuleDataField;
