import clsx from 'clsx';
import { WithChildren, WithClassName } from '../withs';

export function SectionModuleGroup({ children, className }: SectionModuleGroupProps) {
    return <section className={clsx('w-full', className)}>{children || ''}</section>;
}

export interface SectionModuleGroupProps extends WithChildren, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default SectionModuleGroup;
