import DoneeFooter from '../../../molecules/footers/DoneeFooter';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardFooterDoneeModule({ donee, ...rest }: StandardFooterDoneeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <DoneeFooter {...props} donee={donee} />
        </ModuleContainer>
    );
}

export interface StandardFooterDoneeModuleProps {
    donee: any;
}

export default StandardFooterDoneeModule;
