import { WithDefaultValue, WithLabel, WithName, WithRequired, WithType } from '../withs';
import TextField from '../atoms/fields/TextField';
import SelectField from '../atoms/fields/SelectField';
import TextareaField from '../atoms/fields/TextareaField';
import DateField from '../atoms/fields/DateField';
import CheckboxField from '../atoms/fields/CheckboxField';
import NumberField from '../atoms/fields/NumberField';
import FloatField from '../atoms/fields/FloatField';
import EmailField from '../atoms/fields/EmailField';
import AmountField from '../atoms/fields/AmountField';

const customComponent = {
    text: TextField,
    textarea: TextareaField,
    choice: SelectField,
    date: DateField,
    flag: CheckboxField,
    number: NumberField,
    float: FloatField,
    email: EmailField,
    amount: AmountField,
};

export function DynamicCustomChildren({ type, ...props }: DynamicCustomChildrenProps) {
    const Component = customComponent[type];

    return <Component title={props.label || props.name || ''} required className={'w-full'} {...props} />;
}

export interface DynamicCustomChildrenProps
    extends WithDefaultValue<string>,
        WithName,
        WithLabel,
        WithRequired,
        Required<WithType> {}

// noinspection JSUnusedGlobalSymbols
export default DynamicCustomChildren;
