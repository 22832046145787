import ModuleContainer from '../../../organisms/ModuleContainer';
import { WithZoneCustomization } from '../../../withs';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useModuleContext from '@gotombola/react-modules-engine/lib/hooks/useModuleContext';

export function StandardUnknownCommonModule(rest: StandardUnknownCommonModuleProps) {
    const [zprops] = useZoneCustomizationProps(rest);

    const m = useModuleContext();

    return (
        <ModuleContainer {...zprops}>
            <div className={'text-red-600 h-20 flex items-center text-center justify-center'}>
                <div>
                    UNKNOWN TEMPLATE MODULE <span className={'font-bold'}>{m.type}</span> at position #{m.index}
                </div>
            </div>
        </ModuleContainer>
    );
}

export type StandardUnknownCommonModuleProps = WithZoneCustomization;

export default StandardUnknownCommonModule;
