import loadable from '@loadable/component';
import { SellerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./banner/StandardBannerSellerModule')),
};

export function BannerSellerModule(props: BannerSellerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type BannerSellerModuleProps = SellerModuleProps;

export default BannerSellerModule;
