import { WithClassName, WithLogo, WithUrl } from '../withs';
import Img from '../atoms/Img';
import BaseFooter from './BaseFooter';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import clsx from 'clsx';
import Text from '../atoms/Text';
import { children } from '@gotombola/react-types';
import { useMemo } from 'react';
import LightSocialNetworks from './LightSocialNetworks';
import shortenurl from '@genstackio/shortenurl';

const mapper: Record<string, string> = {
    twitter: 'x',
};

export function LightFooter({ className, logo, url, socialNetworks, adChildren }: LightFooterProps) {
    const { logoShapeClass } = useStyling();
    const socialLinks = useMemo(() => {
        if (!socialNetworks) return [];
        return Object.keys(socialNetworks).map((k) => {
            const n = k.slice(0, -3);
            return {
                url: socialNetworks[k],
                type: `${mapper[n || ''] || n}-white`,
            };
        });
    }, [socialNetworks]);
    const shortUrl = useMemo(() => !!url && shortenurl(url), [shortenurl, url]);
    const secondaryChildren = (
        <div
            className={
                'flex flex-col gap-2 text-center break-words leading-relaxed justify-center items-center text-white text-xl'
            }
        >
            {!!socialNetworks && <LightSocialNetworks items={socialLinks} />}
            {!!url && (
                <a href={url} target={'_blank'} rel='noreferrer'>
                    <Text basic className={'text-2xl text-inherit'}>
                        {(shortUrl as string) || url}
                    </Text>
                </a>
            )}
        </div>
    );

    return (
        <BaseFooter className={className} secondaryChildren={secondaryChildren} divider={false}>
            <div className={'flex items-center justify-center'}>
                {!!logo && (
                    <Img
                        className={clsx('sm:block max-w-xs p-2', logoShapeClass)}
                        url={logo.url}
                        alt={logo.alt || 'logo'}
                        maxSize={200}
                    />
                )}
                <div className={'flex flex-col items-center sm:items-start gap-y-2'}>{!!adChildren && adChildren}</div>
            </div>
        </BaseFooter>
    );
}

export interface LightFooterProps extends WithClassName, WithLogo, WithUrl {
    adChildren?: children;
    socialNetworks?: {
        facebookUrl?: string;
        twitterUrl?: string;
        youtubeUrl?: string;
        instagramUrl?: string;
        linkeidnUrl?: string;
        tiktokUrl?: string;
    };
}

// noinspection JSUnusedGlobalSymbols
export default LightFooter;
