import { WithLabel, WithValue } from '@gotombola/react-types';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import clsx from 'clsx';

export function CountDownItem({ label, value, last = false, light = false }: CountDownItemProps) {
    const { t } = useTrans();
    const finalLabel = light ? (label as string)?.slice(0, 1) : label;

    return (
        <>
            {last && (
                <div className={clsx(!light && 'text-go-nav-secondary text-2xl', 'mx-1 font-extralight')}>
                    {t('game_count_down_and')}
                </div>
            )}
            <div
                className={clsx(
                    !light
                        ? 'min-w-[75px] sm:min-w-[83px] max-w-32 p-2 bg-go-nav-secondary text-go-nav-primary rounded-lg'
                        : 'flex items-end',
                )}
            >
                <div className={clsx(!light && 'font-mono mb-px', 'leading-none')}>
                    {value > 9 ? value : `0${value}`}
                </div>
                <div className={clsx(!light ? 'font-mono' : 'mb-0.5', 'uppercase text-sm leading-none')}>
                    {finalLabel}
                </div>
            </div>
        </>
    );
}

export interface CountDownItemProps extends WithLabel, WithValue {
    last?: boolean;
    light?: boolean;
}

export default CountDownItem;
