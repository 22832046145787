import RowCellContent from './RowCellContent';

const defaultContent = [];

export function RowContent({ content = defaultContent, ...props }: RowContentProps) {
    const n = content?.length || 0;

    return (
        <div className={'flex flex-col lg:flex-row gap-4 sm:gap-10'}>
            {(content || []).map((content: any, index: number) => (
                <RowCellContent key={index} content={content} {...props} index={index} total={n} />
            ))}
        </div>
    );
}

export interface RowContentProps {
    content?: any[];
    index: number;
    total: number;
}

// noinspection JSUnusedGlobalSymbols
export default RowContent;
