import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(
        () => import('./serviceSaleProcessScreenshots/StandardServiceSaleProcessScreenshotsPartnerModule'),
    ),
};

export function ServiceSaleProcessScreenshotsPartnerModule(props: ServiceSaleProcessScreenshotsPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ServiceSaleProcessScreenshotsPartnerModuleProps = PartnerModuleProps;

export default ServiceSaleProcessScreenshotsPartnerModule;
