import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import GameTemplate, { GameTemplateProps } from './GameTemplate';

export function DynamicGamePublicTemplate(props: DynamicGamePublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <GameTemplate {...props} game={context.doc} />;
}

export type DynamicGamePublicTemplateProps = Omit<GameTemplateProps, 'game'>;

export default DynamicGamePublicTemplate;
