import GameGodfathers from '../../../organisms/GameGodfathers';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardGodfathersGameModule({ game, ...rest }: StandardGodfathersGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    if (!game?.godfatherTitle && !game?.godfather2Title && !game?.godfather3Title) return null;

    return (
        <ModuleContainer {...zprops}>
            <GameGodfathers {...props} game={game} />
        </ModuleContainer>
    );
}

export interface StandardGodfathersGameModuleProps {
    game: any;
}

export default StandardGodfathersGameModule;
