import { group_definition, group_zones } from '../types';
import { WithChildren } from '../withs';
import GroupInstances from '@gotombola/react-modules-engine/lib/organisms/GroupInstances';
import useGroupZones from '@gotombola/react-modules-engine/lib/hooks/useGroupZones';

const defaultTemplateModules = {};
const defaultModuleProps = {};

const zones = {
    main: 'main',
    aside_left: 'aside',
    aside_right: 'aside',
    aside: 'aside',
};

const zoneFn = (g: string, s: group_zones) => (s.main?.length || s.aside?.length ? 'afters' : 'befores');

export function TwoColumnTemplateLayout({
    children,
    columnPosition = 'left',
    groups,
    moduleProps = defaultModuleProps,
    templateModules = defaultTemplateModules,
}: TwoColumnTemplateLayoutProps) {
    const { befores, main, aside, afters } = useGroupZones(groups, zones, zoneFn);

    return (
        <div className={'w-full'}>
            <GroupInstances items={befores} templateModules={templateModules} moduleProps={moduleProps} />
            <div className={'w-full sm:flex justify-between gap-4 sm:gap-6 md:gap-10 lg:gap-14'}>
                {'left' === columnPosition && (
                    <div className={'w-full sm:w-1/3'}>
                        <GroupInstances
                            items={aside}
                            templateModules={templateModules}
                            moduleProps={moduleProps}
                            paddingMode={'left'}
                        />
                    </div>
                )}
                <div className={'w-full sm:w-2/3'}>
                    <GroupInstances
                        items={main}
                        templateModules={templateModules}
                        moduleProps={moduleProps}
                        paddingMode={
                            'left' === columnPosition && aside.length
                                ? 'right'
                                : 'right' === columnPosition && aside.length
                                ? 'left'
                                : 'all'
                        }
                    />
                    {children || ''}
                </div>
                {'right' === columnPosition && (
                    <div className={'w-full sm:w-1/3'}>
                        <GroupInstances
                            items={aside}
                            templateModules={templateModules}
                            moduleProps={moduleProps}
                            paddingMode={'right'}
                        />
                    </div>
                )}
            </div>
            <GroupInstances items={afters} templateModules={templateModules} moduleProps={moduleProps} />
        </div>
    );
}

export interface TwoColumnTemplateLayoutProps extends WithChildren {
    namedGroups: Record<string, group_definition>;
    groups: group_definition[];
    templateModules?: any;
    moduleProps?: any;
    columnPosition?: 'left' | 'right';
}

export default TwoColumnTemplateLayout;
