import { useCallback } from 'react';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { dynamic_page_body_item_config } from '../../types';
import { WithClassName } from '../../withs';
import useDynamicPageContext from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import OnboardingForm from '../../molecules/OnboardingForm';
import OnboardingCongratulation from '../../molecules/OnboardingCongratulation';
import useQueryString from '../../hooks/useQueryString';
import useFrontendPostApiState from '../../hooks/useFrontendPostApiState';
import Loading from '../../molecules/Loading';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function OnboardingCustomModule({ className, ...rest }: OnboardingCustomModuleProps) {
    const [, props] = useZoneCustomizationProps(rest);
    const { t } = useTrans();
    const { setStep, context } = useDynamicPageContext();
    const campaignCode = context.campaignCode; // code campaign
    const offerCode = context.offerCode; // code campaign
    const qs = useQueryString();
    const partner = qs.get('a') || '';
    const externalId = qs.get('accountid') || qs.get('i') || '';
    const onSubmitted = useCallback(
        ({ error }: any) => {
            setStep(!!error ? 'failure' : 'success');
        },
        [setStep],
    );
    const onErrored = useCallback(() => {
        setStep('error');
    }, [setStep]);
    const { submit, loading, data, error, onFieldChange, state } = useFrontendPostApiState(
        'game/register',
        () => ({
            campaignCode,
            offerCode,
            organizationName: qs.get('structure') || qs.get('o') || '',
            organizationCity: qs.get('c') || '',
            organizationState: qs.get('s') || '',
            organizationZipCode: qs.get('z') || '',
            organizationPresentation: '',
            gamePresentation: '',
            gameName: qs.get('n') || '',
            externalId,
            ticketPriceAmount: qs.get('p') || '',
            ticketPriceCurrency: qs.get('pc') || context.ticketPriceCurrency || '',
            ownerEmail: qs.get('e') || '',
            ownerFirstName: qs.get('f') || '',
            ownerLastName: qs.get('l') || '',
            bunches: qs.get('x') || '',
            plannedDrawnAt: qs.get('d') || '',
            partner,
            organizationExternalReference: partner && externalId ? `${partner}:${externalId}` : '',
        }),
        () => true,
        onSubmitted,
        onErrored,
    );

    if (!error && data) return <OnboardingCongratulation className={className} data={data} />;
    if (loading) return <Loading className={className} title={t('onboarding_form_loading_title')} />;

    return (
        <OnboardingForm
            {...props}
            className={className}
            onFieldChange={onFieldChange}
            defaultValues={state}
            onSubmit={submit}
            error={error}
        />
    );
}

export interface OnboardingCustomModuleProps extends WithClassName {
    config: dynamic_page_body_item_config;
}

export default OnboardingCustomModule;
