import { WithItems, WithTitle } from '../withs';
import Items from '../atoms/Items';
import String from '../atoms/String';
import { useMemo } from 'react';
import BoxIcon from '../atoms/BoxIcon';

const defaultItems = [];

export function FlatIconsBlock({ title, items = defaultItems }: FlatIconsBlockProps) {
    const convertItems = useMemo(
        () =>
            items?.map((x: { url?: string; icon?: string; className?: string; flatIcon?: string }) => {
                return { url: x.url, icon: x.flatIcon };
            }),
        [items],
    );

    return (
        <div className={'flex justify-between items-center'}>
            {!!title && <String className={'font-go-text text-go-text font-semibold'}>{title}</String>}
            <div className={'flex space-x-4'}>
                <Items items={convertItems} component={BoxIcon} format={'svg'} flat />
            </div>
        </div>
    );
}

export interface FlatIconsBlockProps
    extends WithItems<{ url?: string; icon?: string; className?: string; flatIcon?: string }>,
        WithTitle {}

export default FlatIconsBlock;
