import useTypeTranslation from '../../../hooks/useTypeTranslation';
import ModuleContainer from '../../../organisms/ModuleContainer';
import { WithZoneCustomization } from '../../../withs';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import WelcomeModal from '../../../organisms/WelcomeModal';

export function StandardOwnerGameWelcomeGameModule({
    typeType,
    game,
    ...rest
}: StandardOwnerGameWelcomeGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <WelcomeModal
                size={'sm'}
                closable={false}
                title={t('owner_game_welcome_title')}
                text={t('owner_game_welcome_text')}
                buttonLabel={t('owner_game_welcome_cta')}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface StandardOwnerGameWelcomeGameModuleProps extends WithZoneCustomization {
    game: any;
    typeType: string;
}

export default StandardOwnerGameWelcomeGameModule;
