import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./serviceFeatures/StandardServiceFeaturesPartnerModule')),
};

export function ServiceFeaturesPartnerModule(props: ServiceFeaturesPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ServiceFeaturesPartnerModuleProps = PartnerModuleProps;

export default ServiceFeaturesPartnerModule;
