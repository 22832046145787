import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('../type/banner/StandardBannerTypeModule')),
    cta: loadable(() => import('../type/banner/CtaBannerTypeModule')),
};

export function BannerPartnerModule(props: BannerPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type BannerPartnerModuleProps = TypeModuleProps;

export default BannerPartnerModule;
