import { useMemo } from 'react';
import { WithClassName } from '../../withs';
import OrganizationLocaleSwitcher from '../OrganizationLocaleSwitcher';
import Header from '../../organisms/Header';
import Img from '../../atoms/Img';
import clsx from 'clsx';
import String from '../../atoms/String';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function OrganizationHeader({ className, light = false, locale, organization }: OrganizationHeaderProps) {
    const logoImage = !!organization.logoImage && !!organization.logoImage.url ? organization.logoImage : undefined;
    const localeVersions = useMemo(
        () => organization?.organizationVersions?.filter((x: any) => x.type === 'locale') || [],
        [organization],
    );
    const hasVersions = !!localeVersions && !!localeVersions.length;
    const { logoShapeClass } = useStyling();

    return (
        <Header className={className}>
            <div className={'flex w-full items-center justify-center'}>
                {!!logoImage && (
                    <Img
                        className={clsx('hidden md:block h-11 flex mr-4', logoShapeClass)}
                        url={logoImage.url}
                        alt={logoImage.alt || 'logo'}
                        maxSize={250}
                    />
                )}
                <div
                    className={clsx(
                        hasVersions ? 'ml-4 text-left sm:ml-0' : 'text-center',
                        'sm:text-left flex-1 py-4 font-bold text-xl truncate',
                    )}
                >
                    {!!organization.name && (
                        <span className={'font-go-text hidden lg:inline-block'}>
                            <String basic>{organization.name}</String>
                        </span>
                    )}
                </div>
            </div>
            {!!organization.code && (hasVersions || !light) && (
                <div className={'flex w-auto items-center justify-end space-x-2'}>
                    <div className={'hidden sm:flex sm:flex-1 items-center justify-end space-x-2'}></div>
                    {hasVersions && (
                        <div className={'w-auto pr-4 sm:pr-0'}>
                            <OrganizationLocaleSwitcher organization={organization} locale={locale} />
                        </div>
                    )}
                </div>
            )}
        </Header>
    );
}

export interface OrganizationHeaderProps extends WithClassName {
    organization?: any;
    light?: boolean;
    locale?: string;
}

// noinspection JSUnusedGlobalSymbols
export default OrganizationHeader;
