import { WithDescription, WithOverline, WithTitle } from '../withs';
import Img from '../atoms/Img';
import Avatar from './Avatar';
import String from '../atoms/String';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import clsx from 'clsx';
import ModuleSection from '../organisms/ModuleSection';

export function FeaturedSection({
    description,
    donation,
    mainImage,
    overline,
    sponsoring,
    title,
    titleStyling,
    website,
}: FeaturedSectionProps) {
    const { blockShapeClass, mediaShapeClass } = useStyling();

    return (
        <ModuleSection title={title} titleStyling={titleStyling}>
            <div className='container mx-auto flex px-5 lg:flex-row py-2 flex-col items-center'>
                <div className='w-full lg:pr-16 space-y-4 flex flex-col lg:items-start lg:text-left lg:mb-0 '>
                    <div className='font-go-text sm:text-3xl text-3xl mb-4 font-medium text-gray-900'>
                        <String basic>{overline}</String>
                    </div>
                    {!!description && (
                        <div className='font-go-text text-lg font-light'>
                            <String>{description}</String>
                        </div>
                    )}
                    {!!website && <Avatar logoImage={website.logoImage} websiteUrl={website.url} name={website.name} />}
                    {!!sponsoring && (
                        <Avatar
                            logoImage={sponsoring.logoImage}
                            websiteUrl={sponsoring.websiteUrl}
                            name={sponsoring.name}
                        />
                    )}
                    {!!donation && (
                        <div className={'font-go-text text-lg font-light'}>
                            <String className={'mb-2'}>{donation.label}</String>
                            <a
                                href={donation.url}
                                className={clsx(
                                    'font-go-button text-white text-lg p-2 cursor-pointer bg-go-primary hover:bg-go-secondary',
                                    blockShapeClass,
                                )}
                            >
                                <String basic>{donation.buttonLabel}</String>
                            </a>
                        </div>
                    )}
                </div>
                {!!mainImage && (
                    <div className='mt-4 lg:mt-0 lg:w-1/2 w-5/6 flex justify-center items-center'>
                        <Img
                            className={clsx('flex-shrink-0 object-contain', mediaShapeClass)}
                            url={mainImage.url}
                            alt={mainImage.alt || ('string' === typeof title ? title : undefined) || 'featured'}
                        />
                    </div>
                )}
            </div>
        </ModuleSection>
    );
}

export interface FeaturedSectionProps extends WithDescription, WithOverline, WithTitle {
    mainImage?: { url: string; alt: string };
    donation?: { url: string; label: string; buttonLabel: string };
    website?: { name?: string; url?: string; logoImage?: { url: string; alt: string } };
    sponsoring?: {
        websiteUrl?: string;
        description?: string;
        name?: string;
        logoImage?: { url: string; alt: string };
        mainImage?: { url: string; alt: string };
    };
    titleStyling?: string;
}

// noinspection JSUnusedGlobalSymbols
export default FeaturedSection;
