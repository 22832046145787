import requestItems from '@genstackio/request-items';
import { pack } from '../types';

export function buildSaleItemsFromRequested(requested: string, packs: pack[], ticketPrice: number) {
    requested = String(requested || '').trim();
    const map = requested
        .split(/\s*,\s*/g)
        .map((x) => {
            const [a, b = undefined] = x.split(/\s*=\s*/g);
            if (!a || !b) return undefined;
            return { type: a.trim(), quantity: Number(b || 0) };
        })
        .filter((x) => !!x && !!x.quantity)
        .reduce((acc, x: any) => {
            acc[x.type || ''] = acc[x.type || ''] || 0;
            acc[x.type || ''] += x.quantity || 0;
            return acc;
        }, {});

    const tickets = map['ticket'] || 0;
    const donation = map['donation'] || 0;

    delete map['ticket'];
    delete map['donation'];

    const products = (packs || []).map((x) => ({
        id: 'ticket' === x.code ? x.code : `pack:${x.code}`,
        units: x.tickets,
    }));
    const productMap = (packs || []).reduce(
        (acc, p) => Object.assign(acc, { ['ticket' === p.code ? p.code : `pack:${p.code}`]: p }),
        {},
    );

    const selectedItems = Object.values(
        Object.entries(map).reduce(
            (acc, x) => {
                if (acc[x[0]]) {
                    acc[x[0]].quantity = (acc[x[0]].quantity || 0) + (x[1] || 0);
                } else {
                    acc[x[0]] = { id: x[0], quantity: x[1] || 0 };
                }
                return acc;
            },
            requestItems(products, tickets).reduce((acc, p) => Object.assign(acc, { [p.id]: p }), {}),
        ),
    )
        .map((it: any) => {
            const p = productMap[it.id || ''];
            if (!p) {
                return undefined;
            }
            if ('ticket' === p.id)
                return {
                    id: 'ticket',
                    type: 'ticket',
                    quantity: it.quantity,
                    tickets: 1,
                    donation: 0,
                    amount: ticketPrice,
                };

            return {
                id: p.id,
                type: 'pack',
                quantity: it.quantity,
                tickets: p.tickets,
                donation: p.donation,
                amount: p.price,
            };
        })
        .filter((x) => !!x);

    return [
        ...(donation > 0 ? [{ id: 'donation', type: 'donation', quantity: 1, donation, amount: donation }] : []),
        ...selectedItems,
    ] as {
        id: string;
        quantity: number;
        donation?: number;
        tickets?: number;
        amount?: number;
    }[];
}

export default buildSaleItemsFromRequested;
