import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./contactOwner/StandardContactOwnerTypeModule')),
};

export function ContactOwnerTypeModule(props: ContactOwnerTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ContactOwnerTypeModuleProps = TypeModuleProps;

export default ContactOwnerTypeModule;
