import { useCallback } from 'react';
import clsx from 'clsx';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { WithClassName, WithError, WithOnSubmit } from '../withs';
import Error from './Error';
import TextField from '../atoms/fields/TextField';
import { trackContact } from '../services/tracking';
import NumberField from '../atoms/fields/NumberField';
import DateField from '../atoms/fields/DateField';
import PrizesField from '../atoms/fields/PrizesField';
import EmailField from '../atoms/fields/EmailField';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function OnboardingForm({
    className,
    defaultValues: data,
    error,
    onFieldChange,
    onSubmit,
}: OnboardingFormProps) {
    const { t } = useTrans();
    const handleBunchesChange = useCallback(
        (prizes: any) => onFieldChange('bunches')({ target: { value: prizes } }),
        [onFieldChange],
    );
    const isEmailValid = !!data.ownerEmail && /^.+@.+\..+$/.test(data.ownerEmail);
    const isButtonDisabled =
        !data.organizationName ||
        !data.gameName ||
        !data.ticketPriceAmount ||
        !data.ownerFirstName ||
        !data.ownerLastName ||
        !isEmailValid ||
        !data.organizationZipCode ||
        !data.organizationCity ||
        !data.organizationState ||
        !data.plannedDrawnAt;
    const onClick = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            onSubmit(data);
        },
        [onSubmit],
    );
    const onContactClick = useCallback(() => {
        trackContact();
    }, []);
    const { blockShapeClass } = useStyling();

    return (
        <div className={clsx('w-full', className)}>
            {!!error && <Error error={error} subject={t('subject_register_error', { ns: 'email' })} />}
            <form title={'Mon association'} className={'flex flex-col mt-8 space-y-4'}>
                <div className={'flex flex-col -mt-4 space-y-4'}>
                    <TextField
                        name={'organizationName'}
                        defaultValue={data['organizationName']}
                        title={t('onboarding_form_organization_name_field')}
                        onChange={onFieldChange('organizationName')}
                        error={error}
                        required
                    />
                    <TextField
                        name={'organizationCity'}
                        defaultValue={data['organizationCity']}
                        title={t('onboarding_form_organization_city_field')}
                        helper={t('onboarding_form_organization_city_field_helper', '')}
                        placeholder={t('onboarding_form_organization_city_field_placeholder')}
                        onChange={onFieldChange('organizationCity')}
                        error={error}
                        required
                    />
                    <TextField
                        name={'organizationState'}
                        defaultValue={data['organizationState']}
                        title={t('onboarding_form_organization_state_field')}
                        helper={t('onboarding_form_organization_state_field_helper', '')}
                        placeholder={t('onboarding_form_organization_state_field_placeholder')}
                        onChange={onFieldChange('organizationState')}
                        error={error}
                        required
                    />
                    <TextField
                        name={'organizationZipCode'}
                        defaultValue={data['organizationZipCode']}
                        title={t('onboarding_form_organization_zip_code_field')}
                        onChange={onFieldChange('organizationZipCode')}
                        error={error}
                        required
                    />
                    <TextField
                        name={'organizationPresentation'}
                        defaultValue={data['organizationPresentation']}
                        title={t('onboarding_form_organization_presentation_field')}
                        onChange={onFieldChange('organizationPresentation')}
                        error={error}
                        rows={5}
                    />
                    <TextField
                        name={'gameName'}
                        defaultValue={data['gameName']}
                        title={t('onboarding_form_game_name_field')}
                        onChange={onFieldChange('gameName')}
                        error={error}
                        required
                    />
                    <TextField
                        name={'gamePresentation'}
                        defaultValue={data['gamePresentation']}
                        title={t('onboarding_form_game_presentation_field')}
                        onChange={onFieldChange('gamePresentation')}
                        error={error}
                        rows={5}
                    />
                    <NumberField
                        name={'ticketPriceAmount'}
                        defaultValue={data['ticketPriceAmount']}
                        prefix={data.ticketPriceCurrency}
                        title={t('onboarding_form_ticket_price_amount_field')}
                        onChange={onFieldChange('ticketPriceAmount')}
                        error={error}
                        required
                    />
                    <DateField
                        name={'plannedDrawnAt'}
                        defaultValue={data['plannedDrawnAt']}
                        title={t('onboarding_form_planned_drawn_at_field')}
                        onChange={onFieldChange('plannedDrawnAt')}
                        error={error}
                        required
                    />
                    <PrizesField
                        name={'bunches'}
                        defaultValue={data['bunches']}
                        title={t('onboarding_form_bunches_field')}
                        onChange={handleBunchesChange}
                        error={error}
                    />
                    <TextField
                        name={'ownerFirstName'}
                        defaultValue={data['ownerFirstName']}
                        title={t('onboarding_form_owner_first_name_field')}
                        onChange={onFieldChange('ownerFirstName')}
                        error={error}
                        required
                    />
                    <TextField
                        name={'ownerLastName'}
                        defaultValue={data['ownerLastName']}
                        title={t('onboarding_form_owner_last_name_field')}
                        onChange={onFieldChange('ownerLastName')}
                        error={error}
                        required
                    />
                    <EmailField
                        name={'ownerEmail'}
                        defaultValue={data['ownerEmail']}
                        title={t('onboarding_form_owner_email_field')}
                        onChange={onFieldChange('ownerEmail')}
                        error={error}
                        required
                    />
                    <button
                        type={'button'}
                        className={clsx(
                            'font-go-button bg-blue-500 disabled:bg-blue-100 hover:bg-yellow-500 p-3 text-white disabled:cursor-default w-full',
                            blockShapeClass,
                        )}
                        disabled={isButtonDisabled}
                        onClick={onClick}
                    >
                        {t('onboarding_landing_create')}
                    </button>
                </div>
            </form>
            <div className={'font-go-text text-center mt-4'}>
                {t('onboarding_form_support_info')}
                <br />
                {t('onboarding_form_support_contact')}
                <a
                    className={'text-blue-500 hover:text-blue-700 underline cursor-pointer'}
                    onClick={onContactClick}
                    href={`mailto:${t('contact', { ns: 'email' })}?subject=${t('subject_register', { ns: 'email' })}`}
                >
                    contact@gotombola.co
                </a>
            </div>
        </div>
    );
}

export interface OnboardingFormProps extends WithClassName, WithError<any>, Required<WithOnSubmit> {
    defaultValues?: any;
    onFieldChange: Function;
}

export default OnboardingForm;
