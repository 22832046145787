import ModuleSection from './ModuleSection';
import useTypeTranslation from '../hooks/useTypeTranslation';
import { WithSubtitle, WithText, WithTitle } from '../withs';
import useTypeModuleImages from '../hooks/useTypeModuleImages';

export function GameOrganizationPresentation({
    game,
    title: forcedTitle,
    titleStyling,
    subtitle,
    subtitleStyling,
    text: forcedText,
    textStyling,
    msVariant: variant,
    ...props
}: GameOrganizationPresentationProps) {
    const { t } = useTypeTranslation('game', game);
    const moduleImages = useTypeModuleImages('game', game);

    const title =
        forcedTitle ||
        t([`game_organization_${(game?.domain || '').replace(/-/g, '_')}_label`, 'game_organization_label']);
    const text = forcedText || game.organizationPresentation;

    if (!text) return null;

    return (
        <ModuleSection
            title={title}
            titleStyling={titleStyling}
            subtitle={subtitle}
            subtitleStyling={subtitleStyling}
            text={text}
            textStyling={textStyling}
            variant={variant}
            defaultVariant={'bordered'}
            {...moduleImages}
            {...props}
        />
    );
}

export interface GameOrganizationPresentationProps extends WithTitle, WithSubtitle, WithText {
    game: any;
    msVariant?: string;
    titleStyling?: string;
    subtitleStyling?: string;
    textStyling?: string;
}

export default GameOrganizationPresentation;
