import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import SellerTemplate, { SellerTemplateProps } from './SellerTemplate';

export function DynamicSellerPublicTemplate(props: DynamicSellerPublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <SellerTemplate {...props} seller={context.doc} game={context.game} />;
}

export type DynamicSellerPublicTemplateProps = Omit<SellerTemplateProps, 'seller'>;

export default DynamicSellerPublicTemplate;
