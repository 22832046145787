import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./description/StandardDescriptionTypeModule')),
};

export function DescriptionTypeModule(props: DescriptionTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type DescriptionTypeModuleProps = TypeModuleProps;

export default DescriptionTypeModule;
