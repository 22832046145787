import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./demoBanner/StandardDemoBannerTypeModule')),
};

export function DemoBannerTypeModule(props: DemoBannerTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type DemoBannerTypeModuleProps = TypeModuleProps;

export default DemoBannerTypeModule;
