import { DefaultNamespace, KeyPrefix, Namespace, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { useCallback } from 'react';
import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';
import useOrganizationContext from '@gotombola/react-contexts-type/lib/hooks/useOrganizationContext';
import usePartnerContext from '@gotombola/react-contexts-type/lib/hooks/usePartnerContext';
import useCampaignContext from '@gotombola/react-contexts-type/lib/hooks/useCampaignContext';
import useTenantContext from '@gotombola/react-contexts-type/lib/hooks/useTenantContext';

const map = {
    game: useGameContext,
    organization: useOrganizationContext,
    partner: usePartnerContext,
    campaign: useCampaignContext,
    tenant: useTenantContext,
};

export function useTypeTranslation<N extends Namespace = DefaultNamespace, TKPrefix extends KeyPrefix<N> = undefined>(
    type: string,
    doc?: any,
    ns?: N | Readonly<N>,
    options?: UseTranslationOptions<TKPrefix>,
): UseTranslationResponse<N, TKPrefix> {
    const ctx = (map[type || ''] || (() => ({})))();
    const finalDoc = doc || (ctx || {})[type];
    const { t, i18n, ready } = useTrans(ns, options);
    const localType = finalDoc?.type || finalDoc?.type;
    const enhancedT = useCallback(
        (key: string | string[], opts?: any) => {
            key = Array.isArray(key) ? key : [key];
            return t(
                (key as string[]).reduce((acc: string[], k: string) => {
                    const [a, ...b] = k.split(/_/);
                    acc.push(
                        `${type}_${localType || ''}${localType ? '_' : ''}${a}${
                            b && b.length ? `_${b.join('_')}` : ''
                        }`,
                    );
                    acc.push(`${type}_${a}${b && b.length ? `_${b.join('_')}` : ''}`);
                    acc.push(`${a}_${localType}${b && b.length ? `_${b.join('_')}` : ''}`);
                    acc.push(`${a}${b && b.length ? `_${b.join('_')}` : ''}`);
                    return acc;
                }, [] as string[]) as any,
                opts,
            );
        },
        [t, localType, type],
    );
    return { t: enhancedT, i18n, ready } as UseTranslationResponse<N, TKPrefix>;
}

// noinspection JSUnusedGlobalSymbols
export default useTypeTranslation;
