import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./agenda/StandardAgendaTypeModule')),
};

export function AgendaTypeModule(props: AgendaTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type AgendaTypeModuleProps = TypeModuleProps;

export default AgendaTypeModule;
