import { useMemo } from 'react';
import clsx from 'clsx';
import { WithItems } from '../withs';
import Badge from '../atoms/Badge';
import Items from '../atoms/Items';

const defaultItems = [];

export function GalleryBadges({ items = defaultItems, limit = undefined, size = 'xl' }: GalleryBadgesProps) {
    items = useMemo(() => (limit ? items?.slice(0, limit) : items), [items, limit]);

    return (
        <div className={clsx('flex flex-wrap relative gap-2 justify-center')}>
            <Items items={items} component={Badge} size={size} />
        </div>
    );
}

export interface GalleryBadgesProps extends WithItems<{ name: string; icon: string; description?: string }> {
    size?: 'sm' | 'md' | 'xl' | '2xl';
    limit?: number;
}

export default GalleryBadges;
