import { useMemo } from 'react';
import { buildMetadata } from '../utils';

export function StaticPixel({ disabled = false, app, event, env = 'prod', children, ...rest }: StaticPixelProps) {
    const metadata = useMemo(() => buildMetadata({ app, event, rest, env }, true), [app, event, rest, env]);
    return !!disabled || !metadata ? null : <img src={metadata.pixelUrl} alt={'m'} />;
}

export interface StaticPixelProps {
    disabled?: boolean;
    event: string;
    app: string;
    env: string;
    [key: string]: any;
    children?: any;
}

export default StaticPixel;
