export function fromMods(mods: any[], propertyName: string, defaultValue: any = {}) {
    return mods.reduce(
        (acc, m) =>
            Array.isArray(defaultValue)
                ? [
                      ...acc,
                      ...(m[propertyName]
                          ? Array.isArray(m[propertyName])
                              ? m[propertyName]
                              : [m[propertyName]]
                          : []),
                  ]
                : { ...acc, ...(m[propertyName] || {}) },
        (Array.isArray(defaultValue) ? defaultValue : defaultValue) as any,
    );
}

export default fromMods;
