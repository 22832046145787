import { WithClassName } from '../../withs';
import Footer from '../../organisms/Footer';

export function SponsorFooter({ className, sponsor }: SponsorFooterProps) {
    const logoImage = !!sponsor.logoImage && !!sponsor.logoImage.url ? sponsor.logoImage : undefined;

    return (
        <Footer
            logo={logoImage}
            className={className}
            email={sponsor.email}
            name={sponsor.name}
            phone={sponsor.phone}
        />
    );
}

export interface SponsorFooterProps extends WithClassName {
    sponsor?: any;
}

export default SponsorFooter;
