import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./miniBanner/StandardMiniBannerTypeModule')),
};

export function MiniBannerTypeModule(props: MiniBannerTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type MiniBannerTypeModuleProps = TypeModuleProps;

export default MiniBannerTypeModule;
