import ModuleContainer from '../../organisms/ModuleContainer';
import SpacerModule from '../../modules/SpacerModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function SpacerCommonModule({ config, ...rest }: SpacerCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <SpacerModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface SpacerCommonModuleProps {
    config: any;
}

export default SpacerCommonModule;
