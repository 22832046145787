import useTypePropertyAsTaggedList from './useTypePropertyAsTaggedList';

export function useTypeVideos(type, doc: any, sort?: string, tag?: string) {
    return useTypePropertyAsTaggedList(
        `${(type || '').slice(0, 1)}videos`,
        (i: any) => i.mainVideoUrl && { url: i.mainVideoUrl },
        type,
        doc,
        sort,
        tag,
    );
}

// noinspection JSUnusedGlobalSymbols
export default useTypeVideos;
