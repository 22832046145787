import clsx from 'clsx';
import Img from '../../atoms/Img';
import { WithAlt, WithNativeOnClick } from '../../withs';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { image } from '@gotombola/types/lib/types/base';

export function ThumbnailBunchItem({ alt, onClick, mainImage }: ThumbnailBunchItemProps) {
    const { logoShapeClass } = useStyling();
    if (!mainImage?.url) return null;
    return (
        <div
            className={clsx(
                'bg-white w-36 h-20 flex justify-center items-center border-2 border-red-200 hover:cursor-pointer hover:border-2 hover:border-go-primary',
                logoShapeClass,
            )}
        >
            <Img className={'h-14 object-contain'} url={mainImage?.url} alt={alt || 'bunch'} onClick={onClick} />
        </div>
    );
}

export interface ThumbnailBunchItemProps extends Required<WithAlt>, WithNativeOnClick {
    mainImage?: image;
}

// noinspection JSUnusedGlobalSymbols
export default ThumbnailBunchItem;
