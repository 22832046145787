import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import PartnerFeatures from '../../../organisms/PartnerFeatures';

export function StandardServiceFeaturesPartnerModule({ partner, ...rest }: StandardServiceFeaturesPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <PartnerFeatures {...props} />
        </ModuleContainer>
    );
}

export interface StandardServiceFeaturesPartnerModuleProps {
    partner: any;
}

export default StandardServiceFeaturesPartnerModule;
