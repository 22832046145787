import { createContext } from 'react';
import { campaign_context_value } from '../types';
import createDefaultCampaignContextValue from '../utils/createDefaultCampaignContextValue';

export const CampaignContext = createContext<campaign_context_value>(createDefaultCampaignContextValue());
// noinspection JSUnusedGlobalSymbols
export const CampaignContextProvider = CampaignContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const CampaignContextConsumer = CampaignContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default CampaignContext;
