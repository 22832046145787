import { WithButton } from '../../../withs';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';
import MobileCallToAction from '../../../organisms/MobileCallToAction';

export function StandardMobileCallToActionGameModule({
    game,
    buttonLabel,
    buttonTarget,
    ...rest
}: StandardMobileCallToActionGameModuleProps) {
    game = ((game ? { game } : useGameContext()) || {}).game;
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <MobileCallToAction game={game} buttonLabel={buttonLabel} buttonTarget={buttonTarget} {...props} />
        </ModuleContainer>
    );
}

export interface StandardMobileCallToActionGameModuleProps extends WithButton {
    game: any;
}

export default StandardMobileCallToActionGameModule;
