import computeCustomPacks from './computeCustomPacks';
import getDefaultDefaultCustomPacks from './getDefaultDefaultCustomPacks';

export function getDefaultPacks(
    doc: { features?: string; customPacks?: string },
    forcedDefaultCustomPacks: string | undefined = undefined,
) {
    return computeCustomPacks(doc.features, undefined, forcedDefaultCustomPacks || getDefaultDefaultCustomPacks());
}

export default getDefaultPacks;
