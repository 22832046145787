import { WithButton, WithClassName, WithLogo, WithName } from '../withs';
import Img from '../atoms/Img';
import String from '../atoms/String';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import Button from '../atoms/Button';
import BaseFooter from './BaseFooter';
import { children } from '@gotombola/react-types/lib/types';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import clsx from 'clsx';
import Text from '../atoms/Text';

export function Footer({
    address,
    buttonLabel,
    buttonTarget,
    className,
    email,
    fullName,
    logo,
    name,
    nin,
    phone,
    presentation,
    street,
    adChildren,
}: FooterProps) {
    const { t } = useTrans();
    const { logoShapeClass } = useStyling();
    const secondaryChildren = (
        <div className={'flex flex-col text-center break-words leading-relaxed justify-center items-center text-xl'}>
            <Text className={'text-inherit'}>{fullName}</Text>
            {!!phone && (
                <a href={`tel:${phone}`}>
                    <Text basic className={'text-inherit'}>
                        {phone}
                    </Text>
                </a>
            )}
            <Text basic className={'text-inherit'}>
                {email}
            </Text>
            <Text className={'text-inherit'}>{street}</Text>
            <Text className={'text-inherit'}>{address}</Text>
            {!!nin && <Text className={'text-base text-inherit'}>{t('label_nin', { nin })}</Text>}
        </div>
    );

    return (
        <BaseFooter className={className} secondaryChildren={secondaryChildren}>
            <div
                className={'flex flex-col lg:flex-row sm:space-x-6 space-y-4 sm:space-y-0 items-center justify-center'}
            >
                {!!logo && (
                    <Img
                        className={clsx('sm:block max-w-xs p-2 bg-go-logo', logoShapeClass)}
                        url={logo.url}
                        alt={logo.alt || 'logo'}
                        maxSize={200}
                    />
                )}
                <div className={'flex flex-col items-center sm:items-start gap-y-2'}>
                    <div className={'font-go-text text-2xl font-medium tracking-tight'}>
                        <String basic>{name}</String>
                    </div>
                    {!!presentation && (
                        <div className={'font-go-text text-sm font-light tracking-tight'}>
                            <String basic>{presentation}</String>
                        </div>
                    )}
                    {!!buttonLabel && !!buttonTarget && <Button target={buttonTarget} label={buttonLabel} />}
                    {!!adChildren && adChildren}
                </div>
            </div>
        </BaseFooter>
    );
}

export interface FooterProps extends WithClassName, WithButton, WithLogo, WithName {
    adChildren?: children;
    address?: string;
    email?: string;
    fullName?: string;
    nin?: string;
    phone?: string;
    presentation?: string;
    street?: string;
}

// noinspection JSUnusedGlobalSymbols
export default Footer;
