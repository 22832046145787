import StatsGameModule from '../template-modules/game/StatsGameModule';

// noinspection JSUnusedLocalSymbols
export function GameKeyInfos(props: GameKeyInfosProps) {
    return <StatsGameModule hideTicketPrice={false} {...props} />;
}

export interface GameKeyInfosProps {
    game: any;
    drawDate?: Date;
    hideDrawDate?: boolean;
}

export default GameKeyInfos;
