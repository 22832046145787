import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./serviceQuote/StandardServiceQuotePartnerModule')),
};

export function ServiceQuotePartnerModule(props: ServiceQuotePartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ServiceQuotePartnerModuleProps = PartnerModuleProps;

export default ServiceQuotePartnerModule;
