import clsx from 'clsx';
import { WithClassName, WithTitle } from '../withs';
import Spinner from '../atoms/Spinner';
import RichText from '../atoms/RichText';

export function Loading({ className, title }: LoadingProps) {
    return (
        <div className={clsx('flex flex-col mb-20', className)}>
            <div className={'mt-10 flex flex-col items-center justify-center'}>
                <Spinner />
                {!!title && (
                    <div className={'font-go-text mt-10'}>
                        <RichText value={title} />
                    </div>
                )}
            </div>
        </div>
    );
}

export interface LoadingProps extends WithClassName, WithTitle {}

export default Loading;
