import ModuleContainer from '../../organisms/ModuleContainer';
import ImageModule from '../../modules/ImageModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function ImageCommonModule({ config, ...rest }: ImageCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <ImageModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface ImageCommonModuleProps {
    config: any;
}

export default ImageCommonModule;
