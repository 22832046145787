import loadable from '@loadable/component';
import { ModuleProps } from '../types';
import ModuleMode from '../organisms/ModuleMode';

const modes = {
    centered: loadable(() => import('./headings/CenteredHeadingModule')),
    left: loadable(() => import('./headings/LeftHeadingModule')),
    right: loadable(() => import('./headings/RightHeadingModule')),
};

export function HeadingModule(props: HeadingModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'centered'} />;
}

export type HeadingModuleProps = ModuleProps;

export default HeadingModule;
