import clsx from 'clsx';
import RichText from './RichText';
import { flag, rich_text } from '../types';
import { WithChildren, WithClassName, WithNativeOnClick, WithValue } from '../withs';
import useStyledText from '../hooks/useStyledText';

export function Text({
    className,
    value,
    styling,
    inline = false,
    bold = false,
    semibold = false,
    center = false,
    basic = false,
    relaxed = false,
    extralight = false,
    light = false,
    numeric = false,
    upper = false,
    children,
    onClick,
}: TextProps) {
    const [text, textClassName] = useStyledText(value, styling, children);
    const cl = clsx(
        'font-go-text text-go-text',
        className,
        center && 'text-center',
        bold && 'font-bold',
        semibold && 'font-semibold',
        relaxed && 'leading-relaxed',
        extralight && 'font-extralight',
        light && 'font-light',
        upper && 'uppercase',
        !!onClick && 'cursor-pointer hover:underline',
        textClassName,
    );
    if (!text) return null;
    const content = numeric ? value || children : <RichText basic={basic} value={text} />;
    return inline ? (
        <span className={cl} onClick={onClick}>
            {content}
        </span>
    ) : (
        <div className={cl} onClick={onClick}>
            {content}
        </div>
    );
}

export interface TextProps extends WithChildren, WithClassName, WithValue<rich_text>, WithNativeOnClick {
    basic?: flag;
    bold?: flag;
    styling?: string;
    semibold?: flag;
    center?: flag;
    inline?: flag;
    relaxed?: flag;
    extralight?: flag;
    light?: flag;
    numeric?: flag;
    upper?: flag;
}

export default Text;
