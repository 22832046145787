import GenericLocaleSwitcher, { GenericLocaleSwitcherProps } from './GenericLocaleSwitcher';

export function CampaignLocaleSwitcher({ campaign, ...props }: CampaignLocaleSwitcherProps) {
    return <GenericLocaleSwitcher item={campaign} versionKey={'campaignVersions'} {...props} />;
}

export interface CampaignLocaleSwitcherProps extends Omit<GenericLocaleSwitcherProps, 'item' | 'versionKey'> {
    campaign: any;
}

export default CampaignLocaleSwitcher;
