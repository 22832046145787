import clsx from 'clsx';
import { WithClassName } from '../withs';
import StaticLogo from '../atoms/StaticLogo';
import useTypeTranslation from '../hooks/useTypeTranslation';

export function ServiceFooter({ className }: ServiceFooterProps) {
    const { t } = useTypeTranslation('game');
    const facebookUrl = t('facebook_gotombola', { ns: 'url' });
    const linkedinUrl = t('linkedin_gotombola', { ns: 'url' });
    const twitterUrl = t('twitter_gotombola', { ns: 'url' });
    const gotombolaUrl = t('gotombola', { ns: 'url' });

    return (
        <div className={clsx('pb-24 sm:pb-4 flex flex-col mt-12 text-center', className)}>
            <div className={'text-center justify-center flex pb-4'}>
                <StaticLogo type={'gotombola-small-2'} className={'max-w-xs'} />
            </div>
            <a className={'font-go-text text-center py-4 sm:pt-0'} href={gotombolaUrl}>
                {t('footer_generic_slogan')}
            </a>
            <div className={'font-go-text mt-6'}>{t('footer_follow_links')}</div>
            <hr className={'my-8 mx-auto w-11/12'} />
            <div className={'flex space-x-6 mx-auto'}>
                <a href={facebookUrl}>
                    <StaticLogo type={'facebook-white-outlined'} />
                </a>
                <a href={linkedinUrl}>
                    <StaticLogo type={'linkedin-white-outlined'} />
                </a>
                <a href={twitterUrl}>
                    <StaticLogo type={'twitter-white-outlined'} />
                </a>
            </div>
        </div>
    );
}

export type ServiceFooterProps = WithClassName;

export default ServiceFooter;
