import Pixel from '../atoms/Pixel';
import PushNotification from '../atoms/PushNotification';
import { WithChildren, WithLocale, WithNoindex } from '../withs';
import BasicStaticPage from './BasicStaticPage';

const defaultPixelProps = {};
const defaultPushProps = {};

export function SimplePage({
    children,
    noindex,
    page,
    pixelProps = defaultPixelProps,
    pixels,
    pushProps = defaultPushProps,
    pushs,
}: SimplePageProps) {
    return (
        <BasicStaticPage noindex={noindex}>
            {children || ''}
            {!!pixels && <Pixel app={pixels['app']} event={pixels['pages'][page || 'default']} {...pixelProps} />}
            {!!pushs && <PushNotification app={pushs['app']} type={pushs['pages'][page || 'default']} {...pushProps} />}
        </BasicStaticPage>
    );
}

export interface SimplePageProps extends WithChildren, WithLocale, WithNoindex {
    page?: string;
    pixelProps?: Record<string, any>;
    pixels?: Record<string, any>;
    pushProps?: Record<string, any>;
    pushs?: Record<string, any>;
}

// noinspection JSUnusedGlobalSymbols
export default SimplePage;
