import {
    WithButton,
    WithChildren,
    WithErrors,
    WithLoading,
    WithOnSubmit,
    WithText,
    WithTitle,
    WithType,
} from '../withs';
import String from '../atoms/String';
import Button from '../atoms/Button';
import Error from '../molecules/Error';
import SectionTitle from '../atoms/SectionTitle';

export function TypeFunnelStep({
    children,
    title,
    titleStyling,
    text,
    buttonLabel,
    buttonTarget,
    onSubmit,
    buttonDisabled = false,
    loading,
    errors,
}: TypeFunnelStepProps) {
    return (
        <form onSubmit={onSubmit as any}>
            <div className={'text-center flex flex-col gap-y-4'}>
                <SectionTitle title={title} titleStyling={titleStyling} className={'sm:text-4xl my-8'} />
                {!!text && <String className={'font-go-text text-go-text'}>{text}</String>}
                {!!errors && !!errors.globalError && <Error error={errors.globalError} />}
                {children}
                {!!buttonLabel && (!!buttonTarget || !!onSubmit) && (
                    <div className={'w-full mt-8 flex flex-col justify-center'}>
                        <Button
                            {...(onSubmit ? { type: 'submit' } : { target: buttonTarget })}
                            label={buttonLabel}
                            disabled={buttonDisabled || loading}
                            loading={loading}
                        />
                    </div>
                )}
            </div>
        </form>
    );
}

export interface TypeFunnelStepProps
    extends Required<WithType>,
        WithErrors,
        WithOnSubmit,
        WithTitle,
        WithText,
        WithButton,
        WithChildren,
        WithLoading {
    doc: any;
    titleStyling?: string;
}

// noinspection JSUnusedGlobalSymbols
export default TypeFunnelStep;
