import { WithId } from '@gotombola/react-types';
import useAdZoneComponent from '../hooks/useAdZoneComponent';

export function AdZone({ id, wrapperComponent: WrapperComponent, ...props }: AdZoneProps) {
    const Comp = useAdZoneComponent(id, props);
    if (!Comp) return null;

    const content = <Comp {...props} />;

    return WrapperComponent ? <WrapperComponent>{content}</WrapperComponent> : content;
}

export interface AdZoneProps extends WithId {
    wrapperComponent?: any;
}

export default AdZone;
