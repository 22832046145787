import TypeSponsors, { TypeSponsorsProps } from '../../../organisms/type/TypeSponsors';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { GameModuleProps } from '@gotombola/react-types/lib/types';

const properties = ['logoImage', 'mainImage'];

export function LogosSponsorsGameModule({ ...rest }: LogosSponsorsGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    if (!props.game?.sponsorings?.items?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeSponsors
                {...props}
                type={'game'}
                items={props.game?.sponsorings.items}
                property={properties}
                defaultMsVariant={'basic'}
            />
        </ModuleContainer>
    );
}

export interface LogosSponsorsGameModuleProps extends Omit<TypeSponsorsProps, 'type'>, GameModuleProps {}

export default LogosSponsorsGameModule;
