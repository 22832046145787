import loadable from '@loadable/component';
import { ModuleProps } from '../types';
import ModuleMode from '../organisms/ModuleMode';

const modes = {
    content_left: loadable(() => import('./pushs/ContentLeftPushModule')),
    content_right: loadable(() => import('./pushs/ContentRightPushModule')),
    content_left_heading: loadable(() => import('./pushs/ContentLeftHeadingPushModule')),
    content_right_heading: loadable(() => import('./pushs/ContentRightHeadingPushModule')),
    // push_large
    // push_large_right
};

export function PushModule(props: PushModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'content_left'} />;
}

export type PushModuleProps = ModuleProps;

export default PushModule;
