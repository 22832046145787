import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from '@tsparticles/engine';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        fpsLimit: 120,
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            color: {
                value: 'random',
            },
            shape: {
                type: 'circle',
            },
            opacity: {
                value: 0.8,
            },
            size: {
                value: { min: 5, max: 10 },
            },
            move: {
                angle: {
                    offset: 0,
                    value: 30,
                },
                enable: true,
                speed: 15,
                direction: 'top',
                random: false,
                straight: false,
                outModes: {
                    default: 'destroy',
                },
            },
        },
        detectRetina: true,
        emitters: [
            {
                direction: 'top',
                position: {
                    y: 100,
                },
                life: {
                    duration: 3,
                    delay: 5,
                    count: 0,
                },
            },
        ],
    };
};
