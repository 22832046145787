import clsx from 'clsx';
import { module_section_variant } from '../../types';
import Section from '../../molecules/Section';

export function BorderedModuleSection({
    children,
    className,
    containerClassName,
    subtitle,
    subtitleStyling,
    title,
    titleStyling,
    nobg,
}: BorderedModuleSectionProps) {
    return (
        <Section
            title={title}
            titleStyling={titleStyling}
            nobg={nobg}
            className={containerClassName}
            subtitle={subtitle}
            subtitleStyling={subtitleStyling}
        >
            <div className={clsx('w-full', className)}>{children}</div>
        </Section>
    );
}

export type BorderedModuleSectionProps = module_section_variant;

export default BorderedModuleSection;
