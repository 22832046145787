import { image } from '@gotombola/types';
import CountDown from '../organisms/CountDown';
import DynamicPageHead from '../organisms/DynamicPageHead';

const defaultMetas: any[] = [];

export function CountDownPage({
    delta,
    value,
    locale,
    children,
    title,
    subtitle,
    text,
    websiteUrl,
    mainImage,
    mode,
    now,
}: CountDownPageProps) {
    return (
        <>
            {delta >= 3600 && <DynamicPageHead lang={locale} title={title} metas={defaultMetas} />}
            <CountDown
                title={title}
                subtitle={subtitle}
                value={value}
                text={text}
                websiteUrl={websiteUrl}
                mainImage={mainImage}
                mode={mode}
                reloadPage={delta >= 3600}
                now={now}
            />
            {delta < 3600 && children}
        </>
    );
}

export interface CountDownPageProps {
    children?: any;
    delta: number;
    locale: string | undefined;
    value: number;
    title: string;
    subtitle: string;
    text?: string;
    websiteUrl?: string;
    mainImage?: image;
    mode?: string;
    now?: number;
}

export default CountDownPage;
