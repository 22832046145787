import { TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import Text from '../../atoms/Text';

export function TypeAbout({ className, doc, type }: TypeAboutProps) {
    const { t } = useTypeTranslation(type, doc);

    return (
        <div className={className}>
            <div className={'space-y-12 my-4'} role={'content'}>
                {!!doc?.presentation && (
                    <>
                        <Text className={'sm:text-xl'}>
                            {t([`${type}_communication_page_about_section`, 'communication_page_about_section'])}
                        </Text>
                        <Text relaxed className={'sm:text-lg text-gray-500'}>
                            {doc?.presentation}
                        </Text>
                    </>
                )}
                <div>
                    <a
                        href={doc?.publicPageShortUrl}
                        className={
                            'font-go-text sm:text-xl text-blue-500 tracking-tight leading-relaxed cursor-pointer font-medium hover:underline'
                        }
                    >
                        {t(`${type}_communication_page_link_label`)}
                    </a>
                </div>
            </div>
        </div>
    );
}

export interface TypeAboutProps extends TypeProps, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default TypeAbout;
