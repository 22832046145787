import clsx from 'clsx';
import { WithClassName, WithLabel } from '../withs';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

const sizes = {
    sm: 'text-md p-2 font-medium ',
    md: 'text-lg p-3 font-medium ',
    lg: 'text-3xl p-4 font-medium ',
};

const colors = {
    primary: 'text-white bg-go-primary hover:bg-go-secondary hover:shadow-md',
    secondary: 'text-white bg-go-secondary hover:bg-go-primary hover:shadow-md',
    light: 'text-go-primary hover:underline',
};

export function Link({ className, color = 'primary', href, label, size = 'md' }: LinkProps) {
    const { blockShapeClass } = useStyling();

    return (
        <a
            href={href}
            className={clsx(
                'font-go-text cursor-pointer w-full sm:w-auto',
                sizes[size || ''] || sizes['md'],
                colors[color || ''] || colors['primary'],
                blockShapeClass,
                className,
            )}
        >
            {label || ''}
        </a>
    );
}

export interface LinkProps extends WithClassName, WithLabel {
    href?: string;
    size?: 'sm' | 'md' | 'lg';
    color?: 'primary' | 'secondary' | 'light';
}

// noinspection JSUnusedGlobalSymbols
export default Link;
