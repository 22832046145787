import { rich_text } from '../types';
import { WithLabel, WithStatus, WithValue } from '../withs';
import Text from './Text';

export function SummaryItem({ label, status, value }: SummaryItemProps) {
    return (
        <div
            className={
                'w-full lg:w-5/12 m-2 px-4 py-4 lg:py-2 flex justify-between items-center rounded-lg border border-gray-200'
            }
        >
            {!!label && <Text>{label}</Text>}
            {!!value && <Text numeric>{value}</Text>}
            {!!status && (
                <div className={'flex items-center space-x-2'}>
                    <div className={'w-5 h-5 bg-green-500 bg-opacity-20 rounded-full flex justify-center items-center'}>
                        <div className={'w-2 h-2 bg-green-500 rounded-sm'}></div>
                    </div>
                    <Text className={'text-green-500'}>{status}</Text>
                </div>
            )}
        </div>
    );
}

export interface SummaryItemProps extends WithLabel, WithStatus, WithValue<rich_text> {}

// noinspection JSUnusedGlobalSymbols
export default SummaryItem;
