import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import ModuleContainer from '../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';
import Text from '../../atoms/Text';

function Component({ game, ...rest }: any) {
    const [zprops] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const date = new Date().toLocaleDateString();

    return (
        <ModuleContainer {...zprops}>
            <div className={'w-full space-y-2'}>
                <Text bold className={'sm:text-xl'}>
                    {t('game_legal_page_update_time', { date: date })}
                </Text>
                <div className={'flex flex-col gap-2'}>
                    <Text inline relaxed className={'sm:text-lg'}>
                        {`${game.organizationName} ${game.organizationPresentation || ''}`}
                    </Text>
                </div>
            </div>
        </ModuleContainer>
    );
}

export function LegalUpdateTimeGameModule(props: LegalUpdateTimeGameModuleProps) {
    return <ModuleMode {...props} component={Component} />;
}

export type LegalUpdateTimeGameModuleProps = GameModuleProps;

export default LegalUpdateTimeGameModule;
