import { Trans } from 'react-i18next';
import { dynamic_page_body_item_config } from '../types';
import { WithChildren, WithItems } from '../withs';
import LeftBoxLayout from '../layouts/LeftBoxLayout';
import DynamicPageBodyItems from '../organisms/DynamicPageBodyItems';

export function LeftBoxTemplate({ children, items = [] }: LeftBoxTemplateProps) {
    const headerChildren = (
        <div className={'flex items-center justify-center h-full w-full'}>
            <h1 className={'text-center text-white text-lg'}>
                <Trans i18nKey={'template_central_box_big_title'}>
                    xxx <span className={'text-yellow-500'}>yyy</span> zzz
                </Trans>
            </h1>
        </div>
    );

    const secondaryChildren = <DynamicPageBodyItems items={items} zone={'secondary'} />;

    return (
        <LeftBoxLayout headerChildren={headerChildren} secondaryChildren={secondaryChildren}>
            {children || ''}
            <DynamicPageBodyItems items={items} />
        </LeftBoxLayout>
    );
}

export interface LeftBoxTemplateProps extends WithChildren, WithItems<dynamic_page_body_item_config> {}

export default LeftBoxTemplate;
