import clsx from 'clsx';
import { WithClassName, WithImage, WithLabel } from '../../withs';
import Img from '../../atoms/Img';

export function MediumImageModule({ className, image }: MediumImageModuleProps) {
    return (
        <div className={clsx('w-2/3 relative my-2 mx-auto flex justify-center', className)}>
            {!!image?.url && <Img {...image} className={'object-cover'} alt={image.alt || 'presentation image'} />}
        </div>
    );
}

export interface MediumImageModuleProps extends WithClassName, Required<WithImage>, WithLabel {}

export default MediumImageModule;
