import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useTypeUrl from '../../../hooks/useTypeUrl';
import TypeFunnelStep from '../../../organisms/TypeFunnelStep';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import useQueryString from '../../../hooks/useQueryString';

export function WelcomeSellergroupRegistraionFormGameModule({
    game,
    ...rest
}: WelcomeSellergroupRegistraionFormGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const qs = useQueryString();
    const targetUrl = useTypeUrl('game_sellergroup', game, 'registration_team', qs);

    return (
        <ModuleContainer {...zprops}>
            <TypeFunnelStep
                type={'game'}
                doc={game}
                title={t('game_sellergroup_registration_welcome_title')}
                text={t('game_sellergroup_registration_welcome_text')}
                buttonLabel={t('game_sellergroup_registration_welcome_cta')}
                buttonTarget={targetUrl}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface WelcomeSellergroupRegistraionFormGameModuleProps {
    game: any;
}

export default WelcomeSellergroupRegistraionFormGameModule;
