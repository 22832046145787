import clsx from 'clsx';
import { WithClassName, WithText } from '../../withs';
import Text from '../../atoms/Text';

const colors = {
    info: 'text-gray-600 bg-blue-50',
    danger: 'text-white bg-red-500',
    custom: 'text-white bg-go-primary',
};

const shapes = {
    default: 'rounded',
    rounded: 'rounded',
    square: '',
};

const textAligns = {
    default: 'text-justify',
    justify: 'text-justify',
    left: 'text-left',
    right: 'text-right',
    center: 'text-center',
};

export function AlertAlertBannerModule({
    className,
    color = 'info',
    shape = 'rounded',
    textAlign = 'justify',
    text,
}: AlertAlertBannerModuleProps) {
    return !!text ? (
        <div className={clsx('flex flex-col', className)}>
            <div className={'w-full'}>
                <Text
                    className={clsx(
                        'text-md p-4',
                        undefined !== shapes[shape || ''] ? shapes[shape || ''] : shapes['default'],
                        colors[color || ''] || colors['info'],
                        textAligns[textAlign || ''] || textAlign['default'],
                    )}
                >
                    {text}
                </Text>
            </div>
        </div>
    ) : null;
}

export interface AlertAlertBannerModuleProps extends WithClassName, WithText {
    color?: string;
    shape?: string;
    textAlign?: string;
}

export default AlertAlertBannerModule;
