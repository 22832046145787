import { WithBasic, WithClassName, WithValue } from '../withs';
import RichTextPart from './RichTextPart';
import MultiLineText from './MultiLineText';

export function RichText({ value, basic, className }: RichTextProps) {
    if (!value) return null;
    if ('string' === typeof value) return <MultiLineText className={className} text={value} basic={basic} />;
    if (!Array.isArray(value)) return value;
    if (!value.length) return null;

    return (
        <>
            {value.map((item: any, i: number) => (
                <RichTextPart key={i} className={className} {...item} />
            ))}
        </>
    );
}

export interface RichTextProps extends WithBasic, WithClassName, WithValue {}

export default RichText;
