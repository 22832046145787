import clsx from 'clsx';
import { rich_text, TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import Img from '../../atoms/Img';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import VideoModule from '../../modules/VideoModule';
import { useMemo } from 'react';
import Text from '../../atoms/Text';
import useStyledText from '../../hooks/useStyledText';

const variants = {
    contained: {
        container: 'bg-go-panel-primary text-white border border-gray-200',
        title: '',
        text: '',
    },
    'contained-light': {
        container: 'bg-go-panel-primary text-go-primary border border-gray-200',
        title: '',
        text: '',
    },
    outlined: {
        container: 'text-go-primary border border-go-panel-primary',
        title: '',
        text: '',
    },
    filled: {
        container: '',
        title: 'text-go-primary',
        text: 'text-go-text',
    },
};

export function TypeQuote({
    className,
    doc,
    title: forcedRawTitle,
    titleStyling,
    variant = 'contained',
    largeMedia = false,
}: TypeQuoteProps) {
    const text = doc.mainQuote;
    const image = doc.mainQuoteImage;
    const video = doc.mainQuoteVideoUrl;
    const { blockShapeClass, blockHeaderShapeClass, blockFooterShapeClass } = useStyling();
    const {
        container: containerClass = '',
        title: titleClass = '',
        text: textClass = '',
    } = useMemo(() => variants[variant || ''] || {}, [variant]);
    const [title, titleClassName] = useStyledText(forcedRawTitle, titleStyling, doc.mainQuoteTitle, 'sectionTitle');
    if (!text && !title) return null;

    return (
        <div
            className={clsx(
                'flex flex-col md:flex-row items-center space-y-6 md:space-x-3 md:space-y-0 p-4',
                containerClass,
                className,
                blockShapeClass,
            )}
        >
            {(!!title || !!text) && (
                <div className={clsx('flex flex-col gap-4', (!!video || !!largeMedia) && 'w-full sm:w-7/12')}>
                    {!!title && (
                        <div className={'mx-4'}>
                            <blockquote>
                                <Text
                                    bold
                                    basic
                                    className={clsx('text-3xl text-center md:text-left', titleClass, titleClassName)}
                                >
                                    {title}
                                </Text>
                            </blockquote>
                        </div>
                    )}
                    {!!text && (
                        <div className={'mx-4'}>
                            <blockquote>
                                <Text basic className={clsx('font-normal text-center text-lg md:text-left', textClass)}>
                                    {text}
                                </Text>
                            </blockquote>
                        </div>
                    )}
                </div>
            )}
            {!!image?.url && (
                <div className={clsx('text-center', !!largeMedia && 'flex-1 w-full')}>
                    <Img
                        className={clsx(
                            'md:min-w-[200px]',
                            !!largeMedia && 'w-full',
                            doc.mainQuoteImageTitle || doc.mainQuoteImageSubtitle
                                ? blockHeaderShapeClass
                                : blockShapeClass,
                        )}
                        alt={doc.name || 'quote'}
                        {...image}
                        maxSize={!!largeMedia ? 800 : 400}
                    />
                    {!!doc.mainQuoteImageTitle && (
                        <div className={clsx('bg-go-primary p-2', blockFooterShapeClass)}>
                            <Text>{doc.mainQuoteImageTitle}</Text>
                            {!!doc.mainQuoteImageSubtitle && (
                                <Text className={'text-sm'}>{doc.mainQuoteImageSubtitle}</Text>
                            )}
                        </div>
                    )}
                </div>
            )}
            {!!video && (
                <div className={'text-center flex-1 w-full'}>
                    <VideoModule
                        hsize={'custom'}
                        url={video}
                        className={clsx(
                            'w-full md:min-w-[300px] h-48 md:h-72',
                            doc.mainQuoteVideoTitle || doc.mainQuoteVideoSubtitle
                                ? blockHeaderShapeClass
                                : blockShapeClass,
                        )}
                    />
                    {!!doc.mainQuoteVideoTitle && (
                        <div className={clsx('bg-go-primary p-2', blockFooterShapeClass)}>
                            <Text>{doc.mainQuoteVideoTitle}</Text>
                            {!!doc.mainQuoteVideoSubtitle && (
                                <Text className={'text-sm'}>{doc.mainQuoteVideoSubtitle}</Text>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export interface TypeQuoteProps extends TypeProps, WithClassName {
    variant?: string;
    title?: rich_text;
    titleStyling?: string;
    largeMedia?: boolean;
}

// noinspection JSUnusedGlobalSymbols
export default TypeQuote;
