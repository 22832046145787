import { useMemo } from 'react';
import { image, TypeProps } from '../../types';
import { WithClassName, WithItems, WithSubtitle, WithTitle } from '../../withs';
import TypeImages from './TypeImages';
import ModuleSection from '../ModuleSection';
import SponsoringImage from '../../atoms/SponsoringImage';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import useTypeModuleImages from '../../hooks/useTypeModuleImages';

const defaultItems = [];

export function TypeSponsors({
    className,
    doc,
    items = defaultItems,
    property = 'mainImage',
    component = SponsoringImage,
    type,
    title: forcedTitle,
    titleStyling,
    subtitle,
    subtitleStyling,
    msVariant,
    defaultMsVariant,
    ...props
}: TypeSponsorsProps) {
    const { t } = useTypeTranslation(type, doc);
    const moduleImages = useTypeModuleImages(type, doc);
    const images = useMemo(() => {
        return items
            .map((i: any) => {
                const ppp = (Array.isArray(property) ? property : [property]).find((pp) => i[pp]?.url);
                return i[ppp || ''];
            })
            .filter((x) => !!x);
    }, [items, property]) as unknown as image[];

    const title = forcedTitle || t(`${type || 'generic'}_sponsorings_label`);
    return (
        <ModuleSection
            title={title}
            titleStyling={titleStyling}
            subtitle={subtitle}
            subtitleStyling={subtitleStyling}
            className={className}
            variant={msVariant}
            defaultVariant={defaultMsVariant}
            {...moduleImages}
            {...props}
        >
            <TypeImages doc={doc} type={type} items={images} component={component} className={'gap-10'} />
        </ModuleSection>
    );
}

export interface TypeSponsorsProps
    extends TypeProps,
        WithTitle,
        WithSubtitle,
        WithClassName,
        WithItems<{ mainImage: { url: string } }> {
    property?: string | string[];
    component?: any;
    msVariant?: string;
    defaultMsVariant?: string;
    titleStyling?: string;
    subtitleStyling?: string;
}

// noinspection JSUnusedGlobalSymbols
export default TypeSponsors;
