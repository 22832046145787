import { WithChildren } from '../withs';

export function Row({ children }: RowProps) {
    return <div className={'flex'}>{children || ''}</div>;
}

export type RowProps = WithChildren;

// noinspection JSUnusedGlobalSymbols
export default Row;
