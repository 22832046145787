import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    logos: loadable(() => import('./sponsors/LogosSponsorsGameModule')),
    lightlogos: loadable(() => import('./sponsors/LightLogosSponsorsGameModule')),
    list: loadable(() => import('./sponsors/ListSponsorsGameModule')),
};

export function SponsorsGameModule(props: SponsorsGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'logos'} />;
}

export type SponsorsGameModuleProps = GameModuleProps;

export default SponsorsGameModule;
