import { createContext } from 'react';
import { custom_module_modes_context_value } from '../types';
import createDefaultCustomModuleModesContextValue from '../utils/createDefaultCustomModuleModesContextValue';

export const CustomModuleModesContext = createContext<custom_module_modes_context_value>(
    createDefaultCustomModuleModesContextValue(),
);
export const CustomModuleModesContextProvider = CustomModuleModesContext.Provider;
export const CustomModuleModesContextConsumer = CustomModuleModesContext.Consumer;

export default CustomModuleModesContext;
