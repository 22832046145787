import loadable from '@loadable/component';

export default {
    common_alert_banner: loadable(() => import('./AlertBannerCommonModule')),
    common_banner: loadable(() => import('./BannerCommonModule')),
    common_custom: loadable(() => import('./CustomCommonModule')),
    common_dynbody: loadable(() => import('./DynamicPageBodyCommonModule')),
    common_footer: loadable(() => import('./FooterCommonModule')),
    common_header: loadable(() => import('./HeaderCommonModule')),
    common_heading: loadable(() => import('./HeadingCommonModule')),
    common_image: loadable(() => import('./ImageCommonModule')),
    common_items: loadable(() => import('./ItemsCommonModule')),
    common_paragraph: loadable(() => import('./ParagraphCommonModule')),
    common_push: loadable(() => import('./PushCommonModule')),
    common_spacer: loadable(() => import('./SpacerCommonModule')),
    common_tenant_footer: loadable(() => import('./TenantFooterCommonModule')),
    common_video: loadable(() => import('./VideoCommonModule')),
    common_contents: loadable(() => import('./ContentsCommonModule')),
    common_content: loadable(() => import('./ContentCommonModule')),
    common_title: loadable(() => import('./TitleCommonModule')),
    common_message: loadable(() => import('./MessageCommonModule')),
    common_unknown: loadable(() => import('./UnknownCommonModule')),
    unknown: loadable(() => import('./UnknownCommonModule')),
};
