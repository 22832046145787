import TypePresentation, { TypePresentationProps } from '../../../organisms/type/TypePresentation';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any) => !!doc?.presentation;

export function StandardPresentationTypeModule({ typeType, ...rest }: StandardPresentationTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    if (!doc) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypePresentation {...props} type={type} doc={doc} />
        </ModuleContainer>
    );
}

export interface StandardPresentationTypeModuleProps extends Omit<TypePresentationProps, 'type'> {
    typeType: string;
}

export default StandardPresentationTypeModule;
