import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import ModuleContainer from '../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';
import Text from '../../atoms/Text';

function Component({ doc, page, typeType, ...rest }: any) {
    const { t } = useTypeTranslation(typeType, doc);
    const [zprops] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <div className={'w-full mb-8 sm:mb-16'}>
                <Text
                    bold
                    center
                    className={'text-3xl sm:text-4xl text-gray-800 my-8 mx-2 mt-6 sm:my-12 sm:mx-6 sm:mt-16'}
                >
                    {t(`${typeType}_${page}_page_title`)}
                </Text>
            </div>
        </ModuleContainer>
    );
}

export function PageTitleTypeModule(props: PageTitleTypeModuleProps) {
    return <ModuleMode {...props} component={Component} />;
}

export type PageTitleTypeModuleProps = TypeModuleProps;

export default PageTitleTypeModule;
