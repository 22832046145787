import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./quote/StandardQuoteTypeModule')),
};

export function QuoteTypeModule(props: QuoteTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type QuoteTypeModuleProps = TypeModuleProps;

export default QuoteTypeModule;
