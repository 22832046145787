import Img from './Img';
import { WithAlt, WithNativeOnClick, WithUrl } from '../withs';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function LightSponsoringImage({ alt, onClick, url }: LightSponsoringImageProps) {
    const { logoShapeClass } = useStyling();

    return (
        <div
            className={clsx(
                'max-w-[200px] h-24 justify-center items-center border-2 border-transparent overflow-hidden hover:cursor-pointer hover:border-2 hover:border-go-primary',
                logoShapeClass,
            )}
        >
            <Img
                className={'w-auto max-h-full object-fill'}
                url={url}
                alt={alt || 'sponsoring'}
                onClick={onClick as any}
            />
        </div>
    );
}

export interface LightSponsoringImageProps extends Required<WithAlt>, WithNativeOnClick, Required<WithUrl> {}

// noinspection JSUnusedGlobalSymbols
export default LightSponsoringImage;
