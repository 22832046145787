import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from '@tsparticles/engine';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        backgroundMask: {
            enable: false,
            cover: {
                value: {
                    r: 0,
                    g: 0,
                    b: 0,
                },
            },
        },
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            color: {
                value: [
                    '#3998D0',
                    '#2EB6AF',
                    '#A9BD33',
                    '#FEC73B',
                    '#F89930',
                    '#F45623',
                    '#D62E32',
                    '#EB586E',
                    '#9952CF',
                ],
                animation: {
                    enable: true,
                    speed: 30,
                },
            },
            move: {
                direction: 'bottom',
                enable: true,
                outModes: {
                    default: 'out',
                },
                size: true,
                speed: {
                    min: 1,
                    max: 3,
                },
            },
            number: {
                value: 500,
                density: {
                    enable: true,
                    area: 800,
                } as any,
            },
            opacity: {
                value: 1,
                animation: {
                    enable: false,
                    startValue: 'max',
                    destroy: 'min',
                    speed: 0.3,
                    sync: true,
                },
            },
            rotate: {
                value: {
                    min: 0,
                    max: 360,
                },
                direction: 'random',
                move: true,
                animation: {
                    enable: true,
                    speed: 60,
                },
            },
            tilt: {
                direction: 'random',
                enable: true,
                move: true,
                value: {
                    min: 0,
                    max: 360,
                },
                animation: {
                    enable: true,
                    speed: 60,
                },
            },
            shape: {
                type: ['triangle', 'circle', 'square'],
            },
            size: {
                value: {
                    min: 3,
                    max: 5,
                },
            },
            roll: {
                darken: {
                    enable: true,
                    value: 30,
                },
                enlighten: {
                    enable: true,
                    value: 30,
                },
                enable: true,
                speed: {
                    min: 15,
                    max: 25,
                },
            },
            wobble: {
                distance: 30,
                enable: true,
                move: true,
                speed: {
                    min: -15,
                    max: 15,
                },
            },
        },
    };
};
