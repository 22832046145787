import { useCallback } from 'react';
import clsx from 'clsx';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import buildTextureUrl from '@gotombola/react-modules-engine/lib/utils/buildTextureUrl';
import useVals from '../hooks/useVals';

const styles = {
    defaults: {
        size: 'md',
    },
    sizes: {
        xs: 'border-1 h-2 w-2',
        sm: 'border-2 h-6 w-6',
        md: 'border-2 h-10 w-10',
        lg: 'border-4 h-14 w-14',
    },
};

const themes = {
    standard: [
        { value: 'webp/az_subtle.webp', label: 'az_subtle' },
        { value: 'webp/back_pattern.webp', label: 'back_pattern' },
        { value: 'webp/bananas.webp', label: 'bananas' },
        { value: 'webp/blue-snow.webp', label: 'blue-snow' },
        { value: 'webp/bright_squares.webp', label: 'bright_squares' },
        { value: 'webp/checkerboard-cross.webp', label: 'checkerboard-cross' },
        { value: 'webp/chevron.webp', label: 'chevron' },
        { value: 'webp/circles-and-roundabouts.webp', label: 'circles-and-roundabouts' },
        { value: 'webp/circles-dark.webp', label: 'circles-dark' },
        { value: 'webp/circles-light.webp', label: 'circles-light' },
        { value: 'webp/congruent_pentagon.webp', label: 'congruent_pentagon' },
        { value: 'webp/connect.webp', label: 'connect' },
        { value: 'webp/contemporary_china.webp', label: 'contemporary_china' },
        { value: 'webp/contemporary_china_2.webp', label: 'contemporary_china_2' },
        { value: 'webp/country-quilt.webp', label: 'country-quilt' },
        { value: 'webp/diagonal_striped_brick.webp', label: 'diagonal_striped_brick' },
        { value: 'webp/dimension.webp', label: 'dimension' },
        { value: 'webp/doodles.webp', label: 'doodles' },
        { value: 'webp/dot-grid.webp', label: 'dot-grid' },
        { value: 'webp/double-bubble-outline.webp', label: 'double-bubble-outline' },
        { value: 'webp/dynamic-style.webp', label: 'dynamic-style' },
        { value: 'webp/egg_shell.webp', label: 'egg_shell' },
        { value: 'webp/eight_horns.webp', label: 'eight_horns' },
        { value: 'webp/email-pattern.webp', label: 'email-pattern' },
        { value: 'webp/escheresque.webp', label: 'escheresque' },
        { value: 'webp/escheresque_ste.webp', label: 'escheresque_ste' },
        { value: 'webp/fake_luxury.webp', label: 'fake_luxury' },
        { value: 'webp/fishnets-and-hearts.webp', label: 'fishnets-and-hearts' },
        { value: 'webp/foggy_birds.webp', label: 'foggy_birds' },
        { value: 'webp/folk-pattern.webp', label: 'folk-pattern' },
        { value: 'webp/food.webp', label: 'food' },
        { value: 'webp/fresh_snow.webp', label: 'fresh_snow' },
        { value: 'webp/full-bloom.webp', label: 'full-bloom' },
        { value: 'webp/funky-lines.webp', label: 'funky-lines' },
        { value: 'webp/gaming-pattern.webp', label: 'gaming-pattern' },
        { value: 'webp/geometric-leaves.webp', label: 'geometric-leaves' },
        { value: 'webp/geometry.webp', label: 'geometry' },
        { value: 'webp/geometry2.webp', label: 'geometry2' },
        { value: 'webp/gradient_squares.webp', label: 'gradient_squares' },
        { value: 'webp/graphy.webp', label: 'graphy' },
        { value: 'webp/gravel.webp', label: 'gravel' },
        { value: 'webp/greek-vase.webp', label: 'greek-vase' },
        { value: 'webp/grey_sandbag.webp', label: 'grey_sandbag' },
        { value: 'webp/greyfloral.webp', label: 'greyfloral' },
        { value: 'webp/gridme.webp', label: 'gridme' },
        { value: 'webp/grunge_wall.webp', label: 'grunge_wall' },
        { value: 'webp/hotel-wallpaper.webp', label: 'hotel-wallpaper' },
        { value: 'webp/houndstooth-pattern.webp', label: 'houndstooth-pattern' },
        { value: 'webp/hypnotize.webp', label: 'hypnotize' },
        { value: 'webp/intersection.webp', label: 'intersection' },
        { value: 'webp/large_leather.webp', label: 'large_leather' },
        { value: 'webp/leaves-pattern.webp', label: 'leaves-pattern' },
        { value: 'webp/leaves.webp', label: 'leaves' },
        { value: 'webp/let-there-be-sun.webp', label: 'let-there-be-sun' },
        { value: 'webp/light-grey-terrazzo.webp', label: 'light-grey-terrazzo' },
        { value: 'webp/lilypads.webp', label: 'lilypads' },
        { value: 'webp/linedpaper.webp', label: 'linedpaper' },
        { value: 'webp/logo_x_pattern.webp', label: 'logo_x_pattern' },
        { value: 'webp/lyonnette.webp', label: 'lyonnette' },
        { value: 'webp/memphis-colorful.webp', label: 'memphis-colorful' },
        { value: 'webp/memphis-mini.webp', label: 'memphis-mini' },
        { value: 'webp/merely_cubed.webp', label: 'merely_cubed' },
        { value: 'webp/new_year_background.webp', label: 'new_year_background' },
        { value: 'webp/nice_snow.webp', label: 'nice_snow' },
        { value: 'webp/nistri.webp', label: 'nistri' },
        { value: 'webp/noisy_grid.webp', label: 'noisy_grid' },
        { value: 'webp/organic-tiles.webp', label: 'organic-tiles' },
        { value: 'webp/p4.webp', label: 'p4' },
        { value: 'webp/paisley.webp', label: 'paisley' },
        { value: 'webp/palm-leaf.webp', label: 'palm-leaf' },
        { value: 'webp/papyrus.webp', label: 'papyrus' },
        { value: 'webp/photography.webp', label: 'photography' },
        { value: 'webp/pinstriped_suit.webp', label: 'pinstriped_suit' },
        { value: 'webp/pipes.webp', label: 'pipes' },
        { value: 'webp/pixel-heart.webp', label: 'pixel-heart' },
        { value: 'webp/polonez_car.webp', label: 'polonez_car' },
        { value: 'webp/pow-star.webp', label: 'pow-star' },
        { value: 'webp/ps_neutral.webp', label: 'ps_neutral' },
        { value: 'webp/psychedelic_pattern.webp', label: 'psychedelic_pattern' },
        { value: 'webp/purty_wood.webp', label: 'purty_wood' },
        { value: 'webp/pyramid.webp', label: 'pyramid' },
        { value: 'webp/qbkls.webp', label: 'qbkls' },
        { value: 'webp/regal.webp', label: 'regal' },
        { value: 'webp/restaurant_icons.webp', label: 'restaurant_icons' },
        { value: 'webp/rip_jobs.webp', label: 'rip_jobs' },
        { value: 'webp/round.webp', label: 'round' },
        { value: 'webp/sakura.webp', label: 'sakura' },
        { value: 'webp/sativa.webp', label: 'sativa' },
        { value: 'webp/seigaiha.webp', label: 'seigaiha' },
        { value: 'webp/silver_scales.webp', label: 'silver_scales' },
        { value: 'webp/skelatal_weave.webp', label: 'skelatal_weave' },
        { value: 'webp/skulls.webp', label: 'skulls' },
        { value: 'webp/sneaker_mesh_fabric.webp', label: 'sneaker_mesh_fabric' },
        { value: 'webp/soft_circle_scales.webp', label: 'soft_circle_scales' },
        { value: 'webp/spikes.webp', label: 'spikes' },
        { value: 'webp/spiration-light.webp', label: 'spiration-light' },
        { value: 'webp/sports.webp', label: 'sports' },
        { value: 'webp/spring.webp', label: 'spring' },
        { value: 'webp/stonehaven.webp', label: 'stonehaven' },
        { value: 'webp/stripes-light (1).webp', label: 'stripes-light (1)' },
        { value: 'webp/stripes-light.webp', label: 'stripes-light' },
        { value: 'webp/sun-pattern.webp', label: 'sun-pattern' },
        { value: 'webp/swirl_pattern.webp', label: 'swirl_pattern' },
        { value: 'webp/tic-tac-toe.webp', label: 'tic-tac-toe' },
        { value: 'webp/tileable_wood_texture.webp', label: 'tileable_wood_texture' },
        { value: 'webp/topography.webp', label: 'topography' },
        { value: 'webp/trees.webp', label: 'trees' },
        { value: 'webp/triangle-mosaic.webp', label: 'triangle-mosaic' },
        { value: 'webp/vintage-leaves.webp', label: 'vintage-leaves' },
        { value: 'webp/washi.webp', label: 'washi' },
        { value: 'webp/wavy-dots.webp', label: 'wavy-dots' },
        { value: 'webp/what-the-hex.webp', label: 'what-the-hex' },
        { value: 'webp/white-waves.webp', label: 'white-waves' },
        { value: 'webp/white_brick_wall.webp', label: 'white_brick_wall' },
        { value: 'webp/white_leather.webp', label: 'white_leather' },
        { value: 'webp/xv.webp', label: 'xv' },
        { value: 'png/arab_tile.png', label: 'arab_tile' },
        { value: 'png/asanoha-400px.png', label: 'asanoha-400px' },
        { value: 'png/checkered_pattern.png', label: 'checkered_pattern' },
        { value: 'png/confectionary.png', label: 'confectionary' },
        { value: 'png/congruent_outline.png', label: 'congruent_outline' },
        { value: 'png/crisp_paper_ruffles.png', label: 'crisp_paper_ruffles' },
        { value: 'png/diagonales_decalees.png', label: 'diagonales_decalees' },
        { value: 'png/floor-tile.png', label: 'floor-tile' },
        { value: 'png/gplaypattern.png', label: 'gplaypattern' },
        { value: 'png/grilled.png', label: 'grilled' },
        { value: 'png/hexellence.png', label: 'hexellence' },
        { value: 'png/ignasi_pattern_s.png', label: 'ignasi_pattern_s' },
        { value: 'png/interlaced.png', label: 'interlaced' },
        { value: 'png/leaves.png', label: 'leaves' },
        { value: 'png/light_grey.png', label: 'light_grey' },
        { value: 'png/light_honeycomb.png', label: 'light_honeycomb' },
        { value: 'png/lined_paper.png', label: 'lined_paper' },
        { value: 'png/moroccan-flower.png', label: 'moroccan-flower' },
        { value: 'png/old_moon.png', label: 'old_moon' },
        { value: 'png/paisley.png', label: 'paisley' },
        { value: 'png/pineapplecut.png', label: 'pineapplecut' },
        { value: 'png/retina_wood.png', label: 'retina_wood' },
        { value: 'png/sayagata-400px.png', label: 'sayagata-400px' },
        { value: 'png/spikes.png', label: 'spikes' },
        { value: 'png/strange_bullseyes.png', label: 'strange_bullseyes' },
        { value: 'png/symphony.png', label: 'symphony' },
        { value: 'png/textured_stripes.png', label: 'textured_stripes' },
        { value: 'png/tree_bark.png', label: 'tree_bark' },
        { value: 'png/upfeathers.png', label: 'upfeathers' },
        { value: 'png/wet_snow.png', label: 'wet_snow' },
        { value: 'png/white_wall2.png', label: 'white_wall2' },
        { value: 'png/whitediamond.png', label: 'whitediamond' },
        { value: 'png/y-so-serious-white.png', label: 'y-so-serious-white' },
    ],
};
export function TextureDataField({
    placeholder,
    onChange,
    emptyAllowed = true,
    theme = 'standard',
    values = undefined,
    allData,
    size = 'md',
    ...props
}: TextureDataFieldProps) {
    const { t } = useTrans();
    const selectedValues = useVals(
        values,
        themes,
        theme,
        'standard',
        t,
        emptyAllowed,
        'data_field_texture_empty_label',
    );

    const handleClick = useCallback(
        (value) => (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            onChange && onChange({ target: { value } });
        },
        [onChange],
    );

    return (
        <div className={'flex flex-wrap gap-2'}>
            {selectedValues.map(({ value }: any, index: number) => {
                const finalValue = `url(${buildTextureUrl(value)})`;
                return (
                    <div
                        key={index}
                        className={clsx(
                            'rounded-sm hover:border-go-primary cursor-pointer shadow shadow-3xl',
                            styles.sizes[size || styles.defaults.size] || false,
                            !value && 'border-dotted',
                            value === props.value ? 'border-go-primary' : !value ? '' : 'border-transparent',
                        )}
                        style={{ background: finalValue }}
                        onClick={handleClick(value)}
                    />
                );
            })}
        </div>
    );
}

export interface TextureDataFieldProps {
    name: string;
    onChange: Function;
    value: any;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    emptyAllowed?: boolean;
    values?: any[];
    theme?: string;
    allData?: any;
    register: Function;
    control: Function;
    size?: string;
}

// noinspection JSUnusedGlobalSymbols
export default TextureDataField;
