import { useMemo } from 'react';
import baseModules from '../modules';
import useModulesContext from '@gotombola/react-contexts/lib/hooks/useModulesContext';

export function useModule(type: string, config: any) {
    const { modules: extraModules } = useModulesContext();
    const modules = useMemo(() => ({ ...baseModules, ...extraModules }), [extraModules]);
    const m = modules[type || ''];
    if (!m) console.log(`gotb: unknown module '${type}'`, config);
    return m || modules['unknown'];
}

export default useModule;
