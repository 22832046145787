import useActionsContext from './useActionsContext';
import defaultFallbackAction from '../utils/defaultFallbackAction';
import { action_fn } from '../types';
import { useCallback } from 'react';
import api from '../utils/api';

export function useAction(name: string): action_fn {
    const fn = useActionsContext()[name] as undefined | action_fn;

    return useCallback(
        async (data?: any, context?: { api?: Function; [key: string]: any }) => {
            return (fn || defaultFallbackAction)(data, { api, ...context });
        },
        [fn],
    );
}

export default useAction;
