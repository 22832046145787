import loadable from '@loadable/component';
import { ModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./message/StandardMessageCommonModule')),
};

export function MessageCommonModule(props: MessageCommonModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type MessageCommonModuleProps = ModuleProps;

export default MessageCommonModule;
