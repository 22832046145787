import { TypeModuleProps } from '../../../types';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import Text from '../../../atoms/Text';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any) => !!(doc?.features || []).includes('demo');

export function StandardDemoBannerTypeModule({ typeType, ...rest }: StandardDemoBannerTypeModuleProps) {
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    const { t } = useTypeTranslation(type, doc);

    if (!doc) return null;

    return (
        <div className={'fixed left-0 bottom-0 right-0 z-40 h-auto bg-red-500 text-white'}>
            <Text center>{t('demo_disclaimer')}</Text>
        </div>
    );
}

export type StandardDemoBannerTypeModuleProps = TypeModuleProps;

export default StandardDemoBannerTypeModule;
