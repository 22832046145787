import clsx from 'clsx';
import { WithClassName } from '../../withs';
import useQueryString from '../../hooks/useQueryString';

export function PageHeaderModule({ className }: PageHeaderModuleProps) {
    const query = useQueryString();

    return (
        <>
            {!query.has('embed') && (
                <div
                    className={clsx(
                        'bg-blue-500 h-80 top-0 left-0 absolute flex flex-col items-center md:flex-row md:justify-around w-full -z-10 shadow-md px-8',
                        className,
                    )}
                />
            )}
        </>
    );
}

export type PageHeaderModuleProps = WithClassName;

export default PageHeaderModule;
