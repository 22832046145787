import { useMemo } from 'react';
import { WithButton, WithButton2, WithButton3, WithClassName } from '../withs';
import Button from '../atoms/Button';
import clsx from 'clsx';

const parsedKey = {
    target: 'targetMode',
};

function parseFormat(str: string) {
    return str
        .split(/\s*,\s*/g)
        .map((x) => x.split(/\s*=\s*/))
        .reduce((acc, [k, v]) => {
            acc[parsedKey[k] || k] = v;
            return acc;
        }, {});
}

export function Buttons({
    buttonLabel,
    buttonTarget,
    buttonFormat,
    button2Label,
    button2Target,
    button2Format,
    button3Label,
    button3Target,
    button3Format,
    className,
}: ButtonsProps) {
    if (!buttonTarget && !button2Target && !button3Target) return null;
    const buttonProps = useMemo(() => !!buttonFormat && parseFormat(buttonFormat), [buttonFormat]);
    const button2Props = useMemo(() => !!button2Format && parseFormat(button2Format), [button2Format]);
    const button3Props = useMemo(() => !!button3Format && parseFormat(button3Format), [button3Format]);

    return (
        <div className={clsx('sm:mt-4 flex flex-wrap gap-2', className)}>
            {!!buttonLabel && !!buttonTarget && <Button label={buttonLabel} target={buttonTarget} {...buttonProps} />}
            {!!button2Label && !!button2Target && (
                <Button label={button2Label} target={button2Target} variant={'secondary'} {...button2Props} />
            )}
            {!!button3Label && !!button3Target && (
                <Button label={button3Label} target={button3Target} {...button3Props} />
            )}
        </div>
    );
}

export interface ButtonsProps extends WithButton, WithButton2, WithButton3, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default Buttons;
