import ChoiceMenu from '../../molecules/ChoiceMenu';
import { Controller } from 'react-hook-form';
import { forwardRef } from 'react';

const defaultItems: any[] = [];
// noinspection JSUnusedLocalSymbols
export const ChoiceField = forwardRef(
    ({ name, defaultValue, items = defaultItems, ...props }: ChoiceFieldProps, ref) => {
        // noinspection JSUnusedLocalSymbols
        return (
            <Controller
                name={name}
                defaultValue={defaultValue}
                {...props}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                }) => {
                    return <ChoiceMenu items={items} />;
                }}
            />
        );
    },
);

export interface ChoiceFieldProps {
    items: any[];
    name: string;
    defaultValue?: any;
}

// noinspection JSUnusedGlobalSymbols
export default ChoiceField;
