import { useCallback, useState } from 'react';
import clsx from 'clsx';
import DataSectionContent from '../molecules/DataSectionContent';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';

const types = {
    data_section: DataSectionContent,
};

const defaultItems: any[] = [];

export function Accordion({ items = defaultItems, itemProps, defaultOpened = undefined }: AccordionProps) {
    const { t } = useTrans();
    const [open, setOpen] = useState<number | undefined>(defaultOpened);
    const handleOpen = useCallback(
        (index: number) => (e: any) => {
            e?.stopPropagation();
            e?.preventDefault();
            setOpen(open === index ? undefined : index);
        },
        [setOpen, open],
    );
    return (
        <div className='flex flex-col w-full gap-px'>
            {items.map(({ title, ...item }: any, index: number) => {
                const Comp = types[item.type || ''] || undefined;
                const opened = index === open;
                const onToggle = handleOpen(index);
                const content = Comp ? <Comp {...item} {...itemProps} onToggle={onToggle} opened={opened} /> : null;

                return (
                    <div key={index} className='flex flex-col gap-2 w-full'>
                        <div
                            className={clsx(
                                'font-go-text cursor-pointer text-left w-full p-4 text-white text-xl',
                                opened
                                    ? 'font-bold hover:bg-go-primary bg-go-secondary'
                                    : 'bg-go-primary hover:bg-go-secondary font-normal',
                            )}
                            onClick={onToggle as any}
                        >
                            {t(title)}
                        </div>
                        <div className={clsx('p-4', !opened && 'hidden')}>{content}</div>
                    </div>
                );
            })}
        </div>
    );
}

export interface AccordionProps {
    items: any[];
    itemProps: Record<string, any>;
    defaultOpened?: number;
}

export default Accordion;
