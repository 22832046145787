import { createContext } from 'react';
import { edit_mode_context_value } from '../types';
import createDefaultEditModeContextValue from '../utils/createDefaultEditModeContextValue';

export const EditModeContext = createContext<edit_mode_context_value>(createDefaultEditModeContextValue());
// noinspection JSUnusedGlobalSymbols
export const EditModeContextProvider = EditModeContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const EditModeContextConsumer = EditModeContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default EditModeContext;
