export function fbq(action: string, name?: string, data?: any) {
    try {
        if ('undefined' !== typeof window) {
            if (window) {
                window['fbq'] && window['fbq'](action, name, data);
            }
        }
    } catch (e) {
        console.error(e);
    }
}

export function trackCompleteRegistration() {
    fbq('track', 'CompleteRegistration');
}
export function trackLead() {
    fbq('track', 'Lead');
}
export function trackContact() {
    fbq('track', 'Contact');
}
