import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useTypeUrl from '../../../hooks/useTypeUrl';
import TypeFunnelStep from '../../../organisms/TypeFunnelStep';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import useQueryString from '../../../hooks/useQueryString';

export function IntroductionRegisterFormPartnerModule({
    partner,
    ...rest
}: IntroductionRegisterFormPartnerModuleProps) {
    // @ts-ignore
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('partner', partner);
    const qs = useQueryString();
    const targetUrl = useTypeUrl('partner', partner, 'register_website', qs);

    return (
        <ModuleContainer {...zprops}>
            <TypeFunnelStep
                type={'partner'}
                doc={partner}
                title={t('partner_register_form_introduction_title')}
                text={t('partner_register_form_introduction_text')}
                buttonLabel={t('partner_register_form_introduction_cta')}
                buttonTarget={targetUrl}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface IntroductionRegisterFormPartnerModuleProps {
    partner: any;
}

export default IntroductionRegisterFormPartnerModule;
