import { DefaultNamespace, KeyPrefix, Namespace, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';
import { useCallback } from 'react';
import useTypeTranslation from './useTypeTranslation';

export function usePageTypeTranslation<
    N extends Namespace = DefaultNamespace,
    TKPrefix extends KeyPrefix<N> = undefined,
>(
    page: string,
    type: string,
    doc?: any,
    ns?: N | Readonly<N>,
    options?: UseTranslationOptions<TKPrefix>,
): UseTranslationResponse<N, TKPrefix> {
    const { t, i18n, ready } = useTypeTranslation(type, doc, ns, options);
    const enhancedT = useCallback(
        (key: string | string[], opts?: any) => {
            key = Array.isArray(key) ? key : [key];
            return t(
                (key as string[]).reduce((acc: string[], k: string) => {
                    acc.push(`${page}_page_${k}`);
                    acc.push(k); // keep this label/key in case it does not comply with naming pattern in translation file
                    return acc;
                }, [] as string[]) as any,
                opts,
            );
        },
        [t, page],
    );
    return { t: enhancedT, i18n, ready } as UseTranslationResponse<N, TKPrefix>;
}

// noinspection JSUnusedGlobalSymbols
export default usePageTypeTranslation;
