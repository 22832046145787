import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import ModuleContainer from '../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';
import Text from '../../atoms/Text';

function Component({ game, ...rest }: any) {
    const [zprops] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);

    return (
        <ModuleContainer {...zprops}>
            <div className={'w-full space-y-2'}>
                <Text bold className={'sm:text-xl'}>
                    {t('game_legal_page_about_website')}
                </Text>
                <div className={'flex flex-col gap-2'}>
                    {!!game.organizationName && (
                        <Text relaxed className={'sm:text-lg'}>{`${t('game_legal_page_about_organization')} ${
                            game.organizationName
                        }`}</Text>
                    )}
                    {!!game.serviceProviderName && (
                        <Text relaxed className={'sm:text-lg'}>{`${t('game_legal_page_about_provider')} ${
                            game.serviceProviderName
                        }`}</Text>
                    )}
                    {!!game.serviceHostName && (
                        <Text inline relaxed className={'sm:text-lg'}>{`${t('game_legal_page_about_host')} ${
                            game.serviceHostName
                        }`}</Text>
                    )}
                </div>
            </div>
        </ModuleContainer>
    );
}

export function LegalAboutWebsiteGameModule(props: LegalAboutWebsiteGameModuleProps) {
    return <ModuleMode {...props} component={Component} />;
}

export type LegalAboutWebsiteGameModuleProps = GameModuleProps;

export default LegalAboutWebsiteGameModule;
