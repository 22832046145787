import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function DoneeTemplate({ donee, page = 'donee_public', ...props }: DoneeTemplateProps) {
    return <TypeTemplate type={'donee'} page={page.replace(/^donee_/, '')} doc={donee} {...props} />;
}

export interface DoneeTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    donee?: any;
}

export default DoneeTemplate;
