import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypePresentation from '../../../organisms/type/TypePresentation';

export function StandardPresentationGameModule({ game, ...rest }: StandardPresentationGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <TypePresentation {...props} type={'game'} doc={game} />
        </ModuleContainer>
    );
}

export interface StandardPresentationGameModuleProps {
    game?: any;
}

export default StandardPresentationGameModule;
