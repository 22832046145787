import DrawPodiumCard from '../molecules/DrawPodiumCard';
import clsx from 'clsx';

export function GameDrawResultsPodium({
    draw,
    from = 0,
    to = 3,
    obfuscate = true,
    rankLoading,
}: GameDrawResultsPodiumProps) {
    const items = ((draw || {}).winners || {}).items || [];

    return (
        <div className={clsx('w-full flex flex-col sm:flex-row gap-6', items?.length === 1 && 'justify-center')}>
            {items.map(({ id, ...item }: any, index: number) => {
                return (
                    <DrawPodiumCard
                        key={id || index}
                        obfuscate={obfuscate}
                        {...item}
                        revealed={from <= index && index < to}
                        rankLoading={rankLoading}
                    />
                );
            })}
        </div>
    );
}

export interface GameDrawResultsPodiumProps {
    game: any;
    draw?: any;
    from?: number;
    to?: number;
    obfuscate?: boolean;
    rankLoading?: number;
}

// noinspection JSUnusedGlobalSymbols
export default GameDrawResultsPodium;
