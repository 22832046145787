import TypeDescription, { TypeDescriptionProps } from '../../../organisms/type/TypeDescription';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any) => !!doc?.description;

export function StandardDescriptionTypeModule({ typeType, ...rest }: StandardDescriptionTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    if (!doc) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeDescription {...props} type={type} doc={doc} />
        </ModuleContainer>
    );
}

export interface StandardDescriptionTypeModuleProps extends TypeDescriptionProps {
    typeType: string;
}

export default StandardDescriptionTypeModule;
