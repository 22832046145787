import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./callToAction3/StandardCallToAction3GameModule')),
};

export function CallToAction3GameModule(props: CallToAction3GameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type CallToAction3GameModuleProps = GameModuleProps;

export default CallToAction3GameModule;
