import { createContext } from 'react';
import { donee_context_value } from '../types';
import createDefaultDoneeContextValue from '../utils/createDefaultDoneeContextValue';

export const DoneeContext = createContext<donee_context_value>(createDefaultDoneeContextValue());
// noinspection JSUnusedGlobalSymbols
export const DoneeContextProvider = DoneeContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const DoneeContextConsumer = DoneeContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default DoneeContext;
