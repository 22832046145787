import ServiceFooter from './ServiceFooter';
import StaticIcon from '../atoms/StaticIcon';
import useTypeTranslation from '../hooks/useTypeTranslation';
import useQueryString from '../hooks/useQueryString';
import CountDownPage from '../pages/CountDownPage';
import { RestrictedPage } from '../pages/RestrictedPage';

export function GamePageWrapper({ game, children, locale }: GamePageWrapperProps) {
    const { t } = useTypeTranslation('game', game);
    const qs = useQueryString();

    if (
        (qs.has('unpublished') && 1 === Number(qs.get('unpublished'))) ||
        (!!game.publicPagePublishingStatus && 'PUBLISHED' !== game.publicPagePublishingStatus)
    )
        return (
            <div className={'mt-10 flex flex-col items-center'}>
                <StaticIcon type={'triangle-exclamation'} format={'png'} className={'w-24'} />
                <div className={'font-go-text text-go-text mt-10 text-center text-3xl font-bold'}>
                    {t('game_wrapper_unpublished_title')}
                </div>
                <div className={'font-go-text p-10 text-center font-light text-lg text-red-500'}>
                    {t('game_wrapper_unpublished_text')}
                </div>
                <ServiceFooter />
            </div>
        );

    switch (game.accessStatus) {
        case undefined:
        case null:
        case 'OPENED':
            break;
        case 'ACCESSCODE_RESTRICTED':
            const providedAccesscode: string | undefined = (qs.has('_ac') ? qs.get('_ac') : undefined) || undefined;
            if (!providedAccesscode) return <RestrictedPage accesscode />;
            const foundAccesscode = game?.accesscodes?.items?.find((ac: any) => ac.code === providedAccesscode);
            if (!foundAccesscode) return <RestrictedPage accesscode error={'invalid_code'} />;
            break;
        default:
            return <RestrictedPage />;
    }
    let forcedNow = 0;
    try {
        if (qs.has('__') && 10 < (qs.get('__')?.length || 0)) {
            forcedNow = new Date(qs.get('__') || '').valueOf();
        }
    } catch (e: any) {
        // unable to decode date, ignore
    }
    const delta = Math.ceil(((game?.plannedStartedAt || 0) - (forcedNow || new Date().valueOf())) / 1000);
    if (
        0 < delta &&
        ((qs.has('countdown') && 1 === Number(qs.get('countdown'))) || 'COUNT_DOWN' === game?.openingStatus) &&
        (!qs.has('__') || 1 !== Number(qs.get('__'))) &&
        (!qs.has('nocountdown') || !game?.publicToken || game?.publicToken !== qs.get('nocountdown'))
    ) {
        return (
            <CountDownPage
                delta={delta}
                now={forcedNow > 0 ? forcedNow : undefined}
                locale={locale}
                value={game?.plannedStartedAt || 0}
                title={game?.countDownTitle || t('game_count_down_title')}
                subtitle={game?.countDownSubtitle || t('game_count_down_by', { name: game?.['organizationName'] })}
                text={game?.countDownText || undefined}
                websiteUrl={game?.websiteUrl || game?.organizationWebsiteUrl || undefined}
                mainImage={game?.countDownMainImage || undefined}
                mode={game?.countDownMode || undefined}
            >
                {children}
            </CountDownPage>
        );
    }

    return <>{children}</>;
}

export interface GamePageWrapperProps {
    game: any;
    locale: string | undefined;
    children?: any;
}

export default GamePageWrapper;
