import loadable from '@loadable/component';

export default {
    custom: loadable(() => import('./CustomModule')),
    items: loadable(() => import('./ItemsModule')),
    header: loadable(() => import('./HeaderModule')),
    heading: loadable(() => import('./HeadingModule')),
    paragraph: loadable(() => import('./ParagraphModule')),
    alert_banner: loadable(() => import('./AlertBannerModule')),
    footer: loadable(() => import('./FooterModule')),
    video: loadable(() => import('./VideoModule')),
    unknown: loadable(() => import('./UnknownModule')),
    image: loadable(() => import('./ImageModule')),
    push: loadable(() => import('./PushModule')),
    spacer: loadable(() => import('./SpacerModule')),
};
