export function buildAttributionLabel(a: any, { t }: { t: Function }) {
    if (!a) return '';

    if ('person' === a.type && ('child' === a.subtype || 'orphan' === a.subtype)) {
        if (a.name) {
            return a.name;
        }
        const parts: string[] = [];
        parts.push(a.nickName || a.firstName);
        a.age && parts.push(t(`attribution_age${a.age >= 2 ? '_other' : '_one'}`, { age: a.age }));
        if (a.nationality && 'orphan' === a.subtype)
            parts.push(t('attribution_orphan_nationality', { nationality: a.nationality }));
        else if (a.nationality && 'child' === a.subtype)
            parts.push(t('attribution_child_nationality', { nationality: a.nationality }));
        else if (a.nationality) parts.push(t('attribution_nationality', { nationality: a.nationality }));
        return parts.join(', ');
    }

    return a.nickName || a.firstName || a.name;
}

// noinspection JSUnusedGlobalSymbols
export default buildAttributionLabel;
