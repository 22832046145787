import { WithChildren, WithClassName, WithType } from '../withs';
import useTypeContainerPageCustomizations from '@gotombola/react-modules-engine/lib/hooks/useTypeContainerPageCustomizations';
import ZoneContainer from './ZoneContainer';
import clsx from 'clsx';

export function TypeMainContainer({ className, children, doc, page }: TypeMainContainerProps) {
    const customizationProps = useTypeContainerPageCustomizations(doc, page);

    return (
        <ZoneContainer
            className={clsx('flex-1 flex flex-col bg-go-background-primary font-go-global', className)}
            {...customizationProps}
        >
            {children || ''}
        </ZoneContainer>
    );
}

export interface TypeMainContainerProps extends WithClassName, WithChildren, Required<WithType> {
    page: string;
    doc: any;
}

export default TypeMainContainer;
