import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import BlockQuote from '../../../organisms/BlockQuote';

export function StandardServiceQuotePartnerModule({ partner, ...rest }: StandardServiceQuotePartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'xl'} {...zprops}>
            <BlockQuote
                title={partner.mainQuoteImageTitle}
                subtitle={partner.mainQuoteImageSubtitle}
                text={partner.mainQuote}
                image={partner.mainQuoteImage}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface StandardServiceQuotePartnerModuleProps {
    partner: any;
}

export default StandardServiceQuotePartnerModule;
