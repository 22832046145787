export function buildTypeNextStepUrl(
    type: string,
    doc: any,
    nextStep: any,
    query: URLSearchParams,
    urlBuilder: Function,
    extraVars?: any,
    caseName = 'default',
) {
    if (!nextStep) return undefined;

    const cases = nextStep.cases || { default: nextStep };
    const currentCase = cases[caseName] || cases['default'] || cases[Object.keys(cases)[0]];

    if (!currentCase) return undefined;

    const {
        url: finalUrl = currentCase.url,
        doc: finalDoc = doc,
        extraVars: finalExtraVars = extraVars,
        query: finalQuery = query,
    } = (currentCase?.nextFn ? currentCase.nextFn({ doc, extraVars, query, url: currentCase?.url }) : undefined) || {};

    finalQuery?.sort && finalQuery.sort();
    const qs = finalQuery.toString();

    return urlBuilder(finalUrl, qs, finalExtraVars, finalDoc);
}

// noinspection JSUnusedGlobalSymbols
export default buildTypeNextStepUrl;
