import { createContext } from 'react';
import { ad_zones_context_value } from '../types';
import createDefaultAdZonesContextValue from '../utils/createDefaultAdZonesContextValue';

export const AdZonesContext = createContext<ad_zones_context_value>(createDefaultAdZonesContextValue());
// noinspection JSUnusedGlobalSymbols
export const AdZonesContextProvider = AdZonesContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const AdZonesContextConsumer = AdZonesContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default AdZonesContext;
