import { WithButton, WithOpened, WithText, WithTitle } from '../withs';
import RichText from '../atoms/RichText';
import Modal2, { Modal2Props } from '../molecules/Modal2';
import { useCallback, useState } from 'react';
import useQueryString from '../hooks/useQueryString';

export function WelcomeModal({ text, opened = undefined, buttonLabel, ...props }: WelcomeModalProps) {
    const defaultIsOpened = opened === undefined && useQueryString().has('welcome') ? true : opened;
    const [isOpened, setIsOpened] = useState(defaultIsOpened);
    const onClose = useCallback(() => {
        setIsOpened(false);
    }, [setIsOpened]);
    const onClick = useCallback(() => {
        const url = new URL(window.location.href);
        const baseUrl = url.toString().split('?')[0];
        const params = new URLSearchParams(url.search);
        params.delete('welcome');
        window.location.href = `${baseUrl}?${params}`;
    }, []);

    return (
        <Modal2 opened={isOpened} onClose={onClose} buttonLabel={buttonLabel} buttonTarget={onClick} {...props}>
            <div className={'font-go-text w-3/4 m-auto text-center space-y-6'}>
                {!!text && <RichText value={text} />}
            </div>
        </Modal2>
    );
}

export interface WelcomeModalProps
    extends WithText,
        WithTitle,
        WithButton,
        WithOpened,
        Omit<Modal2Props, 'opened' | 'onClose'> {}

export default WelcomeModal;
