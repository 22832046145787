import loadable from '@loadable/component';
import { SellerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./infos/StandardInfosSellerModule')),
};

export function InfosSellerModule(props: InfosSellerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type InfosSellerModuleProps = SellerModuleProps;

export default InfosSellerModule;
