import { useCallback, useEffect, useState } from 'react';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import CountDownItem from '../molecules/CountDownItem';
import { image } from '@gotombola/types';
import Img from '../atoms/Img';
import Link from '../atoms/Link';
import simplifyUrl from '../utils/simplifyUrl';
import String from '../atoms/String';
import Modal2 from '../molecules/Modal2';

function calculateTimeLeft(value: number, t, now?: number) {
    let timeLeft = {};
    now = undefined === now ? new Date().valueOf() : now;

    const difference = value - now;

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return Object.entries(timeLeft).reduce((acc: any[], [key, value]) => {
        acc.push({ label: t(`game_count_down_${key}`), value: value });
        return acc;
    }, []);
}

export function CountDown({
    title,
    subtitle,
    text,
    mainImage,
    websiteUrl,
    value,
    now = undefined,
    reloadPage = false,
    light = false,
}: CountDownProps) {
    const { t } = useTrans();
    const [opened, setOpened] = useState(false);
    const handleClickToggleConfig = useCallback(
        (e: any) => {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            setOpened(!opened);
        },
        [setOpened, opened],
    );
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(value, t, now));

    const difference = (value || 0) - (now || new Date().valueOf());

    useEffect(() => {
        if (difference < 0) {
            if (reloadPage && 'undefined' !== typeof window) {
                window.location.href =
                    window.location.href.indexOf('?') >= 0
                        ? `${window.location.href}&_reload=${new Date().valueOf()}`
                        : `${window.location.href}?_reload=${new Date().valueOf()}`;
            }
            return;
        }
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(value, t, now));
        }, 1000);
        return () => clearTimeout(timer);
    });

    if (difference < 0) return null;

    if (light)
        return (
            <div className={'flex w-full items-center justify-center gap-2 font-go-text font-light text-2xl'}>
                {(timeLeft || [])?.map(({ label, value }, i) => {
                    return <CountDownItem key={i} label={t(label)} value={value} last={false} light />;
                })}
            </div>
        );

    return (
        <div
            className={
                'z-40 fixed inset-0 min-w-screen min-h-screen bg-go-nav-primary flex flex-col items-center justify-center px-5 py-5'
            }
        >
            <div className={'flex flex-col h-full'}>
                <div className={'flex-1 flex flex-col justify-end'}>
                    {!!mainImage?.url && <Img alt={'main image'} {...mainImage} className={'mb-6'} maxSize={500} />}
                    {!!title && '-' !== title && (
                        <h1 className={'text-go-nav-secondary text-3xl text-center mb-6 font-extralight'}>{title}</h1>
                    )}
                </div>
                <div
                    className={
                        'text-3xl sm:text-6xl text-center flex w-full items-center justify-center gap-1 sm:gap-2'
                    }
                >
                    {(timeLeft || [])?.map(({ label, value }, i) => {
                        return <CountDownItem key={i} label={t(label)} value={value} last={false} />;
                    })}
                </div>
                <div className={'flex-1 flex flex-col justify-start'}>
                    {!!text && '-' !== text && (
                        <String className={'text-go-nav-secondary text-lg text-center my-6'}>{text}</String>
                    )}
                    {!!subtitle && '-' !== subtitle && (
                        <p className={'text-go-nav-secondary text-md text-center mt-6'}>{subtitle}</p>
                    )}
                    {!!websiteUrl && (
                        <Link
                            className={'text-go-nav-secondary text-center mt-4'}
                            label={simplifyUrl(websiteUrl)}
                            href={websiteUrl}
                            color={'light'}
                            size={'sm'}
                        />
                    )}
                    <div
                        className={
                            'text-go-nav-secondary text-xs cursor-pointer underline hover:no-underline mt-6 flex w-full items-center justify-center'
                        }
                        onClick={handleClickToggleConfig}
                    >
                        {t('game_count_down_modal_label')}
                    </div>
                </div>
            </div>
            <Modal2
                opened={opened}
                onClose={handleClickToggleConfig}
                size={'sm'}
                title={t('game_count_down_modal_title')}
            >
                <div className={'w-full m-6 flex flex-col gap-6'}>
                    <String>{t('game_count_down_modal_text_info')}</String>
                    <String>{t('game_count_down_modal_text_settings')}</String>
                </div>
            </Modal2>
        </div>
    );
}

export interface CountDownProps {
    title?: string;
    websiteUrl?: string;
    subtitle?: string;
    value: number;
    now?: number;
    reloadPage?: boolean;
    text?: string;
    mainImage?: image;
    mode?: string;
    light?: boolean;
}

// noinspection JSUnusedGlobalSymbols
export default CountDown;
