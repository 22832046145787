import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import AlertAlertBannerModule from '../../../modules/alert-banners/AlertAlertBannerModule';
import { useMemo } from 'react';

export function StandardAlertBannerSellerModule({
    seller,
    threshold = 10,
    ...rest
}: StandardAlertBannerSellerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('seller', seller);
    const text = useMemo(() => {
        return seller?.statPaidTickets >= threshold
            ? t('seller_alert_banner_text_tickets_sold', {
                  tickets: `**${seller?.statPaidTickets}**`,
                  name: `**${seller?.name}**`,
              })
            : t('seller_alert_banner_text_supported_by', { name: `**${seller?.name}**` });
    }, [t, seller?.name, seller?.statPaidTickets]);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <AlertAlertBannerModule {...props} text={text} color={'custom'} shape={'square'} textAlign={'center'} />
        </ModuleContainer>
    );
}

export interface StandardAlertBannerSellerModuleProps {
    seller: any;
    game?: any;
    threshold?: number;
}

export default StandardAlertBannerSellerModule;
