import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkGemoji from 'remark-gemoji';
import remarkBreaks from 'remark-breaks';
import remarkImages from 'remark-images';
import clsx from 'clsx';
import { WithBasic, WithChildren, WithClassName } from '../withs';

const plugins = {
    default: [remarkBreaks, remarkGfm, remarkGemoji, remarkImages],
};

// noinspection JSUnusedGlobalSymbols
const components: any = {
    default: {
        ul: ({ className, ordered, depth, node, ...props }) => {
            if (ordered) return <ol className={clsx('list-decimal list-ouside ml-4', className)} {...props} />;
            return <ul className={clsx('list-disc list-ouside ml-4', className)} {...props} />;
        },
        ol: ({ className, ordered, depth, node, ...props }) => {
            if (ordered) return <ol className={clsx('list-decimal list-ouside ml-4', className)} {...props} />;
            return <ul className={clsx('list-disc list-ouside ml-4', className)} {...props} />;
        },
        h1: ({ className, children }) => {
            return <h1 className={clsx('text-2xl', className)}>{children}</h1>;
        },
        h2: ({ className, children }) => {
            return <h2 className={clsx('text-xl', className)}>{children}</h2>;
        },
        h3: ({ className, children }) => {
            return <h3 className={clsx('text-lg', className)}>{children}</h3>;
        },
        h4: ({ className, children }) => {
            return <h4 className={clsx('font-bold', className)}>{children}</h4>;
        },
        strong: ({ className, children }) => {
            return <strong className={clsx('font-bold', className)}>{children}</strong>;
        },
        em: ({ className, children }) => {
            return <em className={clsx('italic', className)}>{children}</em>;
        },
    },
};

components['basic'] = {
    ...components.default,
    p: ({ children }) => <>{children}</>,
};

export function Markdown({ basic = false, children, className }: MarkdownProps) {
    const c = components[basic ? 'basic' : 'default'] || components['default'];
    const p = plugins[basic ? 'basic' : 'default'] || plugins['default'];
    const z = useMemo(() => {
        if ('string' === typeof children) {
            const parts: { type: string; value?: any }[] = [];
            children?.split(/\n\n/g).forEach((x, i: number) => {
                i > 0 && parts.push({ type: 'ep' });
                parts.push({ type: 'md', value: x });
            });
            return parts;
        }
        return [{ type: 'md', value: children }];
    }, [children]);

    return (
        <>
            {z
                .map((zz: any, i: number) => {
                    switch (zz.type) {
                        case 'md':
                            return (
                                <ReactMarkdown key={i} remarkPlugins={p} components={c} className={className}>
                                    {zz.value || ''}
                                </ReactMarkdown>
                            );
                        case 'ep':
                            return (
                                <p key={i}>
                                    <br />
                                </p>
                            );
                        default:
                            return null;
                    }
                })
                .filter((xx) => !!xx)}
        </>
    );
}

export interface MarkdownProps extends WithBasic, WithChildren, WithClassName {}

export default Markdown;
