import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./prizes/StandardPrizesGameModule')),
};

export function PrizesGameModule(props: PrizesGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export interface PrizesGameModuleProps extends GameModuleProps {
    prizes?: any[];
    typeType?: string;
    nocontact?: boolean;
}

export default PrizesGameModule;
