import { Trans } from 'react-i18next';
import { dynamic_page_body_item_config } from '../types';
import { WithChildren, WithItems } from '../withs';
import CentralBoxLayout from '../layouts/CentralBoxLayout';
import DynamicPageBodyItems from '../organisms/DynamicPageBodyItems';

export function CentralBoxTemplate({ children, items = [], size }: CentralBoxTemplateProps) {
    const headerChildren = (
        <div className={'flex items-center justify-center h-full w-full'}>
            <h1 className={'text-center text-white text-lg'}>
                <Trans i18nKey={'template_central_box_big_title'}>
                    xxx <span className={'text-yellow-500'}>yyy</span> zzz
                </Trans>
            </h1>
        </div>
    );

    return (
        <CentralBoxLayout headerChildren={headerChildren} size={size}>
            {children || ''}
            <DynamicPageBodyItems items={items} />
        </CentralBoxLayout>
    );
}

export interface CentralBoxTemplateProps extends WithChildren, WithItems<dynamic_page_body_item_config> {
    size?: any;
}

export default CentralBoxTemplate;
