import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    thumbnails: loadable(() => import('./bunches/ThumbnailsBunchesGameModule')),
    list: loadable(() => import('./bunches/ListBunchesGameModule')),
    featured: loadable(() => import('./bunches/FeaturedBunchesGameModule')),
    gallery: loadable(() => import('./bunches/GalleryBunchesGameModule')),
    mosaic: loadable(() => import('./bunches/MosaicBunchesGameModule')),
    cards: loadable(() => import('./bunches/CardsBunchesGameModule')),
};

export function BunchesGameModule(props: BunchesGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'list'} />;
}

export type BunchesGameModuleProps = GameModuleProps;

export default BunchesGameModule;
