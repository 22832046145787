import { WithClassName } from '../../withs';
import useQueryString from '../../hooks/useQueryString';
import ServiceFooter from '../../organisms/ServiceFooter';

export function PageFooterModule({ className }: PageFooterModuleProps) {
    const query = useQueryString();

    return <>{!query.has('embed') && <ServiceFooter className={className} />}</>;
}

export type PageFooterModuleProps = WithClassName;

export default PageFooterModule;
