import clsx from 'clsx';
import { WithChildren, WithClassName } from '../withs';

export function HeaderModuleGroup({ children, className }: HeaderModuleGroupProps) {
    return (
        <header id={'header'} className={clsx('w-full', className)}>
            {children || ''}
        </header>
    );
}

export interface HeaderModuleGroupProps extends WithChildren, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default HeaderModuleGroup;
