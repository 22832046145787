import { createContext } from 'react';
import { godfather_context_value } from '../types';
import createDefaultGodfatherContextValue from '../utils/createDefaultGodfatherContextValue';

export const GodfatherContext = createContext<godfather_context_value>(createDefaultGodfatherContextValue());
// noinspection JSUnusedGlobalSymbols
export const GodfatherContextProvider = GodfatherContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const GodfatherContextConsumer = GodfatherContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default GodfatherContext;
