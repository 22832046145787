import { TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import TextBlock from '../../blocks/TextBlock';
import useTypeTranslation from '../../hooks/useTypeTranslation';

export function TypeAgenda({ className, doc, type }: TypeAgendaProps) {
    const { t } = useTypeTranslation(type, doc);

    return <TextBlock className={className} title={t(`${type}_agenda_label`)} text={doc?.agenda} />;
}

export interface TypeAgendaProps extends TypeProps, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default TypeAgenda;
