import { useMemo } from 'react';

export function useDocAttribute(doc: any, attribute: string | string[] | undefined, forcedText?: string) {
    return useMemo(() => {
        if (forcedText) return forcedText;
        if (!attribute) return undefined;
        let attrs: string[] = [];
        if (!Array.isArray(attribute)) attrs = [attribute];
        else attrs = [...attribute];
        const k = attrs.find((x: any) => doc?.[x || '']);
        return doc?.[k || ''];
    }, [attribute, doc]);
}

export default useDocAttribute;
