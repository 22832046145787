import { useCallback, useState } from 'react';
import { http_state } from '../types';
import callHttp from '../utils/callHttp';

export function useHttp(
    url: string,
    method = 'GET',
    headers: any = { 'Content-Type': 'application/json' },
    options: any = {},
) {
    const [state, setState] = useState<http_state>({
        called: false,
        loading: false,
        data: undefined,
        error: undefined,
    });
    const call = useCallback(
        async ({ variables }: { variables?: object } = {}) => {
            const newState = { ...state, called: true, loading: true };
            setState(newState);
            const { error, data } = await callHttp(url, method, headers, variables, options);
            const finalNewState = {
                ...newState,
                loading: false,
                data,
                error,
            };
            setState(finalNewState);
            return finalNewState;
        },
        [url, method, state, setState, headers, options],
    );

    if (!!options['direct']) {
        if (!state.called) {
            call().then(() => {
                // call is completed
            });
        }
        return state;
    }

    return [call, state];
}

export default useHttp;
