// noinspection ES6PreferShortImport
import { SellergroupContextProvider } from '../contexts/SellergroupContext';
// noinspection ES6PreferShortImport
import { GodfatherContextProvider } from '../contexts/GodfatherContext';
// noinspection ES6PreferShortImport
import { SponsoringContextProvider } from '../contexts/SponsoringContext';
// noinspection ES6PreferShortImport
import { GameContextProvider } from '../contexts/GameContext';
// noinspection ES6PreferShortImport
import { OrganizationContextProvider } from '../contexts/OrganizationContext';
// noinspection ES6PreferShortImport
import { PartnerContextProvider } from '../contexts/PartnerContext';
// noinspection ES6PreferShortImport
import { CampaignContextProvider } from '../contexts/CampaignContext';
// noinspection ES6PreferShortImport
import { TenantContextProvider } from '../contexts/TenantContext';
// noinspection ES6PreferShortImport
import { AdminContextProvider } from '../contexts/AdminContext';
// noinspection ES6PreferShortImport
import { SellerContextProvider } from '../contexts/SellerContext';
// noinspection ES6PreferShortImport
import { SponsorContextProvider } from '../contexts/SponsorContext';
// noinspection ES6PreferShortImport
import { DoneeContextProvider } from '../contexts/DoneeContext';

const map = {
    godfather: GodfatherContextProvider,
    sponsoring: SponsoringContextProvider,
    sellergroup: SellergroupContextProvider,
    seller: SellerContextProvider,
    game: GameContextProvider,
    sponsor: SponsorContextProvider,
    donee: DoneeContextProvider,
    organization: OrganizationContextProvider,
    partner: PartnerContextProvider,
    campaign: CampaignContextProvider,
    tenant: TenantContextProvider,
    admin: AdminContextProvider,
};

export function useTypeContextProviderComponent(type, throwError = true) {
    const comp = map[type || ''];

    if (!comp && throwError) throw new Error(`Unknown type '${type}'`);

    return comp;
}

// noinspection JSUnusedGlobalSymbols
export default useTypeContextProviderComponent;
