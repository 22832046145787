import CenteredHeadingModule from '../modules/headings/CenteredHeadingModule';
import Img from '../atoms/Img';
import Items from '../atoms/Items';
import Figure from '../atoms/Figure';
import { WithImage, WithItems, WithTitle } from '../withs';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function Figures({ title, items = [], image }: FiguresProps) {
    const { mediaShapeClass } = useStyling();

    return (
        <div className={'flex flex-col'}>
            {!!title && <CenteredHeadingModule title={title} />}
            <div className={'flex flex-col sm:flex-row items-center gap-4 sm:gap-10'}>
                {!!image?.url && (
                    <Img
                        {...image}
                        className={clsx('object-cover max-w-full sm:max-w-[500px]', mediaShapeClass)}
                        alt={image.alt || 'image item'}
                        maxSize={800}
                    />
                )}
                {!!items?.length && (
                    <div className={'space-y-6'}>
                        <Items items={items} component={Figure} />
                    </div>
                )}
            </div>
        </div>
    );
}

export interface FiguresProps extends WithImage, WithItems, WithTitle {}

// noinspection JSUnusedGlobalSymbols
export default Figures;
