import Title from '../Title';
import { flag, TypeProps } from '../../types';
import { WithCenter, WithClassName, WithTitle } from '../../withs';

export function TypeTitle({ doc, center = false, title: forcedTitle, ...props }: TypeTitleProps) {
    return <Title title={forcedTitle || doc.name} center={center} {...props} />;
}

export interface TypeTitleProps extends TypeProps, WithClassName, WithTitle, WithCenter {
    bold?: flag;
}

// noinspection JSUnusedGlobalSymbols
export default TypeTitle;
