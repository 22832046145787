import String from '../atoms/String';
import clsx from 'clsx';
import censureContent from '@gotombola/censure';
import useTypeTranslation from '../hooks/useTypeTranslation';
import Spinner from '../atoms/Spinner';

export function DrawPodiumCard({
    revealed = false,
    rankLoading,
    rank,
    ticketCustomCode,
    ticketCode,
    bunchName,
    email,
    phone,
    lastName,
    firstName,
    ticketSellerOnly = false,
    obfuscate,
}: DrawPodiumCardProps) {
    const { t } = useTypeTranslation('game');

    return (
        <div
            className={clsx(
                'w-full sm:w-1/3 border-2 rounded-t-xl rounded-b-sm p-4 pb-10 relative min-h-[250px] overflow-hidden',
                1 === rank && 'sm:order-2',
                2 === rank && 'sm:mt-20 sm:order-1',
                3 === rank && 'sm:mt-28 sm:order-3',
                !revealed && rankLoading !== rank && 'bg-gray-50 border-gray-200',
                !revealed && rankLoading == rank && 'border-go-primary',
                ticketCustomCode && revealed && 'border-go-primary',
                !ticketCustomCode && revealed && 'border-go-secondary',
            )}
        >
            <div className={'flex items-center gap-4 mb-4'}>
                {!!rank && (
                    <div
                        className={clsx(
                            'font-go-text rounded-full p-4 w-16 h-16 text-white flex items-center justify-center text-4xl font-extrabold',
                            !revealed && 'bg-gray-200',
                            ticketCustomCode && revealed && 'bg-go-primary',
                            !ticketCustomCode && revealed && 'bg-go-secondary',
                        )}
                    >
                        {rank}
                    </div>
                )}
                {revealed && (
                    <div>
                        <div className={'font-go-text flex items-center gap-1 font-bold text-lg'}>
                            {!!firstName && (
                                <div>
                                    {!!firstName && firstName}{' '}
                                    {!!lastName && <span className={'font-medium'}>{lastName?.toUpperCase()}</span>}
                                </div>
                            )}
                        </div>
                        {(!!email || !!phone) && (
                            <div className={clsx('font-go-text ', !lastName ? 'font-medium' : 'font-light')}>
                                {censureContent(email || phone || '', email ? 'email' : 'phone', obfuscate)}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {revealed && !!bunchName && <div className={'font-go-text pt-4 font-light'}>{bunchName}</div>}
            {(!revealed || rank === rankLoading) && (
                <div className={'w-full flex justify-center h-16'}>
                    {!revealed && rank !== rankLoading && (
                        <div className={'font-go-text font-extrabold text-5xl text-gray-200'}>?</div>
                    )}
                    {rankLoading === rank && <Spinner type={'default'} />}
                </div>
            )}
            {!!ticketCode && revealed && (
                <div
                    className={clsx(
                        'flex gap-2 items-center absolute left-0 right-0 bottom-0 p-1 justify-end border-t-2',
                        ticketCustomCode ? 'bg-go-primary border-go-primary' : 'bg-go-secondary border-go-secondary',
                    )}
                >
                    <span className={'font-go-text text-white'}>{t('draw_ticket_winner_label')}</span>
                    <div
                        className={clsx(
                            'font-go-text text-white py-1 px-2 rounded-sm text-lg font-bold',
                            ticketCustomCode ? 'text-go-primary' : 'text-go-secondary',
                            'bg-white',
                        )}
                    >
                        {ticketCustomCode ? t('draw_ticket_number', { code: ticketCustomCode }) : ticketCode}
                    </div>
                </div>
            )}
            {!!ticketSellerOnly && revealed && (
                <String
                    className={clsx(
                        'font-go-text text-xs font-bold absolute top-3 right-3 uppercase',
                        ticketCustomCode ? 'text-go-primary' : 'text-go-secondary',
                    )}
                >
                    {t('game_results_page_seller_only_label')}
                </String>
            )}
        </div>
    );
}

export interface DrawPodiumCardProps {
    rank?: number;
    ticketCustomCode?: string;
    ticketCode?: string;
    bunchName?: string;
    email?: string;
    phone?: string;
    lastName?: string;
    firstName?: string;
    ticketSellerOnly?: boolean;
    obfuscate?: boolean;
    revealed?: boolean;
    rankLoading?: number;
}

// noinspection JSUnusedGlobalSymbols
export default DrawPodiumCard;
