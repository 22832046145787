import { TypeModuleProps } from '../../../types';
import TypeContactOwner from '../../../organisms/type/TypeContactOwner';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardContactOwnerTypeModule({ typeType, ...rest }: StandardContactOwnerTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <TypeContactOwner {...props} type={typeType} />
        </ModuleContainer>
    );
}

export type StandardContactOwnerTypeModuleProps = TypeModuleProps;

export default StandardContactOwnerTypeModule;
