import loadable from '@loadable/component';
import { SellergroupModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./infos/StandardInfosSellergroupModule')),
};

export function InfosSellergroupModule(props: InfosSellergroupModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type InfosSellergroupModuleProps = SellergroupModuleProps;

export default InfosSellergroupModule;
