import ModuleContainer from '../../organisms/ModuleContainer';
import AlertBannerModule from '../../modules/AlertBannerModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function AlertBannerCommonModule({ config, ...rest }: AlertBannerCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <AlertBannerModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface AlertBannerCommonModuleProps {
    config: any;
}

export default AlertBannerCommonModule;
