import { useMemo } from 'react';
import clsx from 'clsx';
import { WithChildren, WithClassName, WithZoneCustomization } from '../withs';
import computeProps from '@gotombola/react-modules-engine/lib/utils/computeProps';

export function ZoneContainer({
    children,
    className,
    bgColor,
    bgStyle,
    bgCustom,
    bgTexture,
    bgImage,
    bgVideoUrl,
}: ZoneContainerProps) {
    const backgrounds = useMemo(() => {
        const items: any[] = [];
        bgStyle && items.push({ type: 'style', value: bgStyle });
        bgCustom && items.push({ type: 'custom', value: bgCustom });
        bgColor && items.push({ type: 'color', value: bgColor });
        bgTexture && items.push({ type: 'texture', value: bgTexture });
        bgImage?.url && items.push({ type: 'image', value: bgImage });
        bgVideoUrl && items.push({ type: 'video', value: bgVideoUrl });
        return items;
    }, [bgStyle, bgCustom, bgColor, bgTexture, bgImage?.url, bgVideoUrl]);
    const { className: extraClassName, ...computedProps } = useMemo(() => computeProps(backgrounds), [backgrounds]);

    return (
        <div className={clsx('w-full', extraClassName, className)} {...computedProps}>
            {children || ''}
        </div>
    );
}

export interface ZoneContainerProps extends WithChildren, WithClassName, WithZoneCustomization {}

export default ZoneContainer;
