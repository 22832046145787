import Helmet from 'react-helmet';
import useApplicationContext from '@gotombola/react-contexts/lib/hooks/useApplicationContext';
import { dynamic_page_head_meta } from '../types';
import { WithTitle } from '../withs';

const defaultMetas: dynamic_page_head_meta[] = [];
const defaultFavicon = 'https://statics.gotombola.co/tenants/gotombola/images/favicons/gotombola/favicon-32x32.png';
const defaultAppleTouchIcon =
    'https://statics.gotombola.co/tenants/gotombola/images/favicons/gotombola/apple-touch-icon.png';

export function DynamicPageHead({
    appleTouchIcon = defaultAppleTouchIcon,
    favicon = defaultFavicon,
    lang = undefined,
    metas = defaultMetas,
    noindex = false,
    title = undefined,
}: DynamicPageHeadProps) {
    const { manifestPath } = useApplicationContext();
    if (!metas.find((m) => m.type === 'http-equiv' && m.name === 'X-UA-Compatible')) {
        metas = [{ type: 'http-equiv', name: 'X-UA-Compatible', value: 'IE=edge' }, ...metas];
    }
    if (!metas.find((m) => m.type === 'doc' && m.name === 'viewport')) {
        metas = [{ type: 'doc', name: 'viewport', value: 'width=device-width, initial-scale=1.0' }, ...metas];
    }

    return (
        <Helmet>
            {!!title && <title>{title}</title>}
            {(metas || []).map((meta: dynamic_page_head_meta, i: number) => {
                switch (meta.type) {
                    case 'charset':
                        return <meta key={i} charSet={meta.value} />;
                    case 'http-equiv':
                        return <meta key={i} httpEquiv={meta.name} content={meta.value} />;
                    case 'property':
                        return <meta key={i} property={meta.name} content={meta.value} />;
                    case 'og':
                        return <meta key={i} property={meta.name} content={meta.value} />;
                    case 'doc':
                        return <meta key={i} name={meta.name} content={meta.value} />;
                    default:
                        return <meta key={i} name={meta.name} content={meta.value} />;
                }
            })}
            {!!noindex && <meta name='robots' content='noindex' />}
            <link rel='icon' href={favicon} />
            <link rel='apple-touch-icon' href={appleTouchIcon} />
            {!!manifestPath && <link rel={'manifest'} href={manifestPath} />}
            <html {...(lang ? { lang } : {})} className={'font-go-global'} />
        </Helmet>
    );
}

export interface DynamicPageHeadProps extends WithTitle<string> {
    lang?: string;
    favicon?: string;
    appleTouchIcon?: string;
    metas: dynamic_page_head_meta[];
    noindex?: boolean;
}

export default DynamicPageHead;
