import loadable from '@loadable/component';
import { ModuleProps } from '../types';
import ModuleMode from '../organisms/ModuleMode';

const modes = {
    page: loadable(() => import('./footers/PageFooterModule')),
};

export function FooterModule(props: FooterModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'page'} />;
}

export type FooterModuleProps = ModuleProps;

export default FooterModule;
