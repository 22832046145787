import GenericLocaleSwitcher, { GenericLocaleSwitcherProps } from './GenericLocaleSwitcher';

export function PartnerLocaleSwitcher({ partner, ...props }: PartnerLocaleSwitcherProps) {
    return <GenericLocaleSwitcher item={partner} versionKey={'partnerVersions'} {...props} />;
}

export interface PartnerLocaleSwitcherProps extends Omit<GenericLocaleSwitcherProps, 'item' | 'versionKey'> {
    partner: any;
}

export default PartnerLocaleSwitcher;
