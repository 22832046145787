import PartnerHeader from '../../../molecules/headers/PartnerHeader';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardHeaderPartnerModule({ partner, ...rest }: StandardHeaderPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <PartnerHeader partner={partner} {...props} />
        </ModuleContainer>
    );
}

export interface StandardHeaderPartnerModuleProps {
    partner: any;
}

export default StandardHeaderPartnerModule;
