import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function GameTemplate({ game, page = 'game_public', ...props }: GameTemplateProps) {
    return <TypeTemplate type={'game'} page={page.replace(/^game_/, '')} doc={game} {...props} />;
}

export interface GameTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    game?: any;
}

export default GameTemplate;
