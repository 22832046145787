import { WithImage, WithUrl } from '../withs';
import ClickableContent from './ClickableContent';
import Img from './Img';
import clsx from 'clsx';

const variants = {
    mobile: 'rounded-3xl border border-8',
    desktop: 'rounded-sm border border-2',
};

export function Screenshot({ image, url, variant = 'mobile' }: ScreenshotProps) {
    return (
        <ClickableContent url={url} mode={'_blank'}>
            <Img
                className={clsx('w-32 sm:w-full', variants[variant])}
                url={image.url}
                alt={image.alt || 'image item'}
            />
        </ClickableContent>
    );
}

export interface ScreenshotProps extends Required<WithImage>, WithUrl {
    variant?: string;
}

// noinspection JSUnusedGlobalSymbols
export default Screenshot;
