import TypeSmsKeyword, { TypeSmsKeywordProps } from '../../../organisms/type/TypeSmsKeyword';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardSmsKeywordTypeModule({ typeType, ...rest }: StandardSmsKeywordTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    if (!props.doc?.smsKeyword) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeSmsKeyword {...props} type={typeType} />
        </ModuleContainer>
    );
}

export interface StandardSmsKeywordTypeModuleProps extends Omit<TypeSmsKeywordProps, 'type'> {
    typeType: string;
}

export default StandardSmsKeywordTypeModule;
