import loadable from '@loadable/component';

export default {
    game_beneficiaries: loadable(() => import('./BeneficiariesGameModule')),
    game_cta2: loadable(() => import('./CallToAction2GameModule')),
    game_cta3: loadable(() => import('./CallToAction3GameModule')),
    game_cta: loadable(() => import('./CallToActionGameModule')),
    game_godfathers: loadable(() => import('./GodfathersGameModule')),
    game_bunches: loadable(() => import('./BunchesGameModule')),
    game_featured_bunches: loadable(() => import('./FeaturedBunchesGameModule')),
    game_infos: loadable(() => import('./InfosGameModule')),
    game_mobilecta: loadable(() => import('./MobileCallToActionGameModule')),
    game_organization_presentation: loadable(() => import('./OrganizationPresentationGameModule')),
    game_presentation: loadable(() => import('./PresentationGameModule')),
    game_prizes: loadable(() => import('./PrizesGameModule')),
    game_stats: loadable(() => import('./StatsGameModule')),
    game_footer: loadable(() => import('./FooterGameModule')),
    game_header: loadable(() => import('./HeaderGameModule')),
    game_header_sellergroup: loadable(() => import('./HeaderSellergroupGameModule')),
    game_header_seller: loadable(() => import('./HeaderSellerGameModule')),
    game_key_infos: loadable(() => import('./KeyInfosGameModule')),
    game_share: loadable(() => import('./ShareGameModule')),
    game_leaderboard: loadable(() => import('./LeaderboardGameModule')),
    game_sponsors: loadable(() => import('./SponsorsGameModule')),
    game_files: loadable(() => import('./FilesGameModule')),
    game_labels: loadable(() => import('./LabelsGameModule')),
    game_keyfigures: loadable(() => import('./KeyfiguresGameModule')),
    // legal
    game_legal_about_website: loadable(() => import('./LegalAboutWebsiteGameModule')),
    game_legal_contact_organization: loadable(() => import('./LegalContactOrganizationGameModule')),
    game_legal_contact_us: loadable(() => import('./LegalContactUsGameModule')),
    game_legal_update_time: loadable(() => import('./LegalUpdateTimeGameModule')),
    game_legal_website_host: loadable(() => import('./LegalWebsiteHostGameModule')),
    game_legal_website_provider: loadable(() => import('./LegalWebsiteProviderGameModule')),
    game_owner_game_welcome: loadable(() => import('./OwnerGameWelcomeGameModule')),
    game_sellergroup_registration_form: loadable(() => import('./SellergroupRegistrationFormGameModule')),
    game_godfathers_ranking: loadable(() => import('./GodfathersRankingGameModule')),
    game_collect_mode: loadable(() => import('./CollectModeGameModule')),
};
