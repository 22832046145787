import clsx from 'clsx';
import { WithClassName } from '../../withs';

export function StandardSpacerModule({ className }: StandardSpacerModuleProps) {
    return <div className={clsx('flex flex-col h-2 sm:h-10', className)} />;
}

export type StandardSpacerModuleProps = WithClassName;

export default StandardSpacerModule;
