import clsx from 'clsx';
import { WithChildren, WithClassName } from '../withs';

export function UnknownModuleGroup({ children, className }: UnknownModuleGroupProps) {
    return <div className={clsx('w-full', className)}>{children || ''}</div>;
}

export interface UnknownModuleGroupProps extends WithChildren, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default UnknownModuleGroup;
