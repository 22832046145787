import MultiLineText from './MultiLineText';
import { WithClassName, WithText, WithType } from '../withs';

export function RichTextPartSpan({ className, end, start, text, type = 'normal' }: RichTextPartSpanProps) {
    if (!text) return null;
    const children = <MultiLineText text={text.slice(start, end)} className={className} />;

    switch (type) {
        case 'strong':
            return <b className={className}>{children}</b>;
        case 'underline':
            return <u className={className}>{children}</u>;
        case 'italic':
            return <i className={className}>{children}</i>;
        case 'em':
            return <em className={className}>{children}</em>;
        default:
            return <>{children}</>;
    }
}

export interface RichTextPartSpanProps extends WithClassName, WithText<string>, WithType {
    start?: number;
    end?: number;
}

export default RichTextPartSpan;
