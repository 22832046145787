import createDefaultRouterContextValue from '@gotombola/react-contexts/lib/utils/createDefaultRouterContextValue';
import replaceDocParams from './replaceDocParams';

const defaultRouterContextValue = createDefaultRouterContextValue();

export function buildUrlFactory(routes: any[] = []) {
    const m = routes
        .map((r) => [r.name || r.ssr, r.path])
        .reduce((acc, [a, b]) => Object.assign(acc, { [a]: b }), {} as any);
    return (name: string, doc: any, qs?: any, extraVars?: any) => {
        const key = (name || '').replace(/[^a-z0-9_]+/gi, '_');
        const x = m[key];
        if (!x) return defaultRouterContextValue.buildUrl(name, doc, qs, extraVars);
        let url = replaceDocParams(x, doc, extraVars);
        url = url || '/';
        return `${url}${qs ? (-1 === url.indexOf('?') ? `?` : url.slice(-1) === '?' ? '' : '&') : ''}${qs || ''}`;
    };
}

export default buildUrlFactory;
