import { useMemo } from 'react';
import useTypeContextProviderComponent from '@gotombola/react-contexts-type/lib/hooks/useTypeContextProviderComponent';
import { def_keys_builder, dynamic_page_body_item_config } from '../types';
import { WithChildren, WithClassName, WithItems, WithLocale, WithType } from '../withs';
import useStylingDsn from '@gotombola/react-modules-engine/lib/hooks/useStylingDsn';
// noinspection ES6PreferShortImport
import TypeMainContainer from '../organisms/TypeMainContainer';
import TypeModules from '@gotombola/react-modules-engine/lib/organisms/TypeModules';
import defaultTemplateModules, { aliases as moduleAliases } from '../template-modules';
import StylingProvider from '@gotombola/react-modules-engine/lib/StylingProvider';
import { ContentComponentsContextProvider } from '@gotombola/react-modules-engine/lib/contexts/ContentComponentsContext';
import moduleLayouts from '../template-layouts';
import moduleGroups from '../module-groups';
import { Modal2 } from '../molecules/Modal2';
import { ModuleLayoutsContextProvider } from '@gotombola/react-modules-engine/lib/contexts/ModuleLayoutsContext';
import { ModuleGlobalContextProvider } from '@gotombola/react-modules-engine/lib/contexts/ModuleGlobalContext';
import { ModuleAliasesContextProvider } from '@gotombola/react-modules-engine/lib/contexts/ModuleAliasesContext';
import { ModuleGroupsContextProvider } from '@gotombola/react-modules-engine/lib/contexts/ModuleGroupsContext';
import { ModuleModalComponentContextProvider } from '@gotombola/react-modules-engine/lib/contexts/ModuleModalComponentContext';
import useTemplatesContext from '@gotombola/react-modules-engine/lib/hooks/useTemplatesContext';

export function TypeTemplate({
    children,
    defaultModules,
    doc,
    items = [],
    locale,
    page,
    templateModules,
    type,
    layout,
    defKeysBuilder,
    className,
    ...props
}: TypeTemplateProps) {
    const pageName = `${type}_${page}`;
    const typeContextProviderValue = useMemo(() => ({ [type]: doc, locale }), [type, doc, locale]);
    const keys = [`${page}PageStyling`, 'publicPageStyling'];
    const variantKeys = [`${page}PageModulesVariant`, 'publicPageModulesVariant'];
    const key = keys.find((k) => (doc || {})[k]);
    const variantKey = variantKeys.find((k) => (doc || {})[k]);
    const styling = useStylingDsn((doc || {})[key || '']);
    const variant = useMemo(() => (doc || {})[variantKey || ''], [doc, variantKey]);
    const templates = useTemplatesContext();
    const tm = useMemo(() => ({ ...defaultTemplateModules, ...templateModules }), [type, templateModules]);
    const dgm = useMemo(
        () => ({ ...(templates[pageName || ''] || {}), ...defaultModules }),
        [pageName, defaultModules],
    );
    const TypeContextProviderComp = useTypeContextProviderComponent(type);
    const contentComponentsContextProviderValue = useMemo(() => tm, [tm]);
    const moduleLayoutsProviderValue = useMemo(() => moduleLayouts, [moduleLayouts]);
    const moduleAliasesProviderValue = useMemo(() => moduleAliases, [moduleAliases]);
    const moduleGroupsProviderValue = useMemo(() => moduleGroups, [moduleGroups]);
    const moduleModalComponentProviderValue = useMemo(() => Modal2, [Modal2]);
    const moduleGlobalProviderValue = useMemo(() => ({ variant }), [variant]);

    return (
        <ContentComponentsContextProvider value={contentComponentsContextProviderValue}>
            <TypeContextProviderComp value={typeContextProviderValue}>
                <StylingProvider styling={styling}>
                    <ModuleGlobalContextProvider value={moduleGlobalProviderValue}>
                        <ModuleLayoutsContextProvider value={moduleLayoutsProviderValue}>
                            <ModuleAliasesContextProvider value={moduleAliasesProviderValue}>
                                <ModuleGroupsContextProvider value={moduleGroupsProviderValue}>
                                    <ModuleModalComponentContextProvider value={moduleModalComponentProviderValue}>
                                        <TypeMainContainer doc={doc} type={type} page={page} className={className}>
                                            <TypeModules
                                                {...props}
                                                anchor={true}
                                                doc={doc}
                                                type={type}
                                                page={page}
                                                items={items}
                                                locale={locale}
                                                templateModules={tm}
                                                defaultModules={dgm}
                                                layout={layout}
                                                defKeysBuilder={defKeysBuilder}
                                            >
                                                {children || ''}
                                            </TypeModules>
                                        </TypeMainContainer>
                                    </ModuleModalComponentContextProvider>
                                </ModuleGroupsContextProvider>
                            </ModuleAliasesContextProvider>
                        </ModuleLayoutsContextProvider>
                    </ModuleGlobalContextProvider>
                </StylingProvider>
            </TypeContextProviderComp>
        </ContentComponentsContextProvider>
    );
}

export interface TypeTemplateProps
    extends WithChildren,
        WithItems<dynamic_page_body_item_config>,
        WithLocale,
        WithClassName,
        Required<WithType> {
    page: string;
    doc: any;
    templateModules?: any;
    defaultModules?: any;
    layout?: string;
    defKeysBuilder?: def_keys_builder;
}

export default TypeTemplate;
