import { WithNoindex } from '../withs';
import StaticPage from './StaticPage';
import BasicLayout, { BasicLayoutProps } from '../layouts/BasicLayout';
import ServiceFooter from '../organisms/ServiceFooter';

export function BasicStaticPage({ noindex, ...props }: BasicStaticPageProps) {
    return (
        <StaticPage noindex={noindex}>
            <BasicLayout {...props} />
            <ServiceFooter className={'mt-20'} />
        </StaticPage>
    );
}

export interface BasicStaticPageProps extends BasicLayoutProps, WithNoindex {}

// noinspection JSUnusedGlobalSymbols
export default BasicStaticPage;
