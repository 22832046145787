import Banner from '../../../organisms/Banner';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { SellergroupModuleProps } from '../../../types';
import { useMemo } from 'react';
import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';
import { GameModuleProps } from '@gotombola/react-types/lib/types';
import useQueryString from '../../../hooks/useQueryString';
import useTypeUrl from '../../../hooks/useTypeUrl';

const targets = {
    game: (doc: any, buyUrl: string) =>
        doc?.status === 'RUNNING'
            ? doc?.customBannerTargetUrl || buyUrl || doc?.customSalePageUrl || doc?.salePageUrl
            : undefined,
};

export function StandardBannerSellergroupModule({ game, sellergroup, ...rest }: StandardBannerSellergroupModuleProps) {
    game = ((game ? { game } : useGameContext()) || {}).game;
    const [zprops, props] = useZoneCustomizationProps(rest);

    const qs = useQueryString();
    qs.set('sg', sellergroup.code);
    const buyUrl = useTypeUrl('game', game, 'buy', qs.toString());
    const target = useMemo(() => targets['game']?.(game, buyUrl), [game, buyUrl]);
    const images = useMemo(
        () =>
            [
                [sellergroup?.bannerImage, sellergroup?.tabletBannerImage, sellergroup?.mobileBannerImage],
                [sellergroup?.gameBannerImage, sellergroup?.gameTabletBannerImage, sellergroup?.gameMobileBannerImage],
                [
                    sellergroup?.organizationBannerImage,
                    sellergroup?.organizationTabletBannerImage,
                    sellergroup?.organizationMobileBannerImage,
                ],
                [
                    sellergroup?.partnerBannerImage,
                    sellergroup?.partnerTabletBannerImage,
                    sellergroup?.partnerMobileBannerImage,
                ],
                [
                    sellergroup?.campaignBannerImage,
                    sellergroup?.campaignTabletBannerImage,
                    sellergroup?.campaignMobileBannerImage,
                ],
                [
                    sellergroup?.tenantBannerImage,
                    sellergroup?.tenantTabletBannerImage,
                    sellergroup?.tenantMobileBannerImage,
                ],
                [game?.bannerImage, game?.tabletBannerImage, game?.mobileBannerImage],
                [
                    game?.organizationBannerImage,
                    game?.organizationTabletBannerImage,
                    game?.organizationMobileBannerImage,
                ],
                [game?.partnerBannerImage, game?.partnerTabletBannerImage, game?.partnerMobileBannerImage],
                [game?.campaignBannerImage, game?.campaignMobileBannerImage, game?.campaignMobileBannerImage],
                [game?.tenantBannerImage, game?.tenantTabletBannerImage, game?.tenantMobileBannerImage],
            ].find((x) => !!x?.[0]?.url),
        [sellergroup, game],
    );

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <Banner
                image={images?.[0]}
                tabletImage={images?.[1]}
                mobileImage={images?.[2]}
                onClick={target}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface StandardBannerSellergroupModuleProps extends SellergroupModuleProps, GameModuleProps {}

export default StandardBannerSellergroupModule;
