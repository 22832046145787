import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./header/StandardHeaderPartnerModule')),
};

export function HeaderPartnerModule(props: HeaderPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type HeaderPartnerModuleProps = PartnerModuleProps;

export default HeaderPartnerModule;
