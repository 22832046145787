import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./mobileCallToAction/StandardMobileCallToActionPartnerModule')),
};

export function MobileCallToActionPartnerModule(props: MobileCallToActionPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type MobileCallToActionPartnerModuleProps = PartnerModuleProps;

export default MobileCallToActionPartnerModule;
