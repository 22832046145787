import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./godfathers/StandardGodfathersGameModule')),
};

export function GodfathersGameModule(props: GodfathersGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type GodfathersGameModuleProps = GameModuleProps;

export default GodfathersGameModule;
