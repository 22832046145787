import { WithClassName, WithLogo } from '../../withs';
import LightFooter from '../../organisms/LightFooter';
import AdZone from '@gotombola/react-ad/lib/organisms/AdZone';
import useGameFooterLogo from '@gotombola/react-game/lib/hooks/useGameFooterLogo';
import useGameWebsiteUrl from '@gotombola/react-game/lib/hooks/useGameWebsiteUrl';
import useGameSocialNetworks from '@gotombola/react-game/lib/hooks/useGameSocialNetworks';

export function LightGameFooter({ className, game, logo, websiteUrl }: LightGameFooterProps) {
    const logoImage = useGameFooterLogo(game, logo);
    const socialNetworks = useGameSocialNetworks(game);
    const url = useGameWebsiteUrl(game, websiteUrl);

    return (
        <LightFooter
            adChildren={<AdZone id={'GP-2-1'} />}
            className={className}
            logo={logoImage}
            socialNetworks={socialNetworks}
            url={url}
        />
    );
}

export interface LightGameFooterProps extends WithClassName, WithLogo {
    game?: any;
    websiteUrl?: string;
}

export default LightGameFooter;
