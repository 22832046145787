import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypeFunnelStep from '../../../organisms/TypeFunnelStep';
import Input from '../../../atoms/Input';
import useTypeFormStep from '../../../hooks/useTypeFormStep';

export function WebsiteRegisterFormPartnerModule({ partner, ...rest }: WebsiteRegisterFormPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t, stepProps, inputProps, register } = useTypeFormStep('partner', partner, 'register', 'website');

    return (
        <ModuleContainer {...zprops}>
            <TypeFunnelStep {...stepProps} {...props}>
                <Input {...register('url')} placeholder={t('field_website_placeholder')} autoFocus {...inputProps} />
            </TypeFunnelStep>
        </ModuleContainer>
    );
}

export interface WebsiteRegisterFormPartnerModuleProps {
    partner: any;
}

export default WebsiteRegisterFormPartnerModule;
