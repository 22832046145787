import clsx from 'clsx';
import { WithUrl } from '../withs';
import VideoModule from '../modules/VideoModule';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

const defaultConfig = { type: 'player' };

const sizes = {
    sm: 'w-40 h-32',
    md: 'w-60 h-48',
    lg: 'w-80 h-60',
};

export function VideoThumbnail({ hsize = 'full', size = 'md', url }: VideoThumbnailProps) {
    const { mediaShapeClass } = useStyling();
    return (
        <div
            className={clsx(
                'box-content border-2 border-transparent hover:cursor-pointer hover:border-2 hover:border-go-primary',
                sizes[size || ''] || sizes['md'],
                mediaShapeClass,
            )}
        >
            <VideoModule url={url} config={defaultConfig} playing={false} hsize={hsize} hmargin={false} />
        </div>
    );
}

export interface VideoThumbnailProps extends Required<WithUrl> {
    size?: string;
    hsize?: string;
}

// noinspection JSUnusedGlobalSymbols
export default VideoThumbnail;
