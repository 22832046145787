import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    welcome: loadable(() => import('./sellergroupRegistrationForm/WelcomeSellergroupRegistrationFormGameModule')),
    owner: loadable(() => import('./sellergroupRegistrationForm/OwnerSellergroupRegistrationFormGameModule')),
    team: loadable(() => import('./sellergroupRegistrationForm/TeamSellergroupRegistrationFormGameModule')),
    summary: loadable(() => import('./sellergroupRegistrationForm/SummarySellergroupRegistrationFormGameModule')),
};

export function SellergroupRegistrationFormGameModule(props: SellergroupRegistrationFormGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'welcome'} />;
}

export type SellergroupRegistrationFormGameModuleProps = GameModuleProps;

export default SellergroupRegistrationFormGameModule;
