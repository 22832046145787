import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    list: loadable(() => import('./keyfigures/ListKeyfiguresGameModule')),
};

export function KeyfiguresGameModule(props: KeyfiguresGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'list'} />;
}

export type KeyfiguresGameModuleProps = GameModuleProps;

export default KeyfiguresGameModule;
