import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypeFunnelStep from '../../../organisms/TypeFunnelStep';
import useTypeFormStep from '../../../hooks/useTypeFormStep';
import { useEffect, useMemo, useState } from 'react';
import Table from '../../../atoms/Table';
import useAction from '@gotombola/react-contexts/lib/hooks/useAction';
import ProgressBar from '../../../atoms/ProgressBar';
import Spinner2 from '../../../atoms/Spinner2';

export function SummaryRegistrationFormPartnerModule({
    partner,
    registration,
    ...rest
}: SummaryRegistrationFormPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [state, setState] = useState<any>({ calls: 0, data: undefined });
    const [finalPhase, setFinalPhase] = useState<any>(false);
    const { t, stepProps, data } = useTypeFormStep('partner', partner, 'registration_complete', 'summary', {
        registration,
        calls: state.calls,
    });
    const getRegistration = useAction('get_registration');
    const items = useMemo(
        () =>
            [
                { label: t('field_organizationname_placeholder'), value: registration?.requestedOrganizationName },
                { label: t('field_zipcode_placeholder'), value: registration?.zipCode },
                { label: t('field_nin_placeholder'), value: registration?.nin },
                { label: t('field_gamename_placeholder'), value: registration?.requestedGameName },
                { label: t('field_startdate_placeholder'), value: registration?.startDate },
                { label: t('field_enddate_placeholder'), value: registration?.endDate },
                { label: t('field_amount_placeholder'), value: registration?.amount },
                { label: t('field_firstname_placeholder'), value: registration?.firstName },
                { label: t('field_lastname_placeholder'), value: registration?.lastName },
                { label: t('field_email_placeholder'), value: registration?.email },
                { label: t('field_phone_placeholder'), value: registration?.phone },
            ].filter((x) => !!x.value),
        [t, registration],
    );
    useEffect(() => {
        const x =
            !!data &&
            !state.data?.game &&
            'phase3' !== phase &&
            'phase4' !== phase &&
            setTimeout(
                () =>
                    getRegistration(data, { registration: state.data || data, calls: state.calls }).then((r) =>
                        setState({ calls: state.calls + 1, data: r }),
                    ),
                1000,
            );
        return x ? () => clearTimeout(x) : undefined;
    }, [data, state.calls]);

    const phase =
        !data && !state.data ? 'phase1' : data && !state.data?.game ? 'phase2' : !finalPhase ? 'phase3' : 'phase4';

    const finalStepProps = useMemo(() => {
        switch (phase) {
            case 'phase4':
                return {
                    ...stepProps,
                    title: t('registration_complete_form_summary_title_phase3'),
                    buttonTarget: `${state.data.gamePublicPageShortUrl}?welcome`,
                    buttonLabel: t('registration_complete_form_summary_game_cta'),
                    onSubmit: undefined,
                    text: t('registration_complete_form_summary_congratulations'),
                };
            case 'phase3':
            case 'phase2':
                return {
                    ...stepProps,
                    title: t('registration_complete_form_summary_title_phase2'),
                    buttonTarget: undefined,
                    buttonLabel: undefined,
                    text: t('registration_complete_form_summary_loading'),
                };
            case 'phase1':
                return {
                    ...stepProps,
                    ...(!!items && !!items.length
                        ? {}
                        : { title: t('registration_complete_form_summary_title_phase1_empty') }),
                };
            default:
                return stepProps;
        }
    }, [stepProps, phase, t, state.data?.gamePublicPageShortUrl]);

    useEffect(() => {
        const x =
            'phase3' === phase &&
            setTimeout(() => {
                setFinalPhase(true);
            }, 1200);

        return x ? () => clearTimeout(x) : undefined;
    }, [phase]);

    useEffect(() => {
        if (!data && registration.gamePublicPageShortUrl) {
            const x = setTimeout(() => {
                'undefined' !== typeof window &&
                    (window.location.href = `${registration.gamePublicPageShortUrl}?welcome`);
            }, 1000);

            return () => clearTimeout(x);
        }
        return undefined;
    }, [data, registration.gamePublicPageShortUrl]);

    if (!data && registration.gamePublicPageShortUrl) {
        return (
            <ModuleContainer {...zprops}>
                <div className={'w-full flex flex-col items-center text-center justify-center'}>
                    <Spinner2 size={'md'} />
                </div>
            </ModuleContainer>
        );
    }

    return (
        <ModuleContainer {...zprops}>
            <TypeFunnelStep {...finalStepProps} {...props}>
                {'phase1' === phase && !!items && !!items?.length && <Table items={items} />}
                {('phase2' === phase || 'phase3' === phase) && (
                    <div className={'w-full flex flex-col items-center text-center justify-center'}>
                        <Spinner2 size={'md'} />
                        <ProgressBar
                            label={t(
                                `registration_complete_progress_step_${(
                                    state.data?.progressStepName || 'queued'
                                ).replace('-', '_')}`,
                            )}
                            ratio={state.data?.progressRatio}
                        />
                    </div>
                )}
            </TypeFunnelStep>
        </ModuleContainer>
    );
}

export interface SummaryRegistrationFormPartnerModuleProps {
    partner: any;
    registration: any;
}

export default SummaryRegistrationFormPartnerModule;
