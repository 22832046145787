import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import CampaignTemplate, { CampaignTemplateProps } from './CampaignTemplate';

export function DynamicCampaignPublicTemplate(props: DynamicCampaignPublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <CampaignTemplate {...props} campaign={context.doc} />;
}

export type DynamicCampaignPublicTemplateProps = Omit<CampaignTemplateProps, 'campaign'>;

export default DynamicCampaignPublicTemplate;
