import clsx from 'clsx';
import { WithCenter, WithLabel, WithOverline, WithValue } from '../withs';
import { flag } from '@gotombola/types';
import StaticIcon from './StaticIcon';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Text from './Text';

const colors =
    'bg-go-choice-primary text-go-choice-secondary group-hover:bg-go-choice-secondary group-hover:text-go-choice-primary';
const invertedColors =
    'bg-go-choice-secondary text-go-choice-primary group-hover:bg-go-choice-primary group-hover:text-go-choice-secondary';

export function DefaultChoiceMenuItem({
    center,
    label,
    overline,
    value,
    checkbox = false,
    checked,
}: DefaultChoiceMenuItemProps) {
    const multipleLines = String(value).length > 8;
    const { itemShapeClass } = useStyling();

    return (
        <div
            className={clsx(
                'flex w-full',
                multipleLines ? 'items-start sm:items-center flex-col sm:flex-row' : 'items-center',
            )}
        >
            <div
                className={clsx(
                    'text-2xl font-medium flex-1 tracking-tight w-full',
                    checked ? invertedColors : colors,
                    !!value || (checkbox && 'pl-2'),
                )}
            >
                {!checkbox && <Text center={!value && center}>{label}</Text>}
                {checkbox && (
                    <div className={clsx('w-10 h-10 bg-white', itemShapeClass)}>
                        {checked && (
                            <div className={'p-2 text-go-secondary'}>
                                <StaticIcon type={'cross'} />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {!!value && !checkbox && (
                <div
                    className={clsx(
                        'flex justify-end',
                        overline && 'items-center',
                        overline && 'justify-between',
                        !!value && multipleLines && 'pl-2 sm:pl-0',
                    )}
                >
                    {!!overline && !!value && (
                        <div
                            className={clsx(
                                'font-go-text text-xs font-medium p-2 mr-1 sm:mr-2 md:mr-8',
                                invertedColors,
                                itemShapeClass,
                            )}
                        >
                            {overline}
                        </div>
                    )}
                    <div className={clsx('flex justify-end items-center p-2', colors, itemShapeClass)}>
                        <Text bold className={'text-3xl'} numeric>
                            {value}
                        </Text>
                    </div>
                </div>
            )}
            {checkbox && (
                <div className={'flex justify-end'}>
                    <div
                        className={clsx(
                            'flex justify-end items-center p-2',
                            checked ? invertedColors : colors,
                            itemShapeClass,
                        )}
                    >
                        <Text className={'text-2xl font-medium flex-1 tracking-tight'}>{label}</Text>
                    </div>
                </div>
            )}
        </div>
    );
}

export interface DefaultChoiceMenuItemProps extends WithCenter, WithLabel, WithOverline, WithValue {
    checkbox?: flag;
    checked?: flag;
}

export default DefaultChoiceMenuItem;
