import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./share/StandardShareTypeModule')),
    panel: loadable(() => import('./share/PanelShareTypeModule')),
};

export function ShareTypeModule(props: ShareTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ShareTypeModuleProps = TypeModuleProps;

export default ShareTypeModule;
