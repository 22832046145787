import { WithClassName, WithLogo, WithName } from '../../withs';
import formatCountry from '../../utils/formatCountry';
import Footer from '../../organisms/Footer';
import hasFeature from '../../utils/hasFeature';
import AdZone from '@gotombola/react-ad/lib/organisms/AdZone';
import useGameFooterLogo from '@gotombola/react-game/lib/hooks/useGameFooterLogo';

export function GameFooter({
    className,
    game,
    logo,
    address,
    email,
    fullName,
    name,
    nin,
    phone,
    street,
}: GameFooterProps) {
    const logoImage = useGameFooterLogo(game, logo);
    address =
        address ||
        (!!game.city
            ? `${game.zipCode} ${game.city}${game.country ? ' ' : ''}${formatCountry(game.country)}`
            : game.organizationCity &&
              `${game.city ? game.zipCode : game.organizationZipCode} ${game.city ? game.city : game.organizationCity}${
                  game.organizationCountry ? ' ' : ''
              }${formatCountry(game.organizationCountry)}`);
    email =
        email ||
        (!hasFeature(game, 'disable_display_public_email')
            ? game.publicEmail || game.organizationPublicEmail
            : undefined);
    fullName =
        fullName || game.fullName || game.organizationFullName || game.organizationLegalName || game.organizationName;
    name = name || game.fullName || game.organizationName;
    nin = nin || game.nin;
    phone =
        phone ||
        (!hasFeature(game, 'disable_display_public_phone')
            ? game.publicPhone || game.organizationPublicPhone
            : undefined);
    street =
        street ||
        (!hasFeature(game, 'disable_display_public_address') ? game.street || game.organizationStreet : undefined);

    return (
        <Footer
            logo={logoImage}
            address={!hasFeature(game, 'disable_display_public_address') ? address : undefined}
            className={className}
            email={email}
            fullName={fullName}
            name={name}
            nin={nin}
            phone={phone}
            street={street}
            adChildren={<AdZone id={'GP-2-1'} />}
        />
    );
}

export interface GameFooterProps extends WithClassName, WithLogo, WithName {
    game?: any;
    street?: string;
    nin?: string;
    fullName?: string;
    address?: string;
    email?: string;
    phone?: string;
}

export default GameFooter;
