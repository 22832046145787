import { Helmet } from 'react-helmet';
import useFacebookPixelContext from '@gotombola/react-contexts/lib/hooks/useFacebookPixelContext';
import { facebook_pixel_action } from '@gotombola/react-contexts/lib/types';

// noinspection JSUnusedLocalSymbols
export function FacebookPixel({ id: forcedId }: FacebookPixelProps) {
    const { id: contextualId = undefined, actions = [] } = useFacebookPixelContext();
    const id = forcedId || contextualId;

    if (!id) return null;

    return (
        <Helmet>
            <script type='text/javascript'>{`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window,document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${id}');
${(actions || [])
    .filter((a: facebook_pixel_action) => a && a.action)
    .map(
        (a: facebook_pixel_action) =>
            `                fbq('${a.action}', '${a.name}'${!!a.data ? ', ' : ''}${
                !!a.data ? JSON.stringify(a.data) : ''
            });`,
    )
    .join('\n')}
                fbq('track', 'PageView');
            `}</script>
            <noscript>{`
                <img alt="fbp" height="1" width="1" src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1"/>
            `}</noscript>
        </Helmet>
    );
}

export interface FacebookPixelProps {
    id?: string;
}

// noinspection JSUnusedGlobalSymbols
export default FacebookPixel;
