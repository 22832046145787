import GenericLocaleSwitcher, { GenericLocaleSwitcherProps } from './GenericLocaleSwitcher';

export function TenantLocaleSwitcher({ tenant, ...props }: TenantLocaleSwitcherProps) {
    return <GenericLocaleSwitcher item={tenant} versionKey={'tenantVersions'} {...props} />;
}

export interface TenantLocaleSwitcherProps extends Omit<GenericLocaleSwitcherProps, 'item' | 'versionKey'> {
    tenant: any;
}

export default TenantLocaleSwitcher;
