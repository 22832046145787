import useTypeTranslation from '../hooks/useTypeTranslation';
import GalleryBadges from './GalleryBadges';
import Img from '../atoms/Img';
import StaticIcon from '../atoms/StaticIcon';
import String from '../atoms/String';
import QRCodeDownload from '../molecules/QRCodeDownload';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import SectionTitle from '../atoms/SectionTitle';
import { WithTitle } from '@gotombola/react-types/lib/withs';

export function OrganizationInfos({ organization, title: forcedTitle }: OrganizationInfosProps) {
    const { t } = useTypeTranslation('organization', organization);
    const logoImage = !!organization.logoImage && !!organization.logoImage.url ? organization.logoImage : undefined;
    const { blockShapeClass, logoShapeClass } = useStyling();

    const title = forcedTitle || organization.name;

    return (
        <div className={'flex justify-center sm:justify-between'}>
            <div className={'-mt-16 flex flex-col sm:items-start items-center'}>
                <div className={'relative mb-2'}>
                    {!!logoImage && !!logoImage.url ? (
                        <Img
                            className={clsx(
                                'h-32 w-32 bg-go-logo flex items-center justify-center border shadow-sm p-2 object-contain',
                                logoShapeClass,
                            )}
                            url={logoImage.url}
                            maxSize={300}
                            alt={logoImage.alt || t('organization_avatar')}
                        />
                    ) : (
                        <StaticIcon
                            type={'ticket'}
                            className={clsx(
                                'p-2 w-32 h-32 text-white bg-white border border-gray-300 shadow-sm',
                                logoShapeClass,
                            )}
                        />
                    )}
                </div>
                <div className={'flex flex-col mt-8 space-y-2'}>
                    <SectionTitle title={title} className={'sm:text-left mb-2'} basic />
                    {!!organization.overline && (
                        <div className={'font-go-text font-light text-2xl sm:text-left text-center mb-2'}>
                            <String basic>{organization.overline}</String>
                        </div>
                    )}
                    {!!organization.badges && !!organization.badges.items && !!organization.badges.items.length && (
                        <GalleryBadges items={organization.badges.items} size={'sm'} limit={4} />
                    )}
                </div>
            </div>
            {!!organization?.publicPageShortUrl && (
                <div className={'items-center hidden sm:flex sm:flex-col z-10'}>
                    <div
                        className={clsx(
                            '-mt-24 overflow-hidden items-center w-48 h-auto hidden sm:flex sm:flex-col z-10 bg-white',
                            blockShapeClass,
                        )}
                    >
                        <QRCodeDownload
                            lazy={false}
                            qrCodeClassName={'w-48 h-48'}
                            target={organization!.publicPageShortUrl!}
                            withHeader
                            filename={`${organization?.code}-qrcode`}
                            style={organization?.tenantCode}
                            customLogo={organization?.qrcodeLogoImage?.url}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export interface OrganizationInfosProps extends WithTitle {
    organization: any;
}

export default OrganizationInfos;
