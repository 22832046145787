import loadable from '@loadable/component';
import { SellergroupModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./alertBanner/StandardAlertBannerSellergroupModule')),
};

export function AlertBannerSellergroupModule(props: AlertBannerSellergroupModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type AlertBannerSellergroupModuleProps = SellergroupModuleProps;

export default AlertBannerSellergroupModule;
