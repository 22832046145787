import ModuleContainer from '../../organisms/ModuleContainer';
import ParagraphModule from '../../modules/ParagraphModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function ParagraphCommonModule({ config, ...rest }: ParagraphCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <ParagraphModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface ParagraphCommonModuleProps {
    config: any;
}

export default ParagraphCommonModule;
