import { useMemo } from 'react';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';

const defaultValues: any = [];

export function SelectDataField({
    placeholder,
    onChange,
    emptyAllowed = false,
    values = defaultValues,
    allData,
    ...props
}: SelectDataFieldProps) {
    const { t } = useTrans();
    const selectedValues = useMemo(() => {
        let vals = values || [];
        vals = vals.map((x) => ({ ...x, label: x.label ? t(x.label) : x.label }));
        vals.sort((a: any, b: any) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
        return vals;
    }, [values, t]);

    return (
        <select {...props} onChange={onChange as any} placeholder={t(placeholder || '')}>
            {!!emptyAllowed && <option value={''}>{t('data_field_select_empty_label')}</option>}
            {selectedValues.map(({ value, label }: any, index: number) => (
                <option key={value || index} value={value}>
                    {label}
                </option>
            ))}
        </select>
    );
}

export interface SelectDataFieldProps {
    name: string;
    onChange: Function;
    value: any;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    emptyAllowed?: boolean;
    values?: any[];
    allData?: any;
    register: Function;
    control: Function;
}

// noinspection JSUnusedGlobalSymbols
export default SelectDataField;
