import { useMemo } from 'react';
import { image } from '@gotombola/types/lib/types/base';

export function useGameTypedLogo(game: any, type: string, forcedLogo?: image) {
    return useMemo(
        () =>
            [
                forcedLogo,
                game?.[`${type}LogoImage`],
                game?.[`organization${type}LogoImage`],
                game?.logoImage,
                game?.organizationLogoImage,
            ].find((x) => !!x?.url) || undefined,
        [
            type,
            forcedLogo,
            game?.[`${type}LogoImage`],
            game?.[`organization${type}LogoImage`],
            game?.logoImage,
            game?.organizationLogoImage,
        ],
    );
}

export default useGameTypedLogo;
