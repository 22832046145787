import NumericInput from '../atoms/inputs/NumericInput';

export function QuantityDataField({ placeholder, allData, ...props }: QuantityDataFieldProps) {
    return <NumericInput {...props} placeholder={placeholder || ''} />;
}

export interface QuantityDataFieldProps {
    name: string;
    onChange: Function;
    value: any;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    allData?: any;
    register: Function;
    control: Function;
}

// noinspection JSUnusedGlobalSymbols
export default QuantityDataField;
