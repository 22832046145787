import clsx from 'clsx';
import { WithImage, WithOverline, WithTitle, WithValue } from '../withs';
import Cell from './Cell';
import String from '../atoms/String';
import TableRow from './TableRow';
import { flag } from '@gotombola/types';
import Img from '../atoms/Img';

const defaultFormat = (value, unit) => `${value} ${unit}`;

export function LeaderboardRow({
    title,
    format,
    overline,
    index,
    unit,
    value,
    image,
    withImage = false,
}: LeaderboardRowProps) {
    const isVip = index <= 3;
    const isFirst = index === 1;
    const className = clsx(isFirst && 'text-go-title', isVip && 'border-go-title');
    format = format || defaultFormat;

    return (
        <TableRow className={clsx('p-1 sm:p-0 flex gap-2', className)}>
            <Cell
                className={clsx(className, 'overflow-hidden bg-white')}
                childrenClassName={clsx(
                    'font-go-text md:py-1 md:px-2 lg:px-4 w-8 sm:w-16 flex justify-center items-center text-xl',
                    isVip && 'font-semibold',
                    isFirst && 'font-bold',
                )}
            >
                {index}
            </Cell>
            {!!withImage && (
                <Cell
                    className={clsx(className, 'overflow-hidden bg-white')}
                    childrenClassName={clsx(
                        'font-go-text md:py-1 w-8 sm:w-16 flex justify-center items-center text-xl',
                        isVip && 'font-semibold',
                        isFirst && 'font-bold',
                    )}
                >
                    {!!image?.url && (
                        <Img className={'w-20 h-14 object-contain'} {...image} alt={image?.alt || 'leader'} />
                    )}
                </Cell>
            )}
            <Cell
                className={clsx('flex-1', className, 'overflow-hidden bg-white')}
                childrenClassName={clsx('md:py-1 md:px-2 lg:px-4', isVip && 'font-semibold', isFirst && 'font-bold')}
            >
                <String className={'font-go-text text-md md:text-left'}>{title}</String>
                <String className={'font-go-text text-md md:text-left font-light'}>{overline}</String>
            </Cell>
            <Cell
                className={clsx(className, 'overflow-hidden bg-white')}
                childrenClassName={clsx(
                    'md:py-1 md:px-2 lg:px-4 w-24 sm:w-40 flex justify-end items-end text-xl mr-2 sm:mr-0',
                    isVip && 'font-semibold',
                    isFirst && 'font-bold',
                )}
            >
                <String className={'font-go-text text-md text-right'} basic>
                    {format(value, unit)}
                </String>
            </Cell>
        </TableRow>
    );
}

export interface LeaderboardRowProps extends WithTitle, WithOverline, WithValue, WithImage {
    unit?: string;
    format?: Function;
    index: number;
    withImage?: flag;
}

// noinspection JSUnusedGlobalSymbols
export default LeaderboardRow;
