import { useCallback } from 'react';
import clsx from 'clsx';
import { WithClassName, WithRequired } from '../../withs';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function AmountField({ amount, className, setAmount, required }: AmountFieldProps) {
    const handleInput = useCallback(
        (event: any) => {
            setAmount(event.target.value);
        },
        [setAmount],
    );
    const { blockShapeClass } = useStyling();

    return (
        <div className={clsx('flex items-center', className)}>
            <input
                pattern='^[0-9,.]+$'
                type={'text'}
                className={clsx(
                    'font-go-text text-go-text p-3 border w-full',
                    required ? 'border-gray-500' : 'border-gray-300',
                    blockShapeClass,
                    className,
                )}
                value={amount || 0}
                onChange={handleInput}
                required={required}
            />
        </div>
    );
}

export interface AmountFieldProps extends WithClassName, WithRequired {
    amount: number;
    setAmount: Function;
    floating?: boolean;
}

// noinspection JSUnusedGlobalSymbols
export default AmountField;
