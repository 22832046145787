import useTypePropertyAsTaggedList from './useTypePropertyAsTaggedList';

export function useTypeImages(type: string, doc: any, sort?: string, tag?: string) {
    return useTypePropertyAsTaggedList(
        `${(type || '').slice(0, 1)}images`,
        (i: any) => i.mainImage && { url: i.mainImage.url, tags: i.tags, rank: i.rank || [] },
        type,
        doc,
        sort,
        tag,
    );
}

// noinspection JSUnusedGlobalSymbols
export default useTypeImages;
