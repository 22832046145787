import common from './common';
import sellergroup from './sellergroup';
import seller from './seller';
import game from './game';
import organization from './organization';
import partner from './partner';
import campaign from './campaign';
import tenant from './tenant';
import donee from './donee';
import sponsor from './sponsor';
import custom from './custom';
import type from './type';

export default {
    ...common,
    ...sellergroup,
    ...seller,
    ...game,
    ...organization,
    ...partner,
    ...campaign,
    ...tenant,
    ...sponsor,
    ...donee,
    ...custom,
    ...type,
};

// for legacy compatibility, clean prod before removing these aliases

export const aliases = {
    sponsors: 'game_sponsors',
    banner: 'type_banner',
    stats: 'game_stats',
};
