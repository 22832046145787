import { BrowserRouter } from 'react-router-dom';
import I18nProvider from '@gotombola/react-i18n/lib/I18nProvider';
import { page_context_value } from '@gotombola/react-contexts/lib/types';
import { useMemo } from 'react';

export function ClientPage({ context, i18n, component: App, mode, options }: ClientPageProps) {
    const ctx = useMemo(
        () => ({ ...(context?.data || {}), locale: context?.locale, mode, options }),
        [context?.data, context?.locale, mode, options],
    );
    return (
        <BrowserRouter>
            <I18nProvider value={i18n}>
                <App mode={mode} options={options} data={ctx} applicationContext={context?.applicationContext} />
            </I18nProvider>
        </BrowserRouter>
    );
}

export interface BaseClientPageProps {
    context: page_context_value;
    mode?: string;
    options?: any;
}
export interface ClientPageProps extends BaseClientPageProps {
    i18n: any;
    component: any;
}

// noinspection JSUnusedGlobalSymbols
export default ClientPage;
