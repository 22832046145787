import { routes_manager, routes, routes_manager_object } from '../types';
import createBuildI18nRoutesFromConfigure from './createBuildI18nRoutesFromConfigure';

export function buildRoutes(routes: routes_manager | undefined, data: any, options?: any, mode?: any) {
    if (!routes) return [] as routes;
    if (!routes['configure'] && !routes['build']) return routes;
    const manager: routes_manager_object = routes as routes_manager_object;

    const build = manager.configure
        ? createBuildI18nRoutesFromConfigure(manager.configure(data?.data || {}, options, mode))
        : manager.build;

    return build!(data, options, mode) || ([] as routes);
}

export default buildRoutes;
