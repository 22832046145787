import { createContext } from 'react';
import { tenant_context_value } from '../types';
import createDefaultTenantContextValue from '../utils/createDefaultTenantContextValue';

export const TenantContext = createContext<tenant_context_value>(createDefaultTenantContextValue());
// noinspection JSUnusedGlobalSymbols
export const TenantContextProvider = TenantContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const TenantContextConsumer = TenantContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default TenantContext;
