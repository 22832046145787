import loadable from '@loadable/component';
import { OrganizationModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./footer/StandardFooterOrganizationModule')),
};

export function FooterOrganizationModule(props: FooterOrganizationModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type FooterOrganizationModuleProps = OrganizationModuleProps;

export default FooterOrganizationModule;
