import TypeLocationPresentation, {
    TypeLocationPresentationProps,
} from '../../../organisms/type/TypeLocationPresentation';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any) => !!doc?.locationName;

export function StandardLocationPresentationTypeModule({
    typeType,
    ...rest
}: StandardLocationPresentationTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    if (!doc) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeLocationPresentation {...props} type={type} doc={doc} />
        </ModuleContainer>
    );
}

export interface StandardLocationPresentationTypeModuleProps extends Omit<TypeLocationPresentationProps, 'type'> {
    typeType: string;
}

export default StandardLocationPresentationTypeModule;
