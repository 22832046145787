export function getRankSizeCategory(rank: string | undefined) {
    switch (true) {
        case String(rank).length > 8:
            return 'very-very-long';
        case String(rank).length > 4:
            return 'very-long';
        case String(rank).length > 2:
            return 'long';
        case String(rank).length > 0:
            return 'non-empty';
        default:
            return 'empty';
    }
}

export default getRankSizeCategory;
