import { useMemo } from 'react';
import clsx from 'clsx';
import { TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import SocialIcon from '../../atoms/SocialIcon';
import Items from '../../atoms/Items';

export function TypeMiniSocialNetworks({ className, doc }: TypeMiniSocialNetworksProps) {
    const socialLinks = useMemo(() => {
        const socialLinks: any[] = [];
        doc.facebookUrl && socialLinks.push({ url: doc.facebookUrl, icon: 'facebook-2' });
        doc.twitterUrl && socialLinks.push({ url: doc.twitterUrl, icon: 'twitter-2' });
        return socialLinks;
    }, [doc.facebookUrl, doc.twitterUrl]);

    return (
        <div className={clsx('flex my-8 gap-2 items-center', className)}>
            {!!socialLinks && <Items items={socialLinks} component={SocialIcon} />}
        </div>
    );
}

export interface TypeMiniSocialNetworksProps extends TypeProps, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default TypeMiniSocialNetworks;
