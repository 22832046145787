import { TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import TextBlock from '../../blocks/TextBlock';

export function TypeDescription({ className, doc }: TypeDescriptionProps) {
    if (!doc?.description) return null;

    return <TextBlock className={className} text={doc.description} />;
}

export interface TypeDescriptionProps extends TypeProps, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default TypeDescription;
