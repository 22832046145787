import loadable from '@loadable/component';
import { OrganizationModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./header/StandardHeaderOrganizationModule')),
};

export function HeaderOrganizationModule(props: HeaderOrganizationModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type HeaderOrganizationModuleProps = OrganizationModuleProps;

export default HeaderOrganizationModule;
