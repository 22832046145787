import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import TenantTemplate, { TenantTemplateProps } from './TenantTemplate';

export function DynamicTenantPublicTemplate(props: DynamicTenantPublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <TenantTemplate {...props} tenant={context.doc} />;
}

export type DynamicTenantPublicTemplateProps = Omit<TenantTemplateProps, 'tenant'>;

export default DynamicTenantPublicTemplate;
