import RowContent from './RowContent';
import Content from '@gotombola/react-modules-engine/lib/organisms/Content';

export function DynamicContent({ content, ...props }: DynamicContentProps) {
    return !content ? null : Array.isArray(content) ? (
        <RowContent content={content} {...props} />
    ) : (
        <Content content={content} {...props} />
    );
}

export interface DynamicContentProps {
    content?: any;
    index: number;
    total: number;
}

// noinspection JSUnusedGlobalSymbols
export default DynamicContent;
