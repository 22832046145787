import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import SellergroupTemplate, { SellergroupTemplateProps } from './SellergroupTemplate';

export function DynamicSellergroupPublicTemplate(props: DynamicSellergroupPublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <SellergroupTemplate {...props} sellergroup={context.doc} game={context.game} />;
}

export type DynamicSellergroupPublicTemplateProps = Omit<SellergroupTemplateProps, 'sellergroup'>;

export default DynamicSellergroupPublicTemplate;
