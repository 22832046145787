import ModuleContainer from '../../organisms/ModuleContainer';
import ItemsModule from '../../modules/ItemsModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function ItemsCommonModule({ config, ...rest }: ItemsCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <ItemsModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface ItemsCommonModuleProps {
    config: any;
}

export default ItemsCommonModule;
