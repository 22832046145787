import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./banner/StandardBannerTypeModule')),
    cta: loadable(() => import('./banner/CtaBannerTypeModule')),
    corporate: loadable(() => import('./banner/CorporateBannerTypeModule')),
};

export function BannerTypeModule(props: BannerTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type BannerTypeModuleProps = TypeModuleProps;

export default BannerTypeModule;
