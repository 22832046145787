import Loader from 'react-loader-spinner';
import { WithClassName } from '../../withs';

// noinspection JSUnusedLocalSymbols
export function CirclesSpinner(props: CirclesSpinnerProps) {
    return <Loader type='Circles' color='#00BFFF' height={100} width={100} timeout={3000} />;
}

export type CirclesSpinnerProps = WithClassName;

export default CirclesSpinner;
