import { WithButton } from '../../../withs';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';
import MobileCallToAction from '../../../organisms/MobileCallToAction';

export function StandardMobileCallToActionSellergroupModule({
    sellergroup,
    game,
    buttonLabel,
    buttonTarget,
    ...rest
}: StandardMobileCallToActionSellergroupModuleProps) {
    const extraBuyQs = `sg=${sellergroup.code}`;

    game = ((game ? { game } : useGameContext()) || {}).game;
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <MobileCallToAction
                game={game}
                buttonLabel={buttonLabel}
                buttonTarget={buttonTarget}
                extraBuyQs={extraBuyQs}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface StandardMobileCallToActionSellergroupModuleProps extends WithButton {
    sellergroup: any;
    game: any;
}

export default StandardMobileCallToActionSellergroupModule;
