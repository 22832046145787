import ModuleContainer from '../../organisms/ModuleContainer';
import HeadingModule from '../../modules/HeadingModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function HeadingCommonModule({ config, ...rest }: HeadingCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <HeadingModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface HeadingCommonModuleProps {
    config: any;
}

export default HeadingCommonModule;
