import { createContext } from 'react';
import { storage_context_value } from '../types';
import createDefaultStorageContextValue from '../utils/createDefaultStorageContextValue';

export const StorageContext = createContext<storage_context_value>(createDefaultStorageContextValue());
// noinspection JSUnusedGlobalSymbols
export const StorageContextProvider = StorageContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const StorageContextConsumer = StorageContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default StorageContext;
