import { dynamic_page_body_item_config } from '../types';
import { WithChildren, WithItems } from '../withs';
import BasicLayout from '../layouts/BasicLayout';
import DynamicPageBodyItems from '../organisms/DynamicPageBodyItems';

export function BasicTemplate({ children, items = [] }: BasicTemplateProps) {
    return (
        <BasicLayout>
            {children || ''}
            <DynamicPageBodyItems items={items} />
        </BasicLayout>
    );
}

export interface BasicTemplateProps extends WithChildren, WithItems<dynamic_page_body_item_config> {}

export default BasicTemplate;
