import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import Screenshots from '../../../organisms/Screenshots';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { useMemo } from 'react';
import toICULocale from '@gotombola/locale/lib/utils/toICULocale';

export function StandardServiceSaleProcessScreenshotsPartnerModule({
    gameCode = 'GSKP391',
    locale,
    partner,
    ...rest
}: StandardServiceSaleProcessScreenshotsPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTrans();
    locale = useMemo(() => toICULocale(locale), [locale]);
    const items = useMemo(
        () => [
            {
                image: {
                    url: `https://image.api.gotombola.co/images/z/${gameCode}/a21/mobile/xxxx/game-${gameCode}-buy-quantity.webp?locale=${locale}`,
                },
            },
            {
                image: {
                    url: `https://image.api.gotombola.co/images/z/${gameCode}/a22/mobile/xxxx/game-${gameCode}-buy-payment.webp?locale=${locale}`,
                },
            },
            {
                image: {
                    url: `https://image.api.gotombola.co/images/z/${gameCode}/a23/mobile/xxxx/game-${gameCode}-buy-owner.webp?locale=${locale}`,
                },
            },
            {
                image: {
                    url: `https://image.api.gotombola.co/images/z/${gameCode}/a24/mobile/xxxx/game-${gameCode}-buy-bonus.webp?locale=${locale}`,
                },
            },
        ],
        [gameCode, locale],
    );

    return (
        <ModuleContainer {...zprops}>
            <Screenshots title={t('partner_sale_process_screenshots_title')} items={items} {...props} />
        </ModuleContainer>
    );
}

export interface StandardServiceSaleProcessScreenshotsPartnerModuleProps {
    locale: string;
    partner: any;
    gameCode?: string;
}

export default StandardServiceSaleProcessScreenshotsPartnerModule;
