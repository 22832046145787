import {
    WithButtons,
    WithImage,
    WithLogo,
    WithNopadding,
    WithOverline,
    WithSubtext,
    WithText,
    WithTitle,
} from '../../withs';
import ContentHeadingPushModule from '../../organisms/ContentHeadingPushModule';

export function ContentLeftHeadingPushModule(props: ContentLeftHeadingPushModuleProps) {
    return <ContentHeadingPushModule {...props} />;
}

export interface ContentLeftHeadingPushModuleProps
    extends Required<WithImage>,
        WithLogo,
        WithNopadding,
        WithOverline,
        WithSubtext,
        WithText,
        WithTitle,
        WithButtons {}

export default ContentLeftHeadingPushModule;
