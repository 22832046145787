import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from '@tsparticles/engine';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            number: {
                value: 100,
            },
            color: {
                value: '#ffff00',
            },
            life: {
                count: 1,
                duration: {
                    value: 5,
                },
            },
            shape: {
                type: 'circle',
            },
            opacity: {
                value: 1,
            },
            size: {
                value: 3,
            },
            move: {
                enable: true,
                speed: 10,
                decay: 0.005,
                direction: 'outside',
                straight: true,
                outModes: 'destroy',
                trail: {
                    enable: false,
                    length: 15,
                    fill: {
                        color: '#000000',
                    },
                },
            },
        },
        emitters: {
            position: {
                x: 50,
                y: 50,
            },
            size: {
                width: 100,
                height: 100,
            },
            rate: {
                quantity: 10,
                delay: 0.1,
            },
        },
    };
};
