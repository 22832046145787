import { dynamic_page_body_item_config } from '../types';
import { WithAny, WithChildren, WithClassName, WithItems, WithLocale, WithType } from '../withs';
import useTemplate from '../hooks/useTemplate';

const defaultItems = [];

export function DynamicPageTemplate({
    children,
    items = defaultItems,
    locale,
    type,
    ...props
}: DynamicPageTemplateProps) {
    const Template = useTemplate(type);

    return (
        <Template items={items} locale={locale} {...props}>
            {children || ''}
        </Template>
    );
}

export interface DynamicPageTemplateProps
    extends WithChildren,
        WithItems<dynamic_page_body_item_config>,
        WithLocale,
        WithType,
        WithClassName,
        WithAny {}

export default DynamicPageTemplate;
