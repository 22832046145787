import { createContext } from 'react';
import { facebook_pixel_context_value } from '../types';
import createDefaultFacebookPixelContextValue from '../utils/createDefaultFacebookPixelContextValue';

export const FacebookPixelContext = createContext<facebook_pixel_context_value>(
    createDefaultFacebookPixelContextValue(),
);
export const FacebookPixelContextProvider = FacebookPixelContext.Provider;
export const FacebookPixelContextConsumer = FacebookPixelContext.Consumer;

export default FacebookPixelContext;
