import clsx from 'clsx';
import { children, class_name } from '../types';
import { WithChildren, WithClassName } from '../withs';
import StaticLogo from '../atoms/StaticLogo';

export function RightBoxLayout({
    children,
    childrenClassName,
    className,
    headerChildren,
    headerChildrenClassName,
    secondaryChildren,
    secondaryChildrenClassName,
}: RightBoxLayoutProps) {
    return (
        <div className={clsx('w-full flex', className)}>
            <div className={'flex-1 flex flex-col items-center z-20'}>
                <StaticLogo type={'gotombola-small-2'} className={'mt-10 w-80 text-white object-contain'} />
                <div
                    className={clsx(
                        'flex mb-10 h-20 w-full items-center justify-center px-2 sm:px-8 md:justify-around',
                        headerChildrenClassName,
                    )}
                >
                    {headerChildren || ''}
                </div>
                <div className={'p-8 w-full sm:flex md:px-20 space-x-8 sm:flex-row-reverse'}>
                    <div
                        className={clsx(
                            'border border-gray-200 rounded-lg bg-white shadow-md -mt-20 p-8 sm:p-8 w-full xl:w-1/2 md:w-2/3',
                            childrenClassName,
                        )}
                    >
                        {children || ''}
                    </div>
                    <div className={clsx('sm:-mt-20 p-8 sm:p-8 w-full xl:w-1/2 md:w-1/3', secondaryChildrenClassName)}>
                        {secondaryChildren || ''}
                    </div>
                </div>
            </div>
            <div className={'bg-blue-500 h-80 top-0 left-0 right-0 absolute z-0 shadow-md px-8'} />
        </div>
    );
}

export interface RightBoxLayoutProps extends WithChildren, WithClassName {
    childrenClassName?: class_name;
    headerChildrenClassName?: class_name;
    headerChildren?: children;
    secondaryChildrenClassName?: class_name;
    secondaryChildren?: children;
}

export default RightBoxLayout;
