import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { WithType } from '../../withs';
import Items from '../../atoms/Items';
import { StaticLogo } from '../../atoms/StaticLogo';
import SocialIcon from '../../atoms/SocialIcon';
import String from '../../atoms/String';
import useTypeTranslation from '../../hooks/useTypeTranslation';

const themes: Record<string, Record<string, string>> = {
    black: { className: 'bg-black text-white' },
    blue: { bgColor: '#2e88ff', textColor: '#ffffff' },
    orange: { bgColor: '#ff9f23', textColor: '#ffffff' },
    white: { className: 'bg-white text-black' },
    primary: { className: 'bg-go-primary text-white' },
    secondary: { className: 'bg-go-secondary text-white' },
};

export function GotombolaFooter({
    type,
    theme = 'black',
    customBgColor = undefined,
    customTextColor = undefined,
    locale = undefined,
    country = undefined,
}: GotombolaFooterProps) {
    const { t } = useTypeTranslation(type);
    const tUrl = useCallback(
        (key: string) => t([`${key}_${country || ''}`.replace('-', '_').toLowerCase(), key], { ns: 'url' }),
        [locale, country, t],
    );
    const facebookUrl = tUrl('facebook_gotombola');
    const linkedinUrl = tUrl('linkedin_gotombola');
    const twitterUrl = tUrl('twitter_gotombola');
    const gotombolaUrl = tUrl('gotombola');
    const socialLinks = useMemo(() => {
        const socialLinks: { url: string; icon?: string }[] = [];
        facebookUrl && socialLinks.push({ url: facebookUrl, icon: 'facebook-black' });
        twitterUrl && socialLinks.push({ url: twitterUrl, icon: 'twitter-black' });
        linkedinUrl && socialLinks.push({ url: linkedinUrl, icon: 'linkedin-black' });
        return socialLinks;
    }, [facebookUrl, twitterUrl, linkedinUrl]);
    const [forcedBgColor, forcedTextColor, extraClassName] = useMemo(() => {
        const th = themes[theme || ''];
        const bgColor = customBgColor || th?.bgColor;
        const textColor = customTextColor || th?.textColor;
        const thClassName = th?.className;
        return [bgColor, textColor, !!bgColor && !!textColor ? undefined : thClassName];
    }, [customBgColor, customTextColor, theme]);
    const forcedStyles = useMemo(
        () =>
            forcedBgColor && forcedTextColor ? { backgroundColor: forcedBgColor, color: forcedTextColor } : undefined,
        [forcedBgColor, forcedTextColor],
    );
    return (
        <div
            className={clsx(
                'border-t flex-1 flex-col  items-center justify-between lg:flex lg:flex-row pb-2 pt-2',
                extraClassName,
            )}
            {...(forcedStyles ? { style: forcedStyles } : {})}
        >
            <div className={'flex-1 flex flex-col items-center py-4 sm:py-0 m-0 '}>
                <a href={gotombolaUrl} className={'sm:block lg:w-48 max-w-xs w-full'}>
                    <StaticLogo type={'gotombola-small-2'} className={''} />
                </a>
            </div>
            <div className={'flex-1 py-4 sm:py-0 sm:m-4 px-4'}>
                <String className={'flex text-clip sm:break-words items-center text-center text-sm justify-center'}>
                    {t('footer_generic_slogan')}
                </String>
            </div>
            <div className={'lg:flex-1 flex-auto py-4 sm:py-0 sm:m-2'}>
                <div className={'flex space-x-2 justify-center items-center'}>
                    {!!socialLinks && <Items items={socialLinks} component={SocialIcon} size={'sm'} />}
                </div>
            </div>
        </div>
    );
}

export interface GotombolaFooterProps extends Required<WithType> {
    theme?: string;
    customBgColor?: string;
    customTextColor?: string;
    locale?: string;
    country?: string;
}

export default GotombolaFooter;
