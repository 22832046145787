import { MouseEventHandler, useMemo } from 'react';
import clsx from 'clsx';
import ContentLeftPushModule from '../../modules/pushs/ContentLeftPushModule';
import ContentRightPushModule from '../../modules/pushs/ContentRightPushModule';
import CenteredHeadingModule from '../../modules/headings/CenteredHeadingModule';
import { formatAmount } from '@gotombola/amount';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { image, WithDescription, WithName, WithOnClick, WithOverline, WithQuantity } from '@gotombola/react-types';
import Img from '../../atoms/Img';

export function FeaturedBunchItem({
    description,
    longDescription,
    mainImage,
    name,
    overline,
    priceAmount,
    priceCurrency,
    quantity,
    rank,
    indexCounter,
    onClick,
    sponsorings,
    image1Image,
    image2Image,
    image3Image,
    image4Image,
    image5Image,
    image6Image,
    image7Image,
    image8Image,
    image9Image,
}: FeaturedBunchItemProps) {
    const { t } = useTrans();
    const { blockShapeClass, logoShapeClass } = useStyling();
    const hasImage = !!mainImage?.url;
    const title = useMemo(
        () =>
            `${!!quantity && quantity > 1 ? `*${quantity} x*` : ''} ${name}${
                !!priceAmount ? ` - ${formatAmount(priceAmount, priceCurrency)}` : ''
            }`,
        [quantity, name, priceAmount, priceCurrency, formatAmount],
    );
    const buttonPush = useMemo(
        () => [
            {
                label: t('more_details_button_label'),
                className: clsx(
                    'mx-auto whitespace-nowrap cursor-pointer px-4 py-2 hover:shadow-md font-medium bg-go-primary text-white border-2 border-go-primary hover:border-go-secondary hover:bg-go-secondary',
                    blockShapeClass,
                ),
            },
        ],
        [clsx, blockShapeClass],
    );
    const buttonHeading = useMemo(() => {
        return (
            !!longDescription && {
                buttonLabel: t('more_details_button_label'),
                buttonFormat: clsx(
                    'mx-auto whitespace-nowrap cursor-pointer px-4 py-2 hover:shadow-md font-medium bg-go-primary text-white border-2 border-go-primary hover:border-go-secondary hover:bg-go-secondary',
                    blockShapeClass,
                ),
                buttonTarget: () => {
                    /* empty */
                },
            }
        );
    }, [clsx, blockShapeClass, longDescription]);
    const sponsoringsImages =
        !!sponsorings?.length && !!sponsorings?.filter((x) => !!x?.mainImage?.url || !!x?.logoImage?.url);
    const extraImages = useMemo(
        () =>
            [
                image1Image,
                image2Image,
                image3Image,
                image4Image,
                image5Image,
                image6Image,
                image7Image,
                image8Image,
                image9Image,
            ].filter((x: any) => !!x?.url) as any[],
        [
            image1Image,
            image2Image,
            image3Image,
            image4Image,
            image5Image,
            image6Image,
            image7Image,
            image8Image,
            image9Image,
        ],
    );

    const sponsorContent = !!sponsorings?.length && (
        <div className={'w-full flex flex-col items-center gap-2 p-2 mb-8'}>
            <div className={'flex flex-wrap justify-center items-center gap-x-2'}>
                {sponsoringsImages &&
                    sponsorings.map(({ mainImage, logoImage }, i) => (
                        <Img
                            key={i}
                            className={clsx(
                                'w-16 h-16 object-contain object-center flex-shrink-0 box-content',
                                logoShapeClass,
                            )}
                            url={logoImage?.url || mainImage?.url || ''}
                            maxSize={300}
                            alt={logoImage?.alt || mainImage?.alt || 'avatar'}
                        />
                    ))}
            </div>
            <div className={'font-go-text text-sm text-go-text font-light text-center'}>
                {t(
                    [`game_sponsoring_overline_${String(sponsorings.length)}`, `game_sponsoring_overline`],
                    sponsorings.reduce(
                        (acc, s, index) =>
                            Object.assign(acc, { [`name${index > 0 ? String(index + 1) : ''}`]: s?.name }),
                        {} as any,
                    ),
                )}
            </div>
        </div>
    );

    if (hasImage)
        return (
            <div
                className={'hover:bg-blue-50 hover:cursor-pointer hover:shadow-sm'}
                onClick={onClick as MouseEventHandler<HTMLDivElement>}
            >
                {((indexCounter !== undefined ? indexCounter + 1 : rank) || 1) % 2 === 0 ? (
                    <ContentRightPushModule
                        title={title}
                        titleCentered
                        image={mainImage as any}
                        extraImages={extraImages}
                        text={description}
                        overline={overline}
                        buttons={!!longDescription ? buttonPush : undefined}
                    >
                        {sponsorContent}
                    </ContentRightPushModule>
                ) : (
                    <ContentLeftPushModule
                        title={title}
                        titleCentered
                        image={mainImage as any}
                        extraImages={extraImages}
                        text={description}
                        overline={overline}
                        buttons={!!longDescription ? buttonPush : undefined}
                    >
                        {sponsorContent}
                    </ContentLeftPushModule>
                )}
            </div>
        );

    return (
        <div
            className={clsx(
                'hover:bg-blue-50 hover:cursor-pointer hover:shadow-sm',
                !description && !overline && 'sm:pt-8',
            )}
            onClick={onClick as MouseEventHandler<HTMLDivElement>}
        >
            <CenteredHeadingModule title={title} text={description} overline={overline} {...buttonHeading} />
        </div>
    );
}

export interface FeaturedBunchItemProps extends WithDescription, WithName, WithOverline, WithQuantity, WithOnClick {
    mainImage?: image;
    priceAmount?: number;
    priceCurrency?: string;
    rank?: number;
    indexCounter?: number;
    longDescription?: string;
    sponsorings?: {
        websiteUrl?: string;
        description?: string;
        name?: string;
        logoImage?: { url: string; alt: string };
        mainImage?: { url: string; alt: string };
    }[];
    image1Image?: { url: string; alt: string };
    image2Image?: { url: string; alt: string };
    image3Image?: { url: string; alt: string };
    image4Image?: { url: string; alt: string };
    image5Image?: { url: string; alt: string };
    image6Image?: { url: string; alt: string };
    image7Image?: { url: string; alt: string };
    image8Image?: { url: string; alt: string };
    image9Image?: { url: string; alt: string };
}

export default FeaturedBunchItem;
