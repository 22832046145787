import OrganizationFooter from '../../../molecules/footers/OrganizationFooter';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardFooterOrganizationModule({ organization, ...rest }: StandardFooterOrganizationModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <OrganizationFooter {...props} organization={organization} />
        </ModuleContainer>
    );
}

export interface StandardFooterOrganizationModuleProps {
    organization: any;
}

export default StandardFooterOrganizationModule;
