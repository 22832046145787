import { useMemo } from 'react';

export function useGameWebsiteUrl(game: any, forcedValue?: string) {
    return useMemo(
        () => forcedValue || game?.websiteUrl || game?.organizationWebsiteUrl,
        [forcedValue, game?.websiteUrl, game?.organizationWebsiteUrl],
    );
}

export default useGameWebsiteUrl;
