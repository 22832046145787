import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./organizationPresentation/StandardOrganizationPresentationGameModule')),
};

export function OrganizationPresentationGameModule(props: OrganizationPresentationGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type OrganizationPresentationGameModuleProps = GameModuleProps;

export default OrganizationPresentationGameModule;
