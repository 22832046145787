import BasePixel, { PixelProps as BasePixelProps } from '@gotombola/react-pixel';

export function Pixel(props: PixelProps) {
    return (
        <div style={{ width: 1, height: 1 }}>
            <BasePixel {...(props as any)} env={process.env.RAZZLE_GOTOMBOLA_ENV || 'prod'} />
        </div>
    );
}

export type PixelProps = Omit<BasePixelProps, 'env'>;

// noinspection JSUnusedGlobalSymbols
export default Pixel;
