import { useMemo } from 'react';
import useDynamicPublicPage from '../hooks/useDynamicPublicPage';
import DynamicPage from './DynamicPage';
import Pixel from '../atoms/Pixel';
import PushNotification from '../atoms/PushNotification';
import buildTypePageDefaultDefaults from '../utils/buildTypePageDefaultDefaults';
import { WithChildren, WithLocale, WithNoindex, WithType } from '../withs';

const defaultTemplates = {};
const defaultPixelProps = {};
const defaultPushProps = {};
const defaultCustomModes = {};

export function TypePage({
    children,
    defaults: forcedDefaults,
    doc,
    locale,
    noindex,
    page,
    pixelProps = defaultPixelProps,
    pixels,
    publicPages,
    pushProps = defaultPushProps,
    pushs,
    templates = defaultTemplates,
    type,
    layout,
    customModes = defaultCustomModes,
    extraFn,
    workflows,
    actions,
}: TypePageProps) {
    const defaults = useMemo(
        () => forcedDefaults || buildTypePageDefaultDefaults(type, doc),
        [type, doc, forcedDefaults],
    );
    const extra = useMemo(() => ({ locale, ...(extraFn ? extraFn({ doc, type, locale }) : {}) }), [locale, doc, type]);
    const pageName = useMemo(() => `${type}_${page}`, [type, page]);
    const { config, context } = useDynamicPublicPage(doc, `${pageName}_page`, publicPages, extra, defaults, layout);

    return (
        <DynamicPage
            config={config}
            context={context}
            templates={templates}
            noindex={noindex}
            customModes={customModes}
            workflows={workflows}
            actions={actions}
        >
            {children || ''}
            {!!pixels && <Pixel app={pixels['app']} event={pixels['pages'][pageName]} {...pixelProps} />}
            {!!pushs && <PushNotification app={pushs['app']} type={pushs['pages'][pageName]} {...pushProps} />}
        </DynamicPage>
    );
}

export interface TypePageProps extends WithChildren, WithLocale, WithNoindex, Required<WithType> {
    defaults?: Record<string, any>;
    doc?: any;
    page?: string;
    pixelProps?: Record<string, any>;
    pixels?: Record<string, any>;
    publicPages: Record<string, any>;
    pushProps?: Record<string, any>;
    pushs?: Record<string, any>;
    templates?: Record<string, any>;
    layout?: string;
    customModes?: Record<string, any>;
    extraFn?: Function;
    workflows?: any;
    actions?: any;
}

// noinspection JSUnusedGlobalSymbols
export default TypePage;
