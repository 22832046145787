import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function SponsoringTemplate({ sponsoring, page = 'sponsoring_public', ...props }: SponsoringTemplateProps) {
    return <TypeTemplate type={'sponsoring'} page={page.replace(/^sponsoring_/, '')} doc={sponsoring} {...props} />;
}

export interface SponsoringTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    sponsoring?: any;
    game?: any;
}

export default SponsoringTemplate;
