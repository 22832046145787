import { useMemo } from 'react';
import clsx from 'clsx';
import { WithCenter } from '../withs';
import Img from '../atoms/Img';

const sizes = {
    sm: 'w-8 sm:w-8',
    md: 'w-14 sm:w-8',
    lg: 'w-14 sm:w-14',
};

const onError = ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = 'https://statics.gotombola.co/tenants/gotombola/images/icons/language.svg';
};

export function LocaleItem({ center, full = false, locale, size = 'md' }: LocaleItemProps) {
    const src = useMemo(
        () =>
            `https://statics.gotombola.co/tenants/gotombola/images/icons/flags/4x3/${
                locale.slice(-2).toLowerCase() || ''
            }.svg`,
        [locale],
    );

    return (
        <div className={clsx('flex flex-1 items-center space-x-2 h-10', center && 'justify-center')}>
            <Img lazy={false} url={src} className={clsx(sizes[size || ''])} alt={'language'} onError={onError} />
            <div className={clsx('font-go-text ', !full && 'hidden', 'md:block')}>
                {(((locale || '').split('_') || ['', ''])[0] || '').toUpperCase()}
            </div>
        </div>
    );
}

export interface LocaleItemProps extends WithCenter {
    locale: string;
    full?: boolean;
    size?: 'sm' | 'md' | 'lg';
}

export default LocaleItem;
