import TypeMiniBanner, { TypeMiniBannerProps } from '../../../organisms/type/TypeMiniBanner';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardMiniBannerTypeModule({ typeType, ...rest }: StandardMiniBannerTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <TypeMiniBanner {...props} type={typeType} />
        </ModuleContainer>
    );
}

export interface StandardMiniBannerTypeModuleProps extends Omit<TypeMiniBannerProps, 'type'> {
    typeType: string;
}

export default StandardMiniBannerTypeModule;
