import { useCallback } from 'react';
import useRouterContext from '@gotombola/react-contexts/lib/hooks/useRouterContext';

export function useBuildUrlFor(kind: string, doc: any, autoPrefix = true) {
    const { buildUrl } = useRouterContext();
    return useCallback(
        (type: string, qs?: any, extraVars?: any, forcedDoc?: any) =>
            buildUrl(autoPrefix ? `${kind}_${type}` : type, forcedDoc || doc, qs, extraVars),
        [doc, kind, buildUrl, autoPrefix],
    );
}

// noinspection JSUnusedGlobalSymbols
export default useBuildUrlFor;
