import { ModuleProps } from '../types';
import ModuleMode from '../organisms/ModuleMode';
import useCustomModuleModes from '../hooks/useCustomModuleModes';

export function CustomModule(props: CustomModuleProps) {
    return <ModuleMode {...props} modes={useCustomModuleModes()} defaultMode={'unknown'} />;
}

export type CustomModuleProps = ModuleProps;

export default CustomModule;
