import TypeAgenda, { TypeAgendaProps } from '../../../organisms/type/TypeAgenda';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any) => !!doc?.agenda;

export function StandardAgendaTypeModule({ typeType, ...rest }: StandardAgendaTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    if (!doc) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeAgenda {...props} type={type} doc={doc} />
        </ModuleContainer>
    );
}

export interface StandardAgendaTypeModuleProps extends Omit<TypeAgendaProps, 'type'> {
    typeType: string;
}

export default StandardAgendaTypeModule;
