import TypeSocialNetworks, { TypeSocialNetworksProps } from '../../../organisms/type/TypeSocialNetworks';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import Section from '../../../molecules/Section';
import FlatIconsBlock from '../../../organisms/FlatIconsBlock';
import useTypeTranslation from '../../../hooks/useTypeTranslation';

export function PanelShareTypeModule({ doc, typeType, ...rest }: PanelShareTypeModuleProps) {
    const { t } = useTypeTranslation(typeType, doc);
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <Section>
                <TypeSocialNetworks
                    {...props}
                    doc={doc}
                    type={typeType}
                    component={FlatIconsBlock}
                    title={t('share_this_page')}
                />
            </Section>
        </ModuleContainer>
    );
}

export interface PanelShareTypeModuleProps extends Omit<TypeSocialNetworksProps, 'type'> {
    typeType: string;
}

export default PanelShareTypeModule;
