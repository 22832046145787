import TypeEffects, { TypeEffectsProps } from '../../../organisms/type/TypeEffects';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardEffectsTypeModule({ typeType, ...rest }: StandardEffectsTypeModuleProps) {
    const [, props] = useZoneCustomizationProps(rest);

    return <TypeEffects {...props} type={typeType} />;
}

export interface StandardEffectsTypeModuleProps extends TypeEffectsProps {
    typeType: string;
}

export default StandardEffectsTypeModule;
