import ModuleContainer from '../../organisms/ModuleContainer';
import Contents from '../../organisms/Contents';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function ContentsCommonModule({ config, ...rest }: ContentsCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <Contents config={config} {...props} />
        </ModuleContainer>
    );
}

export interface ContentsCommonModuleProps {
    config: any;
}

export default ContentsCommonModule;
