export function buildStorage() {
    const s = 'undefined' === typeof localStorage ? undefined : localStorage;
    if (!s) return undefined;
    // noinspection JSUnusedGlobalSymbols
    return {
        setItem: (key: string, value: any) => {
            if (!value) return s.removeItem(key);
            return s.setItem(key, JSON.stringify(value));
        },
        getItem: (key: string) => {
            const v = s.getItem(key);
            return v && 'undefined' !== v ? JSON.parse(v) : undefined;
        },
        hasItem: (key: string) => s.hasItem(key),
        removeItem: (key: string) => s.removeItem(key),
    };
}

// noinspection JSUnusedGlobalSymbols
export default buildStorage;
