import { useMemo } from 'react';
import { WithClassName } from '../../withs';
import TenantLocaleSwitcher from '../TenantLocaleSwitcher';
import Header from '../../organisms/Header';
import Img from '../../atoms/Img';
import clsx from 'clsx';
import String from '../../atoms/String';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function TenantHeader({ className, light = false, locale, tenant }: TenantHeaderProps) {
    const logoImage = !!tenant.logoImage && !!tenant.logoImage.url ? tenant.logoImage : undefined;
    const localeVersions = useMemo(
        () => tenant?.tenantVersions?.filter((x: any) => x.type === 'locale') || [],
        [tenant],
    );
    const hasVersions = !!localeVersions && !!localeVersions.length;
    const { logoShapeClass } = useStyling();

    return (
        <Header className={className}>
            <div className={'flex w-full items-center justify-center'}>
                {!!logoImage && (
                    <Img
                        className={clsx('hidden md:block h-11 flex mr-4', logoShapeClass)}
                        url={logoImage.url}
                        alt={logoImage.alt || 'logo'}
                        maxSize={250}
                    />
                )}
                <div
                    className={clsx(
                        hasVersions ? 'ml-4 text-left sm:ml-0' : 'text-center',
                        'sm:text-left flex-1 py-4 font-bold text-xl truncate',
                    )}
                >
                    {!!tenant.name && (
                        <span className={'font-go-text hidden lg:inline-block'}>
                            <String basic>{tenant.name}</String>
                        </span>
                    )}
                </div>
            </div>
            {!!tenant.code && (hasVersions || !light) && (
                <div className={'flex w-auto items-center justify-end space-x-2'}>
                    <div className={'hidden sm:flex sm:flex-1 items-center justify-end space-x-2'}></div>
                    {hasVersions && (
                        <div className={'w-auto pr-4 sm:pr-0'}>
                            <TenantLocaleSwitcher tenant={tenant} locale={locale} />
                        </div>
                    )}
                </div>
            )}
        </Header>
    );
}

export interface TenantHeaderProps extends WithClassName {
    tenant?: any;
    light?: boolean;
    locale?: string;
}

// noinspection JSUnusedGlobalSymbols
export default TenantHeader;
