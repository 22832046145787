import LeftHeadingModule from '../modules/headings/LeftHeadingModule';
import Button from '../atoms/Button';
import { WithButton, WithText, WithTitle } from '../withs';

export function LeftCallToActionContent({ title, text, buttonLabel, buttonTarget }: LeftCallToActionContentProps) {
    return (
        <div className={'flex justify-between items-center'}>
            <LeftHeadingModule title={title} text={text} />
            <Button label={buttonLabel as any} target={buttonTarget} variant={'secondary'} />
        </div>
    );
}

export interface LeftCallToActionContentProps extends WithTitle, WithText, WithButton {}

export default LeftCallToActionContent;
