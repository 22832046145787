import loadable from '@loadable/component';
import { SellerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./presentation/StandardPresentationSellerModule')),
};

export function PresentationSellerModule(props: PresentationSellerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type PresentationSellerModuleProps = SellerModuleProps;

export default PresentationSellerModule;
