import TextareaInput from '../atoms/inputs/TextareaInput';

export function TextareaDataField({ placeholder, allData, ...props }: TextareaDataFieldProps) {
    return <TextareaInput {...props} placeholder={placeholder || ''} />;
}

export interface TextareaDataFieldProps {
    name: string;
    onChange: Function;
    value: any;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    allData?: any;
    register: Function;
    control: Function;
}

// noinspection JSUnusedGlobalSymbols
export default TextareaDataField;
