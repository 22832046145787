import { useMemo } from 'react';
import DataField from '../molecules/DataField';
import clsx from 'clsx';

const defaultContent: any[] = [];

export type spacing = 'none' | 'xs' | 'sm' | 'md' | 'lg';

const styles: any = {
    defaults: {
        spacing: 'md',
    },
    spacings: {
        none: '',
        xs: 'space-y-2',
        sm: 'space-y-4',
        md: 'space-y-8',
        lg: 'space-y-10',
    },
};

const defaultEnabledFeatures = [];

export function DataSectionContent({
    value,
    onChange,
    enabledFeatures = defaultEnabledFeatures,
    spacing = styles.defaults.spacing,
    content = defaultContent,
}: DataSectionContentProps) {
    const field = useMemo(() => ({ data: value, onChange }), [value, onChange]);

    return (
        <div className={clsx('w-full mb-4', styles.spacings[spacing || styles.defaults.spacing])}>
            {content.map((c) => (
                <DataField key={c.name} {...c} {...field} enabledFeatures={enabledFeatures} />
            ))}
        </div>
    );
}

export interface DataSectionContentProps {
    value: any;
    onChange: Function;
    content: any[];
    spacing?: spacing;
    enabledFeatures?: string[];
}

// noinspection JSUnusedGlobalSymbols
export default DataSectionContent;
