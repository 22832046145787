import { WithClassName } from '../../withs';
import Footer from '../../organisms/Footer';

export function CampaignFooter({ className, campaign }: CampaignFooterProps) {
    const logoImage = !!campaign.logoImage && !!campaign.logoImage.url ? campaign.logoImage : undefined;

    return (
        <Footer
            logo={logoImage}
            address={campaign.city && `${campaign.zipCode} ${campaign.city}`}
            className={className}
            email={campaign.publicEmail}
            fullName={campaign.fullName || campaign.legalName}
            name={campaign.name}
            nin={campaign.nin}
            phone={campaign.publicPhone}
            street={campaign.street}
        />
    );
}

export interface CampaignFooterProps extends WithClassName {
    campaign?: any;
}

export default CampaignFooter;
