import clsx from 'clsx';
import { flag } from '../types';
import { WithChildren, WithClassName } from '../withs';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function Cell({ border = true, children, childrenClassName, className, emphasize = false }: CellProps) {
    const { tableShapeClass } = useStyling();

    return (
        <div
            className={clsx(
                'flex items-center md:h-auto overflow-auto',
                (border || emphasize) && 'md:border',
                emphasize && 'md:border-2',
                className,
                tableShapeClass,
            )}
        >
            <div className={childrenClassName}>{children || ''}</div>
        </div>
    );
}

export interface CellProps extends WithChildren, WithClassName {
    childrenClassName?: any;
    border?: flag;
    emphasize?: flag;
}

// noinspection JSUnusedGlobalSymbols
export default Cell;
