import { forwardRef, useMemo } from 'react';
import ChoiceField from './ChoiceField';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { ticket_price_amount_item } from '../../types';
import { formatAmount } from '@gotombola/amount';

const defaultItems: ticket_price_amount_item[] = [];

// noinspection JSUnusedLocalSymbols
export const TicketPriceAmountChoiceField = forwardRef(
    ({ currency = 'EUR', items: rawItems = defaultItems, ...props }: TicketPriceAmountChoiceFieldProps, ref) => {
        const { t } = useTrans();

        const items = useMemo(
            () =>
                rawItems.map((item: ticket_price_amount_item, index: number) => ({
                    id: item.id || index,
                    label: item.label,
                    overline: item.popular ? t('field_ticketpriceamountchoice_item_popular') : undefined,
                    value: t('field_ticketpriceamountchoice_item_label', {
                        price: formatAmount(item.price, item.currency || currency),
                    }),
                })),
            [rawItems, currency],
        );

        return <ChoiceField ref={ref} {...props} items={items} />;
    },
);

export interface TicketPriceAmountChoiceFieldProps {
    currency?: string;
    items: ticket_price_amount_item[];
    name: string;
    defaultValue?: any;
    required?: boolean;
    autoFocus?: boolean;
}

// noinspection JSUnusedGlobalSymbols
export default TicketPriceAmountChoiceField;
