import cs from 'color-string';

export const colorVars = {
    varColorPrimary: 'go-color-primary',
    varColorSecondary: 'go-color-secondary',
    varColorNavPrimary: 'go-color-nav-primary',
    varColorNavSecondary: 'go-color-nav-secondary',
    varColorFooterPrimary: 'go-color-footer-primary',
    varColorFooterSecondary: 'go-color-footer-secondary',
    varColorTitle: 'go-color-title',
    varColorHeading: 'go-color-heading',
    varColorBorderPrimary: 'go-color-border-primary',
    varColorChoicePrimary: 'go-color-choice-primary',
    varColorChoiceSecondary: 'go-color-choice-secondary',
    varColorPanelPrimary: 'go-color-panel-primary',
    varColorPanelSecondary: 'go-color-panel-secondary',
    varColorLogo: 'go-color-logo',
    varColorBackgroundPrimary: 'go-color-background-primary',
};

function rgbize(v) {
    const t = cs.get.rgb(v);
    t[3] === 1 && t.pop();
    return t.join(', ');
}

function cssifyGroup(g: any) {
    if (!g) return undefined;
    return Object.entries(g)
        .map(([k, v]) => {
            if (!k || !v) return '';
            if ('--' === k.slice(0, 2)) return `${k}: ${v};`;
            return ''; // @todo
        })
        .join('\n');
}

function cssify(z: any) {
    if (!z) return undefined;
    return Object.entries(z)
        .map(([k, v]) => {
            return `${k} { ${cssifyGroup(v)} }`;
        })
        .join('\n');
}
export function buildCssFromDoc({ __css: value = {} }: { __css?: Record<string, any> } = {}) {
    const z = Object.entries(value || {}).reduce((acc, [k, v]) => {
        if ('varColor' === k.slice(0, 8)) {
            if (!v || 'string' !== typeof v) return acc;
            acc[':root'] = acc[':root'] || {};
            acc[':root'][`--${colorVars[k]}`] = rgbize(v);
            return acc;
        }
        if ('customTheme' === k) {
            acc[':root'] = Object.entries(v).reduce((acc2, [kk, vv]) => {
                acc2[`--${colorVars[kk]}`] = rgbize(vv);
                return acc2;
            }, acc[':root'] || {});
            return acc;
        }
        return acc;
    }, {});

    return cssify(z);
}

export default buildCssFromDoc;
