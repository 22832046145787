import clsx from 'clsx';
import { WithChildren, WithClassName } from '../withs';

export function BasicLayout({ children, className }: BasicLayoutProps) {
    return <div className={clsx('w-full', className)}>{children || ''}</div>;
}

export interface BasicLayoutProps extends WithChildren, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default BasicLayout;
