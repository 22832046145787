import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Img from './Img';
import clsx from 'clsx';
import StaticIcon from './StaticIcon';
import FlipCard from './FlipCard';
import useAdZoneComponent from '@gotombola/react-ad/lib/hooks/useAdZoneComponent';

export function MainLogo({ url, alt, defaultAlt, target, defaultIcon, adZone }: MainLogoProps) {
    const { logoShapeClass, logoSizeClass, blockBorderClass, blockBorderColorClass } = useStyling();
    const AdComp = useAdZoneComponent(adZone);

    let logoElement = !!url ? (
        <Img
            lazy={false}
            className={clsx(
                'bg-go-logo flex items-center justify-center shadow-sm p-2 object-contain',
                logoSizeClass,
                logoShapeClass,
                blockBorderClass,
                blockBorderColorClass,
            )}
            url={url}
            maxSize={300}
            alt={alt || defaultAlt}
        />
    ) : defaultIcon ? (
        <StaticIcon
            type={defaultIcon}
            className={clsx(
                'p-2 text-white bg-white shadow-sm',
                logoSizeClass,
                logoShapeClass,
                blockBorderClass,
                blockBorderColorClass,
            )}
        />
    ) : null;
    target && (logoElement = <a href={target}>{logoElement}</a>);

    adZone && (logoElement = <FlipCard backChildren={AdComp ? <AdComp /> : null}>{logoElement}</FlipCard>);

    return logoElement;
}

export interface MainLogoProps {
    url?: string;
    alt?: string;
    defaultAlt: string;
    defaultIcon?: string;
    target?: string;
    adZone?: string;
}

export default MainLogo;
