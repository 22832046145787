import useTypeTranslation from '../../../hooks/useTypeTranslation';
import useTypeUrl from '../../../hooks/useTypeUrl';
import useQueryString from '../../../hooks/useQueryString';
import MobileFixedButton from '../../../atoms/MobileFixedButton';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardMobileCallToActionPartnerModule({
    partner,
    ...rest
}: StandardMobileCallToActionPartnerModuleProps) {
    const [, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('partner', partner);
    const qs = useQueryString().toString();
    const targetUrl = useTypeUrl('partner', partner, 'register', qs);

    return <MobileFixedButton buttonTarget={targetUrl} buttonLabel={t('register_label')} {...props} />;
}

export interface StandardMobileCallToActionPartnerModuleProps {
    partner: any;
}

export default StandardMobileCallToActionPartnerModule;
