import CampaignHeader from '../../../molecules/headers/CampaignHeader';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardHeaderCampaignModule({ campaign, ...rest }: StandardHeaderCampaignModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <CampaignHeader {...props} campaign={campaign} />
        </ModuleContainer>
    );
}

export interface StandardHeaderCampaignModuleProps {
    campaign: any;
}

export default StandardHeaderCampaignModule;
