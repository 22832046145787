import clsx from 'clsx';
import { WithStatus } from '../withs';
import useTypeTranslation from '../hooks/useTypeTranslation';
import { formatRawAmount } from '@gotombola/amount';
import formatDate from '@gotombola/date/lib/utils/formatDate';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { useEffect, useState } from 'react';
import CountDown from './CountDown';

export function GameStats({
    drawDate,
    hideDrawDate = false,
    hidePlannedTickets = false,
    hideRemainingTickets = false,
    hideStartDate = false,
    hideStatTickets = false,
    hideTicketPrice = true,
    hideDrawCountDown = true,
    locale,
    permanent,
    plannedTickets,
    remainingTickets,
    startDate,
    statTickets,
    statImportedTickets,
    status,
    ticketPriceAmount,
    ticketPriceCurrency,
    flexModeStatus,
}: GameStatsProps) {
    const { t } = useTypeTranslation('game');
    const isPlaying = status === 'RUNNING' || status === 'DATE_REACHED' || status === 'PAUSED';
    const localPlannedTickets = (plannedTickets || 0) + (statImportedTickets || 0);
    const localStatTickets = (statTickets || 0) + (statImportedTickets || 0);
    const countDownEnd = new Date(drawDate as Date).getTime();
    const [countDownOver, setCountDownOver] = useState(countDownEnd - new Date().valueOf() <= 0);

    const {
        blockShapeClass,
        blockBorderClass,
        blockBorderNoMobileClass,
        blockBorderColorClass,
        blockEmphasizedBorderColorClass,
    } = useStyling();

    useEffect(() => {
        if (hideDrawCountDown && countDownEnd - Date.now() <= 0) {
            setCountDownOver(true);
            return;
        }

        const interval = setInterval(() => {
            if (countDownEnd - Date.now() <= 0) {
                setCountDownOver(true);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownEnd]);

    return (
        <div className={'w-full flex flex-col-reverse md:flex-row flex-wrap justify-center text-xs gap-4'}>
            {!hideStartDate && !permanent && !!startDate && (
                <div
                    className={clsx(
                        'bg-white flex-1 flex justify-center items-center p-3 w-full',
                        blockBorderColorClass,
                        blockBorderNoMobileClass,
                        blockShapeClass,
                    )}
                >
                    <div className={'text-center'}>
                        <div className={'font-go-text text-sm font-light text-gray-400'}>
                            {t('game_starting_label')}
                        </div>
                        {startDate && (
                            <div className={'font-go-text font-light text-2xl'}>{formatDate(startDate, locale)}</div>
                        )}
                    </div>
                </div>
            )}
            {!hidePlannedTickets &&
                (remainingTickets || 0) < 20 &&
                (localPlannedTickets || 0) > 0 &&
                !(
                    'ACTIVATED' === flexModeStatus &&
                    !!localPlannedTickets &&
                    !!localStatTickets &&
                    localStatTickets > localPlannedTickets
                ) && (
                    <div
                        className={clsx(
                            'bg-white flex-1 flex justify-center items-center p-3 w-full',
                            blockBorderColorClass,
                            blockBorderNoMobileClass,
                            blockShapeClass,
                        )}
                    >
                        <div className={'font-light text-xl text-center'}>
                            {!!localPlannedTickets && (
                                <div className={'font-go-text'}>
                                    {t('game_planned_tickets', { count: localPlannedTickets })}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            {!hideRemainingTickets && (remainingTickets || 0) > 20 && isPlaying && (
                <div
                    className={clsx(
                        'bg-white flex-1 flex justify-center items-center p-3 w-full',
                        blockBorderColorClass,
                        blockBorderNoMobileClass,
                        blockShapeClass,
                    )}
                >
                    <div className={'font-light text-xl text-center'}>
                        {!!remainingTickets && (
                            <div className={'font-go-text'}>
                                {t('game_total_remaining_tickets', { count: remainingTickets })}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!hideStatTickets && !!localStatTickets && (
                <div
                    className={clsx(
                        'bg-white flex-1 flex justify-center items-center p-3 w-full',
                        blockShapeClass,
                        blockBorderNoMobileClass,
                        blockBorderColorClass,
                    )}
                >
                    <div className={'text-green-500 font-medium text-xl text-center'}>
                        {!!localStatTickets && (
                            <div className={'font-go-text'}>{t('game_sold_tickets', { count: localStatTickets })}</div>
                        )}
                    </div>
                </div>
            )}
            {!hideTicketPrice && !!ticketPriceAmount && (
                <div
                    className={clsx(
                        'text-left mb-8 bg-white justify-center items-start p-3 w-auto',
                        blockBorderClass,
                        blockShapeClass,
                    )}
                >
                    <div className={'font-go-text text-sm font-medium text-gray-400'}>{t('game_ticket_price')}</div>
                    <div className={'font-go-text font-medium text-xl'}>
                        {formatRawAmount(ticketPriceAmount, ticketPriceCurrency)}
                    </div>
                </div>
            )}
            {!hideDrawDate && !!drawDate && (
                <div
                    className={clsx(
                        'bg-white flex-1 flex justify-center items-center p-3 w-full',
                        blockBorderClass,
                        blockEmphasizedBorderColorClass,
                        blockShapeClass,
                    )}
                >
                    <div className={'text-center'}>
                        <div className={'font-go-text text-sm font-medium text-gray-400'}>{t('game_draw_label')}</div>
                        {drawDate && (
                            <div className={'font-go-text font-medium text-2xl'}>{formatDate(drawDate, locale)}</div>
                        )}
                    </div>
                </div>
            )}
            {!hideDrawCountDown && !!drawDate && !countDownOver && (
                <div
                    className={clsx(
                        'bg-white flex-1 flex justify-center items-center p-3 w-full',
                        blockBorderClass,
                        blockEmphasizedBorderColorClass,
                        blockShapeClass,
                    )}
                >
                    <div className={'text-center flex flex-col gap-1'}>
                        <div className={'font-go-text text-sm font-medium text-gray-400'}>
                            {t('game_draw_count_down_label')}
                        </div>
                        {drawDate && <CountDown value={countDownEnd} light />}
                    </div>
                </div>
            )}
        </div>
    );
}

export interface GameStatsProps extends WithStatus {
    startDate?: Date;
    statTickets?: number;
    statImportedTickets?: number;
    plannedTickets?: number;
    remainingTickets?: number;
    drawDate?: Date;
    permanent?: boolean;
    hidePlannedTickets?: boolean;
    hideRemainingTickets?: boolean;
    hideStatTickets?: boolean;
    hideStartDate?: boolean;
    hideDrawDate?: boolean;
    hideTicketPrice?: boolean;
    hideDrawCountDown?: boolean;
    locale?: string;
    ticketPriceAmount?: number;
    ticketPriceCurrency?: string;
    flexModeStatus?: string;
    features?: string[];
}

export default GameStats;
