import clsx from 'clsx';
import { WithDescription, WithName } from '../../withs';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Img from '../../atoms/Img';
import { useMemo } from 'react';
import { image } from '../../types';
import Text from '../../atoms/Text';
import ClickableContent from '../../atoms/ClickableContent';

export function ListLabelItem({
    description,
    logoImage,
    mainImage,
    name,
    presentation,
    thumbnailImage,
    websiteUrl,
}: ListLabelItemProps) {
    const { logoShapeClass } = useStyling();
    const image = useMemo(
        () => [thumbnailImage, logoImage, mainImage].find((x) => !!x?.url) || undefined,
        [thumbnailImage, logoImage, mainImage],
    );
    const title = useMemo(() => name || presentation, [name, presentation]);
    const text = useMemo(() => (name ? presentation || description : description), [name, presentation, description]);

    return (
        <ClickableContent url={websiteUrl} mode={'_blank'}>
            <div
                className={clsx(
                    'flex flex-col md:flex-row items-center hover:bg-go-choice-primary p-2 lg:p-4 gap-2 md:gap-4',
                    !!websiteUrl && 'hover:cursor-pointer',
                )}
            >
                <div className={clsx('w-2/3 md:w-1/6')}>
                    {!!image?.url && (
                        <Img
                            className={clsx(
                                'w-full sm:mx-auto sm:w-32 sm:h-32 object-contain object-center flex-shrink-0',
                                logoShapeClass,
                            )}
                            url={image.url}
                            maxSize={300}
                            alt={image.alt || 'label'}
                        />
                    )}
                </div>
                <div className={'flex-1 flex flex-col gap-2'}>
                    <Text light className={'text-center md:text-left'}>
                        {title}
                    </Text>
                    <Text light className={'text-center md:text-left text-sm'}>
                        {text}
                    </Text>
                </div>
            </div>
        </ClickableContent>
    );
}

export interface ListLabelItemProps extends WithName, WithDescription {
    logoImage?: image;
    mainImage?: image;
    thumbnailImage?: image;
    presentation?: string;
    websiteUrl?: string;
}

// noinspection JSUnusedGlobalSymbols
export default ListLabelItem;
