import useHttpMutation from './useHttpMutation';
import { useCallback, useMemo, useState } from 'react';

export function useFrontendPostApi(name: string, onSubmitted?: Function, onErrored?: Function) {
    const [state, setState] = useState<{ result: any; error: any }>({ result: undefined, error: undefined });
    const [call, { loading, called }] = useHttpMutation(`/api/${name}`, 'POST', { 'Content-Type': 'application/json' });
    const submit = useCallback(
        (variables: any) => {
            'undefined' !== typeof window && (window as any).scrollTo(0, 0);
            const p = call({ variables });
            p.then((newResult) => {
                setState({ result: newResult?.data, error: newResult?.error });
                onSubmitted && onSubmitted(newResult);
            });
            p.catch((error) => {
                setState({ result: undefined, error });
                onErrored && onErrored(error);
            });
        },
        [call, setState],
    );
    const reset = useCallback(() => {
        setState({ result: undefined, error: undefined });
    }, []);
    return useMemo(
        () => ({ reset, submit, loading, data: state.result, error: state.error, called }),
        [submit, loading, state.result, state.error, called],
    );
}

// noinspection JSUnusedGlobalSymbols
export default useFrontendPostApi;
