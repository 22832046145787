import { WithClassName, WithDisabled, WithLabel, WithLoading, WithOnClick } from '../../withs';
import Spinner2 from '../Spinner2';

export function FunctionButton({ className, label, onClick, loading = false, ...props }: FunctionButtonProps) {
    return (
        <button type={'button'} onClick={onClick as any} {...props} className={className}>
            {loading && <Spinner2 size={'sm'} />}
            {!loading && (label || '')}
        </button>
    );
}

export interface FunctionButtonProps
    extends WithClassName,
        WithDisabled,
        Required<WithLabel>,
        WithOnClick,
        WithLoading {}

// noinspection JSUnusedGlobalSymbols
export default FunctionButton;
