import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { WithImage, WithItems, WithTitle } from '../../../withs';
import Figures from '../../../organisms/Figures';

export function StandardServiceFiguresPartnerModule({ partner, ...rest }: StandardServiceFiguresPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <Figures {...props} />
        </ModuleContainer>
    );
}

export interface StandardServiceFiguresPartnerModuleProps extends WithImage, WithItems, WithTitle {
    partner: any;
}

export default StandardServiceFiguresPartnerModule;
