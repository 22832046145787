import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./locationPresentation/StandardLocationPresentationTypeModule')),
};

export function LocationPresentationTypeModule(props: LocationPresentationTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type LocationPresentationTypeModuleProps = TypeModuleProps;

export default LocationPresentationTypeModule;
