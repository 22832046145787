import Banner from '../../../organisms/Banner';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { TypeProps } from '../../../types';
import CorporateHeading from '../../../organisms/CorporateHeading';
import { useMemo } from 'react';

export function CorporateBannerTypeModule({ typeType, doc, ...rest }: CorporateBannerTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    const image = useMemo(
        () =>
            [
                doc?.bannerImage,
                doc?.organizationBannerImage,
                doc?.partnerBannerImage,
                doc?.campaignBannerImage,
                doc?.tenantBannerImage,
            ].find((x) => !!x?.url),
        [doc],
    );

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            {(!!doc.overline || !!doc.name || !!doc.presentation) && (
                <CorporateHeading
                    overline={doc.overline}
                    title={doc.name}
                    text={doc.presentation}
                    target={doc.websiteUrl}
                />
            )}
            <Banner image={image} {...props} />
        </ModuleContainer>
    );
}

export interface CorporateBannerTypeModuleProps extends TypeProps {
    typeType: string;
}

export default CorporateBannerTypeModule;
