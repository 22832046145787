import TypeAbout, { TypeAboutProps } from '../../../organisms/type/TypeAbout';
import ModuleContainer from '../../../organisms/ModuleContainer';
import { WithZoneCustomization } from '../../../withs';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any) => !!doc?.presentation;

export function StandardAboutTypeModule({ typeType, ...rest }: StandardAboutTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');

    if (!doc) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeAbout {...props} type={type} doc={doc} />
        </ModuleContainer>
    );
}

export interface StandardAboutTypeModuleProps extends Omit<TypeAboutProps, 'type'>, WithZoneCustomization {
    typeType: string;
}

export default StandardAboutTypeModule;
