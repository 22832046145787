import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./footer/StandardFooterPartnerModule')),
};

export function FooterPartnerModule(props: FooterPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type FooterPartnerModuleProps = PartnerModuleProps;

export default FooterPartnerModule;
