import {
    WithAutoFocus,
    WithClassName,
    WithDisabled,
    WithErrors,
    WithLabel,
    WithName,
    WithOnChange,
    WithPlaceholder,
    WithRequired,
    WithType,
    WithValue,
} from '../withs';
import clsx from 'clsx';
import { forwardRef } from 'react';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Text from './Text';

export const Input = forwardRef(
    ({ className, onChange, placeholder, disabled, label, errors, ...props }: InputProps, ref) => {
        const error = errors?.[props.name || ''];
        const { blockShapeClass } = useStyling();

        return (
            <div className={'className'}>
                {!!label && (
                    <label htmlFor={props.name}>
                        <Text inline>{label}</Text>
                        {!!props.required && <Text className={'text-red-500'}>*</Text>}
                    </label>
                )}
                <input
                    ref={ref as any}
                    onChange={onChange as any}
                    placeholder={placeholder as string}
                    className={clsx(
                        'font-go-text text-go-text border p-3 w-full',
                        props.required ? 'border-gray-500' : 'border-gray-300',
                        blockShapeClass,
                        className,
                    )}
                    disabled={disabled}
                    {...props}
                />
                {!!error && <Text className={'text-red-500 text-left pl-2'}>{error?.message}</Text>}
            </div>
        );
    },
);

export interface InputProps
    extends WithClassName,
        WithValue,
        WithLabel,
        WithAutoFocus,
        WithType,
        WithDisabled,
        WithName,
        WithRequired,
        WithErrors,
        Required<WithOnChange>,
        Required<WithPlaceholder> {}
// noinspection JSUnusedGlobalSymbols
export default Input;
