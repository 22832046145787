export class ApiError extends Error {
    constructor(data: any, response: Response) {
        super(ApiError.buildMessage(response, data));
        data = data || {};
        this['response'] = response;
        this['payload'] = data;
        this['errorType'] = data['errorType'] || 'unknown';
        this['data'] = data['data'] || {};
        this['code'] = data['code'] || 1;
    }
    toJson() {
        return {
            message: this.message,
            errorType: this['errorType'],
            data: this['data'],
            code: this['code'],
        };
    }

    protected static buildMessage(response: Response, data: any): string {
        return (data || {})['message'] || `Unexpected API error (code: ${response.status}`;
    }
}

// noinspection JSUnusedGlobalSymbols
export default ApiError;
