import { MouseEventHandler, useMemo } from 'react';
import clsx from 'clsx';
import Img from '../../atoms/Img';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import truncate from '../../utils/truncate';
import AnimatedImage from '../../atoms/AnimatedImage';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { image, WithDescription, WithName, WithOnClick, WithQuantity } from '@gotombola/react-types';

export function CardsBunchItem({
    mainImage,
    name,
    quantity,
    onClick,
    rank,
    sponsorings,
    description,
    image1Image,
    image2Image,
    image3Image,
    image4Image,
    image5Image,
    image6Image,
    image7Image,
    image8Image,
    image9Image,
}: CardsBunchItemProps) {
    const { t } = useTrans();
    const { blockShapeClass, blockBorderClass, logoShapeClass } = useStyling();
    const rankString = `${rank}${quantity! > 1 ? `-${rank! + (quantity! - 1)}` : ''}`;
    const hasImage = !!mainImage?.url;
    const sponsoringsImages =
        !!sponsorings?.length && !!sponsorings?.filter((x) => !!x?.mainImage?.url || !!x?.logoImage?.url);
    const extraImages = useMemo(
        () =>
            [
                image1Image,
                image2Image,
                image3Image,
                image4Image,
                image5Image,
                image6Image,
                image7Image,
                image8Image,
                image9Image,
            ].filter((x: any) => !!x?.url) as any[],
        [
            image1Image,
            image2Image,
            image3Image,
            image4Image,
            image5Image,
            image6Image,
            image7Image,
            image8Image,
            image9Image,
        ],
    );

    return (
        <div
            className={clsx(
                'flex shadow group relative hover:cursor-pointer overflow-hidden hover:border-go-choice-secondary',
                blockBorderClass,
                blockShapeClass,
            )}
            onClick={onClick as MouseEventHandler<HTMLDivElement>}
        >
            <div
                className={clsx(
                    'font-go-text absolute top-2 left-2 bg-opacity-95 bg-go-primary px-2 py-1 text-sm font-light text-white',
                    blockShapeClass,
                )}
            >
                {t('game_listing_rank_position_item', { rankString })}
            </div>
            {hasImage ? (
                <AnimatedImage
                    className={clsx(
                        'object-contain w-5/12 min-h-[350px] p-4 bg-white group-hover:bg-go-choice-secondary',
                        blockShapeClass,
                        'rounded-r-none',
                    )}
                    mainImage={mainImage}
                    maxSize={300}
                    alt={mainImage?.alt || 'image item'}
                    extraImages={extraImages}
                />
            ) : (
                <div className={'w-5/12 min-h-[350px] group-hover:bg-go-choice-primary'} />
            )}
            <div
                className={clsx(
                    'w-7/12 flex-1 flex flex-col gap-2 p-4 bg-go-choice-primary group-hover:bg-go-choice-primary',
                    blockShapeClass,
                    'rounded-l-none',
                )}
            >
                {!!name && (
                    <Text semibold upper className={'flex-1 overflow-hidden text-left text-lg'}>
                        {name}
                    </Text>
                )}
                {!!description && (
                    <Text light className={'flex-1 overflow-hidden text-sm text-left'}>
                        {truncate(description as string, 140)}
                    </Text>
                )}
                {!!sponsorings?.length && (
                    <div className={'flex flex-col gap-2 text-left'}>
                        <div className={'flex flex-wrap justify-start items-center gap-x-4'}>
                            {sponsoringsImages &&
                                sponsorings.map(({ mainImage, logoImage }, i) => (
                                    <Img
                                        key={i}
                                        className={clsx(
                                            'w-14 h-14 object-contain object-center flex-shrink-0 box-content',
                                            logoShapeClass,
                                        )}
                                        url={logoImage?.url || mainImage?.url || ''}
                                        maxSize={300}
                                        alt={logoImage?.alt || mainImage?.alt || 'avatar'}
                                    />
                                ))}
                        </div>
                        <div className={'font-go-text font-semi-bold text-sm'}>
                            {t(
                                [`game_sponsoring_overline_${String(sponsorings.length)}`, `game_sponsoring_overline`],
                                sponsorings.reduce(
                                    (acc, s, index) =>
                                        Object.assign(acc, { [`name${index > 0 ? String(index + 1) : ''}`]: s?.name }),
                                    {} as any,
                                ),
                            )}
                        </div>
                    </div>
                )}
                <Button
                    label={t('more_details_button_label')}
                    className={'text-sm w-auto px-0 py-0 sm:px-2 sm:py-1'}
                    target={onClick}
                    variant={'secondary'}
                />
            </div>
        </div>
    );
}

export interface CardsBunchItemProps extends WithName, WithDescription, WithQuantity, WithOnClick {
    mainImage?: image;
    rank?: number;
    sponsorings?: {
        websiteUrl?: string;
        description?: string;
        name?: string;
        logoImage?: { url: string; alt: string };
        mainImage?: { url: string; alt: string };
    }[];
    image1Image?: { url: string; alt: string };
    image2Image?: { url: string; alt: string };
    image3Image?: { url: string; alt: string };
    image4Image?: { url: string; alt: string };
    image5Image?: { url: string; alt: string };
    image6Image?: { url: string; alt: string };
    image7Image?: { url: string; alt: string };
    image8Image?: { url: string; alt: string };
    image9Image?: { url: string; alt: string };
}

export default CardsBunchItem;
