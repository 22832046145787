import loadable from '@loadable/component';

export default {
    type_about: loadable(() => import('./AboutTypeModule')),
    type_agenda: loadable(() => import('./AgendaTypeModule')),
    type_badges: loadable(() => import('./BadgesTypeModule')),
    type_banner: loadable(() => import('./BannerTypeModule')),
    type_contact_owner: loadable(() => import('./ContactOwnerTypeModule')),
    type_demo_banner: loadable(() => import('./DemoBannerTypeModule')),
    type_description: loadable(() => import('./DescriptionTypeModule')),
    type_detailed_infos: loadable(() => import('./DetailedInfosTypeModule')),
    type_effects: loadable(() => import('./EffectsTypeModule')),
    type_images: loadable(() => import('./ImagesTypeModule')),
    type_location_presentation: loadable(() => import('./LocationPresentationTypeModule')),
    type_mini_banner: loadable(() => import('./MiniBannerTypeModule')),
    type_mini_social_networks: loadable(() => import('./MiniSocialNetworksTypeModule')),
    type_page_title: loadable(() => import('./PageTitleTypeModule')),
    type_presentation: loadable(() => import('./PresentationTypeModule')),
    type_qrcode: loadable(() => import('./QrcodeTypeModule')),
    type_quote: loadable(() => import('./QuoteTypeModule')),
    type_secondary_images: loadable(() => import('./SecondaryImagesTypeModule')),
    type_secondary_videos: loadable(() => import('./SecondaryVideosTypeModule')),
    type_share: loadable(() => import('./ShareTypeModule')),
    type_sms_keyword: loadable(() => import('./SmsKeywordTypeModule')),
    type_sponsors: loadable(() => import('./SponsorsTypeModule')),
    type_terms: loadable(() => import('./TermsTypeModule')),
    type_tertiary_images: loadable(() => import('./TertiaryImagesTypeModule')),
    type_tertiary_videos: loadable(() => import('./TertiaryVideosTypeModule')),
    type_title: loadable(() => import('./TitleTypeModule')),
    type_video: loadable(() => import('./VideoTypeModule')),
    type_videos: loadable(() => import('./VideosTypeModule')),
};
