import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function OrganizationTemplate({
    organization,
    page = 'organization_public',
    ...props
}: OrganizationTemplateProps) {
    return (
        <TypeTemplate type={'organization'} page={page.replace(/^organization_/, '')} doc={organization} {...props} />
    );
}

export interface OrganizationTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    organization?: any;
}

export default OrganizationTemplate;
