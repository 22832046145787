import loadable from '@loadable/component';
import { TenantModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./header/StandardHeaderTenantModule')),
};

export function HeaderTenantModule(props: HeaderTenantModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type HeaderTenantModuleProps = TenantModuleProps;

export default HeaderTenantModule;
