import ModuleContainer from '../../../organisms/ModuleContainer';
import AttributeMessage, { AttributeMessageProps } from '../../../organisms/AttributeMessage';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useDocAttribute from '../../../hooks/useDocAttribute';
import { useMemo } from 'react';

export function StandardMessageCommonModule({ ...rest }: StandardMessageCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const attribute = useMemo(
        () =>
            props.attribute
                ? 'function' === typeof props.attribute
                    ? props.attribute(props)
                    : props.attribute
                : props.attribute,
        [props, props.attribute],
    );
    const text = useDocAttribute(props.doc, attribute);
    if (!text) return null;

    return (
        <ModuleContainer {...zprops}>
            <AttributeMessage text={text} {...props} attribute={undefined} />
        </ModuleContainer>
    );
}

export interface StandardMessageCommonModuleProps extends Omit<AttributeMessageProps, 'attribute'> {
    attribute: string | ((params: unknown) => string | undefined);
}

export default StandardMessageCommonModule;
