import { useCallback, useMemo, useState } from 'react';
import useFrontendPostApi from './useFrontendPostApi';

export function useFrontendPostApiState(
    name: string,
    initialState: any = {},
    isSubmittableFn?: Function,
    onSubmitted?: Function,
    onErrored?: Function,
) {
    const [state, setState] = useState<any>(initialState);
    const { loading, called, reset, submit, error, data } = useFrontendPostApi(name, onSubmitted, onErrored);
    const isSubmitDisabled = useMemo(
        () => (isSubmittableFn ? !isSubmittableFn(state) : false),
        [state, isSubmittableFn],
    );
    const onFieldChange = useCallback(
        (name: string) => (e: any) => {
            setState({ ...state, [name]: e?.target?.value });
        },
        [setState, state],
    );
    const onSubmit = useCallback(() => {
        submit(state);
    }, [submit, state]);
    return useMemo(
        () => ({
            loading,
            called,
            reset,
            submit: onSubmit,
            error,
            data,
            state,
            setState,
            onFieldChange,
            isSubmitDisabled,
        }),
        [setState, loading, called, reset, onSubmit, error, data, setState, state, isSubmitDisabled, onFieldChange],
    );
}

// noinspection JSUnusedGlobalSymbols
export default useFrontendPostApiState;
