import { WithZoneCustomization } from '../withs';

export function useZoneCustomizationProps<T = any>(
    props: WithZoneCustomization & T,
): [
    WithZoneCustomization,
    Omit<T, 'padding' | 'bgStyle' | 'bgCustom' | 'bgImage' | 'bgTexture' | 'bgVideoUrl' | 'bgColor'>,
] {
    const { bgCustom, bgImage, bgStyle, bgTexture, bgVideoUrl, bgColor, padding, ...rest } = props;

    return [{ bgCustom, bgImage, bgStyle, bgTexture, bgVideoUrl, bgColor, ...(padding ? { padding } : {}) }, rest];
}

export default useZoneCustomizationProps;
