import base64url from 'base64url';
import buildMicroserviceEndpoint from './buildMicroserviceEndpoint';

export function buildQrCodeUrl(target: string, format = 'webp', options: any = {}): string {
    const { withoutLogo, customLogo = undefined, style: forcedStyle = undefined, filename = 'q', ...rest } = options;
    const style = customLogo ? `custom:${base64url(customLogo)}` : forcedStyle || 'gotombola';
    const params = Object.keys(rest).length
        ? Object.keys(rest).reduce((acc, x) => acc + `${x}=${options[x]}&`, '?')
        : '';

    if ('svg' === format) {
        return `${buildMicroserviceEndpoint('qrcode.api')}/q/${base64url(
            JSON.stringify({ data: target, ...(options.size ? { scale: options.size / 148 } : {}) }),
        )}/${filename}.svg${params}`;
    }

    return `${buildMicroserviceEndpoint('image.api')}/images/q/${base64url(JSON.stringify({ data: target }))}/${
        !!withoutLogo ? 'none' : style
    }/${style ? `${style}-` : ''}${filename}.${format}${params}`;
}

export default buildQrCodeUrl;
