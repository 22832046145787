import buildSaleItemsFromRequested from './buildSaleItemsFromRequested';
import { order, pack } from '../types';

export function buildSaleItems(requested: string | undefined, order: order, packs: pack[]) {
    requested = String(requested || '').trim();

    const a0 =
        (order.tickets || 0) + ((order.books || 0) > 0 ? (order.books || 0) * parseInt(order.bookTickets as any) : 0);
    const a1 = order.donation || 0;
    const a2 = order.packCode;

    requested = [
        requested,
        a0 ? `ticket=${a0}` : undefined,
        a1 ? `donation=${a1}` : undefined,
        a2 ? `pack:${a2}=1` : undefined,
    ]
        .filter((x) => !!x)
        .join(',');

    packs = packs?.length
        ? packs
        : [{ id: 'ticket', code: 'ticket', tickets: 1, donation: 0, price: order.ticketPriceAmount }];

    const items = buildSaleItemsFromRequested(requested, packs, order.ticketPriceAmount);

    const tickets = items.reduce((acc, item) => acc + (item.tickets || 0) * (item.quantity || 1), 0);
    const donation = items.reduce((acc, item) => acc + (item.donation || 0) * (item.quantity || 1), 0);
    const paymentAmount = items.reduce((acc, item) => acc + (item.amount || 0) * (item.quantity || 1), 0);

    return [requested, items, { tickets, donation, paymentAmount }];
}

export default buildSaleItems;
