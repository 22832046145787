import { MouseEventHandler, useMemo } from 'react';
import clsx from 'clsx';
import truncate from '../../utils/truncate';
import Button from '../../atoms/Button';
import AnimatedImage from '../../atoms/AnimatedImage';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { image, WithDescription, WithName, WithOnClick, WithQuantity } from '@gotombola/react-types';

export function MosaicBunchItem({
    mainImage,
    name,
    nameUpper = true,
    quantity,
    onClick,
    rank,
    sponsorings,
    description,
    image1Image,
    image2Image,
    image3Image,
    image4Image,
    image5Image,
    image6Image,
    image7Image,
    image8Image,
    image9Image,
}: MosaicBunchItemProps) {
    const { t } = useTrans();
    const { blockShapeClass, blockBorderClass } = useStyling();
    const rankString = `${rank}${quantity! > 1 ? `-${rank! + (quantity! - 1)}` : ''}`;
    const hasImage = !!mainImage?.url;
    const extraImages = useMemo(
        () =>
            [
                image1Image,
                image2Image,
                image3Image,
                image4Image,
                image5Image,
                image6Image,
                image7Image,
                image8Image,
                image9Image,
            ].filter((x: any) => !!x?.url) as any[],
        [
            image1Image,
            image2Image,
            image3Image,
            image4Image,
            image5Image,
            image6Image,
            image7Image,
            image8Image,
            image9Image,
        ],
    );

    return (
        <div
            className={clsx(
                'p-2 group shadow relative mx-auto flex flex-col hover:bg-go-choice-primary hover:cursor-pointer border-transparent overflow-hidden w-72',
                blockBorderClass,
                blockShapeClass,
            )}
            onClick={onClick as MouseEventHandler<HTMLDivElement>}
        >
            <div
                className={clsx(
                    'font-go-text absolute top-4 right-4 bg-opacity-95 bg-go-primary px-2 text-sm font-light text-white',
                    blockShapeClass,
                )}
            >
                {t('game_listing_rank_position_item', { rankString })}
            </div>
            {hasImage ? (
                <AnimatedImage
                    className={clsx('object-cover h-72 w-full mx-auto', blockShapeClass)}
                    mainImage={mainImage}
                    maxSize={300}
                    alt={mainImage?.alt || 'image item'}
                    extraImages={extraImages}
                />
            ) : (
                <div className={'h-72 bg-go-secondary'} />
            )}
            <div
                className={clsx(
                    'flex-1 group-hover:bg-go-choice-primary w-full gap-4 flex flex-col justify-center items-center my-2 px-2',
                    blockShapeClass,
                )}
            >
                {!!name && (
                    <div
                        className={clsx(
                            'text-center font-go-text flex-1 overflow-hidden text-lg font-semibold',
                            nameUpper && 'uppercase',
                        )}
                    >
                        {name}
                    </div>
                )}
                {!!description && (
                    <div className={'text-center font-go-text flex-1 overflow-hidden font-light text-sm'}>
                        {truncate(description as string, 160)}
                    </div>
                )}
                {!!sponsorings?.length && (
                    <div className={'text-center font-go-text font-semibold text-sm'}>
                        {t(
                            [`game_sponsoring_overline_${String(sponsorings.length)}`, `game_sponsoring_overline`],
                            sponsorings.reduce(
                                (acc, s, index) =>
                                    Object.assign(acc, { [`name${index > 0 ? String(index + 1) : ''}`]: s?.name }),
                                {} as any,
                            ),
                        )}
                    </div>
                )}
                <Button
                    label={t('more_details_button_label')}
                    className={'text-sm'}
                    target={onClick}
                    variant={'secondary'}
                />
            </div>
        </div>
    );
}

export interface MosaicBunchItemProps extends WithName, WithDescription, WithQuantity, WithOnClick {
    mainImage?: image;
    nameUpper?: boolean;
    rank?: number;
    image1Image?: { url: string; alt: string };
    image2Image?: { url: string; alt: string };
    image3Image?: { url: string; alt: string };
    image4Image?: { url: string; alt: string };
    image5Image?: { url: string; alt: string };
    image6Image?: { url: string; alt: string };
    image7Image?: { url: string; alt: string };
    image8Image?: { url: string; alt: string };
    image9Image?: { url: string; alt: string };
    sponsorings?: {
        websiteUrl?: string;
        description?: string;
        name?: string;
        logoImage?: { url: string; alt: string };
        mainImage?: { url: string; alt: string };
    }[];
}

export default MosaicBunchItem;
