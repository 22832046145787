export const defaultPlatformLocales = {
    local: 'default', // some cities, or province in 1 country
    national: 'default', // lots of cities or provinces in 1 country
    multinational: 'default', // several countries
    international: 'default', // lots of countries
    global: 'default', // many or all countries in the world
    default: 'default', // fallback
};

export const defaultPlatformDefaultLocale = 'en_US';
export const defaultPlatformDefaultScope = 'global';

export function detectUriLocale(doc: any) {
    doc = doc || {};

    let scope = doc.scope || 'default';

    if (doc.scopeLocale) return doc.scopeLocale;

    scope = 'default' === scope ? defaultPlatformDefaultScope : scope;

    let l = 'default';

    switch (scope) {
        case 'national':
            l = doc.mainLocale || doc.locale || defaultPlatformLocales['national'];
            break;
        case 'multinational':
            l = defaultPlatformLocales['multinational'];
            break;
        case 'international':
            l = defaultPlatformLocales['international'];
            break;
        case 'global':
            l = defaultPlatformLocales['global'];
            break;
        default:
        case 'local':
            l = doc.mainLocale || doc.locale || defaultPlatformLocales['local'];
            break;
    }

    return 'default' === l ? defaultPlatformDefaultLocale : l;
}

// noinspection JSUnusedGlobalSymbols
export default detectUriLocale;
