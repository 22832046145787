import { useMemo } from 'react';
import clsx from 'clsx';
import useTypeTranslation from '../hooks/useTypeTranslation';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import SectionTitle, { SectionTitleProps } from '../atoms/SectionTitle';

export function Title({ className, title, ...props }: TitleProps) {
    const { t: t1 } = useTrans();
    const { t: t2 } = useTypeTranslation(props.typeType || props.type || '', props.doc);
    const tt = useMemo(
        () =>
            !!title && 'string' === typeof title
                ? ((props.typeType || props.type) && props.doc ? t2 : t1)(title)
                : title,
        [title, props.type, props.typeType, props.doc, t1, t2],
    );
    return (
        <div className={clsx('w-full', className)}>
            <SectionTitle title={tt} className={'tracking-tight leading-relaxed mb-6 sm:mb-12'} {...props} />
        </div>
    );
}

export interface TitleProps extends SectionTitleProps {
    type?: string;
    typeType?: string;
    doc?: unknown;
}

// noinspection JSUnusedGlobalSymbols
export default Title;
