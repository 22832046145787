import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(
        () =>
            import(
                './servicePublicCustomizationScreenshots/StandardServicePublicCustomizationScreenshotsPartnerModule'
            ),
    ),
};

export function ServicePublicCustomizationScreenshotsPartnerModule(
    props: ServicePublicCustomizationScreenshotsPartnerModuleProps,
) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ServicePublicCustomizationScreenshotsPartnerModuleProps = PartnerModuleProps;

export default ServicePublicCustomizationScreenshotsPartnerModule;
