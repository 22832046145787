import { useCallback, useMemo, useState } from 'react';
import Modal2 from './Modal2';
import QRCode from '../atoms/QRCode';
import { WithLazy, WithTarget, WithText } from '../withs';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import ChoiceMenu from './ChoiceMenu';
import buildQrCodeUrl from '../utils/buildQrCodeUrl';
import { flag } from '@gotombola/types';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import String from '../atoms/String';

export function QRCodeDownload({
    qrCodeClassName,
    target,
    lazy,
    withButton = false,
    withHeader = false,
    filename,
    format,
    style,
    customLogo,
    defaultDownloadFormat = 'png',
}: QRCodeDownloadProps) {
    const { t } = useTrans();
    const {
        blockBorderClass,
        blockBorderColorClass,
        blockFooterBorderClass,
        blockFooterShapeClass,
        blockHeaderBorderClass,
        blockHeaderShapeClass,
        blockShapeClass,
    } = useStyling();
    const [isModalOpened, setIsModalOpened] = useState(false);
    const handleModalOpen = useCallback(() => {
        setIsModalOpened(true);
    }, [setIsModalOpened]);
    const handleModalClose = useCallback(() => {
        setIsModalOpened(false);
    }, [setIsModalOpened]);
    const items = useMemo(
        () => [
            {
                label: t('game_qrcode_choice_small_size'),
                target: buildQrCodeUrl(target, format || defaultDownloadFormat, {
                    style,
                    customLogo,
                    download: true,
                    size: 256,
                    filename: `${filename ? filename : 'qrcode'}-256px`,
                }),
            },
            {
                label: t('game_qrcode_choice_large_size'),
                target: buildQrCodeUrl(target, format || defaultDownloadFormat, {
                    style,
                    customLogo,
                    download: true,
                    size: 512,
                    filename: `${filename ? filename : 'qrcode'}-512px`,
                }),
            },
            {
                label: t('game_qrcode_choice_no_logo'),
                target: buildQrCodeUrl(target, format || defaultDownloadFormat, {
                    style: undefined,
                    download: true,
                    size: 512,
                    withoutLogo: true,
                    filename: `${filename ? filename : 'qrcode'}-512px`,
                }),
            },
            {
                label: t('game_qrcode_choice_svg_format'),
                target: buildQrCodeUrl(target, 'svg', {
                    style: undefined,
                    download: true,
                    size: 512,
                    withoutLogo: true,
                    filename: `${filename ? filename : 'qrcode'}-512px`,
                }),
            },
        ],
        [buildQrCodeUrl, target, style, customLogo, t],
    );

    return (
        <>
            {withHeader && (
                <div
                    className={clsx(
                        'p-0 m-0 w-full text-center',
                        blockHeaderBorderClass,
                        blockHeaderShapeClass,
                        blockBorderColorClass,
                    )}
                >
                    <String className={'font-go-text p-1 text-go-title text-[0.675rem]'}>
                        {t('scan_from_your_mobile')}
                    </String>
                </div>
            )}
            <div
                className={clsx(
                    'relative p-0 m-0 w-full',
                    blockBorderClass,
                    blockFooterBorderClass,
                    blockBorderColorClass,
                    withHeader && blockFooterShapeClass,
                    !withHeader && blockShapeClass,
                )}
            >
                <div
                    onClick={handleModalOpen}
                    className={
                        'transition-all ease-in-out opacity-0 hover:opacity-100 absolute inset-0 z-0 hover:z-10 p-4'
                    }
                >
                    <div
                        className={clsx(
                            'font-go-text cursor-pointer p-2 w-full h-full bg-go-secondary justify-center text-white flex text-center items-center font-medium text-sm',
                            blockShapeClass,
                        )}
                    >
                        {t('game_qrcode_download_text')}
                    </div>
                </div>
                <QRCode
                    className={qrCodeClassName}
                    target={target}
                    lazy={lazy}
                    filename={filename}
                    format={format}
                    style={style}
                    customLogo={customLogo}
                />
                <Modal2
                    opened={isModalOpened}
                    onClose={handleModalClose}
                    size={'sm'}
                    title={t('game_qrcode_modal_title')}
                >
                    <div className={'w-full m-6'}>
                        <ChoiceMenu items={items} />
                    </div>
                </Modal2>
            </div>
            {!!withButton && (
                <div className={'flex justify-center'}>
                    <div
                        onClick={handleModalOpen}
                        className={clsx(
                            'font-go-text sm:text-lg flex sm:w-full tracking-tight leading-relaxed cursor-pointer bg-go-secondary hover:bg-go-primary text-white border px-4 py-2 hover:shadow-sm font-medium text-center justify-center',
                            blockShapeClass,
                        )}
                    >
                        {t('communication_page_qrcode_btn_label')}
                    </div>
                </div>
            )}
        </>
    );
}

export interface QRCodeDownloadProps extends Required<WithTarget>, WithText, WithLazy {
    qrCodeClassName?: string;
    modalTitle?: string;
    withButton?: flag;
    withHeader?: flag;
    filename?: string;
    format?: string;
    style?: string;
    customLogo?: string;
    defaultDownloadFormat?: 'png' | 'jpg' | 'webp' | 'svg' | undefined;
}

export default QRCodeDownload;
