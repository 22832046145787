import { useMemo } from 'react';
import TypeListing, { TypeListingProps } from '../../../organisms/type/TypeListing';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { GameModuleProps } from '@gotombola/react-types/lib/types';
import { WithTitle } from '@gotombola/react-types/lib/withs';

const listProps = {
    defaultMsVariant: 'bordered',
};
export function ListSponsorsGameModule({ title: forcedTitle, ...rest }: ListSponsorsGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', props.game);

    const items = useMemo(
        () => props.game?.sponsorings?.items?.map((x) => ({ ...x, overline: x.description })),
        [props.game?.sponsorings?.items],
    );

    if (!items?.length) return null;

    const title = forcedTitle || t(`game_sponsorings_label`);

    return (
        <ModuleContainer {...zprops}>
            <TypeListing
                title={title}
                {...props}
                doc={props.game}
                type={'game'}
                items={items}
                itemType={'sponsor'}
                withRank={false}
                listProps={listProps}
            />
        </ModuleContainer>
    );
}

export interface ListSponsorsGameModuleProps extends Omit<TypeListingProps, 'type'>, GameModuleProps, WithTitle {}

export default ListSponsorsGameModule;
