import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';
import useAdZonesSettingsContext from './useAdZonesSettingsContext';

const defaultParams = {};

// noinspection JSUnusedLocalSymbols
export function useAdZoneDefinitionFromContext(id: string, params: any = defaultParams) {
    const { game } = useGameContext();
    const allSettings = useAdZonesSettingsContext();

    return {
        visible: game?.['adStatus'] === 'ACTIVATED',
        ...(allSettings?.[id || ''] || {}),
        ...params,
    };
}

// noinspection JSUnusedGlobalSymbols
export default useAdZoneDefinitionFromContext;
