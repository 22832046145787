import { useCallback, useMemo } from 'react';
import useDynamicPageContext from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import { dynamic_page_body_item_config } from '../types';
import { WithItems } from '../withs';
import DynamicPageBodyItem from './DynamicPageBodyItem';

const defaultItems = [];

export function DynamicPageBodyItems({ items = defaultItems, zone = undefined }: DynamicPageBodyItemsProps) {
    const { step } = useDynamicPageContext();
    const itemFilter = useCallback(
        (item: any) => {
            const itemCfg = (item || {}).config || {};
            if ((!!zone || !!itemCfg.zone) && zone !== itemCfg.zone) return false;
            switch (itemCfg.state) {
                case 'always':
                    return true;
                case 'initial':
                    return step === 'initial';
                case 'initial+failure':
                    return step === 'initial' || step === 'failure';
                case 'submitting':
                    return step === 'submitting';
                case 'submitted':
                    return step === 'success' || step === 'error' || step === 'failure';
                case 'success':
                    return step === 'success';
                case 'failure':
                    return step === 'failure';
                case 'error':
                    return step === 'error';
                case 'none':
                    return false;
                case undefined:
                    return true;
                case null:
                    return true;
                default:
                    return true;
            }
        },
        [step],
    );
    const selectedItems = useMemo(
        () => items.map((it: any, index: number) => ({ ...it, id: it.id || `item-${index}` })).filter(itemFilter),
        [items, step, itemFilter],
    );

    return (
        <div>
            {(selectedItems || []).map((item, index) => (
                <DynamicPageBodyItem key={item.id || index} config={item} index={index} />
            ))}
        </div>
    );
}

export interface DynamicPageBodyItemsProps extends WithItems<dynamic_page_body_item_config> {
    zone?: string;
}

export default DynamicPageBodyItems;
