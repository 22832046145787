import loadable from '@loadable/component';
import { ModuleProps } from '../types';
import ModuleMode from '../organisms/ModuleMode';

const modes = {
    // carousel
    gallery: loadable(() => import('./items/GalleryItemsModule')),
    // push_small
    // slider
};

export function ItemsModule(props: ItemsModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'gallery'} />;
}

export type ItemsModuleProps = ModuleProps;

export default ItemsModule;
