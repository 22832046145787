import {
    WithAutoFocus,
    WithClassName,
    WithDisabled,
    WithErrors,
    WithLabel,
    WithName,
    WithOnChange,
    WithPlaceholder,
    WithRequired,
    WithType,
    WithValue,
} from '@gotombola/react-types';
import clsx from 'clsx';
import { forwardRef } from 'react';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Text from '../Text';

export const NumericInput = forwardRef(
    (
        { className, onChange, placeholder, disabled, label, errors, register, control, ...props }: NumericInputProps,
        ref,
    ) => {
        const { t } = useTrans();
        const { blockShapeClass } = useStyling();
        const error = errors?.[props.name || ''];

        return (
            <div className={className}>
                {!!label && (
                    <label htmlFor={props.name}>
                        {'string' === typeof label ? <Text inline>{t(label)}</Text> : label}
                        {!!props.required && (
                            <Text inline className={'text-red-500'}>
                                *
                            </Text>
                        )}
                    </label>
                )}
                <input
                    ref={ref as any}
                    onChange={onChange as any}
                    placeholder={t(placeholder as string)}
                    className={clsx(
                        'font-go-text text-go-text border p-3 w-full',
                        props.required ? 'border-gray-500' : 'border-gray-300',
                        blockShapeClass,
                        className,
                    )}
                    disabled={disabled}
                    type={'number'}
                    {...register(props.name, { valueAsNumber: true })}
                    {...props}
                />
                {!!error && <Text className={'text-red-500 text-left pl-2'}>{error?.message}</Text>}
            </div>
        );
    },
);

export interface NumericInputProps
    extends WithClassName,
        WithValue,
        WithLabel,
        WithAutoFocus,
        WithType,
        WithDisabled,
        WithName,
        WithRequired,
        WithErrors,
        Required<WithOnChange>,
        Required<WithPlaceholder> {
    register: Function;
    control: Function;
}
// noinspection JSUnusedGlobalSymbols
export default NumericInput;
