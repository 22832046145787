import { useMemo } from 'react';

export function useTypedFieldProps(type: string, rawField: any) {
    return useMemo(() => {
        switch (type) {
            case 'price':
                return {
                    ...rawField,
                    value: rawField.value / 100,
                    onChange: (e: any) => {
                        rawField.onChange({ ...e, target: { ...e.target, value: Math.round(e.target.value * 100) } });
                    },
                };
            default:
                return rawField;
        }
    }, [rawField]);
}

// noinspection JSUnusedGlobalSymbols
export default useTypedFieldProps;
