import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function SellergroupTemplate({ sellergroup, page = 'sellergroup_public', ...props }: SellergroupTemplateProps) {
    return <TypeTemplate type={'sellergroup'} page={page.replace(/^sellergroup_/, '')} doc={sellergroup} {...props} />;
}

export interface SellergroupTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    sellergroup?: any;
    game?: any;
}

export default SellergroupTemplate;
