import TenantFooter from '../../../molecules/footers/TenantFooter';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardFooterTenantModule({ tenant, ...rest }: StandardFooterTenantModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <TenantFooter {...props} tenant={tenant} />
        </ModuleContainer>
    );
}

export interface StandardFooterTenantModuleProps {
    tenant: any;
}

export default StandardFooterTenantModule;
