import { WithClassName } from '../withs';
import CirclesSpinner from './spinners/CirclesSpinner';
import DefaultSpinner from './spinners/DefaultSpinner';

const spinners = {
    default: DefaultSpinner,
    circles: CirclesSpinner,
};

export function Spinner({ className, type = 'default' }: SpinnerProps) {
    const Component = spinners[type] || spinners['default'];

    return <Component className={className} />;
}

export interface SpinnerProps extends WithClassName {
    type?: 'default' | 'circles';
}

export default Spinner;
