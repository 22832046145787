import { next_fn, workflow, workflow_settings, workflow_step, workflow_step_cases, workflows } from '../types';

export function getWorkflowStepRecursive(
    workflow: workflow,
    name: string,
    settings: workflow_settings,
    workflows: workflows,
    x?: { nextFn?: next_fn; nextSsr?: boolean },
): workflow_step | workflow_step_cases | undefined {
    // external workflow step
    if (0 < (name || '').indexOf(':')) {
        const [workflowName, stepName] = name.split(':');
        return getWorkflowStepRecursive(
            { ...workflows[getWorkflowId(workflows, workflowName, settings)], id: workflowName },
            stepName,
            settings,
            workflows,
            x,
        );
    }
    // external url
    if (':' === (name || '').slice(0, 1)) {
        return { id: name, name, url: name.slice(1), ...x };
    }

    const step = workflow.steps[name];

    if (!step) throw new Error(`Unknown step '${name}' in workflow '${workflow.id}`);

    const redir = step.redirect
        ? 'function' === typeof step.redirect
            ? step.redirect(settings)
            : step.redirect
        : undefined;

    if (redir) return getWorkflowStepRecursive(workflow, redir, settings, workflows, x);

    if (step.if) {
        if (!step.if(settings)) {
            const nextStepId = step.next || undefined;
            if (!nextStepId) return undefined;
            if ('string' === typeof nextStepId)
                return getWorkflowStepRecursive(workflow, nextStepId, settings, workflows, x);
            return {
                cases: Object.entries(nextStepId).reduce(
                    (acc, [k, v]) =>
                        Object.assign(acc, { [k]: getWorkflowStepRecursive(workflow, v, settings, workflows, x) }),
                    {},
                ),
            };
        }
    }

    return { id: name, name, ...step, ...x };
}

export function getWorkflowNextStepFromStep(
    workflow: workflow,
    step: Omit<workflow_step, 'id' | 'name'>,
    settings: workflow_settings,
    workflows: workflows,
): workflow_step | workflow_step_cases | undefined {
    const nextStepId = step.next || undefined;
    const nextFn = step.nextFn || undefined;
    const nextSsr = step.nextSsr || false;
    if (!nextStepId) return undefined;
    if ('string' === typeof nextStepId)
        return getWorkflowStepRecursive(workflow, nextStepId, settings, workflows, { nextFn, nextSsr });
    return {
        cases: Object.entries(nextStepId).reduce(
            (acc, [k, v]) =>
                Object.assign(acc, {
                    [k]: getWorkflowStepRecursive(workflow, v, settings, workflows, { nextFn, nextSsr }),
                }),
            {},
        ),
    };
}

export function getWorkflowId(workflows: workflows, workflowType: string, settings: workflow_settings) {
    const keys = [
        `${workflowType}__${(settings.game || {}).saleWorkflow}`,
        `${workflowType}__${(settings.game || {}).code}`,
        `${workflowType}__${(settings.organization || {}).saleWorkflow}`,
        `${workflowType}__${(settings.organization || {}).code}`,
        `${workflowType}__${(settings.partner || {}).saleWorkflow}`,
        `${workflowType}__${(settings.partner || {}).code}`,
        `${workflowType}__${(settings.campaign || {}).saleWorkflow}`,
        `${workflowType}__${(settings.campaign || {}).code}`,
        `${workflowType}__${(settings.tenant || {}).saleWorkflow}`,
        `${workflowType}__${(settings.tenant || {}).code}`,
        `${workflowType}__default`,
    ];
    const workflowId: string | undefined = keys.find((k) => workflows[k || '']);

    if (!workflowId || !workflows[workflowId])
        throw new Error(`Unknown workflow '${workflowType}' (available: ${Object.keys(workflows).join(', ')})`);

    return workflowId;
}
export function getWorkflowNextStep(
    workflows: workflows,
    workflowType: string,
    currentStepId: string,
    settings: workflow_settings,
): workflow_step | workflow_step_cases | undefined {
    const workflowId = getWorkflowId(workflows, workflowType, settings);

    const workflow = { id: workflowId, ...workflows[workflowId] };

    const currentStep = workflow.steps[currentStepId];

    if (!currentStep) throw new Error(`Unknown current step '${currentStepId}' in workflow '${workflowId}'`);

    return getWorkflowNextStepFromStep(workflow, currentStep, settings, workflows);
}
