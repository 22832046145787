import { default as basic } from './BasicModuleSection';
import { default as bordered } from './BorderedModuleSection';
import { default as bannered } from './BanneredModuleSection';
import { default as basic_bordered } from './BasicBorderedModuleSection';

export default {
    basic,
    bordered,
    basic_bordered,
    bannered,
    default: bordered,
};
