import { createContext } from 'react';
import { workflows_context_value } from '../types';
import createDefaultWorkflowsContextValue from '../utils/createDefaultWorkflowsContextValue';

export const WorkflowsContext = createContext<workflows_context_value>(createDefaultWorkflowsContextValue());
// noinspection JSUnusedGlobalSymbols
export const WorkflowsContextProvider = WorkflowsContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const WorkflowsContextConsumer = WorkflowsContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default WorkflowsContext;
