import clsx from 'clsx';
import Text from './Text';
import useStyledText from '../hooks/useStyledText';
import { flag } from '@gotombola/types/lib/types/base';
import { WithClassName, WithText } from '@gotombola/react-types/lib/withs';

const sizes = {
    smalltext: 'text-sm',
    text: 'text-md',
};

export function SectionText({
    className,
    bold = false,
    center = false,
    text: rawText,
    textStyling,
    basic = false,
    size = 'text',
}: SectionTextProps) {
    const [text, textClassName] = useStyledText(rawText, textStyling, undefined, 'sectionText');
    if (!text) return null;
    return (
        <Text
            light
            bold={bold}
            center={center}
            value={text}
            className={clsx(sizes[size], className, 'px-4 text-center sm:text-left', textClassName)}
            basic={basic}
        />
    );
}

export interface SectionTextProps extends WithClassName, WithText {
    textStyling?: string;
    basic?: flag;
    bold?: flag;
    center?: flag;
    size?: string;
}

export default SectionText;
