import loadable from '@loadable/component';
import { TenantModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./footer/StandardFooterTenantModule')),
};

export function FooterTenantModule(props: FooterTenantModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type FooterTenantModuleProps = TenantModuleProps;

export default FooterTenantModule;
