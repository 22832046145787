import { useMemo } from 'react';
import { WithType } from '../withs';
import AlertBannerModule from '../modules/AlertBannerModule';

export function DefaultFallbackComponent({ error, subtype, type }: DefaultFallbackComponentProps) {
    const config = useMemo(
        () => ({
            mode: 'default',
            color: 'danger',
            shape: 'square',
            text: `#    ${subtype} (${type})\n\n${error?.message}`,
        }),
        [error?.message, type, subtype],
    );

    return <AlertBannerModule config={config} />;
}

export interface DefaultFallbackComponentProps extends WithType {
    error: Error;
    subtype?: string;
}

// noinspection JSUnusedGlobalSymbols
export default DefaultFallbackComponent;
