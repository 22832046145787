import { WithCenter, WithItems } from '../withs';
import MenuChoice from '../atoms/MenuChoice';
import DefaultChoiceMenuItem from '../atoms/DefaultChoiceMenuItem';
import { flag } from '../types';
import clsx from 'clsx';
import { useCallback } from 'react';

export function ChoiceMenu({ inline = false, center = true, items = [], onChange, value }: ChoiceMenuProps) {
    const checks = value || {};
    const onClickFactory = useCallback(
        (code: string) => {
            return (e: any) => {
                e.stopPropagation();
                e.preventDefault();
                onChange && onChange({ ...checks, [code]: !checks[code] });
            };
        },
        [onChange, checks],
    );

    return (
        <div className={'w-full flex justify-center'}>
            <div
                className={clsx(
                    'w-full justify-center',
                    !inline ? 'w-full space-y-4' : 'grid grid-cols-2 sm:grid-cols-4 gap-4',
                )}
            >
                {(items || []).map(({ props: componentProps, ...item }, index) => {
                    const Component = item.component || DefaultChoiceMenuItem;
                    const x = (
                        <MenuChoice
                            key={inline ? undefined : item.id || index}
                            helper={item.helper}
                            checked={checks[item.code || '']}
                            onClick={item.checkbox ? onClickFactory(item.code || '') : undefined}
                            {...(!item.checkbox
                                ? {
                                      ...('function' === typeof item.target
                                          ? { onClick: item.target as any }
                                          : { href: item.target || '' }),
                                  }
                                : {})}
                            blank={item?.blank}
                        >
                            <Component
                                {...item}
                                {...(componentProps || {})}
                                center={center}
                                checked={checks[item.code || '']}
                            />
                        </MenuChoice>
                    );
                    return inline ? (
                        <div key={item.id || index} className={'inline-block'}>
                            {x}
                        </div>
                    ) : (
                        x
                    );
                })}
            </div>
        </div>
    );
}

export interface ChoiceMenuProps
    extends WithCenter,
        WithItems<{
            id?: string;
            code?: string;
            target?: string | Function;
            label?: any;
            value?: any;
            overline?: any;
            helper?: any;
            component?: any;
            props?: any;
            checkbox?: boolean;
            checked?: boolean;
            blank?: boolean;
        }> {
    inline?: flag;
    value?: any;
    onChange?: Function;
}

export default ChoiceMenu;
