export function buildTypePageDefaultDefaults(type: string, doc: any) {
    const x = {
        title: `${doc.name} | ${doc.organizationName}`,
        favicon: (doc.faviconImage || {}).url || (doc.organizationFaviconImage || {}).url || undefined,
        appleTouchIcon: (doc.appleTouchImage || {}).url || (doc.organizationAppleTouchImage || {}).url || undefined,
    };
    x.favicon && (x.favicon = `${x.favicon}?size=64&size_enlarge=0&corner=8`);
    x.appleTouchIcon && (x.appleTouchIcon = `${x.appleTouchIcon}?size=192&size_enlarge=0`);

    return x;
}

export default buildTypePageDefaultDefaults;
