import Items, { ItemsProps } from '../atoms/Items';
import ModuleSection from '../organisms/ModuleSection';
import TypeImageModal, { TypeImageModalProps } from '../organisms/type/TypeImageModal';
import { WithSubtitle, WithTitle, WithChildren } from '@gotombola/react-types/lib/withs';
import useTypeModuleImages from '../hooks/useTypeModuleImages';

const defaultColsClassNames = {
    'col-0': 'flex flex-wrap justify-center',
    'col-1': 'grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1',
    'col-2': 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2',
    'col-3': 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3',
    'col-4': 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4',
    'col-5': 'grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5',
    'col-6': 'grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-6',
    'col-7': 'grid grid-cols-1 sm:grid-cols-5 lg:grid-cols-7',
    'col-8': 'grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-8',
    'col-9': 'grid grid-cols-1 sm:grid-cols-5 lg:grid-cols-9',
    'col-10': 'grid grid-cols-1 sm:grid-cols-6 lg:grid-cols-10',
};

export function DefaultModuleSectionItemList({
    cols,
    defaultCols = 1,
    colsClassNames = defaultColsClassNames,
    itemsProps,
    modalProps,
    subtitle,
    title,
    xDivider = false,
    yDivider = false,
    nobg,
    hasImages = false,
    msVariant,
    defaultMsVariant,
    space = 'lg',
    children,
    ...props
}: DefaultModuleSectionItemListProps) {
    const moduleImages = useTypeModuleImages(props.type, props.doc);

    return (
        <ModuleSection
            subtitle={subtitle}
            title={title}
            colsClassNames={colsClassNames}
            cols={cols}
            defaultCols={defaultCols}
            xDivider={xDivider}
            yDivider={yDivider}
            nobg={nobg}
            variant={msVariant}
            defaultVariant={defaultMsVariant}
            hasImages={hasImages}
            space={space}
            {...moduleImages}
            {...props}
        >
            <Items {...itemsProps} />
            <TypeImageModal {...modalProps} />
            {children}
        </ModuleSection>
    );
}

export interface DefaultModuleSectionItemListProps extends WithChildren, WithTitle, WithSubtitle {
    cols?: number;
    colsClassNames?: Record<string, string>;
    defaultCols?: number;
    itemsProps: ItemsProps;
    modalProps: TypeImageModalProps;
    xDivider?: boolean;
    yDivider?: boolean;
    nobg?: boolean;
    hasImages?: boolean;
    msVariant?: string;
    defaultMsVariant?: string;
    space?: string;
    type: string;
    doc?: any;
}

export default DefaultModuleSectionItemList;
