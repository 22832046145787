import GameOrganizationPresentation from '../../../organisms/GameOrganizationPresentation';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardOrganizationPresentationGameModule({
    game,
    ...rest
}: StandardOrganizationPresentationGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    if (!game?.organizationPresentation) return null;

    return (
        <ModuleContainer {...zprops}>
            <GameOrganizationPresentation {...props} game={game} />
        </ModuleContainer>
    );
}

export interface StandardOrganizationPresentationGameModuleProps {
    game: any;
}

export default StandardOrganizationPresentationGameModule;
