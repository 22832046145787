import ModuleContainer from '../../organisms/ModuleContainer';
import CustomModule from '../../modules/CustomModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function CustomCommonModule({ config, ...rest }: CustomCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <CustomModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface CustomCommonModuleProps {
    config: any;
}

export default CustomCommonModule;
