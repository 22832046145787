export function buildDefaultConfigPropertiesFromDoc(doc: any = {}) {
    const p: any = {};

    doc?.seoNoindex && Object.assign(p, { noindex: doc.seoNoindex });
    doc?.seoTitle && Object.assign(p, { title: doc.seoTitle });
    doc?.seoDescription && Object.assign(p, { description: doc.seoDescription });
    doc?.seoKeywords && Object.assign(p, { keywords: doc.seoKeywords });
    doc?.seoAuthor && Object.assign(p, { author: doc.seoAuthor });
    doc?.seoAbstract && Object.assign(p, { abstract: doc.seoAbstract });
    doc?.seoLang && Object.assign(p, { lang: doc.seoLang });
    (doc?.metas?.length || doc?.publicPageMetas?.length) &&
        Object.assign(p, { metas: doc.metas || doc.publicPageMetas });

    return p;
}

export default buildDefaultConfigPropertiesFromDoc;
