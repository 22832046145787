import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./stats/StandardStatsGameModule')),
};

export function StatsGameModule(props: StatsGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export interface StatsGameModuleProps extends GameModuleProps {
    hideTicketPrice?: boolean;
}

export default StatsGameModule;
