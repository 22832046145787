import { MouseEventHandler, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { WithChildren, WithClassName, WithOnClose, WithSubtitle, WithTitle } from '../withs';
import StaticIcon from '../atoms/StaticIcon';

export function Modal({ children, className, onClose, subtitle, title }: ModalProps) {
    const [isModalHovered, setIsModalHovered] = useState(false);
    const handleModalHover = useCallback(() => setIsModalHovered(true), [setIsModalHovered]);
    const handleModalLeave = useCallback(() => setIsModalHovered(false), [setIsModalHovered]);
    const handleClick = useCallback(() => !isModalHovered && onClose && onClose(), [isModalHovered, onClose]);
    const handleEscapeKey = useCallback(
        (event: any) => {
            event.keyCode === 27 && onClose && onClose();
        },
        [onClose],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeKey, false);
        return () => document.removeEventListener('keyword', handleEscapeKey);
    }, [handleEscapeKey, document]);

    return (
        <div
            className={clsx(
                className,
                'z-50 h-screen w-screen inset-0 fixed bg-black bg-opacity-70 flex justify-center items-center',
            )}
            onClick={handleClick}
        >
            <div
                className={
                    'w-screen h-screen sm:w-2/3 sm:h-3/4 lg:w-1/2 bg-white bg-opacity-100 sm:rounded flex flex-col'
                }
                onMouseEnter={handleModalHover}
                onMouseLeave={handleModalLeave}
            >
                <div className={'w-full border-b border-gray-200 py-4 px-4 flex justify-between items-center'}>
                    <div className={'flex flex-col'}>
                        <div className={'font-go-text text-4xl font-bold text-gray-800'}>{title}</div>
                        <div className={'font-go-text text-gray-500'}>{subtitle}</div>
                    </div>
                    <button
                        type={'button'}
                        onClick={onClose as MouseEventHandler}
                        className={'hover:text-blue-500 cursor-pointer'}
                    >
                        <StaticIcon type={'close'} />
                    </button>
                </div>
                <div
                    className={
                        'font-go-text w-full h-full rounded-b-md p-12 flex items-center justify-center bg-gray-100'
                    }
                >
                    {children || ''}
                </div>
            </div>
        </div>
    );
}

export interface ModalProps extends WithChildren, WithClassName, WithOnClose, WithSubtitle, WithTitle {}

export default Modal;
