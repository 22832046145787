import Banner from '../../../organisms/Banner';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { TypeProps } from '../../../types';
import { useMemo } from 'react';
import useTypeUrl from '../../../hooks/useTypeUrl';
import useQueryString from '../../../hooks/useQueryString';

const targets = {
    game: (doc: any, buyUrl: string) =>
        doc?.status === 'RUNNING'
            ? doc?.customBannerTargetUrl || buyUrl || doc?.customSalePageUrl || doc?.salePageUrl
            : undefined,
};

export function StandardBannerTypeModule({ typeType, doc, ...rest }: StandardBannerTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    const qs = useQueryString().toString();
    const buyUrl = useTypeUrl('game', doc, 'buy', qs);
    const target = useMemo(() => targets[typeType || '']?.(doc, buyUrl), [typeType, doc, buyUrl]);
    const images = useMemo(
        () =>
            [
                [doc?.bannerImage, doc?.tabletBannerImage, doc?.mobileBannerImage],
                [doc?.organizationBannerImage, doc?.organizationTabletBannerImage, doc?.organizationMobileBannerImage],
                [doc?.partnerBannerImage, doc?.partnerTabletBannerImage, doc?.partnerMobileBannerImage],
                [doc?.campaignBannerImage, doc?.campaignTabletBannerImage, doc?.campaignMobileBannerImage],
                [doc?.tenantBannerImage, doc?.tenantTabletBannerImage, doc?.tenantMobileBannerImage],
            ].find((x) => !!x?.[0]?.url),
        [doc],
    );

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <Banner
                image={images?.[0]}
                tabletImage={images?.[1]}
                mobileImage={images?.[2]}
                onClick={target}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface StandardBannerTypeModuleProps extends TypeProps {
    typeType: string;
}

export default StandardBannerTypeModule;
