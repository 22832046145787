import loadable from '@loadable/component';
import { ModuleProps } from '../types';
import ModuleMode from '../organisms/ModuleMode';

const modes = {
    alert: loadable(() => import('./alert-banners/AlertAlertBannerModule')),
};

export function AlertBannerModule(props: AlertBannerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'alert'} />;
}

export type AlertBannerModuleProps = ModuleProps;

export default AlertBannerModule;
