import PartnerFooter from '../../../molecules/footers/PartnerFooter';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardFooterPartnerModule({ partner, ...rest }: StandardFooterPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <PartnerFooter partner={partner} {...props} />
        </ModuleContainer>
    );
}

export interface StandardFooterPartnerModuleProps {
    partner: any;
}

export default StandardFooterPartnerModule;
