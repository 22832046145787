import { WithText, WithValue } from '../withs';
import Row from './Row';
import clsx from 'clsx';
import Text from './Text';

export function Figure({ value, text }: FigureProps) {
    return (
        <Row>
            {!!value && (
                <Text bold className={clsx('text-go-secondary text-lg', !!text && 'mr-2')}>
                    {value}
                </Text>
            )}
            {!!text && (
                <Text light className={'text-lg'}>
                    {text}
                </Text>
            )}
        </Row>
    );
}

export interface FigureProps extends WithValue, WithText {}

// noinspection JSUnusedGlobalSymbols
export default Figure;
