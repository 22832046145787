import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypeFunnelStep from '../../../organisms/TypeFunnelStep';
import useTypeFormStep from '../../../hooks/useTypeFormStep';
import { useMemo } from 'react';
import TicketPriceAmountChoiceField from '../../../atoms/fields/TicketPriceAmountChoiceField';

const prices = [1, 1.5, 2, 2.5, 3, 4, 5, 10, 15, 20, 50, 100, '-'];
const defaultCurrency = 'EUR';

export function TicketPriceRegistrationFormPartnerModule({
    partner,
    registration,
    ...rest
}: TicketPriceRegistrationFormPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { stepProps, inputProps, register } = useTypeFormStep('partner', partner, 'registration', 'ticketPrice', {
        registration,
    });
    const items = useMemo(
        () =>
            prices.map((item: any) => ({
                id: item,
                price: item,
            })),
        [prices],
    );

    const currency = useMemo(() => defaultCurrency, [defaultCurrency]);

    return (
        <ModuleContainer {...zprops}>
            <TypeFunnelStep {...stepProps} {...props}>
                <TicketPriceAmountChoiceField
                    currency={currency}
                    items={items}
                    {...register('ticketPriceAmount', { required: true })}
                    required
                    autoFocus
                    {...inputProps}
                />
            </TypeFunnelStep>
        </ModuleContainer>
    );
}

export interface TicketPriceRegistrationFormPartnerModuleProps {
    partner: any;
    registration: any;
}

// noinspection JSUnusedGlobalSymbols
export default TicketPriceRegistrationFormPartnerModule;
