import { useMemo } from 'react';
import loadable from '@loadable/component';
import useCustomModuleModesContext from '@gotombola/react-contexts/lib/hooks/useCustomModuleModesContext';

const baseModes = {
    unknown: loadable(() => import('../modules/customs/UnknownCustomModule')),
};
export function useCustomModuleModes() {
    const { modes: extraModes } = useCustomModuleModesContext();
    return useMemo(() => ({ ...baseModes, ...extraModes }), [extraModes]);
}

// noinspection JSUnusedGlobalSymbols
export default useCustomModuleModes;
