import { useEffect, useState } from 'react';
import { pixel_metadata } from '../types';
import { buildMetadata } from '../utils';

export function Pixel({ disabled = false, app, event, env = 'prod', children, ...rest }: PixelProps) {
    const [metadata, setMetadata] = useState<undefined | pixel_metadata>(undefined);
    useEffect(() => {
        if (disabled) return;
        setMetadata(buildMetadata({ app, event, rest, env }));
    }, []);
    return !!disabled || !metadata ? null : <img src={metadata.pixelUrl} alt={'m'} />;
}

export interface PixelProps {
    disabled?: boolean;
    event: string;
    app: string;
    env: string;
    [key: string]: any;
    children?: any;
}

export default Pixel;
