import { TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import TextBlock from '../../blocks/TextBlock';

export function TypeDetailedInfos({ className, doc, type }: TypeDetailedInfosProps) {
    const { t } = useTypeTranslation(type, doc);

    if (!doc?.infos && !doc?.warning) return null;

    return (
        <>
            {!!doc.infos && (
                <TextBlock
                    className={className}
                    title={t(`${type}_detailedinfos_infos_label`)}
                    text={doc.infos}
                    inverted
                />
            )}
            {!!doc.warning && (
                <TextBlock
                    className={className}
                    title={t(`${type}_detailedinfos_warning_label`)}
                    text={doc.warning}
                    inverted
                />
            )}
        </>
    );
}

export interface TypeDetailedInfosProps extends TypeProps, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default TypeDetailedInfos;
