export function buildGameNextStepUrl(
    game: any,
    nextStep: any,
    query: URLSearchParams,
    caseName = 'default',
    uriPrefix?: string,
    urlBuilder?: Function,
) {
    query.sort && query.sort();
    const qs = query.toString();
    if (!nextStep) return undefined;
    const cases = nextStep.cases || { default: nextStep };
    const currentCase = cases[caseName] || cases['default'] || cases[Object.keys(cases)[0]];
    if (!currentCase) return undefined;
    if (urlBuilder && '/' !== (currentCase.url || '').slice(0, 1)) return urlBuilder(currentCase.url, qs);
    uriPrefix = uriPrefix
        ? '/' === uriPrefix.slice(-1) && '/' === (currentCase.url || '').slice(0, 1)
            ? uriPrefix.slice(0, -1)
            : uriPrefix
        : undefined;
    return `${uriPrefix || `/g/${game.code}`}${currentCase.url}${qs ? '?' : ''}${qs || ''}`;
}

// noinspection JSUnusedGlobalSymbols
export default buildGameNextStepUrl;
