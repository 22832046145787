import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import PartnerPricing from '../../../organisms/PartnerPricing';

export function StandardServicePricingPartnerModule({ partner, ...rest }: StandardServicePricingPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <PartnerPricing partner={partner} {...props} />
        </ModuleContainer>
    );
}

export interface StandardServicePricingPartnerModuleProps {
    partner: any;
}

export default StandardServicePricingPartnerModule;
