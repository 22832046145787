import clsx from 'clsx';
import { WithClassName, WithLabel, WithTarget } from '../withs';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Text from '../atoms/Text';

export function CallToAction({ className, label, target }: CallToActionProps) {
    const { blockShapeClass } = useStyling();
    return (
        <div className={clsx('w-full flex justify-center my-2', className)}>
            <a
                href={target}
                className={clsx(
                    'font-go-button w-full sm:w-52 text-white font-semibold text-xl text-center p-4 cursor-pointer bg-go-primary hover:bg-go-secondary',
                    blockShapeClass,
                )}
            >
                <Text inline basic className={'text-inherit'}>
                    {label}
                </Text>
            </a>
        </div>
    );
}

export interface CallToActionProps extends WithClassName, WithLabel, WithTarget<string> {}

export default CallToAction;
