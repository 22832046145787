// noinspection JSUnresolvedLibraryURL

import { Helmet } from 'react-helmet';

export function OneSignalPushNotification({ cfg }: OneSignalPushNotificationProps) {
    if (!cfg?.appId) return null;

    return (
        <Helmet>
            <script src='https://cdn.onesignal.com/sdks/OneSignalSDK.js' async />
            <script>{`
                window.OneSignal = window.OneSignal || [];
                OneSignal.push(function() {
                    OneSignal.init(${JSON.stringify(cfg)});
                });
            `}</script>
        </Helmet>
    );
}

export interface OneSignalPushNotificationProps {
    cfg?: {
        appId?: string;
        [key: string]: any;
    };
    swPath?: string;
}

// noinspection JSUnusedGlobalSymbols
export default OneSignalPushNotification;
