import { router_context_value } from '../types';

// noinspection JSUnusedLocalSymbols
function defaultBuildUrl(name: string, doc: any, qs?: any, extraVars?: any) {
    let url: string | undefined = '/';
    if ('game_' === name) url = doc?.publicPageShortUrl;
    else if ('game_buy' === name) url = doc?.salePageUrl;
    else if ('game_results' === name) url = doc?.publicDrawResultsPageUrl;
    else if ('game_' === name.slice(0, 5)) url = `/g/${doc?.code}/${name.slice(5)}`;
    else if ('organization_' === name.slice(0, 13)) url = `/o/${doc?.code}/${name.slice(13)}`;
    else if ('tenant_' === name.slice(0, 7)) url = `/x/${doc?.code}/${name.slice(7)}`;
    else if ('ticket_' === name.slice(0, 7)) url = `/t/${doc?.code}/${name.slice(7)}`;
    else if ('sale_' === name.slice(0, 5)) url = `/s/${doc?.id}/${name.slice(5)}`;
    else if ('user_' === name.slice(0, 5)) url = `/u/${doc?.id}/${name.slice(5)}`;
    else if ('promise_' === name.slice(0, 8)) url = `/p/${doc?.id}/${name.slice(8)}`;
    url = url || '/';
    qs = (qs && 'function' === typeof qs.trim ? qs : qs ? qs.toString() : '').trim();
    return `${url}${qs.length ? (-1 === url.indexOf('?') ? `?` : url.slice(-1) === '?' ? '' : '&') : ''}${qs}`;
}

export function createDefaultRouterContextValue(): router_context_value {
    return { buildUrl: defaultBuildUrl };
}

export default createDefaultRouterContextValue;
