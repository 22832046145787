import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypePresentation from '../../../organisms/type/TypePresentation';

export function StandardPresentationSellerModule({ seller, game, ...rest }: StandardPresentationSellerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <TypePresentation {...props} type={'seller'} doc={seller} />
        </ModuleContainer>
    );
}

export interface StandardPresentationSellerModuleProps {
    seller: any;
    game?: any;
}

export default StandardPresentationSellerModule;
