import { useMemo } from 'react';

const rankSorter = (a: any, b: any) => (a?.rank > b?.rank ? 1 : a?.rank < b?.rank ? -1 : 0);

export function useGameKeyfigures(game: any, forcedValue?: any[]) {
    return useMemo(() => {
        const gkeyfigures = (game?.gkeyfigures?.items || []).filter((x: any) => !!x?.public);
        const okeyfigures = (game?.organizationOkeyfigures?.items || []).filter((x: any) => !!x?.public);
        gkeyfigures.sort(rankSorter);
        okeyfigures.sort(rankSorter);
        return forcedValue || [...gkeyfigures, ...okeyfigures];
    }, [forcedValue, game?.gkeyfigures?.items, game?.organizationOkeyfigures?.items]);
}

export default useGameKeyfigures;
