import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from '@tsparticles/engine';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            number: {
                value: 50,
            },
            links: {
                distance: 125,
                enable: true,
                triangles: {
                    enable: true,
                    opacity: 0.1,
                },
            },
            move: {
                enable: true,
                speed: 5,
            },
            size: {
                value: 1,
            },
            shape: {
                type: 'circle',
            },
        },
    };
};
