import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypeFunnelStep from '../../../organisms/TypeFunnelStep';
import Input from '../../../atoms/Input';
import useTypeFormStep from '../../../hooks/useTypeFormStep';

export function OwnerRegistrationFormPartnerModule({
    partner,
    registration,
    ...rest
}: OwnerRegistrationFormPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t, stepProps, inputProps, register } = useTypeFormStep('partner', partner, 'registration', 'owner', {
        registration,
    });

    return (
        <ModuleContainer {...zprops}>
            <TypeFunnelStep {...stepProps} {...props}>
                <Input
                    {...register('firstName', { required: true })}
                    placeholder={t('field_firstname_placeholder')}
                    required
                    autoFocus
                    {...inputProps}
                />
                <Input
                    {...register('lastName', { required: true })}
                    placeholder={t('field_lastname_placeholder')}
                    required
                    {...inputProps}
                />
                <Input
                    {...register('email', {
                        required: true,
                        pattern: {
                            value: /^[^@\s]+@[^@\s]+\.[^@\s.]+$/,
                            message: t('field_error_email_format_message'),
                        },
                    })}
                    placeholder={t('field_email_placeholder')}
                    required
                    {...inputProps}
                />
                <Input
                    {...register('phone', {
                        pattern: { value: /^[0-9-+]+$/, message: t('field_error_phone_format_message') },
                    })}
                    placeholder={t('field_phone_placeholder')}
                    {...inputProps}
                />
            </TypeFunnelStep>
        </ModuleContainer>
    );
}

export interface OwnerRegistrationFormPartnerModuleProps {
    partner: any;
    registration: any;
}

export default OwnerRegistrationFormPartnerModule;
