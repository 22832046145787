import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    thumbnails: loadable(() => import('./videos/ThumbnailsVideosTypeModule')),
};

export function SecondaryVideosTypeModule(props: SecondaryVideosTypeModuleProps) {
    return <ModuleMode tag={'secondary'} {...props} modes={modes} defaultMode={'thumbnails'} />;
}

export interface SecondaryVideosTypeModuleProps extends TypeModuleProps {
    size?: string;
    sort?: string;
    tag?: string;
    hsize?: string;
}

export default SecondaryVideosTypeModule;
