import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./detailedInfos/StandardDetailedInfosTypeModule')),
};

export function DetailedInfosTypeModule(props: DetailedInfosTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type DetailedInfosTypeModuleProps = TypeModuleProps;

export default DetailedInfosTypeModule;
