import Items from '../atoms/Items';
import CardContent from '../molecules/CardContent';
import { WithItems } from '@gotombola/react-types';
import { useMemo } from 'react';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';

const icons = {
    'text/csv': 'spreadsheet',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
    'image/jpeg': 'jpeg',
    'image/png': 'png',
    'application/pdf': 'pdf',
    'image/webp': 'webp',
    'application/vnd.ms-excel': 'spreadsheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'spreadsheet',
};

const defaultItems: any[] = [];

export function FilesDownloader({ items = defaultItems }: FilesDownloaderProps) {
    const { t } = useTrans();
    const formattedItems = useMemo(
        () =>
            items.map((x) => {
                return {
                    ...x,
                    text: x.description,
                    buttonTarget: x.publicFile.url,
                    buttonLabel: t('game_files_download'),
                    icon: icons[x.publicFile.contentType || ''] || undefined,
                };
            }),
        [items, t],
    );

    return (
        <div className={'flex flex-col gap-4'}>
            <Items items={formattedItems} component={CardContent} />
        </div>
    );
}

export type FilesDownloaderProps = WithItems;

export default FilesDownloader;
