import { WithTitle } from '@gotombola/react-types';
import Ranking from '../../../organisms/type/Ranking';
import useQueryString from '../../../hooks/useQueryString';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardLeaderBoardGameModule({ game, ...rest }: StandardLeaderBoardGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const qs = useQueryString();

    if (!game.leaderboardEnabled && !qs.has('f:leaderboard')) return null;

    return (
        <ModuleContainer {...zprops}>
            <Ranking items={game?.amountRankedSellergroups?.items || []} game={game} {...props} />
        </ModuleContainer>
    );
}

export interface StandardLeaderBoardGameModuleProps extends WithTitle {
    game: any;
    typeType: string;
    defaultFilter?: string;
    unit?: string;
    defaultSort?: string;
    property?: string;
    rawItems?: any[];
}

export default StandardLeaderBoardGameModule;
