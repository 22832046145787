import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./headerSellergroup/StandardHeaderSellergroupGameModule')),
};

export function HeaderSellergroupGameModule(props: HeaderSellergroupGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type HeaderSellergroupGameModuleProps = GameModuleProps;

export default HeaderSellergroupGameModule;
