import { useMemo } from 'react';

export function useVals(values, allValues, key, defaultKey, t, emptyAllowed, emptyLabel: string) {
    return useMemo(() => {
        let vals = [...(values || allValues[key || ''] || allValues[defaultKey])];
        vals.sort((a: any, b: any) =>
            a.priority || b.priority
                ? a.priority > b.priority
                    ? -1
                    : a.priority < b.priority
                    ? 1
                    : 0
                : a.value > b.value
                ? 1
                : a.value < b.value
                ? -1
                : 0,
        );
        vals = vals.map((x) => ({ ...x, label: x.label ? t(x.label) : x.label }));
        emptyAllowed && vals.unshift({ value: '', label: t(emptyLabel) });
        return vals;
    }, [values, allValues, key, defaultKey, t, emptyAllowed, emptyLabel]);
}

export default useVals;
