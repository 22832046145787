import GameHeader from '../../../molecules/headers/GameHeader';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardHeaderSellerGameModule({ game, seller, ...rest }: StandardHeaderSellerGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <GameHeader {...props} game={game} extraBuyQs={`sg=${seller.code}`} />
        </ModuleContainer>
    );
}

export interface StandardHeaderSellerGameModuleProps {
    game: any;
    seller: any;
}

export default StandardHeaderSellerGameModule;
