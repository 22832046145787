import loadable from '@loadable/component';
import { ModuleProps } from '../types';
import { WithUrl } from '../withs';
import ModuleMode from '../organisms/ModuleMode';

const modes = {
    player: loadable(() => import('./videos/PlayerVideoModule')),
    immersive: loadable(() => import('./videos/ImmersiveVideoModule')),
};

export function VideoModule(props: VideoModuleProps) {
    return <ModuleMode hmargin={true} {...props} modes={modes} defaultMode={'player'} />;
}

export interface VideoModuleProps extends ModuleProps, WithUrl {
    playing?: boolean;
    muted?: boolean;
    hsize?: string;
    hmargin?: boolean;
}

export default VideoModule;
