import { WithClassName } from '../../withs';
import Footer from '../../organisms/Footer';

export function DoneeFooter({ className, donee }: DoneeFooterProps) {
    const logoImage = !!donee.logoImage && !!donee.logoImage.url ? donee.logoImage : undefined;

    return <Footer logo={logoImage} className={className} email={donee.email} name={donee.name} phone={donee.phone} />;
}

export interface DoneeFooterProps extends WithClassName {
    donee?: any;
}

export default DoneeFooter;
