import GameStats from '../../../organisms/GameStats';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardStatsGameModule({
    game,
    hideDrawDate = false,
    hidePlannedTickets = false,
    hideRemainingTickets = false,
    hideStartDate = false,
    hideStatTickets = false,
    hideDrawCountDown = false,
    ...rest
}: StandardStatsGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const drawDate = game.plannedDrawnAt > 0 ? new Date(game.plannedDrawnAt) : undefined;
    const startDate = game.plannedStartedAt > 0 ? new Date(game.plannedStartedAt) : undefined;
    const remainingTickets = undefined !== game.plannedTickets ? game.plannedTickets - game.statTickets : undefined;

    if (!drawDate && !startDate) return null;

    return (
        <ModuleContainer {...zprops}>
            <GameStats
                {...props}
                startDate={startDate}
                drawDate={drawDate}
                statTickets={game.statTickets}
                statImportedTickets={game?.statImportedTickets}
                remainingTickets={remainingTickets}
                plannedTickets={game.plannedTickets}
                status={game.status}
                hidePlannedTickets={hidePlannedTickets || !!game?.features?.includes('hide_planned_tickets')}
                hideRemainingTickets={hideRemainingTickets || !!game?.features?.includes('hide_remaining_tickets')}
                hideStatTickets={hideStatTickets || !!game?.features?.includes('hide_sold_tickets')}
                hideStartDate={hideStartDate || !!game?.features?.includes('hide_start_date')}
                hideDrawDate={hideDrawDate || !!game?.features?.includes('hide_draw_date')}
                hideDrawCountDown={hideDrawCountDown || !game?.features?.includes('display_draw_countdown')}
                locale={game.locale}
                flexModeStatus={game.flexModeStatus}
            />
        </ModuleContainer>
    );
}

export interface StandardStatsGameModuleProps {
    game: any;
    hidePlannedTickets?: boolean;
    hideRemainingTickets?: boolean;
    hideStatTickets?: boolean;
    hideStartDate?: boolean;
    hideDrawDate?: boolean;
    hideDrawCountDown?: boolean;
}

export default StandardStatsGameModule;
