import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from '@tsparticles/engine';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        fpsLimit: 120,
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            number: {
                value: 2,
            },
            color: {
                value: [
                    '#3998D0',
                    '#2EB6AF',
                    '#A9BD33',
                    '#FEC73B',
                    '#F89930',
                    '#F45623',
                    '#D62E32',
                    '#EB586E',
                    '#9952CF',
                ],
            },
            shape: {
                type: 'circle',
            },
            opacity: {
                value: { min: 0.3, max: 1 },
            },
            size: {
                value: { min: 2, max: 30 },
            },
            move: {
                enable: true,
                angle: {
                    value: 30,
                    offset: 0,
                },
                speed: {
                    min: 1,
                    max: 10,
                },
                direction: 'top',
                outModes: {
                    default: 'destroy',
                    bottom: 'none',
                },
            },
        },
        detectRetina: true,
        emitters: {
            position: {
                x: 50,
                y: 150,
            },
            rate: {
                delay: 1,
                quantity: 1,
            },
            size: {
                width: 100,
                height: 50,
            },
        },
    };
};
