import GameInfos from '../../../organisms/GameInfos';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';

export function StandardInfosSellerModule({ seller, game, ...rest }: StandardInfosSellerModuleProps) {
    game = ((game ? { game } : useGameContext()) || {}).game;
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <GameInfos
                {...props}
                game={game}
                publicPageShortUrl={seller.publicPageShortUrl}
                secondLogo={seller?.logoImage}
            />
        </ModuleContainer>
    );
}

export interface StandardInfosSellerModuleProps {
    seller: any;
    game?: any;
}

export default StandardInfosSellerModule;
