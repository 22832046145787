import { WithClassName, WithLabel, WithTarget } from '../../withs';

export function LinkButton({ className, label, target, targetMode, ...props }: LinkButtonProps) {
    return (
        <a href={target} target={targetMode} className={className} {...props}>
            {label || ''}
        </a>
    );
}

export interface LinkButtonProps extends WithClassName, Required<WithLabel>, WithTarget {
    targetMode?: string;
}

// noinspection JSUnusedGlobalSymbols
export default LinkButton;
