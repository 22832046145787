import { useMemo } from 'react';
import buildImageUrl from '../utils/buildImageUrl';

const maxModes = {
    default: {
        desktop: 1280,
        tablet: 960,
        mobile: 600,
    },
    xs: {
        desktop: 640,
        tablet: 640,
        mobile: 600,
    },
    sm: {
        desktop: 860,
        tablet: 860,
        mobile: 600,
    },
    md: {
        desktop: 1280,
        tablet: 960,
        mobile: 600,
    },
    lg: {
        desktop: 1280,
        tablet: 1024,
        mobile: 1024,
    },
    xl: {
        desktop: 1536,
        tablet: 1280,
        mobile: 1024,
    },
    '2xl': {
        desktop: 1792,
        tablet: 1536,
        mobile: 1024,
    },
    '3xl': {
        desktop: 2048,
        tablet: 1536,
        mobile: 1024,
    },
    '4xl': {
        desktop: 2304,
        tablet: 1792,
        mobile: 1280,
    },
    '5xl': {
        desktop: 2560,
        tablet: 1792,
        mobile: 1280,
    },
    '6xl': {
        desktop: 2816,
        tablet: 1792,
        mobile: 1280,
    },
    '7xl': {
        desktop: 3072,
        tablet: 2048,
        mobile: 1280,
    },
};

export function useImageUrls({ url, mobile, tablet, maxSize, maxSizeMobile, maxSizeTablet, maxMode }: any): {
    url?: string;
    mobileUrl?: string;
    tabletUrl?: string;
    maxMode?: string;
} {
    return useMemo(() => {
        const max = maxModes[maxMode || ''] || maxModes['default'];
        const u = url || tablet?.url || mobile?.url;
        return !u
            ? {}
            : {
                  url: buildImageUrl(u, maxSize, max.desktop),
                  tabletUrl: buildImageUrl((tablet || {}).url || url, maxSizeTablet || maxSize, max.tablet),
                  mobileUrl: buildImageUrl(
                      (mobile || {}).url || (tablet || {}).url || url,
                      maxSizeMobile || maxSizeTablet || maxSize,
                      max.mobile,
                  ),
              };
    }, [url, mobile, tablet, maxSize, maxSizeMobile, maxSizeTablet, maxMode]);
}

export default useImageUrls;
