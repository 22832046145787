import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypePresentation from '../../../organisms/type/TypePresentation';

export function StandardPresentationDoneeModule({ donee, ...rest }: StandardPresentationDoneeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <TypePresentation {...props} type={'donee'} doc={donee} />
        </ModuleContainer>
    );
}

export interface StandardPresentationDoneeModuleProps {
    donee?: any;
}

export default StandardPresentationDoneeModule;
