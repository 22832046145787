import GameKeyInfos from '../../../organisms/GameKeyInfos';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardKeyInfosGameModule({ game, hideDrawDate = false, ...rest }: StandardKeyInfosGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const drawDate = game.plannedDrawnAt > 0 ? new Date(game.plannedDrawnAt) : undefined;

    if (!drawDate) return null;

    return (
        <ModuleContainer {...zprops}>
            <GameKeyInfos {...props} game={game} drawDate={drawDate} hideDrawDate={hideDrawDate} />
        </ModuleContainer>
    );
}

export interface StandardKeyInfosGameModuleProps {
    game: any;
    hideDrawDate?: boolean;
}

export default StandardKeyInfosGameModule;
