import GenericLocaleSwitcher, { GenericLocaleSwitcherProps } from './GenericLocaleSwitcher';

export function DoneeLocaleSwitcher({ donee, ...props }: DoneeLocaleSwitcherProps) {
    return <GenericLocaleSwitcher item={donee} versionKey={'doneeVersions'} {...props} />;
}

export interface DoneeLocaleSwitcherProps extends Omit<GenericLocaleSwitcherProps, 'item' | 'versionKey'> {
    donee: any;
}

export default DoneeLocaleSwitcher;
