import clsx from 'clsx';
import { WithClassName, WithLazy, WithNativeOnClick, WithTarget } from '../withs';
import Img from './Img';
import buildQrCodeUrl from '../utils/buildQrCodeUrl';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function QRCode({
    className,
    lazy = true,
    onClick,
    target,
    maxSize = 400,
    filename,
    format,
    style,
    customLogo,
}: QRCodeProps) {
    const { logoShapeClass } = useStyling();
    return (
        <Img
            lazy={lazy}
            onClick={onClick}
            className={clsx('h-full', logoShapeClass, className)}
            url={buildQrCodeUrl(target, format, { filename, style, customLogo })}
            alt={'QRCode'}
            maxSize={maxSize}
        />
    );
}

export interface QRCodeProps extends WithClassName, WithLazy, WithNativeOnClick, Required<WithTarget> {
    maxSize?: number;
    filename?: string;
    format?: string;
    style?: string;
    customLogo?: string;
}

export default QRCode;
