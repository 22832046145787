import GenericLocaleSwitcher, { GenericLocaleSwitcherProps } from './GenericLocaleSwitcher';

export function SponsorLocaleSwitcher({ sponsor, ...props }: SponsorLocaleSwitcherProps) {
    return <GenericLocaleSwitcher item={sponsor} versionKey={'sponsorVersions'} {...props} />;
}

export interface SponsorLocaleSwitcherProps extends Omit<GenericLocaleSwitcherProps, 'item' | 'versionKey'> {
    sponsor: any;
}

export default SponsorLocaleSwitcher;
