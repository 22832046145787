import { WithButtons, WithCenter, WithDescription, WithSubtitle, WithText, WithTitle } from '../withs';
import RichText from '../atoms/RichText';
import Buttons from '../molecules/Buttons';
import useButtonsProps from '../hooks/useButtonsProps';
import { WithItems } from '../withs';
import Items from '../atoms/Items';
import CommonContentItem from '../atoms/CommonContentItem';
import clsx from 'clsx';
import SectionTitle from '../atoms/SectionTitle';

export function CommonContent({
    subtitle,
    text,
    title,
    titleStyling,
    description,
    items = [],
    itemComponent: ItemComponent = CommonContentItem,
    center = false,
    ...rest
}: CommonContentProps) {
    const [bprops] = useButtonsProps(rest);

    return (
        <div className={clsx('w-full flex flex-col gap-6', center && 'text-center')}>
            <SectionTitle title={title} titleStyling={titleStyling} className={'mb-4 sm:mb-8'} />
            {!!subtitle && (
                <div className={'font-go-text text-2xl text-center text-go-secondary font-extrabold uppercase'}>
                    <RichText value={subtitle} />
                </div>
            )}
            {!!items?.length && (
                <div className={'flex flex-col sm:flex-row justify-center items-start gap-6'}>
                    <Items items={items} component={ItemComponent} />
                </div>
            )}
            {!!description && (
                <div className={'font-go-text text-go-text text-md mx-auto font-light sm:mb-4'}>
                    <RichText value={description} />
                </div>
            )}
            <Buttons className={'justify-center'} {...bprops} />
            {!!text && (
                <div className={'font-go-text text-go-text text-md text-center font-medium'}>
                    <RichText value={text} />
                </div>
            )}
        </div>
    );
}

export interface CommonContentProps
    extends WithText,
        WithSubtitle,
        WithTitle,
        WithDescription,
        WithItems,
        WithButtons,
        WithCenter {
    itemComponent?: any;
    titleStyling?: string;
}

export default CommonContent;
