import { useMemo } from 'react';
import { WithClassName } from '../../withs';
import PartnerLocaleSwitcher from '../PartnerLocaleSwitcher';
import String from '../../atoms/String';
import Header from '../../organisms/Header';
import Img from '../../atoms/Img';
import clsx from 'clsx';
import PartnerHeaderMainButton from '../../atoms/buttons/partner/PartnerHeaderMainButton';
import PoweredByTenant from '../../atoms/PoweredByTenant';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function PartnerHeader({ className, light = false, locale, partner }: PartnerHeaderProps) {
    const logoImage = !!partner.logoImage && !!partner.logoImage.url ? partner.logoImage : undefined;
    const localeVersions = useMemo(
        () => partner?.partnerVersions?.filter((x: any) => x.type === 'locale') || [],
        [partner],
    );
    const hasVersions = !!localeVersions && !!localeVersions.length;
    const { logoShapeClass } = useStyling();

    return (
        <Header className={className} variant={'corporate'}>
            <div className={'flex w-full items-center justify-center'}>
                {!!logoImage && (
                    <Img
                        className={clsx('hidden md:block h-11 flex mr-4', logoShapeClass)}
                        url={logoImage.url}
                        alt={logoImage.alt || 'logo'}
                        maxSize={250}
                    />
                )}
                <div
                    className={clsx(
                        hasVersions ? 'ml-4 text-left sm:ml-0' : 'text-center',
                        'sm:text-left flex-1 py-4 font-bold text-xl truncate',
                    )}
                >
                    {!!partner.name && (
                        <span className={'font-go-text inline-block md:hidden'}>
                            <String basic>{partner.name}</String>
                        </span>
                    )}
                </div>
            </div>
            {!partner.main && <PoweredByTenant />}
            <div className={'flex w-auto items-center justify-end space-x-2'}>
                {!!partner.code && (hasVersions || !light) && (
                    <>
                        <div className={'hidden sm:flex sm:flex-1 items-center justify-end space-x-2'}>
                            <PartnerHeaderMainButton partner={partner} />
                        </div>
                        {hasVersions && (
                            <div className={'w-auto pr-4 sm:pr-0'}>
                                <PartnerLocaleSwitcher partner={partner} locale={locale} />
                            </div>
                        )}
                    </>
                )}
            </div>
        </Header>
    );
}

export interface PartnerHeaderProps extends WithClassName {
    partner?: any;
    light?: boolean;
    locale?: string;
}

// noinspection JSUnusedGlobalSymbols
export default PartnerHeader;
