export function RestrictedPage({ accesscode = false, error = undefined }: RestrictedPageProps) {
    return (
        <div
            className={
                'z-40 fixed inset-0 min-w-screen min-h-screen bg-go-nav-primary flex flex-col items-center justify-center px-5 py-5'
            }
        >
            <div className={'flex flex-col h-full'}>
                RESTRICTED {accesscode || ''} {error || ''}
            </div>
        </div>
    );
}

export interface RestrictedPageProps {
    accesscode?: boolean;
    error?: string;
}

export default RestrictedPageProps;
