import GameHeader from '../../../molecules/headers/GameHeader';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardHeaderSellergroupGameModule({
    game,
    sellergroup,
    ...rest
}: StandardHeaderSellergroupGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <GameHeader {...props} game={game} extraBuyQs={`sg=${sellergroup.code}`} />
        </ModuleContainer>
    );
}

export interface StandardHeaderSellergroupGameModuleProps {
    game: any;
    sellergroup: any;
}

export default StandardHeaderSellergroupGameModule;
