import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./leaderboard/StandardLeaderboardGameModule')),
};

export function LeaderboardGameModule(props: LeaderboardGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type LeaderboardGameModuleProps = GameModuleProps;

export default LeaderboardGameModule;
