import { WithButtons } from '../withs';

export function useButtonsProps<T = any>(
    props: WithButtons & T,
): [
    WithButtons,
    Omit<
        T,
        | 'buttonLabel'
        | 'buttonTarget'
        | 'buttonFormat'
        | 'button2Label'
        | 'button2Target'
        | 'button2Format'
        | 'button3Label'
        | 'button3Target'
        | 'button3Format'
    >,
] {
    const {
        buttonLabel,
        buttonTarget,
        buttonFormat,
        button2Label,
        button2Target,
        button2Format,
        button3Label,
        button3Target,
        button3Format,
        ...rest
    } = props;

    return [
        {
            buttonLabel,
            buttonTarget,
            buttonFormat,
            button2Label,
            button2Target,
            button2Format,
            button3Label,
            button3Target,
            button3Format,
        },
        rest,
    ];
}

export default useButtonsProps;
