import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./share/StandardShareGameModule')),
};

export function ShareGameModule(props: ShareGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ShareGameModuleProps = GameModuleProps;

export default ShareGameModule;
