import loadable from '@loadable/component';
import { SellerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./alertBanner/StandardAlertBannerSellerModule')),
};

export function AlertBannerSellerModule(props: AlertBannerSellerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type AlertBannerSellerModuleProps = SellerModuleProps;

export default AlertBannerSellerModule;
