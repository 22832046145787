import { locale as defaultDefaultLocale } from '@gotombola/defaults';
import toLanguageTagLocale from '@gotombola/locale/lib/utils/toLanguageTagLocale';
import prepareDate from './prepareDate';

export function formatDate(
    date: Date | string | number | undefined,
    locale?: string,
    format?: string,
    { defaultLocale = defaultDefaultLocale, ...rest }: { defaultLocale?: string; [key: string]: any } = {},
) {
    return new Intl.DateTimeFormat(toLanguageTagLocale(locale || defaultLocale), rest).format(prepareDate(date));
}

export default formatDate;
