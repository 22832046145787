import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import LeftCallToActionContent from '../../../organisms/LeftCallToActionContent';

export function StandardServiceContactPartnerModule({ partner, ...rest }: StandardServiceContactPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('partner', partner);

    return (
        <ModuleContainer {...zprops}>
            <LeftCallToActionContent
                title={t('service_contact_title')}
                text={t('service_contact_text')}
                buttonLabel={t('service_contact_button_label')}
                buttonTarget={`mailto:contact@gotombola.co?subject=${partner?.name} + GoTombola`}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface StandardServiceContactPartnerModuleProps {
    partner: any;
}

export default StandardServiceContactPartnerModule;
