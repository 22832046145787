import DoneeHeader from '../../../molecules/headers/DoneeHeader';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardHeaderDoneeModule({ donee, ...rest }: StandardHeaderDoneeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <DoneeHeader {...props} donee={donee} />
        </ModuleContainer>
    );
}

export interface StandardHeaderDoneeModuleProps {
    donee: any;
}

export default StandardHeaderDoneeModule;
