export function prepareModuleConfig({ params = [], ...rest }: any) {
    return params.reduce((acc: any, p: any) => {
        if (!p || !p.type || !p.name) return acc;
        switch (p.type) {
            case 'string':
                acc[p.name] = undefined === p.value ? undefined : String(p.value);
                break;
            case 'int':
                acc[p.name] = p.intValue;
                break;
            case 'float':
                acc[p.name] = p.floatValue;
                break;
            case 'boolean':
                acc[p.name] = p.booleanValue;
                break;
            case 'json':
                try {
                    acc[p.name] = JSON.parse(String(p.jsonValue));
                } catch (e) {
                    acc[p.name] = null;
                }
                break;
            default:
                acc[p.name] = undefined;
        }
        return acc;
    }, rest);
}

export default prepareModuleConfig;
