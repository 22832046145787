import ModuleContainer from '../../organisms/ModuleContainer';
import HeaderModule from '../../modules/HeaderModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function HeaderCommonModule({ config, ...rest }: HeaderCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <HeaderModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface HeaderCommonModuleProps {
    config: any;
}

export default HeaderCommonModule;
