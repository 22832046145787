import { createContext } from 'react';
import { template_context_value } from '../types';
import createDefaultTemplateContextValue from '../utils/createDefaultTemplateContextValue';

export const TemplateContext = createContext<template_context_value>(createDefaultTemplateContextValue());
// noinspection JSUnusedGlobalSymbols
export const TemplateContextProvider = TemplateContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const TemplateContextConsumer = TemplateContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default TemplateContext;
