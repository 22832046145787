import { useMemo } from 'react';
import Title, { TitleProps } from '../../../organisms/Title';
import { WithTitle } from '@gotombola/react-types/lib/withs';
import { rich_text } from '@gotombola/react-types/lib/types';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardTitleCommonModule({ ...rest }: StandardTitleCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const title = useMemo(
        () => (props.title ? ('function' === typeof props.title ? props.title(props) : props.title) : props.title),
        [props, props.title],
    );
    if (!title) return null;

    return (
        <ModuleContainer {...zprops}>
            <Title {...props} title={title} />
        </ModuleContainer>
    );
}

export interface StandardTitleCommonModuleProps
    extends Omit<TitleProps, 'title'>,
        WithTitle<rich_text | ((params: unknown) => rich_text)> {}

export default StandardTitleCommonModule;
