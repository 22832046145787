import loadable from '@loadable/component';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./unknown/StandardUnknownCommonModule')),
};

export function UnknownCommonModule(props: UnknownCommonModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UnknownCommonModuleProps {}

export default UnknownCommonModule;
