import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./about/StandardAboutTypeModule')),
};

export function AboutTypeModule(props: AboutTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type AboutTypeModuleProps = TypeModuleProps;

export default AboutTypeModule;
