import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import GodfatherTemplate, { GodfatherTemplateProps } from './GodfatherTemplate';

export function DynamicGodfatherPublicTemplate(props: DynamicGodfatherPublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <GodfatherTemplate {...props} godfather={context.doc} game={context.game} />;
}

export type DynamicGodfatherPublicTemplateProps = Omit<GodfatherTemplateProps, 'godfather'>;

export default DynamicGodfatherPublicTemplate;
