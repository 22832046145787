import { Engine, ISourceOptions } from '@tsparticles/engine';
import { loadHeartShape } from '@tsparticles/shape-heart';
import { loadFull } from 'tsparticles';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    await loadHeartShape(engine);
    return {
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            color: {
                value: ['#ff0028', '#ff4b66', '#f3b5be', '#b03045', '#f64262', '#ab071d', '#ff001f', '#ffe2e4'],
            },
            move: {
                angle: {
                    offset: 0,
                    value: 15,
                },
                direction: 'bottom',
                enable: true,
                outModes: {
                    default: 'out',
                },
                speed: 2,
            },
            number: {
                value: 15,
            },
            opacity: {
                value: 1,
            },
            shape: {
                type: 'heart',
            },
            size: {
                value: 48,
            },

            zIndex: {
                value: {
                    min: 0,
                    max: 100,
                },
                opacityRate: 0,
                velocityRate: 2,
            },
        },
    };
};
