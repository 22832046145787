import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import ReactBnbGallery, { Photo } from 'react-bnb-gallery';
import { TypeProps } from '../../types';
import { WithClassName, WithComponent, WithItems } from '../../withs';
import Thumbnail from '../../atoms/Thumbnail';
import VideoThumbnail from '../../atoms/VideoThumbnail';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import buildImageUrl from '../../utils/buildImageUrl';

const defaultItems = [];
const defaultComponents = {};
const defaultDefaultComponents = {
    image: Thumbnail,
    video: VideoThumbnail,
};
const defaultComponentType = 'image';
const defaultItemProps = {};

export function TypeImages({
    className,
    component = undefined,
    components = defaultComponents,
    doc,
    itemProps = defaultItemProps,
    items = defaultItems,
    type,
}: TypeImagesProps) {
    const { t } = useTypeTranslation(type, doc);
    const [activePhotoIndex, setActivePhotoIndex] = useState<number | undefined>(undefined);
    const handlePhotoClick = useCallback(
        (x: number) => () => {
            setActivePhotoIndex(x);
        },
        [setActivePhotoIndex],
    );
    const handleClose = useCallback(() => {
        setActivePhotoIndex(undefined);
    }, [setActivePhotoIndex]);
    const opened = undefined !== activePhotoIndex;
    const filteredItems = useMemo(() => {
        return Object.values(items.reduce((acc: any, i: any) => Object.assign(acc, i?.url ? { [i?.url]: i } : {}), {}));
    }, [items]);
    const photos: Photo[] = useMemo(
        () =>
            (filteredItems || []).map((i: any, index: number) => ({
                photo: buildImageUrl(i['url'], 1024, 1280),
                number: index,
            })),
        [filteredItems],
    );

    return (
        <div className={clsx('flex flex-wrap justify-center gap-2', className)}>
            {(filteredItems || []).map((item: any, index: number) => {
                const Component =
                    component ||
                    (components || {})[item?.type || ''] ||
                    defaultDefaultComponents[item?.type || ''] ||
                    (components || {})[defaultComponentType] ||
                    defaultDefaultComponents[defaultComponentType];
                return (
                    <Component
                        key={index}
                        {...item}
                        {...itemProps}
                        onClick={handlePhotoClick(index)}
                        alt={t('gallery_image_index', { index: index })}
                    />
                );
            })}
            <ReactBnbGallery activePhotoIndex={activePhotoIndex} show={opened} photos={photos} onClose={handleClose} />
        </div>
    );
}

export interface TypeImagesProps extends TypeProps, WithClassName, WithComponent, WithItems<{ url: string }> {
    components?: Record<string, any>;
    itemProps?: any;
}

export default TypeImages;
