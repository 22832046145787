import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import OrganizationTemplate, { OrganizationTemplateProps } from './OrganizationTemplate';

export function DynamicOrganizationPublicTemplate(props: DynamicOrganizationPublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <OrganizationTemplate {...props} organization={context.doc} />;
}

export type DynamicOrganizationPublicTemplateProps = Omit<OrganizationTemplateProps, 'organization'>;

export default DynamicOrganizationPublicTemplate;
