import { TypeProps } from '../../types';
import ModuleSection from '../ModuleSection';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import useTypeModuleImages from '../../hooks/useTypeModuleImages';
import { WithClassName, WithSubtitle, WithTitle } from '../../withs';

export function TypePresentation({
    className,
    doc,
    type,
    title: forcedTitle,
    titleStyling,
    subtitle,
    subtitleStyling,
    textStyling,
    presentation: forcedPresentation,
    msVariant: variant,
    ...props
}: TypePresentationProps) {
    const { t } = useTypeTranslation(type, doc);
    const moduleImages = useTypeModuleImages(type, doc);

    const title = forcedTitle || t(`${type}_label`);
    const text = forcedPresentation || doc?.presentation;
    if (!text) return null;

    return (
        <ModuleSection
            title={title}
            titleStyling={titleStyling}
            subtitle={subtitle}
            subtitleStyling={subtitleStyling}
            text={text}
            textStyling={textStyling}
            className={className}
            defaultVariant={'bordered'}
            variant={variant}
            {...moduleImages}
            {...props}
        />
    );
}

export interface TypePresentationProps extends TypeProps, WithClassName, WithTitle, WithSubtitle {
    titleStyling?: string;
    subtitleStyling?: string;
    textStyling?: string;
    presentation?: string;
    msVariant?: string;
}

// noinspection JSUnusedGlobalSymbols
export default TypePresentation;
