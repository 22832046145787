import fetch from 'cross-fetch';
import ApiError from '../errors/ApiError';

export async function callHttp(url: string, method: string, headers: any, body: any, options: any) {
    const init = {
        method,
        headers,
        ...(body
            ? {
                  body: 'string' === typeof body ? body : JSON.stringify(body),
              }
            : {}),
        ...options,
    };
    const response = await fetch(url, init);
    const isError = 200 > response.status || 300 <= response.status;
    let data: any;
    if ((response.headers.get('content-type') || '').match(/application\/json/i)) {
        data = await response.json();
    } else {
        data = await response.text();
    }

    return {
        data,
        error: isError ? new ApiError(data, response).toJson() : undefined,
        request: {
            url,
            ...init,
        },
        response,
    };
}

export default callHttp;
