import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./badges/StandardBadgesTypeModule')),
};

export function BadgesTypeModule(props: BadgesTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type BadgesTypeModuleProps = TypeModuleProps;

export default BadgesTypeModule;
