import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from '@tsparticles/engine';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            number: {
                value: 100,
            },
            move: {
                direction: 'bottom',
                enable: true,
                random: false,
                straight: false,
            },
            opacity: {
                value: { min: 0.1, max: 0.5 },
            },
            size: {
                value: { min: 1, max: 10 },
            },
            wobble: {
                distance: 20,
                enable: true,
                speed: {
                    min: -5,
                    max: 5,
                },
            },
        },
    };
};
