import loadable from '@loadable/component';
import { CommonModuleProps, dynamic_page_body_item_config } from '../../types';
import { WithItems } from '../../withs';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./dynamicPageBody/StandardDynamicPageBodyCommonModule')),
};

export function DynamicPageBodyCommonModule(props: DynamicPageBodyCommonModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export interface DynamicPageBodyCommonModuleProps extends CommonModuleProps, WithItems<dynamic_page_body_item_config> {}

export default DynamicPageBodyCommonModule;
