import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import SponsoringTemplate, { SponsoringTemplateProps } from './SponsoringTemplate';

export function DynamicSponsoringPublicTemplate(props: DynamicSponsoringPublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <SponsoringTemplate {...props} sponsoring={context.doc} game={context.game} />;
}

export type DynamicSponsoringPublicTemplateProps = Omit<SponsoringTemplateProps, 'sponsoring'>;

export default DynamicSponsoringPublicTemplate;
