import CenteredHeadingModule from '../modules/headings/CenteredHeadingModule';
import { WithButtons, WithOverline, WithSubtitle, WithText, WithTitle } from '../withs';
import DynamicContents from './DynamicContents';

export function Contents({ contents, config, ...props }: ContentsProps) {
    return (
        <div className={'flex flex-col gap-6'}>
            <CenteredHeadingModule {...config} {...props} />
            <DynamicContents contents={contents} />
        </div>
    );
}

export interface ContentsProps extends WithTitle, WithSubtitle, WithText, WithOverline, WithButtons {
    contents?: any;
    config?: any;
}

// noinspection JSUnusedGlobalSymbols
export default Contents;
