import { useCallback, useMemo, useState } from 'react';
import TopMenuItem from '../atoms/TopMenuItem';
import Modal from './Modal';
import ChoiceMenu from './ChoiceMenu';
import useQueryString from '../hooks/useQueryString';
import LocaleItem from './LocaleItem';

export function GenericLocaleSwitcher({ item, locale, versionKey }: GenericLocaleSwitcherProps) {
    const l = locale || item?.locale;
    const query = useQueryString();
    const [opened, setOpened] = useState<boolean>(false);
    const onToggleOpened = useCallback(
        (e: any) => {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            setOpened(!opened);
        },
        [setOpened, opened],
    );
    const locales = useMemo(
        () => (item || {})[versionKey]?.filter((v) => 'locale' === v?.type).map((v) => v?.locale),
        [(item || {})[versionKey]],
    );
    let currentLocaleQuery = query.toString();
    const items = useMemo(() => {
        return (locales || []).map((i) => {
            query.set('locale', i);
            i === l && (currentLocaleQuery = query.toString());
            return {
                label: i,
                value: i,
                target: `?${query.toString()}`,
                component: LocaleItem,
                props: { locale: i, full: true, size: 'lg' },
            };
        });
    }, [query]);

    return (
        <>
            <TopMenuItem
                linkClassName={'w-14 md:w-20 flex items-center space-x-2 px-1 h-10 justify-center'}
                target={onToggleOpened}
                fakeTarget={`?${currentLocaleQuery}`}
            >
                <LocaleItem locale={l} center />
            </TopMenuItem>
            {opened && !!items.length && (
                <Modal onClose={onToggleOpened} className={''} title={''} subtitle={''}>
                    <ChoiceMenu inline={items?.length >= 5} items={items} center></ChoiceMenu>
                </Modal>
            )}
        </>
    );
}

export interface GenericLocaleSwitcherProps {
    item: any;
    locale?: string;
    versionKey: string;
}

export default GenericLocaleSwitcher;
