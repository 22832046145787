import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypeFunnelStep from '../../../organisms/TypeFunnelStep';
import Input from '../../../atoms/Input';
import useTypeFormStep from '../../../hooks/useTypeFormStep';

export function TeamSellergroupRegistraionFormGameModule({
    game,
    registration,
    ...rest
}: TeamSellergroupRegistraionFormGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t, stepProps, inputProps, register } = useTypeFormStep('game_sellergroup', game, 'registration', 'team', {
        registration,
    });

    return (
        <ModuleContainer {...zprops}>
            <TypeFunnelStep {...stepProps} {...props}>
                <Input
                    {...register('name', { required: true })}
                    placeholder={t('field_teamname_placeholder')}
                    autoFocus
                    required
                    {...inputProps}
                />
            </TypeFunnelStep>
        </ModuleContainer>
    );
}

export interface TeamSellergroupRegistraionFormGameModuleProps {
    game: any;
    registration: any;
}

export default TeamSellergroupRegistraionFormGameModule;
