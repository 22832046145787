import { useCallback, useEffect } from 'react';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { WithButton, WithChildren, WithOnClose, WithOverline, WithTitle } from '../withs';
import { flag } from '../types';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Button from '../atoms/Button';

const sizes = {
    sm: 'w-screen h-screen sm:h-[70%] sm:w-[70%] md:h-[70%] md:w-[50%]',
    md: 'w-screen h-screen sm:h-[90%] sm:w-[90%] md:h-[90%] md:w-[70%]',
    lg: 'w-screen h-screen md:h-[90%] md:w-[90%]',
};

const defaultSize = 'lg';

export function Modal2({
    buttonLabel,
    buttonTarget,
    children,
    onClose,
    opened = false,
    overline,
    title,
    size = defaultSize,
    closable = true,
}: Modal2Props) {
    const { t } = useTrans();
    const ignoreOnClick = useCallback((e: any) => {
        e.stopPropagation();
    }, []);
    const { blockShapeNoMobileClass, blockHeaderShapeClass, blockFooterShapeClass } = useStyling();
    useEffect(() => {
        if (!closable) return;
        const handleEsc = (e: any) => {
            e.keyCode === 27 && onClose && onClose();
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [closable]);

    return !opened ? null : (
        <>
            <div className={'fixed inset-0 z-40 bg-black opacity-80'} />
            <div
                className={'fixed inset-0 z-50 flex justify-center items-center sm:overflow-hidden'}
                onClick={closable ? (onClose as any) : undefined}
            >
                <div
                    className={clsx(
                        'relative shadow-lg flex flex-col bg-white overflow-scroll sm:overflow-hidden',
                        sizes[size || defaultSize] || sizes[defaultSize],
                        blockShapeNoMobileClass,
                    )}
                    onClick={ignoreOnClick}
                >
                    <div
                        className={clsx(
                            'flex justify-between px-6 py-4 border-b border-gray-200',
                            !closable && 'items-center justify-center',
                            blockHeaderShapeClass,
                        )}
                    >
                        <h3 className={clsx('font-go-text text-2xl font-semibold', !closable && 'w-full text-center')}>
                            {title}
                        </h3>
                        {closable && (
                            <Button
                                target={onClose as any}
                                label={t('modal_btn_label_close')}
                                variant={'secondary'}
                                className={'w-auto '}
                            />
                        )}
                    </div>
                    <div className={'font-go-text flex-1 flex overflow-y-auto'}>{children}</div>
                    {(!!overline || !!buttonTarget) && (
                        <div
                            className={clsx(
                                'flex justify-between items-center px-6 py-4 border-t border-gray-200',
                                !overline && 'flex flex-col w-full text-center items-center justify-center',
                                blockFooterShapeClass,
                            )}
                        >
                            {!!overline && (
                                <div className={'font-go-text text-lg text-center p-0.5 font-bold'}>{overline}</div>
                            )}
                            {!!buttonTarget && !!buttonLabel && (
                                <Button target={buttonTarget as any} label={buttonLabel} className={'w-auto '} />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export interface Modal2Props extends WithChildren, WithOnClose, WithOverline, WithTitle, WithButton {
    opened?: boolean;
    closable?: flag;
    size?: 'sm' | 'md' | 'lg';
}

// noinspection JSUnusedGlobalSymbols
export default Modal2;
