import { ad_zones_context_value, ad_zones_settings_context_value } from './types';
import { AdZonesSettingsContextProvider } from './contexts/AdZonesSettingsContext';
import { AdZonesContextProvider } from './contexts/AdZonesContext';

export function AdZonesProvider({ settings, components, children }: AdZonesProviderProps) {
    let content = children;
    components && (content = <AdZonesContextProvider value={components}>{content}</AdZonesContextProvider>);
    settings && (content = <AdZonesSettingsContextProvider value={settings}>{content}</AdZonesSettingsContextProvider>);

    return content;
}

export interface AdZonesProviderProps {
    settings?: ad_zones_settings_context_value;
    components?: ad_zones_context_value;
    children?: any;
}

// noinspection JSUnusedGlobalSymbols
export default AdZonesProvider;
