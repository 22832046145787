import { useMemo } from 'react';
// noinspection ES6PreferShortImport
import { getWorkflowNextStep } from '../services/workflow';
import { workflows, workflow_settings, workflow_step, workflow_step_cases } from '../types';
import useQueryString from './useQueryString';

export function useWorkflowStep(
    workflows: workflows,
    name: string,
    currentStep: string,
    settings: workflow_settings,
): { nextStep: workflow_step | workflow_step_cases | undefined } {
    const query = useQueryString();
    return useMemo<{ nextStep: workflow_step | workflow_step_cases | undefined }>(
        () => ({ nextStep: getWorkflowNextStep(workflows, name, currentStep, { query, ...(settings || {}) }) }),
        [name, currentStep, settings, query],
    );
}

export default useWorkflowStep;
