import { useMemo } from 'react';
import clsx from 'clsx';
import Img from '../../atoms/Img';
import Text from '../../atoms/Text';
import { WithName, WithValue } from '../../withs';
import { image } from '@gotombola/types/lib/types/base';
import ClickableContent from '../../atoms/ClickableContent';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function ListKeyfigureItem({
    name,
    value,
    logoImage,
    thumbnailImage,
    mainImage,
    websiteUrl,
}: ListKeyfigureItemProps) {
    const { blockShapeClass } = useStyling();

    const image = useMemo(
        () => [logoImage, thumbnailImage, mainImage].find((x) => x?.url),
        [logoImage, thumbnailImage, mainImage],
    );

    if (!name && !value) return null;

    return (
        <ClickableContent url={websiteUrl} mode={'_blank'} className={'w-full sm:w-[240px]'}>
            <div
                className={clsx(
                    'flex flex-col justify-center items-center w-full sm:w-[240px] bg-go-secondary hover:bg-go-primary p-4 sm:p-6 text-white',
                    blockShapeClass,
                )}
            >
                {(!!value || !!image?.url) && (
                    <div className={'w-full flex items-center justify-center gap-2'}>
                        {!!value && (
                            <Text bold center className={'text-5xl text-inherit'}>
                                {value}
                            </Text>
                        )}
                        {!!image?.url && (
                            <Img
                                maxSize={225}
                                className={'max-w-[100px] max-h-[36px]'}
                                url={image.url!}
                                alt={image.alt || name || 'keyfigure'}
                            />
                        )}
                    </div>
                )}
                {!!name && (
                    <Text bold center className={'text-inherit'}>
                        {name}
                    </Text>
                )}
            </div>
        </ClickableContent>
    );
}

export interface ListKeyfigureItemProps extends WithName, WithValue {
    websiteUrl?: string;
    logoImage?: image;
    thumbnailImage?: image;
    mainImage?: image;
}

// noinspection JSUnusedGlobalSymbols
export default ListKeyfigureItem;
