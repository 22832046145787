import censureEmailContent from './censureEmailContent';
import censurePhoneContent from './censurePhoneContent';

export function censureContent(content: string, type: string, obfuscate = true) {
    if (!content) return '-';
    if (!obfuscate) return content;
    switch (type) {
        case 'email':
            return censureEmailContent(content);
        case 'phone':
            return censurePhoneContent(content);
        default:
            return content;
    }
}

export default censureContent;
