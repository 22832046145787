import { ReactNode, useEffect } from 'react';
import { UserContextProvider } from '@gotombola/react-contexts/lib/contexts/UserContext';
import { StorageContextProvider } from '@gotombola/react-contexts/lib/contexts/StorageContext';
import ErrorBoundary from '@gotombola/react-error-boundary/lib/ErrorBoundary';
import { DesignerContextProvider } from '@gotombola/react-modules-engine/lib/contexts/DesignerContext';
import { useLocation } from 'react-router';

import jwtDecode from 'jwt-decode';
import useQueryString from '../hooks/useQueryString';
function decodeAuth(auth: string) {
    return jwtDecode(auth) as any;
}
export function AppProvider({ user, storage, designer, children }: AppProviderProps) {
    const qs = useQueryString();
    const auth = qs.get('auth');
    const location = useLocation();

    useEffect(() => {
        if (auth) {
            const decoded = decodeAuth(auth);
            if (decoded?.user?.id !== !user?.user?.id) {
                user?.setUser(decoded?.user);
            }
            qs.delete('auth');
            const qss = qs.toString();
            location?.replace(`${location.pathname}${qss ? `?${qss}` : ''}`);
            return;
        }
    }, [auth, qs, user?.user?.id, location]);

    return (
        <ErrorBoundary>
            <DesignerContextProvider value={designer}>
                <StorageContextProvider value={storage}>
                    <UserContextProvider value={user}>{children || ''}</UserContextProvider>
                </StorageContextProvider>
            </DesignerContextProvider>
        </ErrorBoundary>
    );
}

export interface AppProviderProps {
    storage: any;
    user: any;
    designer: any;
    children?: ReactNode;
}

// noinspection JSUnusedGlobalSymbols
export default AppProvider;
