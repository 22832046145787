import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { WithItems, WithTitle } from '../../../withs';
import Steps from '../../../organisms/Steps';

export function StandardServiceStepsPartnerModule({ partner, ...rest }: StandardServiceStepsPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <Steps {...props} />
        </ModuleContainer>
    );
}

export interface StandardServiceStepsPartnerModuleProps extends WithItems, WithTitle {
    partner: any;
}

export default StandardServiceStepsPartnerModule;
