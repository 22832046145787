import buildI18nRoutes from './buildI18nRoutes';

// noinspection JSUnusedLocalSymbols
export function buildI18nRoutesFromConfigure(
    data,
    {
        routeMap,
        routeList,
        extraRouteList,
        pages,
    }: { routeMap?: any; routeList?: any; extraRouteList?: any; pages?: any } = {},
    options?: any,
    mode?: string,
) {
    return buildI18nRoutes(data, routeMap, routeList, extraRouteList, pages);
}

export default buildI18nRoutesFromConfigure;
