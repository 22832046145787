import loadable from '@loadable/component';
import { CampaignModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./footer/StandardFooterCampaignModule')),
};

export function FooterCampaignModule(props: FooterCampaignModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type FooterCampaignModuleProps = CampaignModuleProps;

export default FooterCampaignModule;
