import clsx from 'clsx';
import { WithClassName, WithText } from '../withs';
import Section, { SectionProps } from '../molecules/Section';
import Text from '../atoms/Text';

export function TextBlock({
    border = true,
    children,
    className,
    containerClassName,
    contentClassName,
    inverted = false,
    text,
    title,
}: TextBlockProps) {
    if (!title && !text && !children) return null;

    return (
        <Section className={className} title={title} inverted={inverted} border={border}>
            {(!!text || !!children) && (
                <div className={clsx('mx-4', containerClassName)}>
                    {!!text && (
                        <div className={clsx('text-md', contentClassName)}>
                            <Text light>{text}</Text>
                        </div>
                    )}
                    {children || ''}
                </div>
            )}
        </Section>
    );
}

export interface TextBlockProps extends SectionProps, WithClassName, WithText {
    containerClassName?: string;
    contentClassName?: string;
}

// noinspection JSUnusedGlobalSymbols
export default TextBlock;
