import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./footer/StandardFooterGameModule')),
    light: loadable(() => import('./footer/LightFooterGameModule')),
};

export function FooterGameModule(props: FooterGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type FooterGameModuleProps = GameModuleProps;

export default FooterGameModule;
