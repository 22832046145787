export function applyOverride(data: any, name: string, def: any) {
    const value = def?.value;
    if (null === value || undefined === value) return data;
    const key = def?.key || name;
    switch (def?.type) {
        case 'value':
            data[key] = value;
            break;
        case 'modules':
            if ('object' !== typeof value) break;
            const entries = Object.entries(value);
            if (!entries || !entries.length) break;
            data.publicPageModulesParams = { ...(data.publicPageModulesParams || {}) };
            entries.reduce((acc, [k, v]) => {
                acc[k] = { ...(acc[k] || {}) };
                if ('string' === typeof v) {
                    acc[k].type = v;
                } else {
                    if ('object' === typeof v) {
                        acc[k] = { ...acc[k], ...v };
                    }
                }
                return acc;
            }, data.publicPageModulesParams);
            break;
    }
    return data;
}

export default applyOverride;
