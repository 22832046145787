import { useMemo } from 'react';
import CallToAction from '../../../organisms/CallToAction';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import ModuleContainer from '../../../organisms/ModuleContainer';
import TypeListing, { TypeListingProps } from '../../../organisms/type/TypeListing';
import { WithZoneCustomization } from '../../../withs';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import Text from '../../../atoms/Text';

const defaultPrizes = [];

export function StandardPrizesGameModule({
    typeType,
    game,
    prizes = defaultPrizes,
    notitle = false,
    nolabel = false,
    nocontact = false,
    ...rest
}: StandardPrizesGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const items = useMemo(() => {
        if (!prizes || !prizes.length) return [];
        const bunches = ((game.rankedBunches || {}).items || []).reduce((acc: any, b: any) => {
            acc[b.id] = b;
            return acc;
        }, {} as any);

        return prizes.map((x: any) => ({ ...bunches[x.bunch], ...x }));
    }, [game.rankedBunches, prizes]);

    if (!items?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeListing
                title={notitle ? undefined : t('game_won_prices_label')}
                {...props}
                type={typeType}
                items={items}
                sponsorings={props.doc?.sponsorings}
                withRank={false}
                withQuantities={false}
                itemType={'prize'}
                {...(nolabel ? { nolabel: true } : {})}
            />
            {!nocontact && (
                <>
                    {!!game.publicEmail && <Text center>{t('game_won_prices_advice')}</Text>}
                    {!!game.publicEmail && (
                        <CallToAction
                            target={`mailto:${game.publicEmail}?subject=${t('prizes_email_contact_title', {
                                count: items?.length,
                                gameName: game?.name,
                                gameCode: game?.code,
                            })}`}
                            label={t('game_won_prices_cta_label')}
                        />
                    )}
                </>
            )}
        </ModuleContainer>
    );
}

export interface StandardPrizesGameModuleProps extends TypeListingProps, WithZoneCustomization {
    game: any;
    prizes?: any[];
    typeType: string;
    notitle?: boolean;
    nolabel?: boolean;
    nocontact?: boolean;
}

export default StandardPrizesGameModule;
