import { WithImage, WithTitle } from '../withs';
import Img from './Img';
import Text from './Text';

export function CommonContentItem({ image, title }: CommonContentItemProps) {
    return (
        <div className={'w-full flex flex-col justify-center items-center gap-4 px-10'}>
            {!!image?.url && (
                <Img
                    className={'rounded-full max-w-[200px] object-contain mx-auto'}
                    alt={image.alt || 'image item'}
                    {...image}
                    maxSize={200}
                />
            )}
            {!!title && (
                <Text bold center className={'max-w-[200px] text-lg'}>
                    {title}
                </Text>
            )}
        </div>
    );
}

export interface CommonContentItemProps extends WithImage, WithTitle {}

// noinspection JSUnusedGlobalSymbols
export default CommonContentItem;
