import VideoModule, { VideoModuleProps } from '../../../modules/VideoModule';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function PlayerVideoTypeModule({ doc, typeType, ...rest }: PlayerVideoTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const videoUrl =
        doc?.mainVideoUrl ||
        doc?.mainShortVideoUrl ||
        doc?.mainLongVideoUrl ||
        doc?.organizationMainVideoUrl ||
        doc?.organizationMainShortVideoUrl ||
        doc?.organizationMainLongVideoUrl;
    if (!videoUrl) return null;

    return (
        <ModuleContainer {...zprops}>
            <VideoModule url={videoUrl} type={'player'} {...props} />
        </ModuleContainer>
    );
}

export interface PlayerVideoTypeModuleProps extends VideoModuleProps {
    doc: any;
    typeType: string;
}

export default PlayerVideoTypeModule;
