import OrganizationHeader from '../../../molecules/headers/OrganizationHeader';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardHeaderOrganizationModule({ organization, ...rest }: StandardHeaderOrganizationModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <OrganizationHeader {...props} organization={organization} />
        </ModuleContainer>
    );
}

export interface StandardHeaderOrganizationModuleProps {
    organization: any;
}

export default StandardHeaderOrganizationModule;
