import ModuleContainer from '../../../organisms/ModuleContainer';
import ThumbnailBunchItem from '../../../molecules/bunches/ThumbnailBunchItem';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypeListing, { TypeListingProps } from '../../../organisms/type/TypeListing';
import DefaultModuleSectionItemList from '../../../molecules/DefaultModuleSectionItemList';
import useTypeUrl from '../../../hooks/useTypeUrl';

const listProps = {
    defaultCols: 0,
    defaultMsVariant: 'basic',
};

export function ThumbnailsBunchesGameModule({ ...rest }: ThumbnailsBunchesGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', props.game);
    const isRunning = 'RUNNING' === props.game?.status;
    const buyUrl = useTypeUrl('game', props.game, 'buy');

    if (!props.game?.rankedBunches?.items?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeListing
                title={t(`game_prices_label`, { count: props.game?.rankedBunches.items.length })}
                {...props}
                doc={props.game}
                type={'game'}
                items={props.game?.rankedBunches.items}
                itemType={'bunch'}
                listComponent={DefaultModuleSectionItemList}
                listProps={listProps}
                itemComponent={ThumbnailBunchItem}
                sponsorings={props.game?.sponsorings}
                target={isRunning ? buyUrl : undefined}
            />
        </ModuleContainer>
    );
}

export interface ThumbnailsBunchesGameModuleProps extends Omit<TypeListingProps, 'type'> {
    game?: any;
}

export default ThumbnailsBunchesGameModule;
