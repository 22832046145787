import { useMemo } from 'react';
import TypeListing, { TypeListingProps } from '../../../organisms/type/TypeListing';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const listProps = {
    defaultMsVariant: 'bordered',
};

const matcher = (doc: any) => !!doc?.sponsorings?.length;

export function ListSponsorsTypeModule({ typeType, title: forcedTitle, ...rest }: ListSponsorsTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    const { t } = useTypeTranslation(typeType, doc);

    const items = useMemo(
        () => doc?.sponsorings?.items?.map((x) => ({ ...x, overline: x.description })),
        [doc?.sponsorings?.items],
    );

    if (!items?.length) return null;

    const title = forcedTitle || t(`${typeType || 'generic'}_sponsorings_label`);

    return (
        <ModuleContainer {...zprops}>
            <TypeListing
                title={title}
                {...props}
                type={type}
                doc={doc}
                items={items}
                itemType={'sponsor'}
                withRank={false}
                listProps={listProps}
            />
        </ModuleContainer>
    );
}

export interface ListSponsorsTypeModuleProps extends Omit<TypeListingProps, 'type'> {
    typeType: string;
}

export default ListSponsorsTypeModule;
