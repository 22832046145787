import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function SponsorTemplate({ sponsor, page = 'sponsor_public', ...props }: SponsorTemplateProps) {
    return <TypeTemplate type={'sponsor'} page={page.replace(/^sponsor_/, '')} doc={sponsor} {...props} />;
}

export interface SponsorTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    sponsor?: any;
}

export default SponsorTemplate;
