import { useMemo } from 'react';
import TextDataField from '../data-fields/TextDataField';
import TextareaDataField from '../data-fields/TextareaDataField';
import PriceDataField from '../data-fields/PriceDataField';
import SelectDataField from '../data-fields/SelectDataField';
import ColorDataField from '../data-fields/ColorDataField';
import TextureDataField from '../data-fields/TextureDataField';
import VariantDataField from '../data-fields/VariantDataField';
import OptionsDataField from '../data-fields/OptionsDataField';
import ModuleDataField from '../data-fields/ModuleDataField';
import QuantityDataField from '../data-fields/QuantityDataField';

const modulePropMarshall = (name: string, path: string) => (data: any) => data?.publicPageModulesParams?.[name]?.[path];
const modulePropUnmarshall = (name: string, path: string) => (value: any, data: any) => ({
    ...data,
    publicPageModulesParams: {
        ...data.publicPageModulesParams,
        [name]: {
            ...data.publicPageModulesParams?.[name],
            [path]: value,
        },
    },
});

const dataFields = {
    text: { component: TextDataField },
    textarea: { component: TextareaDataField },
    color: { component: ColorDataField },
    texture: { component: TextureDataField },
    select: { component: SelectDataField },
    price: { component: PriceDataField },
    quantity: { component: QuantityDataField },
    variant: { component: VariantDataField },
    options: { component: OptionsDataField },
    module: {
        component: ModuleDataField,
        props: ({ name }: { name: string }) => ({
            marshall: modulePropMarshall(name, 'visible'),
            unmarshall: modulePropUnmarshall(name, 'visible'),
        }),
    },
};

export function useDataField(type: string) {
    return useMemo(() => dataFields[type || ''] || undefined, [type]);
}

export default useDataField;
