import useQueryString from './useQueryString';
import { useMemo } from 'react';
import applyOverrides from '../utils/applyOverrides';
import parseQs from '@genstackio/qso';

const overridables: Record<string, any> = {
    effects: { type: 'value', key: 'publicPageEffects' },
    msvariant: { type: 'value', key: 'publicPageModulesVariant' },
    styling: { type: 'value', key: 'publicPageStyling' },
    bgcolor: { type: 'value', key: 'publicPageBackgroundColor' },
    domain: { type: 'value', key: 'domain' },
    modules: { type: 'modules' },
};

export function useQueryPageModifiers(data: any) {
    const x = useQueryString();

    const model = useMemo(() => parseQs(x), [x]);
    const overrides = useMemo(
        () =>
            Object.entries(overridables).reduce(
                (acc, [k, def]) => Object.assign(acc, { [k]: { ...def, value: model[k] } }),
                {} as any,
            ),
        [model],
    );

    return useMemo(() => applyOverrides(data, overrides), [data, overrides]);
}

export default useQueryPageModifiers;
