import Banner from '../../../organisms/Banner';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { WithImage } from '@gotombola/react-types';

export function StandardBannerCommonModule({ image, ...rest }: StandardBannerCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    if (!image?.url) return null;

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <Banner disposition={'full-auto'} image={image} {...props} />
        </ModuleContainer>
    );
}

export type StandardBannerCommonModuleProps = WithImage;

export default StandardBannerCommonModule;
