import { useCallback, useMemo } from 'react';
import useTypedFieldProps from '../hooks/useTypedFieldProps';
import useDataField from '../hooks/useDataField';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import useEditModeContext from '@gotombola/react-contexts/lib/hooks/useEditModeContext';

const defaultFeatures = [];
const defaultEnabledFeatures = [];

function isDataFieldVisible({
    data,
    enabledFeatures = defaultEnabledFeatures,
    features: requiredFeatures = defaultFeatures,
}: {
    data?: any;
    enabledFeatures?: string[];
    features?: string[];
}) {
    if (!requiredFeatures?.length) return true;

    const currentFeatures = [...(data?.features || []), ...enabledFeatures];

    return !!requiredFeatures?.find((f) => currentFeatures?.includes(f));
}

export function DataField(props: DataFieldProps) {
    let {
        type = 'text',
        name,
        data,
        dataKey,
        onChange,
        label,
        description,
        placeholder,
        helper,
        autoFocus = false,
        required = false,
        disabled = false,
        marshall,
        unmarshall,
        css,
        // @ts-ignore
        enabledFeatures,
        ...rest
    } = props;
    const { t } = useTrans();
    const { component: Comp, props: commonPropsFn } = useDataField(type);
    if (commonPropsFn) {
        const commonProps = commonPropsFn(props);
        marshall = marshall || commonProps?.marshall;
        unmarshall = unmarshall || commonProps?.unmarshall;
        css = css || commonProps?.css;
    }
    const value = useMemo(() => {
        const x = data?.[dataKey || name || ''] || undefined;
        return marshall ? marshall(data, x) : x;
    }, [data, dataKey, name, marshall]);

    const handleChange = useCallback(
        (e: any) => {
            const x = e.target.value;
            const finalValue = unmarshall
                ? unmarshall(x, data, dataKey || name)
                : { ...data, [dataKey || name || '']: e.target.value };
            if (css) {
                finalValue.__css = finalValue.__css || {};
                (css as any) === true && (css = (value: any) => value);
                finalValue.__css[name] = css(x, finalValue, data);
                !finalValue.__css[name] && delete finalValue.__css[name];
            }
            data && onChange(finalValue);
        },
        [data, dataKey, name, onChange, unmarshall, css],
    );

    const rawField = useMemo(
        () => ({
            name,
            onChange: handleChange,
            value,
            placeholder: placeholder || '',
            autoFocus,
            required,
            disabled,
        }),
        [name, handleChange, value, placeholder, autoFocus, required, disabled],
    );

    const { register, control } = useEditModeContext();

    const field = useTypedFieldProps(type, rawField);

    if (!isDataFieldVisible(props)) return null;

    return (
        <fieldset className={'space-y-4'}>
            {!!label && <label className={'font-bold'}>{t(label)}</label>}
            {!!description && <p className={'text-gray-400 text-sm font-normal'}>{t(description)}</p>}
            {!!Comp && <Comp {...rest} {...field} register={register} control={control} allData={data} />}
            {!!helper && <p className={'text-gray-400 text-xs font-normal'}>{t(helper)}</p>}
        </fieldset>
    );
}

export interface DataFieldProps {
    type?: string;
    name: string;
    data: any;
    dataKey?: string;
    onChange: Function;
    label?: string;
    helper?: string;
    description?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    marshall?: Function;
    unmarshall?: Function;
    css?: Function;
}

export default DataField;
