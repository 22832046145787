import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(
        () => import('./serviceAutomaticEmailsScreenshots/StandardServiceAutomaticEmailsScreenshotsPartnerModule'),
    ),
};

export function ServiceAutomaticEmailsScreenshotsPartnerModule(
    props: ServiceAutomaticEmailsScreenshotsPartnerModuleProps,
) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ServiceAutomaticEmailsScreenshotsPartnerModuleProps = PartnerModuleProps;

export default ServiceAutomaticEmailsScreenshotsPartnerModule;
