import { useMemo } from 'react';
import RichTextPartSpan from './RichTextPartSpan';
import MultiLineText from './MultiLineText';
import { WithClassName, WithText, WithType } from '../withs';

export function RichTextPart({ className, spans = [], text, type }: RichTextPartProps) {
    const ss = useMemo(() => {
        let i = 0;
        const ss: any = [];
        while (spans.length) {
            const s = spans.shift();
            if (s.start === i) {
                ss.push(s);
                i = s.end;
            } else {
                ss.push({ start: i, end: s.start });
                ss.push(s);
                i = s.end;
            }
        }
        if (i < (text?.length || 0) - 1) {
            ss.push({ start: i, end: text?.length });
        }
        return ss;
    }, [spans, text]);
    if (!text) return null;
    if (!spans || !spans.length) return <MultiLineText text={text} className={className} basic={false} />;
    const children = ss.map((s: any, i: number) => (
        <RichTextPartSpan key={i} text={text} className={className} {...s} />
    ));

    switch (type) {
        case 'paragraph':
            return <p>{children}</p>;
        case 'span':
            return <span>{children}</span>;
        default:
            return <>{children}</>;
    }
}

export interface RichTextPartProps extends WithClassName, WithText<string>, WithType {
    spans?: any[];
}

export default RichTextPart;
