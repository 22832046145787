import { useMemo } from 'react';
import clsx from 'clsx';
import { TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import Img from '../../atoms/Img';

const dispositions = {
    default: 'object-cover w-full h-full',
    'full-cover': 'object-cover w-full h-full',
    'full-contain': 'object-contain w-full h-full',
    'full-auto': 'w-full h-auto',
};

const maxModes = {
    default: {
        maxSize: 1024,
    },
    xs: {
        maxSize: 640,
    },
    sm: {
        maxSize: 860,
    },
    md: {
        maxSize: 1024,
    },
    lg: {
        maxSize: 1280,
        maxSizeMobile: 1024,
        maxSizeTablet: 1024,
    },
    xl: {
        maxSize: 1536,
        maxSizeMobile: 1024,
        maxSizeTablet: 1280,
    },
    '2xl': {
        maxSize: 1792,
        maxSizeMobile: 1024,
        maxSizeTablet: 1536,
    },
    '3xl': {
        maxSize: 2048,
        maxSizeMobile: 1280,
        maxSizeTablet: 1536,
    },
    '4xl': {
        maxSize: 2304,
        maxSizeMobile: 1280,
        maxSizeTablet: 1792,
    },
    '5xl': {
        maxSize: 2560,
        maxSizeMobile: 1280,
        maxSizeTablet: 1792,
    },
    '6xl': {
        maxSize: 2816,
        maxSizeMobile: 1280,
        maxSizeTablet: 1792,
    },
    '7xl': {
        maxSize: 3072,
        maxSizeMobile: 1280,
        maxSizeTablet: 2048,
    },
};

export function TypeMiniBanner({
    className,
    disposition = 'default',
    doc,
    maxMode = undefined,
    maxSize: forcedMaxSize = undefined,
    maxSizeMobile: forcedMaxSizeMobile = undefined,
    maxSizeTablet: forcedMaxSizeTablet = undefined,
    rounded = true,
    type,
}: TypeMiniBannerProps) {
    const { t } = useTypeTranslation(type, doc);
    const { maxSize, maxSizeMobile, maxSizeTablet } = useMemo(
        () => ({
            ...(maxModes[maxMode || ''] || maxModes['default']),
            ...(forcedMaxSize ? { maxSize: forcedMaxSize } : {}),
            ...(forcedMaxSizeMobile ? { maxSizeMobile: forcedMaxSizeMobile } : {}),
            ...(forcedMaxSizeTablet ? { maxSizeTablet: forcedMaxSizeTablet } : {}),
        }),
        [maxMode, forcedMaxSize, forcedMaxSizeMobile, forcedMaxSizeTablet],
    );
    const image = useMemo(
        () =>
            [
                doc?.bannerImage,
                doc?.organizationBannerImage,
                doc?.partnerBannerImage,
                doc?.campaignBannerImage,
                doc?.tenantBannerImage,
            ].find((x) => !!x?.url),
        [doc],
    );

    return (
        <div className={clsx('w-full', rounded && 'rounded overflow-hidden', className)}>
            {!!image?.url && (
                <Img
                    lazy={false}
                    className={clsx(dispositions[disposition || ''] || dispositions['default'])}
                    url={image.url}
                    alt={t(image.alt || 'banner')}
                    maxMode={maxMode}
                    maxSize={maxSize}
                    maxSizeMobile={maxSizeMobile}
                    maxSizeTablet={maxSizeTablet}
                />
            )}
        </div>
    );
}

export interface TypeMiniBannerProps extends TypeProps, WithClassName {
    disposition?: string;
    rounded?: boolean;
    imageClassName?: string;
    maxMode?: string;
    maxSize?: number;
    maxSizeTablet?: number;
    maxSizeMobile?: number;
}

// noinspection JSUnusedGlobalSymbols
export default TypeMiniBanner;
