import { ComponentType, useMemo, useState } from 'react';
import { CustomModuleModesContextProvider } from '@gotombola/react-contexts/lib/contexts/CustomModuleModesContext';
import { DynamicPageContextProvider } from '@gotombola/react-contexts/lib/contexts/DynamicPageContext';
import { FacebookPixelContextProvider } from '@gotombola/react-contexts/lib/contexts/FacebookPixelContext';
import { ModulesContextProvider } from '@gotombola/react-contexts/lib/contexts/ModulesContext';
import { TemplateContextProvider } from '@gotombola/react-contexts/lib/contexts/TemplateContext';
import { dynamic_page_config, dynamic_page_context } from '../types';
import { WithChildren, WithClassName, WithNoindex } from '../withs';
import DynamicPageTemplate from '../organisms/DynamicPageTemplate';
import FacebookPixel from '../atoms/FacebookPixel';
import DynamicPageHead from '../organisms/DynamicPageHead';
import UnknownTemplate from '../templates/UnknownTemplate';
import { WorkflowsContextProvider } from '@gotombola/react-contexts/lib/contexts/WorkflowsContext';
import { ActionsContextProvider } from '@gotombola/react-contexts/lib/contexts/ActionsContext';
import clsx from 'clsx';

const defaultConfig = {};
const defaultTemplates = {
    unknown: UnknownTemplate,
    default: UnknownTemplate,
};
const DefaultTemplateComponent = UnknownTemplate;

export function DynamicPage({
    children,
    config = defaultConfig,
    context = {},
    customModes = undefined,
    modules = undefined,
    noindex,
    templates = defaultTemplates,
    workflows,
    actions,
    className,
}: DynamicPageProps) {
    const [state, setState] = useState<{ step: string; data: Record<string, any> }>({ step: 'initial', data: {} });
    const dynamicPageContextValue = useMemo(() => {
        const updateData = (data: any) => {
            setState({ ...state, data: { ...state.data, ...data } });
        };
        const set = (key: string, value: any) => {
            updateData({ [key]: value });
        };
        const setStep = (name: string) => {
            setState({ ...state, step: name });
        };
        return { ...state, updateData, set, setStep, context };
    }, [state, setState, context]);
    const templateContextProvider = useMemo(() => {
        const getTemplateComponent = (name: string) => {
            return (templates && (templates[name] || templates['unknown'])) || DefaultTemplateComponent;
        };
        return { getTemplateComponent };
    }, [templates, DefaultTemplateComponent]);
    const { template, locale, doc, type, ...props } = context;
    let content = (
        <TemplateContextProvider value={templateContextProvider}>
            <DynamicPageContextProvider value={dynamicPageContextValue}>
                <DynamicPageHead
                    lang={config.lang}
                    title={config.title}
                    metas={config.metas}
                    favicon={config.favicon}
                    appleTouchIcon={config.appleTouchIcon}
                    noindex={undefined !== noindex ? noindex : config.noindex}
                />
                <DynamicPageTemplate
                    type={template || (config || {}).template}
                    items={((config || {}).body || {}).items}
                    locale={locale}
                    {...props}
                    className={clsx(className, props?.className)}
                >
                    {children || ''}
                </DynamicPageTemplate>
            </DynamicPageContextProvider>
        </TemplateContextProvider>
    );
    if (customModes) {
        content = <CustomModuleModesContextProvider value={customModes}>{content}</CustomModuleModesContextProvider>;
    }
    if (modules) {
        content = <ModulesContextProvider value={modules}>{content}</ModulesContextProvider>;
    }
    const facebookPixelContextValue = useMemo(() => {
        if ((config || {}).facebookPixelId) {
            return {
                id: (config || {}).facebookPixelId,
                actions: ((config || {}).facebookPixelActions || []).map((a: any) => {
                    let theData: any;
                    try {
                        theData = JSON.parse(a.data);
                    } catch (e) {
                        theData = undefined;
                    }
                    return {
                        action: a.action,
                        name: a.name,
                        data: theData,
                    };
                }),
            };
        }
        return undefined;
    }, [config]);
    if (facebookPixelContextValue) {
        content = (
            <FacebookPixelContextProvider value={facebookPixelContextValue}>
                {content}
                <FacebookPixel />
            </FacebookPixelContextProvider>
        );
    }
    if (workflows) {
        content = <WorkflowsContextProvider value={workflows}>{content}</WorkflowsContextProvider>;
    }
    if (actions) {
        content = <ActionsContextProvider value={actions}>{content}</ActionsContextProvider>;
    }

    return content;
}

export interface DynamicPageProps extends WithClassName, WithChildren, WithNoindex {
    context: dynamic_page_context;
    templates?: Record<string, ComponentType<any>>;
    config: dynamic_page_config;
    customModes?: any;
    modules?: any;
    workflows?: any;
    actions?: any;
}

// noinspection JSUnusedGlobalSymbols
export default DynamicPage;
