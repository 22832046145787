import clsx from 'clsx';
import LeftHeadingModule from '../modules/headings/LeftHeadingModule';
import ClickableContent from '../atoms/ClickableContent';
import StaticIcon from '../atoms/StaticIcon';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { WithOverline, WithTarget, WithText, WithTitle } from '../withs';

export function CorporateHeading({ overline, text, title, target }: CorporateHeadingProps) {
    const { blockBackgroundClass, blockContentPaddingClass } = useStyling();

    return (
        <div
            className={clsx(
                'sm:flex justify-between items-center gap-6',
                blockContentPaddingClass,
                blockBackgroundClass,
            )}
        >
            <LeftHeadingModule overline={overline} title={title} titleSize={'lg'} text={text} className={'flex-1'} />
            {!!target && (
                <ClickableContent url={target}>
                    <div className={'border border-gray-100 rounded-full p-2 cursor-pointer'}>
                        <StaticIcon type={'share'} className={'mx-auto'} />
                    </div>
                </ClickableContent>
            )}
        </div>
    );
}

export interface CorporateHeadingProps extends WithOverline, WithTarget, WithText, WithTitle {}

export default CorporateHeading;
