import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from '@tsparticles/engine';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        fpsLimit: 40,
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            number: {
                value: 200,
                density: {
                    enable: true,
                },
            },
            color: {
                value: ['#fdcf58', '#757676', '#f27d0c', '#800909', '#f07f13'],
            },
            opacity: {
                value: { min: 0.1, max: 0.9 },
            },
            size: {
                value: { min: 5, max: 15 },
            },
            move: {
                enable: true,
                speed: 6,
                random: false,
            },
        },
        interactivity: {
            detectsOn: 'window',
            events: {
                onClick: {
                    enable: true,
                    mode: 'push',
                },
            },
        },
    };
};
