function isEmailValid(v: string) {
    return /^[^@\s]+@[^@\s]+\.[^@\s.]+$/.test(v);
}
function isPhoneValid(v: string) {
    if (/^[0-9\-+]{4,20}$/.test(v)) return true;
    if (v.length === 10 && v.slice(0, 1) === '0' && !isNaN(Number(v))) return true;
    return (v.length === 12 || v.length === 13) && v.slice(0, 1) === '+' && !isNaN(Number(v));
}

export function isOwnerValid(owner: string): boolean {
    if (!owner) return false;
    const v = owner.replace(/\s+/g, '');
    return v.includes('@') ? isEmailValid(v) : isPhoneValid(v);
}

export default isOwnerValid;
