import { WithItems } from '../withs';
import Text from './Text';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import clsx from 'clsx';

const defaultItems: any[] = [];

export function Table({ items = defaultItems }: TableProps) {
    const { blockShapeClass } = useStyling();

    return (
        <div className={clsx('w-full border border-primary', blockShapeClass)}>
            {items.map(({ label, value }, index) => {
                return (
                    <div key={index} className={'w-full p-2 border border-b flex justify-between'}>
                        <Text className={'mb-2 text-lg'}>{label}</Text>
                        <Text light className={'mb-2 text-lg'}>
                            {value || ''}
                        </Text>
                    </div>
                );
            })}
        </div>
    );
}

export type TableProps = WithItems;

// noinspection JSUnusedGlobalSymbols
export default Table;
