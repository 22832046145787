import { useMemo } from 'react';
import useTypeImages from '../../../hooks/useTypeImages';
import TypeImages, { TypeImagesProps } from '../../../organisms/type/TypeImages';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any, type: string) => !!doc[`${(type || '').slice(0, 1)}images`]?.items?.length;

export function ThumbnailsImagesTypeModule({
    size,
    sort = 'rank',
    tag = undefined,
    typeType,
    ...rest
}: ThumbnailsImagesTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    const images1 = useTypeImages(type, doc, sort, tag);
    const itemProps = useMemo(() => ({ size }), [size]);

    if (!images1?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeImages {...props} type={type} doc={doc} items={images1} itemProps={itemProps} />
        </ModuleContainer>
    );
}

export interface ThumbnailsImagesTypeModuleProps extends Omit<TypeImagesProps, 'type'> {
    typeType: string;
    size?: string;
    sort?: string;
    tag?: string;
}

export default ThumbnailsImagesTypeModule;
