import { createContext } from 'react';
import { user_context_value } from '../types';
import createDefaultUserContextValue from '../utils/createDefaultUserContextValue';

export const UserContext = createContext<user_context_value>(createDefaultUserContextValue());
// noinspection JSUnusedGlobalSymbols
export const UserContextProvider = UserContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const UserContextConsumer = UserContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default UserContext;
