import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function TenantTemplate({ page = 'tenant_public', tenant, ...props }: TenantTemplateProps) {
    return <TypeTemplate type={'tenant'} page={page.replace(/^tenant_/, '')} doc={tenant} {...props} />;
}

export interface TenantTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    tenant?: any;
}

export default TenantTemplate;
