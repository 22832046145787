import loadable from '@loadable/component';
import { CommonModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./tenantFooter/StandardTenantFooterCommonModule')),
};

export function TenantFooterCommonModule(props: TenantFooterCommonModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type TenantFooterCommonModuleProps = CommonModuleProps;

export default TenantFooterCommonModule;
