import { useMemo } from 'react';
import clsx from 'clsx';
import cleanText from '../utils/cleanText';
import SectionTitle from '../atoms/SectionTitle';
import SectionSubtitle from '../atoms/SectionSubtitle';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { WithChildren, WithClassName, WithInverted, WithTitle, WithSubtitle } from '../withs';

export function Section({
    border = true,
    children,
    className,
    inverted = false,
    title: rawTitle,
    titleStyling,
    subtitle: rawSubtitle,
    subtitleStyling,
    nobg = false,
}: SectionProps) {
    const {
        blockShapeClass,
        blockHeaderShapeClass,
        blockFooterShapeClass,
        blockHeaderPaddingClass,
        blockContentPaddingClass,
        blockHeaderBorderClass,
        blockBorderClass,
        blockBorderColorClass,
        titleTextColorClass,
        invertedTitleTextColorClass,
        invertedBlockHeaderBackgroundClass,
        blockHeaderBackgroundClass,
        blockBackgroundClass,
        blockHeaderTextSizeClass,
        blockContentTextSizeClass,
    } = useStyling();

    const title = useMemo(() => cleanText(rawTitle), [rawTitle]);
    const subtitle = useMemo(() => cleanText(rawSubtitle), [rawSubtitle]);

    if (!title && !children) return null;

    return (
        <div className={clsx('w-full', blockShapeClass, className, !nobg && blockBackgroundClass)}>
            {title && (
                <div
                    className={clsx(
                        blockHeaderTextSizeClass,
                        blockHeaderShapeClass,
                        border && blockHeaderBorderClass,
                        border && blockHeaderPaddingClass,
                        border && blockBorderColorClass,
                        inverted ? invertedBlockHeaderBackgroundClass : blockHeaderBackgroundClass,
                    )}
                >
                    <div
                        className={clsx(
                            'text-center sm:text-left',
                            inverted ? invertedTitleTextColorClass : titleTextColorClass,
                        )}
                    >
                        <SectionTitle
                            title={title}
                            titleStyling={titleStyling}
                            basic
                            size={'text'}
                            bold={false}
                            center={false}
                            nocolor={inverted}
                        />
                        <SectionSubtitle
                            size={'smalltext'}
                            center={false}
                            subtitle={subtitle}
                            subtitleStyling={subtitleStyling}
                        />
                    </div>
                </div>
            )}
            <div
                className={clsx(
                    'flex flex-col space-y-3',
                    blockContentTextSizeClass,
                    border && blockContentPaddingClass,
                    border && blockBorderClass,
                    border && blockBorderColorClass,
                    !!title ? blockFooterShapeClass : blockShapeClass,
                )}
            >
                {children || ''}
            </div>
        </div>
    );
}

export interface SectionProps extends WithChildren, WithClassName, WithInverted, WithTitle, WithSubtitle {
    withImg?: boolean;
    border?: boolean;
    nobg?: boolean;
    titleStyling?: string;
    subtitleStyling?: string;
}

// noinspection JSUnusedGlobalSymbols
export default Section;
