import useTypeTranslation from '../../../hooks/useTypeTranslation';
import ModuleContainer from '../../../organisms/ModuleContainer';
import { WithZoneCustomization } from '../../../withs';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import ModuleSection from '../../../organisms/ModuleSection';
import CollectModeItem from '../../../molecules/CollectModeItem';
import { useMemo } from 'react';
import { image } from '@gotombola/types';
import clsx from 'clsx';

type prize_detail = {
    bunch: string;
    winner: string;
    ticket: string;
    ticketCode: string;
    ticketCustomCode?: string;
    bunchCode: string;
    bunchName: string;
    bunchMainImage?: image;
    rank: number;
    bunchRank: number;
};

export function StandardCollectModeGameModule({ game, presult, ...rest }: StandardCollectModeGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const phone = game?.collectModePhone || game?.organizationOwnerPhone || undefined;
    const email = game?.collectModeEmail || game?.organizationOwnerEmail || undefined;

    const presultBunches: prize_detail[] = (presult?.wonPrizesDetails?.prizes as prize_detail[] | undefined) || [];
    const gameBunches = useMemo(
        () =>
            game?.rankedBunches?.items.reduce((acc: any, bunch: any) => {
                acc = { ...acc, [bunch?.id || '']: bunch };
                return acc;
            }, {}) || {},
        [game?.rankedBunches?.items],
    );
    const filteredBunches: any[] =
        presultBunches
            ?.map((prize: prize_detail) => gameBunches[prize?.bunch || ''])
            ?.filter(
                (x) =>
                    !!x && (x.collectModeMessage || x.collectModeEmail || x.collectModePhone || x.collectModes?.length),
            ) || [];

    if (!presultBunches?.length || (!game?.collectModeMessage && !phone && !email && !filteredBunches?.length))
        return null;

    return (
        <ModuleContainer {...zprops}>
            <ModuleSection
                title={t('collect_mode_game_title', { count: presultBunches?.length })}
                defaultVariant={'bordered'}
                className={'flex flex-col gap-4'}
                {...props}
            >
                <div className={clsx('flex flex-col px-4 text-center sm:text-left gap-y-10 pb-4')}>
                    {!!filteredBunches?.length &&
                        filteredBunches?.map((bunch: any, i: number) => (
                            <CollectModeItem
                                key={i}
                                title={bunch?.name}
                                text={
                                    (!!bunch?.collectModeEmail &&
                                        t('presult_page_collect_mode_contact_email', {
                                            email: bunch?.collectModeEmail,
                                        })) ||
                                    undefined
                                }
                                text2={
                                    (!!bunch?.collectModePhone &&
                                        t('presult_page_collect_mode_contact_phone', {
                                            phone: bunch?.collectModePhone,
                                        })) ||
                                    undefined
                                }
                                message={bunch?.collectModeMessage}
                                buttonLabel={t('presult_page_collect_mode_email_contact_button_label')}
                                buttonTarget={
                                    bunch?.collectModeEmail &&
                                    `mailto:${bunch?.collectModeEmail}?subject=${t(
                                        'presult_page_collect_mode_email_contact_title',
                                        { count: presultBunches?.length, gameName: game?.name, gameCode: game?.code },
                                    )}`
                                }
                            />
                        ))}
                    {(!!game?.collectModeMessage || !!phone || !!email) && (
                        <CollectModeItem
                            title={
                                (!!filteredBunches?.length && t('presult_page_collect_mode_title_general')) || undefined
                            }
                            text={(!!email && t('presult_page_collect_mode_contact_email', { email })) || undefined}
                            text2={(!!phone && t('presult_page_collect_mode_contact_phone', { phone })) || undefined}
                            message={game?.collectModeMessage}
                            buttonLabel={t('presult_page_collect_mode_email_contact_button_label')}
                            buttonTarget={
                                (!!email &&
                                    `mailto:${email}?subject=${t('presult_page_collect_mode_email_contact_title', {
                                        count: presultBunches?.length,
                                        gameName: game?.name,
                                        gameCode: game?.code,
                                    })}`) ||
                                undefined
                            }
                        />
                    )}
                </div>
            </ModuleSection>
        </ModuleContainer>
    );
}

export interface StandardCollectModeGameModuleProps extends WithZoneCustomization {
    game: any;
    presult: any;
}

export default StandardCollectModeGameModule;
