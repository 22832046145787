import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';

const defaultValues: any[] = [];

export function VariantDataField({
    placeholder,
    onChange,
    emptyAllowed = true,
    values = defaultValues,
    allData,
    ...props
}: VariantDataFieldProps) {
    const { t } = useTrans();
    const selectedValues = useMemo(() => {
        let vals = [...values];
        vals = vals.map((x) => ({ ...x, label: x.label ? t(x.label) : x.label }));
        vals.sort((a: any, b: any) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
        emptyAllowed && vals.unshift({ value: '', label: t('data_field_variant_empty_label') });
        return vals;
    }, [values, emptyAllowed, t]);

    const handleClick = useCallback(
        (value) => (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            onChange && onChange({ target: { value } });
        },
        [onChange],
    );

    return (
        <div className={'flex flex-wrap gap-2'}>
            {selectedValues.map(({ value, label }: any, index: number) => (
                <div
                    key={index}
                    className={clsx(
                        'font-normal text-md p-2 flex items-center w-full border-4 rounded-sm hover:border-go-primary cursor-pointer',
                        !value && 'border-dotted',
                        value === props.value || (!value && !props.value)
                            ? 'border-go-primary bg-go-primary text-white'
                            : !value
                            ? ''
                            : 'bg-gray-100 border-transparent',
                    )}
                    onClick={handleClick(value)}
                >
                    {t(label)}
                </div>
            ))}
        </div>
    );
}

export interface VariantDataFieldProps {
    name: string;
    onChange: Function;
    value: any;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    emptyAllowed?: boolean;
    values?: any[];
    allData?: any;
    register: Function;
    control: Function;
}

// noinspection JSUnusedGlobalSymbols
export default VariantDataField;
