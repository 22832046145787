import clsx from 'clsx';
import { children, class_name } from '../types';
import { WithChildren, WithClassName } from '../withs';
import StaticLogo from '../atoms/StaticLogo';

export function AllInOneLayout({
    children,
    childrenClassName,
    className,
    headerChildren,
    headerChildrenClassName,
    variant = 'filled',
}: AllInOneLayoutProps) {
    return (
        <div className={clsx('w-full', className)}>
            <div
                className={clsx(
                    'flex justify-between items-center fixed h-20 w-screen p-6 space-x-6 z-10',
                    variant === 'contained' && 'bg-blue-500',
                    variant === 'filled' && 'bg-white',
                )}
            >
                <StaticLogo
                    type={variant === 'contained' ? 'gotombola-small-2' : 'gotombola-small-blue'}
                    object
                    className={'w-44'}
                />
                <div className={clsx('flex-1 flex flex-row space-x-12', headerChildrenClassName)}>
                    {headerChildren || ''}
                </div>
            </div>
            <div className={'h-16 mb-4'} />
            <div className={clsx('flex flex-col items-center', childrenClassName)}>{children || ''}</div>
        </div>
    );
}

export interface AllInOneLayoutProps extends WithChildren, WithClassName {
    childrenClassName?: class_name;
    headerChildrenClassName?: class_name;
    headerChildren?: children;
    variant?: 'filled' | 'contained';
}

export default AllInOneLayout;
