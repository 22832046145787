import TextField from './TextField';
import {
    WithAutoFocus,
    WithClassName,
    WithDefaultValue,
    WithDisabled,
    WithError,
    WithMax,
    WithMin,
    WithName,
    WithNativeOnChange,
    WithRequired,
    WithTitle,
} from '../../withs';

export function NumberField(props: NumberFieldProps) {
    return <TextField type={'number'} {...props} />;
}

export interface NumberFieldProps
    extends WithAutoFocus,
        WithClassName,
        WithDefaultValue<number>,
        WithDisabled,
        WithError,
        WithMin,
        WithMax,
        WithName,
        WithNativeOnChange,
        WithRequired,
        WithTitle {
    prefix?: string;
}

export default NumberField;
