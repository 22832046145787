import { v4 as uuidv4 } from 'uuid';
import { fromByteArray } from 'base64-js';

// try to obfuscate a little
const protocol = 'https';
const rootDomain = 'gotombola.co';
const buildEndpoint = (e: string) => `${protocol}://s.${'prod' === e ? '' : `${e}.`}${rootDomain}`;

export function getPixelTrackingIds(force = false) {
    if ('undefined' === typeof localStorage || 'undefined' === typeof sessionStorage)
        return { sessionId: force ? uuidv4() : undefined, persistentId: force ? uuidv4() : undefined };
    let ts = sessionStorage.getItem('gotb_ts');
    if (!ts) {
        ts = uuidv4();
        sessionStorage.setItem('gotb_ts', ts!);
    }
    let tp = localStorage.getItem('gotb_tp');
    if (!tp) {
        tp = uuidv4();
        localStorage.setItem('gotb_tp', tp!);
    }
    return { sessionId: ts, persistentId: tp };
}

export function buildMetadata(
    { app, event, rest = {}, env = 'prod' }: { app: string; event: string; rest?: any; env?: string },
    force = false,
) {
    const { sessionId, persistentId } = getPixelTrackingIds(force);
    if (!sessionId && !persistentId) return undefined;
    const [type, subType] = (event || '.').split('.');
    const enc = new TextEncoder();
    const l = 'undefined' !== typeof location ? location.href : undefined;
    const metas = encodeURIComponent(
        fromByteArray(
            enc.encode(
                JSON.stringify({
                    app,
                    type,
                    subType,
                    sessionId: sessionId,
                    persistentId: persistentId,
                    l: l,
                    ts: new Date().getTime(),
                    tz: new Date().getTimezoneOffset(),
                    rf: 'undefined' !== typeof document ? document.referrer : undefined,
                    qs: 'undefined' !== typeof window ? window.location.search : undefined,
                    data: {
                        ...rest,
                        iw: 'undefined' !== typeof window ? window.innerWidth : undefined,
                        ih: 'undefined' !== typeof window ? window.innerHeight : undefined,
                        dpr: 'undefined' !== typeof window ? window.devicePixelRatio : undefined,
                        lng: 'undefined' !== typeof window ? window.navigator?.language : undefined,
                        ua: 'undefined' !== typeof window ? window.navigator?.userAgent : undefined,
                        vnd: 'undefined' !== typeof window ? window.navigator?.vendor : undefined,
                        or: 'undefined' !== typeof window ? window.screen?.orientation?.type : undefined,
                    },
                }),
            ),
        ),
    );
    return {
        pixelUrl: `${buildEndpoint(env)}/{type}/{metas}/p.svg`.replace('{type}', type).replace('{metas}', metas),
    };
}
