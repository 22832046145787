import useTenant from '@gotombola/react-contexts-type/lib/hooks/useTenant';
import StaticImg, { StaticImgProps } from './StaticImg';
import { WithAlt, WithType } from '../withs';

export function StaticLogo({ alt, type, ...props }: StaticLogoProps) {
    const tenant = useTenant();

    return <StaticImg src={`tenants/${tenant}/images/logos/${type}.svg`} alt={alt || type || 'logo'} {...props} />;
}

export interface StaticLogoProps extends WithAlt, Omit<StaticImgProps, 'src' | 'alt'>, Required<WithType> {}

export default StaticLogo;
