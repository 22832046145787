import ModuleContainer from '../../organisms/ModuleContainer';
import FooterModule from '../../modules/FooterModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function FooterCommonModule({ config, ...rest }: FooterCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <FooterModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface FooterCommonModuleProps {
    config: any;
}

export default FooterCommonModule;
