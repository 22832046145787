import { TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Text from '../../atoms/Text';

export function TypeContactOwner({ className, doc, type }: TypeContactOwnerProps) {
    const { t } = useTypeTranslation(type, doc);
    const { blockShapeClass } = useStyling();
    const publicEmail = doc?.publicEmail || doc?.organizationPublicEmail;

    return (
        <div className={className}>
            {!!publicEmail && (
                <div>
                    <a
                        href={`mailto:${publicEmail}`}
                        className={clsx(
                            'font-go-text sm:text-lg flex sm:w-full justify-center tracking-tight leading-relaxed cursor-pointer hover:bg-go-secondary text-white bg-go-primary border px-4 py-2 hover:shadow-sm font-medium',
                            blockShapeClass,
                        )}
                    >
                        {t(`communication_page_${type}owner_contact_btn_label`)}
                    </a>
                </div>
            )}
            <div className={clsx('border p-4 bg-white h-auto', !!publicEmail && 'mt-8', blockShapeClass)}>
                <div className={'flex flex-col overflow-auto'}>
                    <Text relaxed className={'sm:text-md'}>
                        {t('communication_page_organization_contact_section_title')}
                    </Text>
                    {!!doc?.organizationName && (
                        <Text relaxed basic className={'sm:text-md'}>
                            {doc?.organizationName}
                        </Text>
                    )}
                    {!!publicEmail && (
                        <Text relaxed className={'sm:text-md'}>
                            {publicEmail}
                        </Text>
                    )}
                    {!!doc.phone && (
                        <Text relaxed className={'sm:text-md'}>
                            {doc.phone}
                        </Text>
                    )}
                    {!!doc.organizationCity && (
                        <Text relaxed className={'sm:text-md'}>
                            {doc.organizationCity}
                        </Text>
                    )}
                </div>
            </div>
        </div>
    );
}

export interface TypeContactOwnerProps extends TypeProps, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default TypeContactOwner;
