import Banner from '../../../organisms/Banner';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { TypeProps } from '../../../types';
import CenteredHeadingModule from '../../../modules/headings/CenteredHeadingModule';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import { useMemo } from 'react';

export function StandardBannerTypeModule({ typeType, doc, ...rest }: StandardBannerTypeModuleProps) {
    const { t } = useTypeTranslation(typeType, doc);
    const [zprops, props] = useZoneCustomizationProps(rest);

    const image = useMemo(
        () =>
            [
                doc?.bannerImage,
                doc?.organizationBannerImage,
                doc?.partnerBannerImage,
                doc?.campaignBannerImage,
                doc?.tenantBannerImage,
            ].find((x) => !!x?.url),
        [doc],
    );

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <div className={'relative'}>
                <Banner image={image} {...props} />
                <CenteredHeadingModule
                    className={
                        'sm:absolute pt-2 sm:pt-0 inset-0 w-11/12 sm:max-w-md mx-auto flex flex-col justify-center'
                    }
                    title={doc.overline || doc.name}
                    text={doc.presentation || doc.description}
                    buttonLabel={t('game_start_button_label')}
                    buttonTarget={doc.createGamePageUrl}
                    button2Label={t('game_infos_button_label')}
                    button2Target={doc.createGameInfosPageUrl}
                />
            </div>
        </ModuleContainer>
    );
}

export interface StandardBannerTypeModuleProps extends TypeProps {
    typeType: string;
}

export default StandardBannerTypeModule;
