import ModuleContainer from '../../organisms/ModuleContainer';
import VideoModule from '../../modules/VideoModule';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';

export function VideoCommonModule({ config, ...rest }: VideoCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <VideoModule config={config} nopadding {...props} />
        </ModuleContainer>
    );
}

export interface VideoCommonModuleProps {
    config: any;
}

export default VideoCommonModule;
