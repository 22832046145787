import useFormAction from './useFormAction';
import useWorkflowStep from './useWorkflowStep';
import useBuildUrlFor from './useBuildUrlFor';
import { useEffect, useMemo } from 'react';
import buildTypeNextStepUrl from '../utils/buildTypeNextStepUrl';
import useQueryString from './useQueryString';
import { useHistory } from 'react-router';
import useTypeTranslation from './useTypeTranslation';
import useWorkflowsContext from '@gotombola/react-contexts/lib/hooks/useWorkflowsContext';

export function useTypeFormStep(type: string, doc: any, form: string, step: string, vars?: any) {
    const { t } = useTypeTranslation(type, doc);
    const workflows = useWorkflowsContext();
    const {
        register,
        submit,
        errors,
        loading: originalLoading,
        data,
        called,
        control,
    } = useFormAction<any, any>(`${type}_${form}`, {}, vars);
    const { nextStep } = useWorkflowStep(workflows, `${type}_${form}`, step, { [type]: doc, data });
    const query = useQueryString();
    const history = useHistory();

    const urlBuilder = useBuildUrlFor(type, doc, false);

    useEffect(() => {
        if (!data) return;
        const url = buildTypeNextStepUrl(type, doc, nextStep, query, urlBuilder, { data });
        if ((nextStep?.['ssr'] || nextStep?.['nextSsr']) && 'undefined' !== typeof window?.location) {
            window.location.href = url;
        } else {
            history.push(url);
        }
    }, [data]);

    const loading = originalLoading || (!!data && (!errors || !Object.keys(errors).length));

    const stepProps = useMemo(
        () => ({
            onSubmit: submit,
            errors,
            loading,
            type,
            doc,
            title: t(`${type}_${form}_form_${step}_title`),
            text: t(`${type}_${form}_form_${step}_text`),
            buttonLabel: t(`${type}_${form}_form_${step}_cta`),
        }),
        [type, doc, submit, errors, loading, t],
    );
    const inputProps = useMemo(() => ({ disabled: loading, control, errors }), [loading, control, errors]);

    return useMemo(
        () => ({ t, type, doc, register, stepProps, inputProps, data, loading, called }),
        [t, type, doc, register, stepProps, inputProps, data, loading, called],
    );
}

export default useTypeFormStep;
