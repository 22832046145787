import clsx from 'clsx';
import { WithChildren, WithClassName, WithLabel } from '../withs';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function TopMenuItem({
    children,
    className,
    fakeTarget = '',
    important,
    label,
    linkClassName,
    target,
}: TopMenuItemProps) {
    const { blockShapeClass } = useStyling();
    return (
        <div className={clsx('justify-center', className)}>
            <a
                href={'function' === typeof target ? fakeTarget : target}
                onClick={'function' === typeof target ? (target as any) : undefined}
                className={clsx(
                    'font-go-button whitespace-nowrap cursor-pointer px-4 py-2 hover:shadow-md font-medium',
                    blockShapeClass,
                    important
                        ? 'bg-go-primary text-white border-2 border-go-primary hover:border-go-secondary hover:bg-go-secondary'
                        : 'bg-white border text-go-secondary hover:text-go-primary',
                    linkClassName,
                )}
            >
                {label || ''}
                {children || ''}
            </a>
        </div>
    );
}

export interface TopMenuItemProps extends WithChildren, WithClassName, WithLabel {
    linkClassName?: string;
    important?: boolean;
    target: string | Function;
    fakeTarget?: string;
}

// noinspection JSUnusedGlobalSymbols
export default TopMenuItem;
