import { useCallback, useMemo, useState } from 'react';
import { TypeProps } from '../../types';
import { WithItems, WithSubtitle, WithTarget, WithTitle, WithChildren } from '../../withs';
import ListingTypeItem from '../../molecules/type/ListingTypeItem';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import DefaultModuleSectionItemList from '../../molecules/DefaultModuleSectionItemList';

const defaultItems = [];
const defaultSponsorings = [];
const defaultItemProps = {};
const defaultListProps = {};

const computeSponsorings = (sponsorings: any[], s: string, s2: string, s3: string, s4: string, s5: string): any[] => {
    if (!s) return [];
    return ((sponsorings || {})['items'] || []).reduce(
        (acc: any[], x: any) =>
            x['id'] === s || x['id'] === s2 || x['id'] === s3 || x['id'] === s4 || x['id'] === s5
                ? [...acc, x]
                : [...acc],
        [],
    );
};

export function TypeListing({
    doc,
    itemComponent = ListingTypeItem,
    items = defaultItems,
    itemType,
    itemProps = defaultItemProps,
    listComponent: ListComponent = DefaultModuleSectionItemList,
    listProps = defaultListProps,
    sponsorings = defaultSponsorings,
    target,
    title,
    titleStyling,
    subtitle,
    subtitleStyling,
    type,
    withRank = true,
    withQuantities = true,
    nobg = false,
    nolabel = false,
    msVariant,
    children,
}: TypeListingProps) {
    const { t } = useTypeTranslation(type, doc);
    const images = useMemo(() => {
        return items.map((i: any) => i.mainImage).filter((x) => !!x);
    }, [items]);
    const listingItems = useMemo(() => {
        const { listingItems } = items.reduce(
            (acc: any, i: any) => {
                const b = { ...i, rank: acc.rank };
                acc.listingItems.push(b);
                acc.rank += withRank && (i.quantity || 1);
                return acc;
            },
            { listingItems: [] as any[], rank: withRank ? 1 : 0 },
        );
        return listingItems;
    }, [items]);
    const [selectedListingItem, setSelectedListingItem] = useState<any>(undefined);
    const openModal = useCallback(
        (x: any) => () => {
            setSelectedListingItem(x);
        },
        [setSelectedListingItem],
    );
    const closeModal = useCallback(() => {
        setSelectedListingItem(undefined);
    }, [setSelectedListingItem]);
    const opened = undefined !== selectedListingItem;
    const itemPropsFn = useCallback(
        (item: any) => {
            return {
                ...itemProps,
                type,
                doc,
                ...(!withQuantities ? { quantity: undefined } : {}),
                ...(nolabel ? { nolabel: true } : {}),
                onClick: openModal(item),
                sponsorings: computeSponsorings(
                    sponsorings,
                    item?.sponsoring,
                    item?.sponsoring2,
                    item?.sponsoring3,
                    item?.sponsoring4,
                    item?.sponsoring5,
                ),
            };
        },
        [type, doc, openModal, sponsorings, itemProps, nolabel],
    );
    const selectedListingItemSponsorings = useMemo(() => {
        return selectedListingItem
            ? selectedListingItem.sponsoring
                ? computeSponsorings(
                      sponsorings,
                      selectedListingItem?.sponsoring,
                      selectedListingItem?.sponsoring2,
                      selectedListingItem?.sponsoring3,
                      selectedListingItem?.sponsoring4,
                      selectedListingItem?.sponsoring5,
                  )
                : []
            : [];
    }, [selectedListingItem, sponsorings]);
    const itemsProps = useMemo(() => {
        return {
            items: listingItems,
            component: itemComponent,
            itemPropsFn: itemPropsFn,
        };
    }, [listingItems, itemPropsFn, itemComponent]);
    const modalProps = useMemo(() => {
        return {
            opened: opened,
            item: selectedListingItem,
            sponsorings: selectedListingItemSponsorings,
            title: t([
                `${type}${itemType ? '_' : ''}${itemType || ''}_modal_title`,
                `${type}_modal_title`,
                'modal_title',
            ]),
            buttonLabel: t([
                `${type}${itemType ? '_' : ''}${itemType || ''}_modal_btn_label`,
                `${type}_modal_btn_label`,
                'modal_btn_label',
            ]),
            buttonTarget: target,
            onClose: closeModal,
            type,
        };
    }, [type, itemType, opened, selectedListingItem, selectedListingItemSponsorings, target, closeModal, t]);

    return (
        <ListComponent
            doc={doc}
            type={type}
            title={title}
            titleStyling={titleStyling}
            subtitle={subtitle}
            subtitleStyling={subtitleStyling}
            itemsProps={itemsProps}
            modalProps={modalProps}
            hasImages={!!images.length}
            nobg={nobg}
            msVariant={msVariant}
            {...listProps}
        >
            {children}
        </ListComponent>
    );
}

export interface TypeListingProps
    extends TypeProps,
        WithItems<{
            quantity?: number;
            name?: string;
            description?: string;
            rank?: number;
            mainImage?: { url?: string };
        }>,
        WithTarget<any>,
        WithTitle,
        WithSubtitle,
        WithChildren {
    sponsorings?: { name?: string; description?: string; websiteUrl?: string; mainImage?: { url: string } }[];
    withRank?: boolean;
    withQuantities?: boolean;
    listComponent?: any;
    listProps?: any;
    itemComponent?: any;
    itemType?: string;
    itemProps?: any;
    nobg?: boolean;
    nolabel?: boolean;
    msVariant?: string;
    titleStyling?: string;
    subtitleStyling?: string;
}

// noinspection JSUnusedGlobalSymbols
export default TypeListing;
