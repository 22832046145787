import { Helmet } from 'react-helmet';

export function GotbPushNotification({ cfg, swPath }: GotbPushNotificationProps) {
    if (!swPath) return null;

    return (
        <Helmet>
            <script>{`
                (() => {
                    if (!window.Gotb) window.Gotb = {};
                    var gotb = window.Gotb;
                    gotb.RegisterPushNotificationServiceWorker = async () => {
                        var registration;
                        if ('serviceWorker' in navigator) {
                            registration = await navigator.serviceWorker.getRegistration();
                            if (!registration) registration = await navigator.serviceWorker.register('${swPath}');
                        }
                    };
                    gotb.SubscribePushNotification = async () => {
                        if ('serviceWorker' in navigator) {
                            const sw = await navigator.serviceWorker.ready;
                            if (!sw.pushManager) {
                                // Maybe iOS on iPhone or iPad - should ask for adding to Home Screen
                                return;
                            }
                            var subscription = await sw.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: '${cfg?.applicationServerKey}'
                            });
                            
                            console.log(subscription, ${JSON.stringify(cfg)});
                        }
                    };        
                    
                    gotb.RegisterPushNotificationServiceWorker().then(() => {});
                })();
            `}</script>
        </Helmet>
    );
}

export interface GotbPushNotificationProps {
    cfg?: Record<string, any>;
    swPath?: string;
}

// noinspection JSUnusedGlobalSymbols
export default GotbPushNotification;
