import DynamicContent from './DynamicContent';

const defaultContents = [];

export function DynamicContents({ contents = defaultContents }: DynamicContentsProps) {
    const n = contents?.length || 0;

    return (
        <>
            {(contents || []).map((content: any, index: number) => (
                <DynamicContent key={index} index={index} content={content} total={n} />
            ))}
        </>
    );
}

export interface DynamicContentsProps {
    contents?: any;
}

// noinspection JSUnusedGlobalSymbols
export default DynamicContents;
