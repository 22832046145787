import { TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import clsx from 'clsx';
import Text from '../../atoms/Text';

export function TypeSmsKeyword({ className, doc, type }: TypeSmsKeywordProps) {
    const { t } = useTypeTranslation(type, doc);
    const { blockShapeClass } = useStyling();

    const keyword = doc.smsKeyword;

    return (
        <div className={className}>
            <div className={clsx('flex-1 mt-8 border p-4 bg-white h-auto', blockShapeClass)}>
                <div className={'flex items-center flex-col sm:text-md leading-relaxed'}>
                    <Text className={'text-blue-500 font-medium'}>{t('communication_page_sms_btn_label')}</Text>
                    <Text bold className={'text-xl tracking-tight sm:truncate'}>
                        {keyword.text}
                    </Text>
                    <Text className={'text-blue-500 font-medium'}>{`${t('communication_page_at')} ${
                        keyword.phone
                    }`}</Text>
                </div>
            </div>
        </div>
    );
}

export interface TypeSmsKeywordProps extends TypeProps, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default TypeSmsKeyword;
