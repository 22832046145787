import loadable from '@loadable/component';
import { ModuleProps } from '../types';
import ModuleMode from '../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./spacers/StandardSpacerModule')),
};

export function SpacerModule(props: SpacerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type SpacerModuleProps = ModuleProps;

export default SpacerModule;
