import loadable from '@loadable/component';
import { CampaignModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./header/StandardHeaderCampaignModule')),
};

export function HeaderCampaignModule(props: HeaderCampaignModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type HeaderCampaignModuleProps = CampaignModuleProps;

export default HeaderCampaignModule;
