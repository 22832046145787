import { useEffect, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadFull } from 'tsparticles';
import { Container } from '@tsparticles/engine';
import config from '../configs/effects/fireworks';

const particlesLoaded = async (container: Container | undefined) => {
    await container?.start();
    container?.play(true);
};

// noinspection JSUnusedLocalSymbols
export function FireworksEffect(_: FireworksEffectProps) {
    const [displayed, setDisplayed] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadFull(engine);
            await engine.addPreset('fireworks', {});
            await engine.addPreset('confetti', {});
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            //await loadFull(engine);
            //await loadBasic(engine);
        }).then(() => {
            setDisplayed(true);
        });
    }, []);

    return displayed ? <Particles id={'tsparticles'} options={config} particlesLoaded={particlesLoaded} /> : null;
}

export type FireworksEffectProps = Record<string, never>;

// noinspection JSUnusedGlobalSymbols
export default FireworksEffect;
