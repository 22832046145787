import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./mobileCallToAction/StandardMobileCallToActionGameModule')),
};

export function MobileCallToActionGameModule(props: MobileCallToActionGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type MobileCallToActionGameModuleProps = GameModuleProps;

export default MobileCallToActionGameModule;
