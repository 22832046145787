import clsx from 'clsx';
import { WithClassName, WithImage, WithLabel } from '../../withs';
import Img from '../../atoms/Img';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function LargeImageModule({ className, image }: LargeImageModuleProps) {
    const { blockShapeClass } = useStyling();

    return (
        <div className={clsx('w-full relative my-2 overflow-hidden flex justify-center', blockShapeClass, className)}>
            {!!image?.url && <Img {...image} className={'object-cover'} alt={image.alt || 'presentation image'} />}
        </div>
    );
}

export interface LargeImageModuleProps extends WithClassName, Required<WithImage>, WithLabel {}

export default LargeImageModule;
