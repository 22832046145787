import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    thumbnails: loadable(() => import('./images/ThumbnailsImagesTypeModule')),
};

export function ImagesTypeModule(props: ImagesTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'thumbnails'} />;
}

export interface ImagesTypeModuleProps extends TypeModuleProps {
    size?: string;
    sort?: string;
    tag?: string;
}

export default ImagesTypeModule;
