import detectUriLocale, { defaultPlatformDefaultLocale } from './detectUriLocale';

const types = ['game', 'organization', 'campaign', 'partner', 'tenant'];

export function buildI18nRoutes(data: any, routes: any, items: any[], extraItems: any[] = [], pages: any = {}) {
    data = data || {};
    const x = types.find((k) => data[k]);
    const lc = detectUriLocale(x ? data[x] : undefined);

    let newItems = items
        .map((item) => {
            'string' === typeof item && (item = { ssr: item });
            item = { ...item };
            item.exact === undefined && (item.exact = true);
            item.component === undefined &&
                item.render === undefined &&
                (pages[item.name || item.ssr || ''] || pages[item.ssr || '']) &&
                (item.component = pages[item.name || item.ssr || ''] || pages[item.ssr || '']);
            const rr = (item.name ? routes[item.name || ''] : routes[item.ssr || '']) || {};
            const r = rr[lc || ''] || rr[defaultPlatformDefaultLocale];
            return r && { path: r, ...item };
        })
        .filter((x) => !!x);

    newItems = newItems.reduce((acc, item) => {
        const rr = (item.name ? routes[item.name || ''] : routes[item.ssr || '']) || {};
        const kk = lc || defaultPlatformDefaultLocale;
        return Object.entries(rr).reduce((acc2, [k, v]: [string, any]) => {
            if (k === kk || v === rr[kk]) return acc2;
            acc2.push({ ...item, path: v, redirect: rr[kk], ssr: undefined, component: undefined });
            return acc2;
        }, acc);
    }, newItems);

    newItems = [...newItems, ...extraItems];

    return newItems;
}

// noinspection JSUnusedGlobalSymbols
export default buildI18nRoutes;
