import { WithButtons, WithImage, WithItems, WithText, WithTitle } from '../withs';
import Items from '../atoms/Items';
import CenteredHeadingModule from '../modules/headings/CenteredHeadingModule';
import Step from '../molecules/Step';
import ListItem from '../molecules/ListItem';
import { useMemo } from 'react';
import useButtonsProps from '../hooks/useButtonsProps';
import Buttons from '../molecules/Buttons';

const variants = {
    default: { component: Step, className: 'space-y-10' },
    step: { component: Step, className: 'space-y-10' },
    list: { component: ListItem, className: 'space-y-6' },
};
export function Steps({ title, text, items = [], variant, ...rest }: StepsProps) {
    const [bprops] = useButtonsProps(rest);
    const { component, className } = useMemo(() => variants[variant || ''] || variants['default'], [variant]);

    return (
        <>
            {!!title && <CenteredHeadingModule title={title} />}
            <div className={className}>
                <Items items={items} component={component} />
            </div>
            {!!text && <CenteredHeadingModule text={text} />}
            <div className={'flex justify-center'}>
                <div className={'w-full sm:w-auto'}>
                    <Buttons {...bprops} />
                </div>
            </div>
        </>
    );
}

export interface StepsProps extends WithItems<{ image?: WithImage; text?: string }>, WithTitle, WithText, WithButtons {
    variant?: string;
}

export default Steps;
