import { WithButton, WithClassName, WithImage, WithOverline, WithText, WithTitle } from '../withs';
import Img from '../atoms/Img';
import String from '../atoms/String';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import StaticIcon from '../atoms/StaticIcon';
import Button from '../atoms/Button';

export function CardContent({
    border = true,
    title,
    text,
    overline,
    image,
    tags = [],
    icon,
    buttonTarget,
    buttonLabel,
}: CardContentProps) {
    const {
        blockShapeClass,
        blockContentPaddingClass,
        blockBorderClass,
        blockBorderColorClass,
        titleTextColorClass,
        blockBackgroundClass,
    } = useStyling();

    return (
        <div
            className={clsx(
                'sm:flex items-center justify-between',
                blockShapeClass,
                border && blockContentPaddingClass,
                border && blockBorderClass,
                blockBorderColorClass,
                blockBackgroundClass,
            )}
        >
            {!!image && (
                <div className={'flex justify-center items-center sm:w-1/4 p-2 sm:p-4'}>
                    <Img {...image} className={'w-28 h-28 mx-auto sm:mx-0 rounded-full'} />
                </div>
            )}
            <div className={clsx('p-2 sm:p-4', !image ? 'sm:w-full' : 'sm:w-3/4')}>
                {!!overline && (
                    <String className={'font-go-text text-md font-thin text-gray-500 text-center sm:text-left'}>
                        {overline}
                    </String>
                )}
                {!!title && (
                    <String
                        className={clsx(
                            'font-go-text text-xl font-bold text-center sm:text-left mb-4',
                            titleTextColorClass,
                        )}
                    >
                        {title}
                    </String>
                )}
                {!!text && <String className={'font-go-text text-md text-justify text-gray-600 mb-4'}>{text}</String>}
                <div className={'flex flex-wrap gap-2 items-start'}>
                    {(tags || []).map((tag, index) => (
                        <String
                            key={index}
                            className={clsx(
                                'font-go-text border border-gray-200 text-gray-400 bg-gray-100 py-1 px-2 text-sm',
                                blockShapeClass,
                            )}
                        >
                            {tag}
                        </String>
                    ))}
                </div>
            </div>
            {((!!buttonTarget && !!buttonLabel) || !!icon) && (
                <div className={'p-2 sm:p-4 flex flex-col items-end'}>
                    {!!icon && (
                        <StaticIcon type={icon as string} className={'text-go-primary w-6 mb-4 mx-auto sm:mx-0'} />
                    )}
                    {!!buttonLabel && !!buttonTarget && <Button label={buttonLabel as string} target={buttonTarget} />}
                </div>
            )}
        </div>
    );
}

export interface CardContentProps
    extends WithClassName,
        WithImage<{ url: string; alt: string }>,
        WithOverline,
        WithText,
        WithTitle,
        WithButton {
    border?: boolean;
    tags?: string[];
    icon?: string;
}

// noinspection JSUnusedGlobalSymbols
export default CardContent;
