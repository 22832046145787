import useTenant from '@gotombola/react-contexts-type/lib/hooks/useTenant';
import { WithAlt, WithType } from '../withs';
import StaticImg, { StaticImgProps } from './StaticImg';

export function StaticBadge({ alt, type, ...props }: StaticBadgeProps) {
    const tenant = useTenant();

    return (
        <StaticImg src={`tenants/${tenant}/images/logos/badges/${type}.png`} alt={alt || type || 'badge'} {...props} />
    );
}

export interface StaticBadgeProps extends WithAlt, Omit<StaticImgProps, 'alt' | 'src'>, Required<WithType> {}

export default StaticBadge;
