import { useMemo } from 'react';
import { type_hierarchy_context } from '../types';

const hierarchies: Record<string, Record<string, string[]>> = {
    basic: {
        seller: ['seller', 'sellergroup', 'game'],
        sellergroup: ['sellergroup', 'game'],
        game: ['game'],
        organization: ['organization'],
        partner: ['partner'],
        campaign: ['campaign'],
        tenant: ['tenant'],
    },
    full: {
        seller: ['seller', 'sellergroup', 'game', 'organization', 'partner', 'campaign', 'tenant'],
        sellergroup: ['sellergroup', 'game', 'organization', 'partner', 'campaign', 'tenant'],
        game: ['game', 'organization', 'partner', 'campaign', 'tenant'],
        organization: ['organization', 'partner', 'campaign', 'tenant'],
        partner: ['partner', 'campaign', 'tenant'],
        campaign: ['campaign', 'tenant'],
        tenant: ['tenant'],
    },
};

export function useTypeTypeHierarchy(
    typeType: string,
    { doc, seller, sellergroup, game, organization, partner, campaign, tenant }: type_hierarchy_context,
    mode: string,
) {
    const ctx = useMemo(
        () => ({ doc, seller, sellergroup, game, organization, partner, campaign, tenant }),
        [doc, seller, sellergroup, game, organization, partner, campaign, tenant],
    );
    return useMemo(() => {
        return ((hierarchies[mode] || [])[typeType] || []).map((x) =>
            ctx[x] ? [x, ctx[x]] : x === typeType ? (ctx['doc'] ? [x, ctx['doc']] : undefined) : undefined,
        );
    }, [typeType, ctx, mode]);
}

export default useTypeTypeHierarchy;
