export async function api(name: string, data?: any, method = 'POST', options?: any, nocache = true) {
    const res = await fetch(`/api/${name.replace(/_/g, '/')}${nocache ? `?_=${Date.now()}` : ''}`, {
        method,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
        ...(data ? { body: JSON.stringify(data) } : {}),
        ...(options || {}),
    });

    if (!res.ok) {
        let details: any;
        try {
            details = await res.json();
        } catch (e: any) {
            details = {};
        }
        throw new Error(`API Error: ${details?.message}`);
    }
    let result: any;
    try {
        result = await res.json();
    } catch (e: any) {
        throw new Error(`API Error: not JSON response`);
    }
    if (!result || !result?.status || 'success' !== result?.status) {
        throw new Error(`API Error: ${result?.status} => ${result?.message}`);
    }

    return result?.data;
}

export default api;
