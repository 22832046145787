import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import Screenshots from '../../../organisms/Screenshots';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { useMemo } from 'react';
import toICULocale from '@gotombola/locale/lib/utils/toICULocale';

const defaultGameCodes = [
    { game: 'GSKP391', email: 'z02k' },
    { game: 'GMWN576', email: 'a45d' },
    { game: 'GSAU010', email: 'g34q' },
    { game: 'GIOL139', email: 'h76w' },
];

const defaultEmailCode = 'a45d';

export function StandardServiceAutomaticEmailsScreenshotsPartnerModule({
    gameCodes = defaultGameCodes,
    locale,
    partner,
    ...rest
}: StandardServiceEmailsScreenshotsPartnerModuleProps) {
    // @ts-ignore
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTrans();
    locale = useMemo(() => toICULocale(locale), [locale]);
    const items = useMemo(
        () =>
            gameCodes.map((c) => {
                const item = 'object' === typeof c ? c : { game: c, email: defaultEmailCode };
                const url = `https://image.api.gotombola.co/images/z/${item.game}:::${partner?.code || ''}/d01:${
                    item.email
                }/mobile/xxxx/game-${item.game}-email-${item.email}.webp?locale=${locale}`;
                return {
                    image: {
                        url,
                    },
                    url: url.replace(/mobile/g, 'desktop'),
                };
            }),
        [gameCodes, partner.code, locale],
    );

    return (
        <ModuleContainer {...zprops}>
            <Screenshots title={t('partner_automatic_emails_screenshots_title')} items={items} {...props} />
        </ModuleContainer>
    );
}

export interface StandardServiceEmailsScreenshotsPartnerModuleProps {
    locale: string;
    partner: any;
    gameCodes?: (string | { game: string; email: string })[];
}

export default StandardServiceAutomaticEmailsScreenshotsPartnerModule;
