import NumericInput from '../atoms/inputs/NumericInput';

export function PriceDataField({ placeholder, allData, ...props }: PriceDataFieldProps) {
    return <NumericInput {...props} placeholder={placeholder || ''} />;
}

export interface PriceDataFieldProps {
    name: string;
    onChange: Function;
    value: any;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    allData?: any;
    register: Function;
    control: Function;
}

// noinspection JSUnusedGlobalSymbols
export default PriceDataField;
