import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function CampaignTemplate({ campaign, page = 'campaign_public', ...props }: CampaignTemplateProps) {
    return <TypeTemplate type={'campaign'} page={page.replace(/^campaign_/, '')} doc={campaign} {...props} />;
}

export interface CampaignTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    campaign?: any;
}

export default CampaignTemplate;
