import { prize } from '../types';

export function useSetRanks(items: prize[]) {
    let count = 0;
    (items || []).map((item) => {
        if (item.quantity > 1) {
            item.rank = `${count + 1}-${count + item.quantity}`;
            count = count + item.quantity;
        } else {
            if (!item.quantity) {
                item.rank = '-';
            } else {
                if (item.quantity < 0) {
                    item.rank = '!';
                } else {
                    count = count + item.quantity;
                    item.rank = `${count}`;
                }
            }
        }
    });
    return items;
}

export default useSetRanks;
