import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./beneficiaries/StandardBeneficiariesGameModule')),
};

export function BeneficiariesGameModule(props: BeneficiariesGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type BeneficiariesGameModuleProps = GameModuleProps;

export default BeneficiariesGameModule;
