import { WithBasic, WithChildren, WithClassName } from '../withs';
import RichText from './RichText';

export function String({ basic, children, className }: StringProps) {
    return <RichText className={className} value={children} basic={basic} />;
}

export interface StringProps extends WithBasic, WithChildren, WithClassName {}

export default String;
