import clsx from 'clsx';
import { WithLabel } from '../withs';
import String from './String';
import { useEffect, useState } from 'react';

export function ProgressBar({ label, ratio = 0 }: ProgressBarProps) {
    const [currentRatio, setCurrentRatio] = useState(ratio);

    useEffect(() => {
        const x =
            currentRatio !== ratio &&
            setTimeout(() => {
                setCurrentRatio(ratio);
            }, 650);
        return x ? () => clearTimeout(x) : undefined;
    }, [ratio, currentRatio]);

    return (
        <div className={'w-full pt-8 text-center'}>
            {!!label && <String className={'mb-4'}>{label}</String>}
            <div
                className={
                    'flex bg-gray-50 border border-solid border-gray-100 rounded-xl justify-center items-center relative h-8'
                }
            >
                <span className={'font-go-text text-go-primary font-bold sm:text-2xl z-10'}>
                    {currentRatio * 100 + ' %'}
                </span>
                <div
                    style={{ width: ratio * 100 + '%' }}
                    className={clsx(
                        'transition-all ease-in delay-500 flex items-center bg-go-secondary rounded-l-lg h-full absolute left-0',
                        ratio === 1 && 'rounded-r-lg',
                    )}
                />
            </div>
        </div>
    );
}

export interface ProgressBarProps extends WithLabel {
    ratio: number;
}

// noinspection JSUnusedGlobalSymbols
export default ProgressBar;
