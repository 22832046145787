import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypePresentation from '../../../organisms/type/TypePresentation';

export function StandardPresentationSponsorModule({ sponsor, ...rest }: StandardPresentationSponsorModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <TypePresentation {...props} type={'sponsor'} doc={sponsor} />
        </ModuleContainer>
    );
}

export interface StandardPresentationSponsorModuleProps {
    sponsor?: any;
}

export default StandardPresentationSponsorModule;
