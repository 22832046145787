import { useMemo } from 'react';
import useTemplateContext from '@gotombola/react-contexts/lib/hooks/useTemplateContext';

export function useTemplate(type: string | undefined) {
    const { getTemplateComponent } = useTemplateContext();
    return useMemo(() => {
        return getTemplateComponent((type || '').toLowerCase().replace(/[^a-z0-9_]+/g, '_'));
    }, [getTemplateComponent, type]);
}

export default useTemplate;
