import { ModuleProps } from '../types';
import { WithComponent } from '../withs';

export function ModuleMode({ component, config, defaultMode = 'default', modes = {}, ...props }: ModuleModeProps) {
    const modeName = config?.type || 'default';
    const Component = component || modes['default' === modeName ? defaultMode : modeName] || modes[defaultMode];

    if (!Component) return <div>UNKNOWN MODULE MODE {modeName}</div>;

    return <Component {...config} {...props} />;
}

export interface ModuleModeProps extends ModuleProps, WithComponent {
    modes?: Record<string, any>;
    defaultMode?: string;
}

export default ModuleMode;
