import clsx from 'clsx';
import { WithChildren, WithClassName } from '../withs';

export function MainModuleGroup({ children, className }: MainModuleGroupProps) {
    return (
        <main id={'main-content'} className={clsx('w-full', className)}>
            {children || ''}
        </main>
    );
}

export interface MainModuleGroupProps extends WithChildren, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default MainModuleGroup;
