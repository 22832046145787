import { useMemo, useState } from 'react';
import useQueryString from './useQueryString';
import useTypeTranslation from './useTypeTranslation';
import createShare from '../utils/createShare';

// @todo do not hard code
const messengerAppId = '000000000000000';
const defaultLocale = 'fr_FR';

function computeShareMessage(message: string, link: string, name: string) {
    if (!message) return;
    return encodeURIComponent(message.replace('{{link}}', link).replace('{{name}}', name));
}

export function useTypeShares(
    type: string,
    doc: any,
): {
    smsShareUrl?: string;
    emailShareUrl?: string;
    facebookShareUrl?: string;
    instagramShareUrl?: string;
    linkedinShareUrl?: string;
    twitterShareUrl?: string;
    whatsappShareUrl?: string;
    flipboardShareUrl?: string;
    messengerShareUrl?: string;
    pinterestShareUrl?: string;
    tumblrShareUrl?: string;
    redditShareUrl?: string;
    telegramShareUrl?: string;
    shareItems: { id: string; title?: string; label?: string; url?: string; onClick?: Function }[];
    shareCopied: boolean;
    setShareCopied: Function;
} {
    const query = useQueryString();
    const { t } = useTypeTranslation(type, doc);
    const zr = query.get('z');
    const [shareCopied, setShareCopied] = useState(false);
    return useMemo(() => {
        const z = !zr || 'undefined' === zr ? undefined : zr;
        const url = (doc.publicPageShortUrl || '').replace(doc.code, !!z ? `${z}-${doc.code}` : doc.code);
        const { body, subject } = createShare(url, doc.name, t);
        const eb = encodeURIComponent(body);
        const eu = encodeURIComponent(url);
        const es = encodeURIComponent(subject);
        const el = encodeURIComponent(doc.locale || defaultLocale);
        const emi = messengerAppId;

        const smsShareUrl = `sms:&body=${
            computeShareMessage(doc?.shareSmsMessage || doc?.shareCommonMessage, url, doc.name) || eb
        }`;
        const emailShareUrl = `mailto:?subject=${es}&body=${
            computeShareMessage(doc?.shareEmailMessage || doc?.shareCommonMessage, url, doc.name) || eb
        }`;
        const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${eu}`;
        const twitterShareUrl = `https://twitter.com/share?url=${eu}&text=${
            computeShareMessage(doc?.shareTwitterMessage || doc?.shareCommonMessage, url, doc.name) || eb
        }`;
        const instagramShareUrl = undefined;
        const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${eu}&title=${
            computeShareMessage(doc?.shareLinkedinMessage || doc?.shareCommonMessage, url, doc.name) || eb
        }&lang=${el}`;
        const whatsappShareUrl = `https://api.whatsapp.com/send?text=${
            computeShareMessage(doc?.shareWhatsappMessage || doc?.shareCommonMessage, url, doc.name) || eb
        }`;
        const messengerShareUrl = `https://www.facebook.com/dialog/send?app_id=${emi}&link=${eu}&redirect_uri=${eu}&locale=${el}`;
        const flipboardShareUrl = `https://share.flipboard.com/bookmarklet/popout?title=${eb}&url=${eu}`;
        const pinterestShareUrl = `https://pinterest.com/pin/create/button/?url=${eu}&description=${eb}`;
        const bufferShareUrl = `https://bufferapp.com/add?text=${eb}&url=${eu}`;
        const tumblrShareUrl = `https://www.tumblr.com/share/link?url=${eb}&name=${es}&description=${eb}`;
        const redditShareUrl = `https://reddit.com/submit?url=${eu}&title=${eb}`;
        const telegramShareUrl = `https://telegram.me/share/url?url=${eu}`;
        const shareItems: { id: string; title?: string; label?: string; url?: string; onClick?: Function }[] = [
            {
                id: 'link',
                title: shareCopied ? t(`${type}_share_page_copied`) : t(`${type}_share_page_copy`),
                label: undefined,
                url: url,
                onClick: (e: any) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(url).then(() => {
                        // content has been copied to clipboard
                    });
                    setShareCopied(true);
                    setTimeout(() => {
                        setShareCopied(false);
                    }, 3000);
                },
            },
            { id: 'email', label: 'Email', url: emailShareUrl },
            { id: 'sms', label: 'SMS', url: smsShareUrl },
            { id: 'facebook', label: 'Facebook', url: facebookShareUrl },
            { id: 'twitter', label: 'Twitter', url: twitterShareUrl },
            { id: 'whatsapp', label: 'Whatsapp', url: whatsappShareUrl },
            //            {id: 'messenger', label: 'Messenger', url: messengerShareUrl},
            { id: 'linkedin', label: 'LinkedIn', url: linkedinShareUrl },
            { id: 'flipboard', label: 'Flipboard', url: flipboardShareUrl },
            { id: 'buffer', label: 'Buffer', url: bufferShareUrl },
            { id: 'tumblr', label: 'Tumblr', url: tumblrShareUrl },
            { id: 'reddit', label: 'Reddit', url: redditShareUrl },
            { id: 'pinterest', label: 'Pinterest', url: pinterestShareUrl },
            { id: 'telegram', label: 'Telegram', url: telegramShareUrl },
        ];

        return {
            smsShareUrl,
            emailShareUrl,
            facebookShareUrl,
            instagramShareUrl,
            linkedinShareUrl,
            twitterShareUrl,
            whatsappShareUrl,
            messengerShareUrl,
            flipboardShareUrl,
            pinterestShareUrl,
            bufferShareUrl,
            tumblrShareUrl,
            telegramShareUrl,
            redditShareUrl,
            shareItems,
            shareCopied,
            setShareCopied,
        };
    }, [zr, doc.code, doc.name, doc.publicPageShortUrl, shareCopied, setShareCopied, type]);
}

// noinspection JSUnusedGlobalSymbols
export default useTypeShares;
