import { useMemo } from 'react';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useTypeShares from '../../../hooks/useTypeShares';
import ChoiceMenu from '../../../molecules/ChoiceMenu';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardShareGameModule({ game, ...rest }: StandardShareGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { shareItems } = useTypeShares('game', game);
    const items = useMemo(
        () =>
            (shareItems || []).map((item: any, index) => ({
                id: item.id || index,
                target: item.onClick || item.url,
                label: item.title ? (
                    <>
                        <div>{item.title}</div>
                        <div className={'text-sm'}>{item.label}</div>
                    </>
                ) : (
                    item.label
                ),
                blank: true,
            })),
        [shareItems],
    );

    return (
        <ModuleContainer {...zprops}>
            <ChoiceMenu {...props} items={items} />
        </ModuleContainer>
    );
}

export interface StandardShareGameModuleProps {
    game: any;
}

export default StandardShareGameModule;
