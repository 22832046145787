import { ComponentType } from 'react';
import ClientPage, { ClientPageProps } from './ClientPage';
import useI18nFromLocale from '@gotombola/react-i18n/lib/hooks/use18nFromLocale';

export function BasicClientPage({ app, createI18n, ...props }: BasicClientPageProps) {
    const i18n = useI18nFromLocale(props.context?.locale, createI18n);

    return <ClientPage {...props} i18n={i18n} component={app} />;
}

export interface BasicClientPageProps extends Omit<ClientPageProps, 'i18n' | 'component'> {
    app: ComponentType<any>;
    createI18n: Function;
}

// noinspection JSUnusedGlobalSymbols
export default BasicClientPage;
