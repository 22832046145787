import TypeQrcode, { TypeQrcodeProps } from '../../../organisms/type/TypeQrcode';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardQrcodeTypeModule({ typeType, ...rest }: StandardQrcodeTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <TypeQrcode {...props} type={typeType} />
        </ModuleContainer>
    );
}

export interface StandardQrcodeTypeModuleProps extends Omit<TypeQrcodeProps, 'type'> {
    typeType: string;
}

export default StandardQrcodeTypeModule;
