import clsx from 'clsx';
import { TypeProps } from '../../types';
import { WithClassName, WithSubtitle, WithTitle } from '../../withs';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import useQueryString from '../../hooks/useQueryString';
import useTypeUrl from '../../hooks/useTypeUrl';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import SectionTitle from '../../atoms/SectionTitle';
import SectionSubtitle from '../../atoms/SectionSubtitle';

export function TypeTerms({
    className,
    doc,
    type,
    title: forcedTitle,
    titleStyling,
    subtitle,
    subtitleStyling,
}: TypeTermsProps) {
    const { t } = useTypeTranslation(type, doc);
    const qs = useQueryString().toString();
    const termsUrl = useTypeUrl(type, doc, 'terms-and-conditions', qs, undefined, 'terms');
    const { blockShapeClass } = useStyling();

    const title = forcedTitle || t(`${type}_terms_title`);

    return (
        <div className={clsx('w-full text-center', className)}>
            <SectionTitle title={title} titleStyling={titleStyling} className={'mb-6 sm:mb-12'} />
            <SectionSubtitle subtitle={subtitle} subtitleStyling={subtitleStyling} className={'mb-4 sm:mb-6'} />
            <div className={'flex justify-center space-x-4'}>
                <div className={'flex flex-col items-center gap-4'}>
                    <a
                        href={termsUrl}
                        className={clsx(
                            'font-go-button text-go-primary text-lg text-center p-2 cursor-pointer border hover:bg-go-primary hover:text-white border-go-primary',
                            blockShapeClass,
                        )}
                    >
                        {t(`${type}_terms_text`)}
                    </a>
                </div>
            </div>
        </div>
    );
}

export interface TypeTermsProps extends TypeProps, WithClassName, WithTitle, WithSubtitle {
    titleStyling?: string;
    subtitleStyling?: string;
}

// noinspection JSUnusedGlobalSymbols
export default TypeTerms;
