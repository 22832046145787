import TsParticlesEffectBase, { TsParticlesEffectBaseProps } from '../misc/TsParticlesEffectBase';

const defaultType = 'fireworks';
export function TsparticlesEffect({ type = defaultType, ...props }: TsparticlesEffectProps) {
    return <TsParticlesEffectBase {...props} type={type} />;
}

export type TsparticlesEffectProps = TsParticlesEffectBaseProps;

// noinspection JSUnusedGlobalSymbols
export default TsparticlesEffect;
