import { useEffect, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { Container, Engine } from '@tsparticles/engine';
import loadable from '@loadable/component';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultParticlesLoaded = async (_: Container | undefined) => {};

async function applyConfigure(configure: Function, params: any, engine: Engine) {
    return configure(engine, params);
}

// noinspection JSUnusedLocalSymbols
export function TsParticlesEffectBase({ id = 'tsparticles', type, onLoaded }: TsParticlesEffectBaseProps) {
    const [state, setState] = useState<{ type: string; Comp: any | undefined; engine: Engine | undefined }>({
        type,
        Comp: undefined,
        engine: undefined,
    });
    const [config, setConfig] = useState<any>(undefined);

    useEffect(() => {
        if (!state.Comp || type !== state.type) {
            initParticlesEngine(async (engine) => {
                setState({ type, Comp: loadable.lib(() => import(`../configs/effects/${type}`)), engine });
            }).then(() => {
                /* nothing to do */
            });
        }
    }, [type, setState]);

    if (!state.Comp) return null;
    if (config) return <Particles id={id} options={config} particlesLoaded={onLoaded || defaultParticlesLoaded} />;

    return (
        <state.Comp>
            {({ default: configure }) => {
                const p = applyConfigure(configure, {}, state.engine!);
                p.then((config: any) => {
                    setConfig(config);
                });
                return null;
            }}
        </state.Comp>
    );
}

export interface TsParticlesEffectBaseProps {
    id?: string;
    type: string;
    onLoaded?: (container: Container | undefined) => Promise<void>;
}

// noinspection JSUnusedGlobalSymbols
export default TsParticlesEffectBase;
