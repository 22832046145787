import clsx from 'clsx';
import useTypeTranslation from '../hooks/useTypeTranslation';
import useTypeUrl from '../hooks/useTypeUrl';
import useQueryString from '../hooks/useQueryString';
import String from '../atoms/String';
import { formatRawAmount } from '@gotombola/amount';

type Props = {
    extraBuyQs?: string;
    game: any;
    buttonTarget?: any;
    buttonLabel?: any;
};

const MobileCallToAction = ({ extraBuyQs, game, buttonTarget, buttonLabel }: Props) => {
    const { t } = useTypeTranslation('game', game);
    const isRunning = 'RUNNING' === game.status;
    const qs = useQueryString().toString();
    const buyUrl = useTypeUrl('game', game, 'buy', !!extraBuyQs ? `${qs}&${extraBuyQs}` : qs);
    const resultsUrl = useTypeUrl('game', game, 'results');
    const isDemo = (game?.features || []).includes('demo');
    if (isRunning)
        return (
            <>
                <div className={'sm:hidden h-16'} />
                <a
                    href={buttonTarget || buyUrl}
                    className={clsx(
                        'font-go-button sm:hidden text-white font-semibold text-3xl text-center p-4 z-30 fixed bottom-0 left-0 right-0 cursor-pointer bg-go-primary hover:bg-go-secondary',
                        isDemo && 'bottom-16',
                    )}
                >
                    <String basic>
                        {buttonLabel ||
                            t('game_participate_price', {
                                price: formatRawAmount(game.ticketPriceAmount, game.ticketPriceCurrency),
                            })}
                    </String>
                </a>
            </>
        );
    if ('PUBLISHED' === game.resultsPublishingStatus)
        return (
            <>
                <div className={'sm:hidden h-16'} />
                <a
                    href={resultsUrl}
                    className={clsx(
                        'font-go-button sm:hidden text-white font-semibold text-3xl text-center p-4 z-30 fixed bottom-0 left-0 right-0 cursor-pointer bg-go-primary hover:bg-go-secondary',
                        isDemo && 'bottom-16',
                    )}
                >
                    <String basic>{t('game_results_page')}</String>
                </a>
            </>
        );

    return null;
};

export default MobileCallToAction;
