import OrganizationInfos from '../../organisms/OrganizationInfos';

export function InfosOrganizationModule({ organization }: InfosOrganizationModuleProps) {
    return <OrganizationInfos organization={organization} />;
}

export interface InfosOrganizationModuleProps {
    organization: any;
}

export default InfosOrganizationModule;
