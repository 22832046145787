const errors = {
    service_unavailable: {
        title: 'Service non disponible',
        message: 'Le service est actuellement indisponible',
        detail: 'Nous nous excusons pour la gêne occasionnée et vous prions de bien vouloir ré-essayer dans quelques instants.',
        retry: true,
        contact: true,
    },
    tenant_mismatch: {
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: "Cette application n'est pas autorisée à réaliser cette opération sur ce tenant.",
        retry: false,
        contact: true,
    },
    unknown_document: {
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: 'Cette application utilise des informations non existantes.',
        retry: false,
        contact: true,
    },
    unknown_document_tenant: ({ data }: any) => ({
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: `Cette application utilise un tenant non existant (tenant: ${data['value']}).`,
        retry: false,
        contact: true,
    }),
    unknown_document_organization: ({ data }: any) => ({
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: `Cette application utilise une association non existante (association: ${data['value']}).`,
        retry: false,
        contact: true,
    }),
    unknown_document_user: ({ data }: any) => ({
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: `Cette application utilise un utilisateur non existant (utilisateur: ${data['value']}).`,
        retry: false,
        contact: true,
    }),
    missing_requirement: {
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: "Les champs obligatoires n'ont pas été remplis correctement.",
        retry: false,
        contact: true,
    },
    missing_requirement_owner: {
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: "L'email renseigné est manquant ou invalide.",
        retry: false,
        contact: true,
    },
    missing_requirement_tenant: {
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: 'Le tenant renseigné est manquant ou invalide.',
        retry: false,
        contact: true,
    },
    document_creation: {
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: 'Une erreur est survenue lors de la création de votre tombola.',
        retry: false,
        contact: true,
    },
    document_creation_game: {
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: 'Une erreur est survenue lors de la création de votre tombola.',
        retry: false,
        contact: true,
    },
    document_creation_user: {
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: 'Une erreur est survenue lors de la création de votre compte.',
        retry: false,
        contact: true,
    },
    document_creation_organization: {
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: "Une erreur est survenue lors de la création de votre compte d'association.",
        retry: false,
        contact: true,
    },
    document_update_game: {
        title: 'Non autorisé',
        message: "Cette opération n'est pas disponible pour cette application",
        detail: 'Une erreur est survenue lors de la mise à jour de votre tombola.',
        retry: false,
        contact: true,
    },
    unknown: {
        title: 'Oups !',
        message: 'Une erreur inattendue est survenue.',
        detail: undefined,
        retry: true,
        contact: true,
    },
    validation: {
        title: 'Vérifiez votre saisie',
        message: 'Merci de vérifier les informations saisies dans votre formulaire',
        detail: undefined,
        retry: false,
        contact: false,
    },
};

export default errors;
