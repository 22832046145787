import { useMemo } from 'react';

export function useGameSocialNetworks(game: any, forcedValue?: Record<string, string>) {
    return useMemo(() => {
        return (
            forcedValue || {
                ...(!!game?.facebookUrl || !!game?.organizationFacebookUrl
                    ? { facebookUrl: game?.facebookUrl || game?.organizationFacebookUrl }
                    : {}),
                ...(!!game?.instagramUrl || !!game?.organizationInstagramUrl
                    ? { instagramUrl: game?.instagramUrl || game?.organizationInstagramUrl }
                    : {}),
                ...(!!game?.twitterUrl || !!game.organizationTwitterUrl
                    ? { twitterUrl: game?.twitterUrl || game.organizationTwitterUrl }
                    : {}),
                ...(!!game?.linkedinUrl || !!game.organizationLinkedinUrl
                    ? { linkedinUrl: game?.linkedinUrl || game.organizationLinkedinUrl }
                    : {}),
                ...(!!game?.tiktokUrl || !!game.organizationTiktokUrl
                    ? { tiktokUrl: game?.tiktokUrl || game.organizationTiktokUrl }
                    : {}),
                ...(!!game?.youtubeUrl || !!game.organizationYoutubeUrl
                    ? { youtubeUrl: game?.youtubeUrl || game.organizationYoutubeUrl }
                    : {}),
            }
        );
    }, [
        forcedValue,
        game?.facebookUrl,
        game?.organizationFacebookUrl,
        game?.twitterUrl,
        game?.organizationTwitterUrl,
        game?.youtubeUrl,
        game?.organizationYoutubeUrl,
        game?.instagramUrl,
        game?.organizationInstagramUrl,
        game?.linkedinUrl,
        game?.organizationLinkedinUrl,
        game?.tiktokUrl,
        game?.organizationTiktokUrl,
    ]);
}

export default useGameSocialNetworks;
