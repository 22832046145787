import loadable from '@loadable/component';
import { SponsorModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./footer/StandardFooterSponsorModule')),
};

export function FooterSponsorModule(props: FooterSponsorModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type FooterSponsorModuleProps = SponsorModuleProps;

export default FooterSponsorModule;
