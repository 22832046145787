import clsx from 'clsx';
import GalleryBadges from './GalleryBadges';
import useTypeTranslation from '../hooks/useTypeTranslation';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import String from '../atoms/String';
import useFirstUrl from '../hooks/useFirstUrl';
import { image } from '@gotombola/types';
import MainLogos from '../molecules/MainLogos';
import { useMemo } from 'react';
import QRCodeDownload from '../molecules/QRCodeDownload';
import firstAvailable from '../utils/firstAvailable';
import SectionTitle from '../atoms/SectionTitle';
import { WithTitle } from '@gotombola/react-types/lib/withs';

const offsets = {
    none: '',
    default: '-mt-24',
    medium: '-mt-24',
    small: '-mt-16',
    mobile_small: '-mt-12 sm:-mt-24',
};

export function GameInfos({
    game,
    publicPageShortUrl,
    secondLogo,
    title: forcedTitle,
    offset = 'default',
}: GameInfosProps) {
    const { t } = useTypeTranslation('game', game);
    const { blockShapeClass } = useStyling();
    const logoImage = firstAvailable(
        [game.mainLogoImage, game.logoImage, game.organizationLogoImage],
        (x) => x !== undefined && !!x.url,
    );
    const logos = useMemo(
        () =>
            [
                secondLogo?.url && { defaultAlt: t('sellergroup_avatar'), ...secondLogo },
                {
                    defaultIcon: 'ticket',
                    defaultAlt: t('organization_avatar'),
                    target: game.websiteUrl || game.organizationWebsiteUrl,
                    ...(logoImage || {}),
                    adZone: 'GP-3-1',
                    hidden: !!game.logoImageHidden,
                },
                ...(game?.coownerSponsorings?.items?.map((l) => (l?.logoImage ? { ...l.logoImage } : undefined)) || []),
            ].filter((x) => !!x && !x?.hidden),
        [
            secondLogo,
            logoImage,
            t,
            game.logoImageHidden,
            game.websiteUrl,
            game.organizationWebsiteUrl,
            game?.coownerSponsorings,
        ],
    );
    const title = forcedTitle || game.name;

    const url = useFirstUrl(publicPageShortUrl, game.publicPageShortUrl);
    const offsetClassName = useMemo(() => offsets[offset || ''], [offset]);

    return (
        <div className={'flex justify-center sm:justify-between'}>
            <div className={clsx('flex flex-col sm:items-start items-center', !!logos?.length && offsetClassName)}>
                <MainLogos className={'mb-2'} items={logos} />
                <div className={'flex flex-col mt-8 space-y-2'}>
                    <SectionTitle title={title} className={'sm:text-left mb-2'} basic level={1} />
                    {!!game.overline && (
                        <div className={'font-go-text font-light text-2xl sm:text-left text-center mb-2'}>
                            <String>{game.overline}</String>
                        </div>
                    )}
                    {!!game.badges && !!game.badges.items && !!game.badges.items.length && (
                        <GalleryBadges items={game.badges.items} size={'sm'} limit={4} />
                    )}
                </div>
            </div>
            <div className={'items-center hidden sm:flex sm:flex-col z-10'}>
                <div
                    className={clsx(
                        '-mt-24 overflow-hidden items-center w-48 h-auto hidden sm:flex sm:flex-col z-10 bg-white',
                        blockShapeClass,
                    )}
                >
                    <QRCodeDownload
                        lazy={false}
                        qrCodeClassName={'w-48 h-48'}
                        target={url}
                        withHeader
                        filename={`${game?.code}-qrcode`}
                        style={game?.tenantCode}
                        customLogo={game?.qrcodeLogoImage?.url}
                    />
                </div>
            </div>
        </div>
    );
}

export interface GameInfosProps extends WithTitle {
    game: any;
    publicPageShortUrl?: string;
    secondLogo?: image;
    offset?: string;
}

export default GameInfos;
