import loadable from '@loadable/component';
import { SponsorModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./presentation/StandardPresentationSponsorModule')),
};

export function PresentationSponsorModule(props: PresentationSponsorModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type PresentationSponsorModuleProps = SponsorModuleProps;

export default PresentationSponsorModule;
