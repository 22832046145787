import { styling_settings } from './types';

const settings: styling_settings = {
    shapes: {
        standard: {
            blockShapeClass: 'rounded-md',
            blockShapeNoMobileClass: 'md:rounded-md',
            blockHeaderShapeClass: 'rounded-t-md',
            blockFooterShapeClass: 'rounded-b-md',
            itemShapeClass: 'rounded-md',
            mediaShapeClass: 'rounded-lg',
            logoShapeClass: 'rounded-xl',
            tableShapeClass: 'rounded-lg',
        },
        square: {
            blockShapeClass: '',
            blockShapeNoMobileClass: '',
            blockHeaderShapeClass: '',
            blockFooterShapeClass: '',
            itemShapeClass: '',
            mediaShapeClass: '',
            logoShapeClass: '',
            tableShapeClass: '',
        },
        rounded: {
            blockShapeClass: 'rounded-lg',
            blockShapeNoMobileClass: 'md:rounded-lg',
            blockHeaderShapeClass: 'rounded-t-lg',
            blockFooterShapeClass: 'rounded-b-lg',
            itemShapeClass: 'rounded-lg',
            mediaShapeClass: 'rounded-xl',
            logoShapeClass: 'rounded-2xl',
            tableShapeClass: 'rounded-xl',
        },
        'extra-rounded': {
            blockShapeClass: 'rounded-2xl',
            blockShapeNoMobileClass: 'md:rounded-2xl',
            blockHeaderShapeClass: 'rounded-t-2xl',
            blockFooterShapeClass: 'rounded-b-2xl',
            itemShapeClass: 'rounded-2xl',
            mediaShapeClass: 'rounded-2xl',
            logoShapeClass: 'rounded-3xl',
            tableShapeClass: 'rounded-2xl',
        },
    },
    paddings: {
        xs: {
            blockHeaderPaddingClass: 'px-4 py-2',
            blockContentPaddingClass: 'p-2',
        },
        sm: {
            blockHeaderPaddingClass: 'px-6 py-4',
            blockContentPaddingClass: 'p-3',
        },
        md: {
            blockHeaderPaddingClass: 'px-8 py-5',
            blockContentPaddingClass: 'p-4',
        },
        standard: {
            blockHeaderPaddingClass: 'px-8 py-5',
            blockContentPaddingClass: 'p-4',
        },
        lg: {
            blockHeaderPaddingClass: 'px-12 py-7',
            blockContentPaddingClass: 'p-6',
        },
    },
    borders: {
        standard: {
            blockHeaderBorderClass: 'border-t border-r border-l',
            blockFooterBorderClass: 'border-b border-r border-l',
            blockContentOpenedBorderClass: 'border-t',
            blockBorderClass: 'border',
            blockBorderNoMobileClass: 'md:border',
            itemBorderClass: 'border',
        },
        bold: {
            blockHeaderBorderClass: 'border-t-2 border-r-2 border-l-2',
            blockFooterBorderClass: 'border-b-2 border-r-2 border-l-2',
            blockContentOpenedBorderClass: 'border-t-2',
            blockBorderClass: 'border-2',
            blockBorderNoMobileClass: 'md:border-2',
            itemBorderClass: 'border-2',
        },
        none: {
            blockHeaderBorderClass: '',
            blockFooterBorderClass: '',
            blockContentOpenedBorderClass: '',
            blockBorderClass: '',
            blockBorderNoMobileClass: '',
            itemBorderClass: '',
        },
    },
    borderColors: {
        standard: {
            blockBorderColorClass: 'border-gray-200',
            blockEmphasizedBorderColorClass: 'border-gray-300',
            itemBorderColorClass: 'border-gray-200',
        },
        dark: {
            blockBorderColorClass: 'border-gray-500',
            blockEmphasizedBorderColorClass: 'border-gray-600',
            itemBorderColorClass: 'border-gray-500',
        },
        light: {
            blockBorderColorClass: 'border-gray-100',
            blockEmphasizedBorderColorClass: 'border-gray-200',
            itemBorderColorClass: 'border-gray-100',
        },
        clear: {
            blockBorderColorClass: 'border-white',
            blockEmphasizedBorderColorClass: 'border-white',
            itemBorderColorClass: 'border-white',
        },
    },
    textColors: {
        standard: {
            titleTextColorClass: 'text-go-title',
            invertedTitleTextColorClass: 'text-white',
        },
    },
    backgrounds: {
        standard: {
            invertedBlockHeaderBackgroundClass: 'bg-go-title',
            blockHeaderBackgroundClass: '',
            blockBackgroundClass: 'bg-white',
        },
    },
    textSizes: {
        sm: {
            blockHeaderTextSizeClass: 'font-semibold text-sm',
            blockContentTextSizeClass: 'text-xs',
        },
        standard: {
            blockHeaderTextSizeClass: 'font-semibold text-lg',
            blockContentTextSizeClass: '',
        },
    },
    logoSizes: {
        xs: {
            logoSizeClass: 'h-24 w-24',
        },
        sm: {
            logoSizeClass: 'h-28 w-28',
        },
        standard: {
            logoSizeClass: 'h-32 w-32',
        },
        md: {
            logoSizeClass: 'h-32 w-32',
        },
        lg: {
            logoSizeClass: 'h-40 w-40',
        },
        xl: {
            logoSizeClass: 'h-48 w-48',
        },
    },
    thumbnail0Sizes: {
        standard: {
            thumbnail0SizeClass: 'w-16 h-16 sm:w-20 sm:h-20',
        },
        md: {
            thumbnail0SizeClass: 'w-16 h-16 sm:w-20 sm:h-20',
        },
        lg: {
            thumbnail0SizeClass: 'w-20 h-20 sm:w-24 sm:h-24',
        },
    },
    bannerSizes: {
        auto: {
            bannerSizeClass: 'h-auto',
        },
        standard: {
            bannerSizeClass: 'h-48 sm:h-96',
        },
        md: {
            bannerSizeClass: 'h-48 sm:h-96',
        },
        lg: {
            bannerSizeClass: 'h-60 sm:h-[28rem]',
        },
        xl: {
            bannerSizeClass: 'h-96 sm:h-[32rem]',
        },
    },
    bannerColors: {
        standard: {
            bannerColorClass: 'bg-go-secondary',
        },
        dark: {
            bannerColorClass: 'bg-gray-600',
        },
        none: {
            bannerColorClass: '',
        },
        custom: {
            bannerColorClass: '',
        },
    },
    textCases: {
        upper: {
            textCaseClass: 'uppercase',
        },
        lower: {
            textCaseClass: 'lowercase',
        },
        capital: {
            textCaseClass: 'capitalize',
        },
        normal: {
            textCaseClass: 'normal-case',
        },
    },
    textOverflows: {
        truncate: {
            textOverflowClass: 'truncate',
        },
        ellipsis: {
            textOverflowClass: 'text-ellipsis',
        },
        clip: {
            textOverflowClass: 'text-clip',
        },
    },
    textDecorations: {
        underline: {
            textDecorationClass: 'underline',
        },
        overline: {
            textDecorationClass: 'overline',
        },
        linethrough: {
            textDecorationClass: 'line-through',
        },
        nounderline: {
            textDecorationClass: 'no-underline',
        },
    },
    textSmoothings: {
        antialiased: {
            textSmoothingClass: 'antialiased',
        },
        subpixelantialiased: {
            textSmoothingClass: 'subpixel-antialiased',
        },
    },
    textStyles: {
        italic: {
            textStyleClass: 'italic',
        },
        notitalic: {
            textStyleClass: 'not-italic',
        },
    },
    textSpacings: {
        tighter: {
            textSpacingClass: 'tracking-tighter',
        },
        tight: {
            textSpacingClass: 'tracking-tight',
        },
        normal: {
            textSpacingClass: 'tracking-normal',
        },
        wide: {
            textSpacingClass: 'tracking-wide',
        },
        wider: {
            textSpacingClass: 'tracking-wider',
        },
        widest: {
            textSpacingClass: 'tracking-widest',
        },
    },
    sectionTextCases: {
        upper: {
            sectionTextCaseClass: 'uppercase',
        },
        lower: {
            sectionTextCaseClass: 'lowercase',
        },
        capital: {
            sectionTextCaseClass: 'capitalize',
        },
        normal: {
            sectionTextCaseClass: 'normal-case',
        },
    },
    sectionTextOverflows: {
        truncate: {
            sectionTextOverflowClass: 'truncate',
        },
        ellipsis: {
            sectionTextOverflowClass: 'text-ellipsis',
        },
        clip: {
            sectionTextOverflowClass: 'text-clip',
        },
    },
    sectionTextDecorations: {
        underline: {
            sectionTextDecorationClass: 'underline',
        },
        overline: {
            sectionTextDecorationClass: 'overline',
        },
        linethrough: {
            sectionTextDecorationClass: 'line-through',
        },
        nounderline: {
            sectionTextDecorationClass: 'no-underline',
        },
    },
    sectionTextSmoothings: {
        antialiased: {
            sectionTextSmoothingClass: 'antialiased',
        },
        subpixelantialiased: {
            sectionTextSmoothingClass: 'subpixel-antialiased',
        },
    },
    sectionTextStyles: {
        italic: {
            sectionTextStyleClass: 'italic',
        },
        notitalic: {
            sectionTextStyleClass: 'not-italic',
        },
    },
    sectionTextSpacings: {
        tighter: {
            sectionTextSpacingClass: 'tracking-tighter',
        },
        tight: {
            sectionTextSpacingClass: 'tracking-tight',
        },
        normal: {
            sectionTextSpacingClass: 'tracking-normal',
        },
        wide: {
            sectionTextSpacingClass: 'tracking-wide',
        },
        wider: {
            sectionTextSpacingClass: 'tracking-wider',
        },
        widest: {
            sectionTextSpacingClass: 'tracking-widest',
        },
    },
    sectionTitleCases: {
        upper: {
            sectionTitleCaseClass: 'uppercase',
        },
        lower: {
            sectionTitleCaseClass: 'lowercase',
        },
        capital: {
            sectionTitleCaseClass: 'capitalize',
        },
        normal: {
            sectionTitleCaseClass: 'normal-case',
        },
    },
    sectionTitleOverflows: {
        truncate: {
            sectionTitleOverflowClass: 'truncate',
        },
        ellipsis: {
            sectionTitleOverflowClass: 'text-ellipsis',
        },
        clip: {
            sectionTitleOverflowClass: 'text-clip',
        },
    },
    sectionTitleDecorations: {
        underline: {
            sectionTitleDecorationClass: 'underline',
        },
        overline: {
            sectionTitleDecorationClass: 'overline',
        },
        linethrough: {
            sectionTitleDecorationClass: 'line-through',
        },
        nounderline: {
            sectionTitleDecorationClass: 'no-underline',
        },
    },
    sectionTitleSmoothings: {
        antialiased: {
            sectionTitleSmoothingClass: 'antialiased',
        },
        subpixelantialiased: {
            sectionTitleSmoothingClass: 'subpixel-antialiased',
        },
    },
    sectionTitleStyles: {
        italic: {
            sectionTitleStyleClass: 'italic',
        },
        notitalic: {
            sectionTitleStyleClass: 'not-italic',
        },
    },
    sectionTitleSpacings: {
        tighter: {
            sectionTitleSpacingClass: 'tracking-tighter',
        },
        tight: {
            sectionTitleSpacingClass: 'tracking-tight',
        },
        normal: {
            sectionTitleSpacingClass: 'tracking-normal',
        },
        wide: {
            sectionTitleSpacingClass: 'tracking-wide',
        },
        wider: {
            sectionTitleSpacingClass: 'tracking-wider',
        },
        widest: {
            sectionTitleSpacingClass: 'tracking-widest',
        },
    },
    sectionSubtitleCases: {
        upper: {
            sectionSubtitleCaseClass: 'uppercase',
        },
        lower: {
            sectionSubtitleCaseClass: 'lowercase',
        },
        capital: {
            sectionSubtitleCaseClass: 'capitalize',
        },
        normal: {
            sectionSubtitleCaseClass: 'normal-case',
        },
    },
    sectionSubtitleOverflows: {
        truncate: {
            sectionSubtitleOverflowClass: 'truncate',
        },
        ellipsis: {
            sectionSubtitleOverflowClass: 'text-ellipsis',
        },
        clip: {
            sectionSubtitleOverflowClass: 'text-clip',
        },
    },
    sectionSubtitleDecorations: {
        underline: {
            sectionSubtitleDecorationClass: 'underline',
        },
        overline: {
            sectionSubtitleDecorationClass: 'overline',
        },
        linethrough: {
            sectionSubtitleDecorationClass: 'line-through',
        },
        nounderline: {
            sectionSubtitleDecorationClass: 'no-underline',
        },
    },
    sectionSubtitleSmoothings: {
        antialiased: {
            sectionSubtitleSmoothingClass: 'antialiased',
        },
        subpixelantialiased: {
            sectionSubtitleSmoothingClass: 'subpixel-antialiased',
        },
    },
    sectionSubtitleStyles: {
        italic: {
            sectionSubtitleStyleClass: 'italic',
        },
        notitalic: {
            sectionSubtitleStyleClass: 'not-italic',
        },
    },
    sectionSubtitleSpacings: {
        tighter: {
            sectionSubtitleSpacingClass: 'tracking-tighter',
        },
        tight: {
            sectionSubtitleSpacingClass: 'tracking-tight',
        },
        normal: {
            sectionSubtitleSpacingClass: 'tracking-normal',
        },
        wide: {
            sectionSubtitleSpacingClass: 'tracking-wide',
        },
        wider: {
            sectionSubtitleSpacingClass: 'tracking-wider',
        },
        widest: {
            sectionSubtitleSpacingClass: 'tracking-widest',
        },
    },
};

export default settings;
