import { dynamic_page_body_item_config } from '../types';
import { WithChildren, WithItems } from '../withs';
import AllInOneLayout from '../layouts/AllInOneLayout';
import DynamicPageBodyItems from '../organisms/DynamicPageBodyItems';

export function AllInOneTemplate({ children, items = [] }: AllInOneTemplateProps) {
    const headerChildren = <DynamicPageBodyItems items={items} zone={'header'} />;

    return (
        <AllInOneLayout headerChildren={headerChildren}>
            {children || ''}
            <DynamicPageBodyItems items={items} />
        </AllInOneLayout>
    );
}

export interface AllInOneTemplateProps extends WithChildren, WithItems<dynamic_page_body_item_config> {}

export default AllInOneTemplate;
