import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import Screenshots from '../../../organisms/Screenshots';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { useMemo } from 'react';
import toICULocale from '@gotombola/locale/lib/utils/toICULocale';

export function StandardServicePublicCustomizationScreenshotsPartnerModule({
    gameCodes = ['GSKP391', 'GMWN576', 'GSAU010', 'GJOD468'],
    locale,
    partner,
    ...rest
}: StandardServicePublicCustomizationScreenshotsPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTrans();
    locale = useMemo(() => toICULocale(locale), [locale]);
    const items = useMemo(
        () =>
            gameCodes.map((code) => {
                return {
                    image: {
                        url: `https://image.api.gotombola.co/images/z/${code}/a01/mobile/xxxx/game-${code}.webp?locale=${locale}`,
                    },
                    url: `https://gotb.la/${code}`,
                };
            }),
        [gameCodes, locale],
    );

    return (
        <ModuleContainer {...zprops}>
            <Screenshots title={t('partner_public_customization_screenshots_title')} items={items} {...props} />
        </ModuleContainer>
    );
}

export interface StandardServicePublicCustomizationScreenshotsPartnerModuleProps {
    locale: string;
    partner: any;
    gameCodes?: string[];
}

export default StandardServicePublicCustomizationScreenshotsPartnerModule;
