import { group_definition } from '../types';
import { WithChildren } from '../withs';
import GroupInstances from '@gotombola/react-modules-engine/lib/organisms/GroupInstances';

const defaultTemplateModules = {};
const defaultModuleProps = {};
const defaultNamedGroups = {};
const defaultGroups = [];

export function ColumnTemplateLayout({
    children,
    groups = defaultGroups,
    moduleProps = defaultModuleProps,
    namedGroups = defaultNamedGroups,
    templateModules = defaultTemplateModules,
}: ColumnTemplateLayoutProps) {
    const hasMain = !!namedGroups['main'];

    return (
        <div className={'w-full'}>
            <GroupInstances items={groups} templateModules={templateModules} moduleProps={moduleProps}>
                {(g: group_definition) => <>{'main' === g.type ? children : ''}</>}
            </GroupInstances>
            {!hasMain && (children || '')}
        </div>
    );
}

export interface ColumnTemplateLayoutProps extends WithChildren {
    namedGroups?: Record<string, group_definition>;
    groups?: group_definition[];
    templateModules?: any;
    moduleProps?: any;
}

export default ColumnTemplateLayout;
