import { useMemo } from 'react';
import { WithClassName } from '../../withs';
import SponsorLocaleSwitcher from '../SponsorLocaleSwitcher';
import Header from '../../organisms/Header';
import clsx from 'clsx';
import Img from '../../atoms/Img';
import String from '../../atoms/String';
import AdZone from '@gotombola/react-ad/lib/organisms/AdZone';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function SponsorHeader({ className, sponsor, light = false, locale }: SponsorHeaderProps) {
    const logoImage =
        !!sponsor.logoImage && !!sponsor.logoImage.url
            ? sponsor.logoImage
            : !!sponsor.organizationLogoImage
            ? sponsor.organizationLogoImage
            : undefined;
    const localeVersions = useMemo(
        () => sponsor?.sponsorVersions?.filter((x: any) => x.type === 'locale') || [],
        [sponsor],
    );
    const hasVersions = !!localeVersions && !!localeVersions.length;
    const { logoShapeClass } = useStyling();

    const name = sponsor.fullName || sponsor.organizationName;

    return (
        <Header className={className}>
            <div className={'flex w-full items-center justify-center'}>
                {!!logoImage && (
                    <Img
                        className={clsx('hidden md:block h-11 flex mr-4', logoShapeClass)}
                        url={logoImage.url}
                        alt={logoImage.alt || 'logo'}
                        maxSize={250}
                    />
                )}
                <div
                    className={clsx(
                        hasVersions ? 'ml-4 text-left sm:ml-0' : 'text-center',
                        'sm:text-left flex-1 py-4 font-bold text-xl truncate',
                    )}
                >
                    {!!sponsor.name && (
                        <span className={'font-go-text hidden lg:inline-block'}>
                            <String basic>{sponsor.name}</String>&nbsp;-&nbsp;
                        </span>
                    )}
                    {!!name && (
                        <span className={'font-go-text font-bold lg:font-normal'}>
                            <String basic>{name}</String>
                        </span>
                    )}
                </div>
            </div>
            {!!sponsor.code && (hasVersions || !light) && (
                <div className={'flex w-auto items-center justify-end space-x-2'}>
                    <AdZone id={'GP-1-1'} />
                    {hasVersions && (
                        <div className={'w-auto pr-4 sm:pr-0'}>
                            <SponsorLocaleSwitcher sponsor={sponsor} locale={locale} />
                        </div>
                    )}
                </div>
            )}
        </Header>
    );
}

export interface SponsorHeaderProps extends WithClassName {
    sponsor?: any;
    light?: boolean;
    locale?: string;
    extraBuyQs?: string;
}

// noinspection JSUnusedGlobalSymbols
export default SponsorHeader;
