import { useMemo } from 'react';
import { image } from '@gotombola/types/lib/types/base';
import useModuleContext from '@gotombola/react-modules-engine/lib/hooks/useModuleContext';

type module_image = image & { id?: string };

export function useTypeModuleImages(_: string, doc: any, forcedModuleName?: string) {
    const currentModuleName = useModuleContext()?.type;
    const moduleName = useMemo(() => forcedModuleName || currentModuleName, [forcedModuleName, currentModuleName]);
    return useMemo(
        () =>
            Object.entries({
                ...(doc?.modulesImages?.['all'] || {}),
                ...(doc?.modulesImages?.[moduleName || ''] || {}),
            }).reduce((acc, [k, v]) => Object.assign(acc, { [`${k}Image`]: v }), {} as Record<string, module_image>),
        [doc, moduleName],
    );
}

export default useTypeModuleImages;
