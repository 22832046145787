import TypeSponsors, { TypeSponsorsProps } from '../../../organisms/type/TypeSponsors';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const properties = ['logoImage', 'mainImage'];

const matcher = (doc: any) => !!doc?.sponsorings?.items?.length;

export function LogosSponsorsTypeModule({ typeType, ...rest }: LogosSponsorsTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    if (!doc) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeSponsors
                {...props}
                type={type}
                doc={doc}
                items={props.doc?.sponsorings.items}
                property={properties}
                defaultMsVariant={'basic'}
            />
        </ModuleContainer>
    );
}

export interface LogosSponsorsTypeModuleProps extends Omit<TypeSponsorsProps, 'type'> {
    typeType: string;
}

export default LogosSponsorsTypeModule;
