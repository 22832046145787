import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import ModuleContainer from '../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';
import Text from '../../atoms/Text';

function Component({ game, ...rest }: any) {
    const [zprops] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);

    return (
        <ModuleContainer {...zprops}>
            <div className={'w-full space-y-2'}>
                <Text bold className={'sm:text-xl'}>
                    {t('game_legal_page_website_host')}
                </Text>
                <div className={'flex flex-col gap-2'}>
                    {!!game.serviceHostName && (
                        <Text inline relaxed className={'sm:text-lg'}>
                            {game.serviceHostName}
                        </Text>
                    )}
                    {!!game.serviceHostStreet && (
                        <Text inline relaxed className={'sm:text-lg'}>
                            {game.serviceHostStreet}
                        </Text>
                    )}
                    <Text inline relaxed className={'sm:text-lg'}>
                        {game.serviceHostCity} {game.serviceHostState} {game.serviceHostZipCode}
                    </Text>
                    {!!game.serviceProviderWebsiteUrl && (
                        <Text inline relaxed className={'sm:text-lg'}>
                            {game.serviceProviderWebsiteUrl}
                        </Text>
                    )}
                </div>
            </div>
        </ModuleContainer>
    );
}

export function LegalWebsiteHostGameModule(props: LegalWebsiteHostGameModuleProps) {
    return <ModuleMode {...props} component={Component} />;
}

export type LegalWebsiteHostGameModuleProps = GameModuleProps;

export default LegalWebsiteHostGameModule;
