import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { WithItems } from '../../../withs';
import Bullets from '../../../organisms/Bullets';

export function StandardServiceBulletsPartnerModule({
    partner,
    items = [],
    ...rest
}: StandardServiceBulletsPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <Bullets items={items} {...props} />
        </ModuleContainer>
    );
}

export interface StandardServiceBulletsPartnerModuleProps extends WithItems {
    partner: any;
}

export default StandardServiceBulletsPartnerModule;
