import { useMemo } from 'react';
import Accordion from '../molecules/Accordion';

const defaultContent: any[] = [];

export function PageEditForm({ value, onChange, content = defaultContent }: PageEditFormProps) {
    const itemProps = useMemo(() => ({ value, onChange }), [value, onChange]);

    return <Accordion items={content} itemProps={itemProps} />;
}

export interface BasePageEditFormProps {
    value: any;
    onChange: Function;
}

export interface PageEditFormProps extends BasePageEditFormProps {
    content?: any[];
}

// noinspection JSUnusedGlobalSymbols
export default PageEditForm;
