import Select from 'react-select';
import {
    WithClassName,
    WithDefaultValue,
    WithDisabled,
    WithError,
    WithName,
    WithOnChange,
    WithRequired,
    WithTitle,
} from '../../withs';
import { useCallback } from 'react';
import Text from '../Text';

export function SelectField({
    className,
    defaultValue,
    required,
    title,
    values,
    nolabel = false,
    name,
    onChange,
    ...props
}: SelectFieldProps) {
    const handleChange = useCallback(
        (newValue: any) => {
            return onChange?.(
                newValue?.target
                    ? newValue
                    : newValue?.value
                    ? { target: { ...newValue, name } }
                    : { target: { name, value: newValue } },
            );
        },
        [onChange, name],
    );
    return (
        <div className={className}>
            {!nolabel && !!title && (
                <div>
                    <Text inline>{title}</Text>
                    {required && <span className={'text-red-500'}>*</span>}
                </div>
            )}
            <Select
                options={values}
                {...props}
                name={name}
                onChange={handleChange}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        height: '50px',
                    }),
                }}
            />
        </div>
    );
}

export interface SelectFieldProps
    extends WithClassName,
        WithDefaultValue<string>,
        WithDisabled,
        WithError,
        WithName,
        WithOnChange<any>,
        WithRequired,
        WithTitle {
    values?: { value: string; label: string }[];
    nolabel?: boolean;
}

export default SelectField;
