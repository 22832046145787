import loadable from '@loadable/component';
import { ModuleProps } from '../types';
import ModuleMode from '../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./paragraphs/StandardParagraphModule')),
};

export function ParagraphModule(props: ParagraphModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ParagraphModuleProps = ModuleProps;

export default ParagraphModule;
