import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./ownerGameWelcome/StandardOwnerGameWelcomeGameModule')),
};

export function OwnerGameWelcomeGameModule(props: OwnerGameWelcomeGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type OwnerGameWelcomeGameModuleProps = GameModuleProps;

export default OwnerGameWelcomeGameModule;
