import { useCallback, useState } from 'react';
import { prize } from '../../types';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import {
    WithClassName,
    WithDefaultValue,
    WithError,
    WithName,
    WithOnChange,
    WithRequired,
    WithTitle,
} from '../../withs';
import useSetRanks from '../../hooks/useSetRanks';
import useForceUpdate from '../../hooks/useForceUpdate';
import PrizeList from '../PrizeList';
import randomBunchId from '../../utils/randomBunchId';
import parseSerializedData from '../../utils/parseSerializedData';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Text from '../Text';

export function PrizesField({
    className,
    defaultValue,
    error,
    name = '',
    onChange,
    required,
    title,
}: PrizesFieldProps) {
    const { t } = useTrans();
    const forceUpdate = useForceUpdate();
    const { blockShapeClass } = useStyling();
    const [prizes, setPrizes] = useState<prize[]>(
        parseSerializedData(defaultValue) || [
            { id: 'first', rank: '1', name: '', quantity: 1 },
            { id: 'second', rank: '2', name: '', quantity: 1 },
            { id: 'third', rank: '3', name: '', quantity: 1 },
        ],
    );
    useSetRanks(prizes);
    const addPrize = useCallback(() => {
        const newItems = prizes;
        newItems.push({ id: randomBunchId(), rank: '', name: '', quantity: 1 });
        setPrizes(newItems);
        forceUpdate();
    }, [prizes, setPrizes, forceUpdate]);
    const removePrize = useCallback(
        (index: number) => {
            const newItems = prizes.filter((item, i) => {
                return i !== index;
            });
            setPrizes(newItems);
        },
        [prizes, setPrizes],
    );
    const onDragEnd = useCallback(
        (result) => {
            const newItems = Array.from(prizes);
            const [reorderedItem] = newItems.splice(result.source.index, 1);
            newItems.splice(result.destination.index, 0, reorderedItem);
            setPrizes(newItems);
        },
        [prizes, setPrizes],
    );
    const handleChange = useCallback(() => {
        onChange && onChange(JSON.stringify(prizes));
    }, [onChange, prizes]);

    return (
        <div className={className} onChange={handleChange}>
            {title && (
                <div>
                    <Text inline>{title}</Text>
                    {required && <span className={'text-red-500'}>*</span>}
                </div>
            )}
            <div className={clsx('flex flex-col border-1 pt-3', blockShapeClass)}>
                <PrizeList items={prizes} setItems={setPrizes} removeItem={removePrize} onDragEnd={onDragEnd} />
                <div>
                    <button
                        type={'button'}
                        className={clsx(
                            'font-go-button text-go-text flex items-center justify-center bg-blue-200 h-10 p-2 m-4 mt-6',
                            blockShapeClass,
                        )}
                        onClick={addPrize}
                    >
                        <Text className={'text-blue-500'}>{t('onboarding_form_bunches_add')}</Text>
                    </button>
                </div>
            </div>
            {error &&
                error.data &&
                error.data.fieldErrors &&
                error.data.fieldErrors[name] &&
                error.data.fieldErrors[name].map((message: string, index: number) => {
                    return (
                        <Text className={'text-red-500'} key={`error-${name}-${index}`}>
                            {message}
                        </Text>
                    );
                })}
        </div>
    );
}

export interface PrizesFieldProps
    extends WithClassName,
        WithDefaultValue,
        WithError<any>,
        WithName,
        WithOnChange,
        WithRequired,
        WithTitle {}

export default PrizesField;
