import { WithClassName } from '../../withs';
import Footer from '../../organisms/Footer';

export function OrganizationFooter({ className, organization }: OrganizationFooterProps) {
    const logoImage = !!organization.logoImage && !!organization.logoImage.url ? organization.logoImage : undefined;

    return (
        <Footer
            logo={logoImage}
            address={organization.city && `${organization.zipCode} ${organization.city}`}
            className={className}
            email={organization.publicEmail}
            fullName={organization.fullName || organization.legalName}
            name={organization.name}
            nin={organization.nin}
            phone={organization.publicPhone}
            street={organization.street}
        />
    );
}

export interface OrganizationFooterProps extends WithClassName {
    organization?: any;
}

export default OrganizationFooter;
