import { WithItems } from '../withs';
import Items from '../atoms/Items';
import Bullet from '../molecules/Bullet';
import { useCallback } from 'react';
import ZoneContainer from '../organisms/ZoneContainer';
import usePadding from '@gotombola/react-modules-engine/lib/hooks/usePadding';
import useModuleContext from '@gotombola/react-modules-engine/lib/hooks/useModuleContext';
import clsx from 'clsx';

export function Bullets({ items = [], ...props }: BulletsProps) {
    const itemsPropsFn = useCallback(() => props, [props]);

    const { first, last, middle } = useModuleContext();

    const paddingClassName = usePadding({ paddingMode: 'all', padding: 'md', first, last, middle });

    return (
        <ZoneContainer className={clsx('bg-go-panel-primary flex', paddingClassName)}>
            <div
                className={
                    'flex-1 flex flex-wrap justify-between items-start place-items-center justify-center py-8 gap-0 sm:gap-4 md:gap-6 lg:gap-10 sm:py-14'
                }
            >
                <Items items={items} component={Bullet} itemPropsFn={itemsPropsFn} />
            </div>
        </ZoneContainer>
    );
}

export type BulletsProps = WithItems;

export default Bullets;
