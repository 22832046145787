import { useMemo } from 'react';
import clsx from 'clsx';
import variants from './module-sections';
import { module_section } from '../types';
import SectionText from '../atoms/SectionText';
import useModuleGlobalContext from '@gotombola/react-modules-engine/lib/hooks/useModuleGlobalContext';

const defaultVariant = 'default';

const spaces = {
    none: '',
    xxs: 'gap-1',
    xs: 'gap-2',
    sm: 'gap-4',
    md: 'gap-6',
    lg: 'gap-10',
    xl: 'gap-14',
};

export function ModuleSection({
    children,
    className: explicitClassName,
    cols = undefined,
    colsClassNames = undefined,
    containerClassName,
    defaultCols = 0,
    defaultSubtitle,
    defaultTitle,
    defaultText,
    subtitle: forcedSubtitle,
    subtitleStyling,
    title: forcedTitle,
    titleStyling,
    text: forcedText,
    textStyling,
    xDivider = false,
    yDivider = false,
    variant = undefined,
    defaultVariant: explicitDefaultVariant = undefined,
    space = 'md',
    ...props
}: ModuleSectionProps) {
    const title = forcedTitle || defaultTitle || undefined;
    const subtitle = forcedSubtitle || defaultSubtitle || undefined;
    const text = forcedText || defaultText || undefined;
    const className = useMemo(
        () =>
            clsx(
                explicitClassName,
                (cols || defaultCols) !== undefined && colsClassNames && colsClassNames?.[`col-${cols || defaultCols}`],
                yDivider && 'divide-y',
                xDivider && 'divide-x',
                spaces[space || ''],
            ),
        [explicitClassName, colsClassNames, defaultCols, cols],
    );
    const globalVariant = useModuleGlobalContext()?.['variant'] || undefined;

    const Comp =
        variants[variant || globalVariant || explicitDefaultVariant || defaultVariant] || variants[defaultVariant];
    return (
        <Comp
            containerClassName={containerClassName}
            title={title}
            titleStyling={titleStyling}
            subtitle={subtitle}
            subtitleStyling={subtitleStyling}
            className={className}
            {...props}
        >
            {text ? (
                <>
                    <SectionText text={text} textStyling={textStyling} />
                    {children}
                </>
            ) : (
                children
            )}
        </Comp>
    );
}

export { BaseModuleSectionProps } from '../types';

export type ModuleSectionProps = module_section;

export default ModuleSection;
