import clsx from 'clsx';
import { WithButtons, WithClassName, WithOverline, WithSubtitle, WithText, WithTitle } from '../../withs';
import Buttons from '../../molecules/Buttons';
import useButtonsProps from '../../hooks/useButtonsProps';
import SectionTitle from '../../atoms/SectionTitle';
import Text from '../../atoms/Text';

const titleSizes = {
    xs: 'text-lg',
    sm: 'text-xl',
    md: 'text-2xl',
    lg: 'text-4xl',
    xl: 'text-6xl',
};

export function CenteredHeadingModule({
    className,
    overline,
    subtitle,
    text,
    title,
    titleSize = 'md',
    ...rest
}: CenteredHeadingModuleProps) {
    const [bprops] = useButtonsProps(rest);

    return !!title || !!text || !!overline || !!subtitle ? (
        <div className={clsx('flex flex-col space-y-4', className)}>
            <div className={'w-full my-6'}>
                {!!overline && (
                    <div className={'text-sm text-center text-gray-300 uppercase mb-2'}>
                        <Text>{overline}</Text>
                    </div>
                )}
                <SectionTitle title={title} className={clsx('mb-4 sm:mb-8', titleSizes[titleSize])} />
                {!!subtitle && (
                    <div className={'text-xl text-center text-go-secondary font-light sm:mb-4'}>
                        <Text>{subtitle}</Text>
                    </div>
                )}
                {!!text && (
                    <div className={'text-md text-center py-4'}>
                        <Text>{text}</Text>
                    </div>
                )}
                <Buttons className={'justify-center'} {...bprops} />
            </div>
        </div>
    ) : null;
}

export interface CenteredHeadingModuleProps
    extends WithClassName,
        WithOverline,
        WithText,
        WithSubtitle,
        WithTitle,
        WithButtons {
    titleSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default CenteredHeadingModule;
