export function getSerializedData(name: string, remove = true) {
    if ('undefined' === typeof window) return undefined;
    const data = window[`_${name.toUpperCase()}_`];
    if (remove) {
        delete window[`_${name.toUpperCase()}_`];
    }
    return data;
}

export default getSerializedData;
