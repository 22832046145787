import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
import BasicClientPage from '../BasicClientPage';
import { hydrate_client_params } from '../types';
import getSerializedData from './getSerializedData';
import unsecretize from '@gotombola/react-ssr-document/lib/utils/unsecretize';

function defaultGetContext() {
    return JSON.parse(unsecretize(getSerializedData('_PAGE_CONTEXT_', false)));
}

function defaultGetRoot() {
    return 'undefined' !== typeof document ? document.getElementById('root') : undefined;
}

function defaultOnError() {
    return (e: any) => {
        console.error(e);
    };
}

export function hydrateClient({
    App,
    createI18n,
    getRoot,
    getContext,
    ClientPage,
    preHydrate,
    postHydrate,
    register,
    onError,
}: hydrate_client_params) {
    const Component = ClientPage || BasicClientPage;
    getContext = getContext || defaultGetContext;
    getRoot = getRoot || defaultGetRoot;
    onError = onError || defaultOnError;

    return loadableReady(() => {
        const context = getContext!();
        !!preHydrate && preHydrate(context);
        hydrate(
            <Component
                app={App}
                mode={context?.mode}
                options={context?.options}
                createI18n={createI18n}
                context={context}
            />,
            getRoot!(),
        );
        !!postHydrate && postHydrate(context);
        !!register && register(context);
    })
        .then(() => {
            /* empty */
        })
        .catch(onError);
}

// noinspection JSUnusedGlobalSymbols
export default hydrateClient;
