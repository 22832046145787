import loadable from '@loadable/component';

export default {
    partner_footer: loadable(() => import('./FooterPartnerModule')),
    partner_header: loadable(() => import('./HeaderPartnerModule')),
    partner_banner: loadable(() => import('./BannerPartnerModule')),
    partner_service_features: loadable(() => import('./ServiceFeaturesPartnerModule')),
    partner_service_pricing: loadable(() => import('./ServicePricingPartnerModule')),
    partner_service_calltoaction: loadable(() => import('./ServiceCallToActionPartnerModule')),
    partner_service_sale_process_screenshots: loadable(() => import('./ServiceSaleProcessScreenshotsPartnerModule')),
    partner_service_public_customization_screenshots: loadable(
        () => import('./ServicePublicCustomizationScreenshotsPartnerModule'),
    ),
    partner_service_automatic_emails_screenshots: loadable(
        () => import('./ServiceAutomaticEmailsScreenshotsPartnerModule'),
    ),
    partner_service_push: loadable(() => import('./ServicePushPartnerModule')),
    partner_service_contact: loadable(() => import('./ServiceContactPartnerModule')),
    partner_register_form: loadable(() => import('./RegisterFormPartnerModule')),
    partner_registration_form: loadable(() => import('./RegistrationFormPartnerModule')),
    partner_mobile_calltoaction: loadable(() => import('./MobileCallToActionPartnerModule')),
    partner_service_quote: loadable(() => import('./ServiceQuotePartnerModule')),
    partner_service_steps: loadable(() => import('./ServiceStepsPartnerModule')),
    partner_service_figures: loadable(() => import('./ServiceFiguresPartnerModule')),
    partner_service_bullets: loadable(() => import('./ServiceBulletsPartnerModule')),
};
