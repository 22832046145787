import { useMemo } from 'react';

const rankSorter = (a: any, b: any) => (a?.rank > b?.rank ? 1 : a?.rank < b?.rank ? -1 : 0);

export function useGameLabels(game: any, forcedValue?: any[]) {
    return useMemo(() => {
        const glabels = (game?.glabels?.items || []).filter((x: any) => !!x?.public);
        const olabels = (game?.organizationOlabels?.items || []).filter((x: any) => !!x?.public);
        glabels.sort(rankSorter);
        olabels.sort(rankSorter);
        return forcedValue || [...glabels, ...olabels];
    }, [forcedValue, game?.glabels?.items, game?.organizationOlabels?.items]);
}

export default useGameLabels;
