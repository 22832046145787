import loadable from '@loadable/component';
import { CommonModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./banner/StandardBannerCommonModule')),
};

export function BannerCommonModule(props: BannerCommonModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type BannerCommonModuleProps = CommonModuleProps;

export default BannerCommonModule;
