import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./serviceCallToAction/StandardServiceCallToActionPartnerModule')),
};

export function ServiceCallToActionPartnerModule(props: ServiceCallToActionPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ServiceCallToActionPartnerModuleProps = PartnerModuleProps;

export default ServiceCallToActionPartnerModule;
