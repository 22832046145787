import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from '@tsparticles/engine';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            number: {
                value: 20,
                limit: 30,
                density: {
                    enable: true,
                    value_area: 800,
                },
            } as any,
            color: {
                value: '#ffffff',
            },
            shape: {
                type: 'circle',
                stroke: {
                    width: 1,
                    color: '#000000',
                },
                polygon: {
                    nb_sides: 5,
                },
            } as any,
            opacity: {
                value: 0.5,
                random: true,
                anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0.5,
                    sync: false,
                },
            } as any,
            size: {
                value: 10,
                random: true,
                anim: {
                    enable: true,
                    speed: 10,
                    size_min: 10,
                    sync: false,
                },
            } as any,
            line_linked: {
                enable: true,
                distance: 100,
                color: '#ffff00',
                opacity: 1,
                width: 10,
            },
            move: {
                enable: true,
                speed: 3,
                direction: 'none',
                random: false,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                },
            } as any,
        },
        interactivity: {
            detect_on: 'canvas',
            events: {
                onHover: {
                    enable: true,
                    mode: 'bubble',
                    parallax: {
                        enable: false,
                        force: 60,
                        smooth: 10,
                    },
                },
                onClick: {
                    enable: true,
                    mode: 'push',
                },
                resize: true,
            } as any,
            modes: {
                grab: {
                    distance: 400,
                    lineLinked: {
                        opacity: 1,
                    },
                },
                bubble: {
                    distance: 400,
                    size: 20,
                    duration: 2,
                    opacity: 1,
                    speed: 2,
                },
                repulse: {
                    distance: 200,
                },
                push: {
                    particles_nb: 4,
                },
                remove: {
                    particles_nb: 2,
                },
            },
        },
        backgroundMask: {
            enable: false,
            cover: {
                color: {
                    value: {
                        r: 0,
                        g: 0,
                        b: 0,
                    },
                },
            } as any,
        },
        retina_detect: true,
        fps_limit: 60,
    };
};
