import { createContext } from 'react';
import { ad_zones_settings_context_value } from '../types';
import createDefaultAdZonesSettingsContextValue from '../utils/createDefaultAdZonesSettingsContextValue';

export const AdZonesSettingsContext = createContext<ad_zones_settings_context_value>(
    createDefaultAdZonesSettingsContextValue(),
);
// noinspection JSUnusedGlobalSymbols
export const AdZonesSettingsContextProvider = AdZonesSettingsContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const AdZonesSettingsContextConsumer = AdZonesSettingsContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default AdZonesSettingsContext;
