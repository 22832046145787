import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./qrcode/StandardQrcodeTypeModule')),
};

export function QrcodeTypeModule(props: QrcodeTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type QrcodeTypeModuleProps = TypeModuleProps;

export default QrcodeTypeModule;
