import GodfatherBlock from '../molecules/GodfatherBlock';
import useNthGodfather from '../hooks/useNthGodfather';

export function GameGodfathers({ game }: GameGodfathersProps) {
    const g1 = useNthGodfather(game);
    const g2 = useNthGodfather(game, 2);
    const g3 = useNthGodfather(game, 3);

    return (
        <div className={'flex flex-col md:flex-row gap-4'}>
            {!!g1 && <GodfatherBlock {...g1} />}
            {!!g2 && <GodfatherBlock {...g2} />}
            {!!g3 && <GodfatherBlock {...g3} />}
        </div>
    );
}

export interface GameGodfathersProps {
    game: any;
}

export default GameGodfathers;
