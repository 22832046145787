import LinkButton, { LinkButtonProps } from './buttons/LinkButton';
import FunctionButton, { FunctionButtonProps } from './buttons/FunctionButton';
import clsx from 'clsx';
import { WithType } from '../withs';
import SubmitButton from './buttons/SubmitButton';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

const variants = {
    primary:
        'font-go-button whitespace-nowrap font-medium hover:shadow-md cursor-pointer w-full sm:w-auto px-4 py-2 text-center disabled:border-gray-300 disabled:text-white disabled:bg-gray-300 disabled:cursor-not-allowed bg-go-primary text-white border-2 border-go-primary hover:border-go-secondary hover:bg-go-secondary hover:text-white',
    secondary:
        'font-go-button whitespace-nowrap cursor-pointer w-full sm:w-auto px-4 py-2 text-center disabled:border-gray-300 disabled:text-white disabled:bg-gray-300 disabled:cursor-not-allowed bg-go-secondary text-white border-2 border-go-secondary hover:border-go-primary hover:bg-go-primary hover:text-white',
};

export function Button({ type, target, variant = 'primary', className, ...props }: ButtonProps) {
    const classNames = clsx(variants[variant || ''], className);
    const { blockShapeClass } = useStyling();

    return 'function' === typeof target ? (
        <FunctionButton className={clsx(classNames, blockShapeClass)} onClick={target} {...props} />
    ) : 'submit' === type ? (
        <SubmitButton className={clsx(classNames, blockShapeClass)} {...props} />
    ) : (
        <LinkButton className={clsx(classNames, blockShapeClass)} target={target} {...props} />
    );
}

export interface ButtonProps extends FunctionButtonProps, Omit<LinkButtonProps, 'target'>, WithType {
    variant?: 'primary' | 'secondary';
    target?: string | Function;
}

export default Button;
