import { useCallback } from 'react';
import clsx from 'clsx';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import Dropzone from 'react-dropzone-uploader/dist/react-dropzone-uploader';
import { WithClassName, WithDescription, WithOnSubmit, WithPlaceholder, WithTitle, WithUrl } from '../withs';

const defaultStyles = {
    dropzone: {
        minHeight: 250,
        maxHeight: 750,
    },
};

export function FileUploader({
    accept,
    autoUpload,
    className,
    description,
    getUploadParams,
    nonEmptyPlaceholder,
    onFileAbort,
    onFileRemove,
    onFileUpload,
    onSubmit,
    placeholder,
    styles = defaultStyles,
    title,
}: FileUploaderProps) {
    const { t } = useTrans();

    const handleChangeStatus = useCallback(
        ({ meta, file, remove }, status) => {
            switch (status) {
                case 'headers_received':
                    if (autoUpload) {
                        onFileUpload && onFileUpload(meta);
                    }
                    break;
                case 'done':
                    onFileUpload && onFileUpload(meta);
                    break;
                case 'removed':
                    onFileRemove && onFileRemove(meta);
                    break;
                case 'aborted':
                    if (autoUpload) {
                        remove();
                    }
                    onFileAbort && onFileAbort(meta);
                    break;
            }
        },
        [onFileUpload, onFileRemove, onFileAbort, autoUpload],
    );

    const handleSubmit = useCallback(
        ({ meta }, allFiles) => {
            allFiles.forEach((f: any) => f.remove());
            onSubmit && onSubmit();
        },
        [onSubmit],
    );

    return (
        <div className={'flex flex-col items-center justify-center text-center'}>
            <div className={'font-go-text tracking-tight mb-2 font-bold sm:text-xl'}>{title}</div>
            <div className={'font-go-text tracking-tight mb-2 font-light'}>{description}</div>
            <div className={clsx('rounded-lg w-full md:w-1/2 border', className)}>
                <Dropzone
                    getUploadParams={getUploadParams as any}
                    onChangeStatus={handleChangeStatus as any}
                    inputContent={placeholder}
                    inputWithFilesContent={nonEmptyPlaceholder || placeholder}
                    onSubmit={handleSubmit as any}
                    styles={styles}
                    accept={accept}
                    submitButtonContent={t('game_inbox_page_uploader_btn_submit_label')}
                />
            </div>
        </div>
    );
}

export interface FileUploaderProps
    extends WithClassName,
        WithDescription,
        WithOnSubmit,
        WithPlaceholder,
        WithTitle,
        WithUrl {
    accept?: string;
    styles?: any;
    nonEmptyPlaceholder?: string;
    onFileAbort?: Function;
    onFileRemove?: Function;
    onFileUpload?: Function;
    getUploadParams?: Function;
    autoUpload?: boolean;
}

// noinspection JSUnusedGlobalSymbols
export default FileUploader;
