import EditProvider from '../misc/EditProvider';
import { useCallback, useMemo } from 'react';
import PageEditForm from '../molecules/PageEditForm';
import { edit_provider_view } from '../types';
import useQueryPageModifiers from '../hooks/useQueryPageModifiers';

export function editablePage<P extends { staticContext: Record<string, any> } = any>(
    docKey: string,
    formComponentOrContent: any,
    Comp: any,
    { staticContext: fromStaticContext = true, view }: { staticContext?: boolean; view?: edit_provider_view } = {},
) {
    return function DynamicEditablePage(props: P) {
        const pp = (fromStaticContext ? props?.staticContext : props) || {};
        const data = useQueryPageModifiers(pp[docKey] || {});
        const onSubmit = useCallback((data: any) => {
            console.log(data);
        }, []);
        const formComponent = useMemo(
            () =>
                'function' === typeof formComponentOrContent
                    ? formComponentOrContent
                    : (props: any) => <PageEditForm content={formComponentOrContent} {...props} />,
            [formComponentOrContent],
        );

        return (
            <EditProvider data={data} formComponent={formComponent} onSubmit={onSubmit} view={view}>
                {(doc: any) => (
                    <Comp
                        {...props}
                        {...{
                            ...(fromStaticContext ? { staticContext: { ...pp, [docKey]: doc } } : { [docKey]: doc }),
                        }}
                    />
                )}
            </EditProvider>
        );
    };
}

// noinspection JSUnusedGlobalSymbols
export default editablePage;
