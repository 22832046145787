import clsx from 'clsx';
import { TypeProps } from '../../types';
import { WithAlt, WithIndex, WithName, WithOverline, WithQuantity } from '../../withs';
import Avatar from '../Avatar';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import String from '../../atoms/String';
import { formatAmount } from '@gotombola/amount';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { useMemo } from 'react';
import AnimatedImage from '../../atoms/AnimatedImage';

export function ListingTypeItem({
    alt,
    doc,
    index,
    itemsCount = 0,
    logoImage,
    mainImage,
    image1Image,
    image2Image,
    image3Image,
    image4Image,
    image5Image,
    image6Image,
    image7Image,
    image8Image,
    image9Image,
    name,
    onClick,
    overline,
    priceAmount,
    priceCurrency,
    quantity,
    rank,
    sponsorings,
    tag,
    type,
    websiteUrl,
    nolabel = false,
}: ListingTypeItemProps) {
    const { t } = useTypeTranslation(type, doc);
    const { blockShapeClass, blockFooterShapeClass, blockHeaderShapeClass } = useStyling();
    const rankString = `${rank}${quantity! > 1 ? `-${rank! + (quantity! - 1)}` : ''}`;
    const needRank = !!rank && itemsCount > 1;

    const extraImages = useMemo(
        () =>
            [
                image1Image,
                image2Image,
                image3Image,
                image4Image,
                image5Image,
                image6Image,
                image7Image,
                image8Image,
                image9Image,
            ].filter((x: any) => !!x?.url) as any[],
        [
            image1Image,
            image2Image,
            image3Image,
            image4Image,
            image5Image,
            image6Image,
            image7Image,
            image8Image,
            image9Image,
        ],
    );
    return (
        <div
            className={clsx('container', (index || 0) % 2 && 'bg-gray-50', nolabel ? '' : 'flex-1')}
            onClick={onClick as any}
        >
            <div
                className={clsx(
                    'lg:flex items-center hover:bg-blue-50 p-6 hover:cursor-pointer group-hover:shadow-sm',
                    nolabel && 'justify-center',
                )}
            >
                <div className={clsx('text-go-panel-secondary justify-center bg-go-panel-primary', blockShapeClass)}>
                    <div className={clsx('relative w-full', nolabel ? 'lg:w-96' : 'lg:w-48')}>
                        {needRank && (
                            <div
                                className={
                                    'font-go-text text-lg text-go-panel-secondary text-center p-0.5 font-bold break-words'
                                }
                            >
                                {t(`${type}_listing_rank_position_item`, { rankString })}
                            </div>
                        )}
                        {!!mainImage?.url && (
                            <AnimatedImage
                                className={clsx(
                                    'w-full object-cover box-border',
                                    blockFooterShapeClass,
                                    !needRank && blockHeaderShapeClass,
                                    nolabel ? 'lg:w-96' : 'lg:w-48',
                                )}
                                mainImage={mainImage}
                                maxSize={300}
                                alt={alt || name || `item #${(index || 0) + 1}`}
                                extraImages={extraImages}
                            />
                        )}
                        {!!tag && (
                            <div
                                className={clsx(
                                    'font-go-text absolute bottom-5 left-4 p-1.5 bg-black text-go-panel-secondary font-bold uppercase group-hover:shadow-md',
                                    blockShapeClass,
                                )}
                            >
                                {tag}
                            </div>
                        )}
                        {!!quantity && quantity > 1 && !!mainImage?.url && (
                            <div
                                className={
                                    'font-go-text flex items-center justify-center absolute right-2 bottom-2 border-4 border-white w-14 h-14 bg-go-panel-primary text-go-panel-secondary text-xl font-bold uppercase rounded-full group-hover:shadow-md'
                                }
                            >
                                {quantity}
                            </div>
                        )}
                    </div>
                </div>
                {!nolabel && (
                    <div className={'flex lg:ml-12 my-8 lg:my-0 lg:w-2/3 flex-col'}>
                        {!!name && !nolabel && (
                            <div className={'font-go-text font-light text-lg'}>
                                <String>{name}</String>
                            </div>
                        )}
                        {!!overline && (
                            <div className={'font-go-text font-light text-sm text-gray-500'}>
                                <String>{overline}</String>
                            </div>
                        )}
                        {!!priceAmount && (
                            <div className={'text-sm'}>
                                {formatAmount(priceAmount, priceCurrency)}
                                <span className={'font-go-text ml-2 font-light text-sm text-gray-500 text-right'}>
                                    {t(`${type}_listing_estimated_value_item`)}
                                </span>
                            </div>
                        )}
                        <div className={'mt-4 flex flex-col gap-4'}>
                            {!!sponsorings?.length && (
                                <div className={'font-go-text text-gray-500 text-sm font-light'}>
                                    {t(
                                        [
                                            `${type}_sponsoring_overline_${sponsorings.length.toString()}`,
                                            `${type}_sponsoring_overline`,
                                        ],
                                        sponsorings.reduce(
                                            (acc, s, index) =>
                                                Object.assign(acc, {
                                                    [`name${index > 0 ? (index + 1).toString() : ''}`]: s?.name,
                                                }),
                                            {} as any,
                                        ),
                                    )}
                                </div>
                            )}
                            {!!sponsorings?.length &&
                                sponsorings.map(({ websiteUrl, mainImage, logoImage }, i) => (
                                    <Avatar
                                        key={i}
                                        websiteUrl={websiteUrl}
                                        logoImage={
                                            (logoImage?.url ? logoImage : undefined) ||
                                            (mainImage?.url ? mainImage : undefined)
                                        }
                                    />
                                ))}
                            {!!websiteUrl && <Avatar websiteUrl={websiteUrl} logoImage={logoImage} />}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export interface ListingTypeItemProps
    extends TypeProps,
        Required<WithAlt>,
        WithIndex,
        WithName,
        WithOverline,
        WithQuantity {
    nolabel?: boolean;
    logoImage?: { url: string; alt: string };
    mainImage?: { url: string; alt: string };
    image1Image?: { url: string; alt: string };
    image2Image?: { url: string; alt: string };
    image3Image?: { url: string; alt: string };
    image4Image?: { url: string; alt: string };
    image5Image?: { url: string; alt: string };
    image6Image?: { url: string; alt: string };
    image7Image?: { url: string; alt: string };
    image8Image?: { url: string; alt: string };
    image9Image?: { url: string; alt: string };
    onClick?: Function;
    priceAmount?: number;
    priceCurrency?: string;
    rank?: number;
    sponsorings?: {
        websiteUrl?: string;
        description?: string;
        name?: string;
        logoImage?: { url: string; alt: string };
        mainImage?: { url: string; alt: string };
    }[];
    tag?: string;
    websiteUrl?: string;
    itemsCount?: number;
}

// noinspection JSUnusedGlobalSymbols
export default ListingTypeItem;
