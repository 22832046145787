import {
    WithAutoFocus,
    WithClassName,
    WithDefaultValue,
    WithDisabled,
    WithName,
    WithNativeOnChange,
    WithNativePlaceholder,
    WithRequired,
    WithType,
    WithValue,
} from '../../../withs';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function SingleLineTextInput({ className, name, required, ...props }: SingleLineTextInputProps) {
    const { blockShapeClass } = useStyling();

    return (
        <input
            {...props}
            className={clsx(
                'font-go-text p-3 border w-full',
                required ? 'border-gray-500' : 'border-gray-300',
                blockShapeClass,
                className,
            )}
            name={name}
            required={required}
        />
    );
}

export interface SingleLineTextInputProps
    extends WithAutoFocus,
        WithClassName,
        WithDefaultValue,
        WithDisabled,
        WithName,
        WithNativeOnChange,
        WithNativePlaceholder,
        WithRequired,
        WithType,
        WithValue {
    prefix?: string;
    pattern?: string;
}

export default SingleLineTextInput;
