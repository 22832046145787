import TypeListing, { TypeListingProps } from '../../../organisms/type/TypeListing';
import ModuleContainer from '../../../organisms/ModuleContainer';
import FeaturedSection from '../../../molecules/FeaturedSection';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import DefaultModuleSectionItemList from '../../../molecules/DefaultModuleSectionItemList';

const listProps = {
    defaultCols: 1,
};

export function StandardFeaturedBunchesGameModule({ ...rest }: StandardFeaturedBunchesGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const featuredBunches = props.game?.rankedBunches?.items?.filter((x) => x.featured);

    if (!featuredBunches?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeListing
                {...props}
                doc={props.game}
                type={'game'}
                items={featuredBunches}
                sponsorings={props.game?.sponsorings}
                listComponent={DefaultModuleSectionItemList}
                listProps={listProps}
                itemComponent={FeaturedSection}
                itemType={'bunch'}
            />
        </ModuleContainer>
    );
}

export interface StandardFeaturedBunchesGameModuleProps extends Omit<TypeListingProps, 'type'> {
    game?: any;
}

export default StandardFeaturedBunchesGameModule;
