import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function PartnerTemplate({ page = 'partner_public', partner, ...props }: PartnerTemplateProps) {
    return <TypeTemplate type={'partner'} page={page.replace(/^partner_/, '')} doc={partner} {...props} />;
}

export interface PartnerTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    partner?: any;
}

export default PartnerTemplate;
