import clsx from 'clsx';
import {
    WithButtons,
    WithImage,
    WithLogo,
    WithNopadding,
    WithOverline,
    WithSubtext,
    WithText,
    WithTitle,
} from '../withs';
import RichText from '../atoms/RichText';
import Img from '../atoms/Img';
import Buttons from '../molecules/Buttons';
import useButtonsProps from '../hooks/useButtonsProps';
import { class_name } from '../types';
import VideoModule from '../modules/VideoModule';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function ContentHeadingPushModule({
    videoUrl,
    image,
    logo,
    nopadding = false,
    overline,
    subtext,
    text,
    title,
    right = false,
    shareUrl,
    buttonsClassName,
    ...rest
}: ContentHeadingPushModuleProps) {
    const [bprops] = useButtonsProps(rest);
    const { mediaShapeClass, logoShapeClass } = useStyling();

    const videoMode = !!videoUrl;
    const imageOnlyMode = !!image?.url && !logo?.url;
    const imageLogoMode = !!image?.url && !!logo?.url;

    return (
        <div
            className={clsx(
                'flex flex-col sm:flex-row justify-between items-center',
                !nopadding && 'py-4 sm:py-16 px-2 sm:px-8',
                (videoMode || imageOnlyMode) && 'gap-8',
            )}
        >
            <div
                className={clsx(
                    'relative mb-4 sm:mb-0',
                    right && ' sm:order-last',
                    !videoMode && 'w-full sm:w-4/12',
                    (videoMode || imageOnlyMode) && 'w-full lg:w-6/12',
                )}
            >
                {!videoMode && (imageOnlyMode || imageLogoMode) && (
                    <Img
                        {...image}
                        className={clsx('object-cover w-full h-auto', mediaShapeClass)}
                        alt={image.alt || 'image item'}
                        maxSize={800}
                        maxSizeTablet={700}
                        maxSizeMobile={500}
                    />
                )}
                {videoMode && (
                    <VideoModule
                        url={videoUrl}
                        nopadding
                        playing
                        className={clsx('object-cover w-full max-h-[300px] h-auto', mediaShapeClass)}
                    />
                )}
                {!videoMode && imageLogoMode && (
                    <div
                        className={clsx(
                            'hidden sm:absolute p-4 bg-go-logo border border-1 border-go-primary overflow-hidden sm:flex justify-center items-center bottom-0 sm:-bottom-4 right-0 sm:-right-8 w-28 h-28',
                            right && 'left-0 sm:-left-8',
                            logoShapeClass,
                        )}
                    >
                        <Img {...logo} className={'object-contain'} alt={image.alt || 'logo'} maxSize={200} />
                    </div>
                )}
            </div>
            <div
                className={clsx(
                    'w-full flex flex-col justify-center items-start',
                    !nopadding && 'sm:p-6',
                    right && 'sm:order-first',
                    !videoMode && 'sm:w-7/12',
                    (videoMode || imageOnlyMode) && 'lg:w-6/12',
                )}
            >
                {!!overline && (
                    <div className={'font-go-text text-sm font-medium text-gray-300 uppercase mb-3 sm:mb-6'}>
                        <RichText value={overline} />
                    </div>
                )}
                {!!title && (
                    <div
                        className={
                            'font-go-text text-center sm:text-left text-5xl text-black leading-none font-bold mb-4 sm:mb-8'
                        }
                    >
                        <RichText value={title} />
                    </div>
                )}
                {!!text && (
                    <div className={'font-go-text text-md text-justify font-light text-gray-600 mb-4 sm:mb-8'}>
                        <RichText value={text} />
                    </div>
                )}
                <Buttons className={clsx(buttonsClassName)} {...bprops} />
                {!!subtext && (
                    <div className={'font-go-text text-xs text-justify font-light text-gray-600 mt-3 sm:mt-6'}>
                        <RichText value={subtext} />
                    </div>
                )}
            </div>
        </div>
    );
}

export interface ContentHeadingPushModuleProps
    extends WithImage,
        WithLogo,
        WithNopadding,
        WithOverline,
        WithSubtext,
        WithText,
        WithTitle,
        WithButtons {
    right?: boolean;
    shareUrl?: string;
    buttonsClassName?: class_name;
    videoUrl?: string;
}

export default ContentHeadingPushModule;
