import { useMemo } from 'react';
import { http_call, http_state } from '../types';
import useHttp from './useHttp';

export function useHttpMutation(
    url: string,
    method = 'POST',
    headers: any = { 'Content-Type': 'application/json' },
    options: any = {},
) {
    options = useMemo(() => ({ ...options, direct: false }), [options]);
    return useHttp(url, method, headers, options) as [http_call, http_state];
}

// noinspection JSUnusedGlobalSymbols
export default useHttpMutation;
