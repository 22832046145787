import { Engine, ISourceOptions } from '@tsparticles/engine';
import { loadHeartShape } from '@tsparticles/shape-heart';
import { loadFull } from 'tsparticles';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    await loadHeartShape(engine);
    return {
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            move: {
                angle: {
                    offset: 0,
                    value: 15,
                },
                direction: 'bottom',
                enable: true,
                outModes: {
                    default: 'out',
                },
                speed: 3,
            },
            number: {
                value: 10,
            },
            opacity: {
                value: 1,
            },
            shape: {
                type: 'image',
                options: {
                    image: [
                        {
                            src: 'https://statics.gotombola.co/tenants/gotombola/images/misc/childhoodcancer.svg',
                        },
                    ],
                },
            },
            size: {
                value: 64,
            },

            zIndex: {
                value: {
                    min: 0,
                    max: 100,
                },
                opacityRate: 0,
                velocityRate: 2,
            },
        },
    };
};
