import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypeFunnelStep from '../../../organisms/TypeFunnelStep';
import Input from '../../../atoms/Input';
import useTypeFormStep from '../../../hooks/useTypeFormStep';

export function GameRegistrationFormPartnerModule({
    partner,
    registration,
    ...rest
}: GameRegistrationFormPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t, stepProps, inputProps, register } = useTypeFormStep('partner', partner, 'registration', 'game', {
        registration,
    });

    return (
        <ModuleContainer {...zprops}>
            <TypeFunnelStep {...stepProps} {...props}>
                <Input
                    {...register('gameName', { required: true })}
                    placeholder={t('field_gamename_placeholder')}
                    required
                    autoFocus
                    {...inputProps}
                />
            </TypeFunnelStep>
        </ModuleContainer>
    );
}

export interface GameRegistrationFormPartnerModuleProps {
    partner: any;
    registration: any;
}

export default GameRegistrationFormPartnerModule;
