import { useMemo } from 'react';
import { TypeProps } from '../../types';
import Effects from '../../misc/Effects';
import useFirstTypeInHierarchyMatching from '../../hooks/useFirstTypeInHierarchyMatching';

const defaultEffects = {
    default: '',
};

const matcher = (doc: any) => doc?.publicPageEffects;

export function TypeEffects({ type, ...rest }: TypeEffectsProps) {
    const [, doc] = useFirstTypeInHierarchyMatching(type, rest, matcher, 'basic');

    const effects = useMemo(
        () =>
            (
                doc?.publicPageEffects ||
                defaultEffects[`${doc?.type || ''}${doc?.permanent ? '_permanent' : ''}`] ||
                defaultEffects[`${doc?.type || ''}`] ||
                defaultEffects['default']
            )
                .split(/\s*,\s*/g)
                .filter((x: any) => !!x)
                .map((x: string) => ({ type: x })),
        [doc?.publicPageEffects],
    );

    return <Effects items={effects} />;
}

export type TypeEffectsProps = TypeProps;

// noinspection JSUnusedGlobalSymbols
export default TypeEffects;
