import clsx from 'clsx';
import { WithClassName, WithDescription, WithName } from '../withs';
import StaticBadge from './StaticBadge';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import Text from './Text';

export function Badge({ className, description, icon, name, size = 'md' }: BadgeProps) {
    const { blockShapeClass } = useStyling();

    return (
        <div
            className={clsx(
                'bg-white group border border-gray-200 flex justify-center items-center cursor-pointer hover:border-go-primary hover:opacity-80',
                'sm' === size && 'w-12 h-10',
                'md' === size && ' w-20 h-16',
                'xl' === size && ' w-28 h-20',
                '2xl' === size && ' w-40 h-28',
                blockShapeClass,
                className,
            )}
        >
            {!!icon && <StaticBadge type={icon} className={clsx('sm' === size && 'w-6 h-7')} />}
            <div
                className={clsx(
                    'hidden group-hover:block absolute -top-20 bg-gray-50 border border-gray-200 px-4 py-2 text-center',
                    blockShapeClass,
                )}
            >
                <Text semibold basic>
                    {name}
                </Text>
                <Text className={'text-gray-600'}>{description}</Text>
            </div>
        </div>
    );
}

export interface BadgeProps extends WithClassName, WithDescription, WithName {
    icon?: string;
    size?: 'sm' | 'md' | 'xl' | '2xl';
}

// noinspection JSUnusedGlobalSymbols
export default Badge;
