import Markdown from './Markdown';
import { WithBasic, WithClassName, WithText } from '../withs';

export function MultiLineText({ basic, className, text }: MultiLineTextProps) {
    if (!text || !text?.length) return null;

    return (
        <Markdown className={className} basic={basic}>
            {text}
        </Markdown>
    );
}

export interface MultiLineTextProps extends WithBasic, WithClassName, WithText<string> {}

export default MultiLineText;
