import GotombolaFooter, { GotombolaFooterProps } from '../../../molecules/footers/GotombolaFooter';
import ModuleContainer from '../../../organisms/ModuleContainer';
import { WithZoneCustomization } from '../../../withs';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { useMemo } from 'react';

export function StandardTenantFooterCommonModule({ typeType, ...rest }: StandardTenantFooterCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const game = useMemo(() => props?.['game'], [props]);
    const organization = useMemo(() => props?.['organization'], [props]);
    const tenant = useMemo(() => props?.['tenant'], [props]);

    const locale = useMemo(
        () => [game?.locale, organization?.locale, tenant?.locale].find((x) => !!x),
        [game, organization, tenant],
    );
    const country = useMemo(
        () => [game?.country, organization?.country, tenant?.country].find((x) => !!x),
        [game, organization, tenant],
    );

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <GotombolaFooter {...props} type={typeType} locale={locale} country={country} />
        </ModuleContainer>
    );
}

export interface StandardTenantFooterCommonModuleProps extends GotombolaFooterProps, WithZoneCustomization {
    typeType: string;
}

export default StandardTenantFooterCommonModule;
