import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import PartnerTemplate, { PartnerTemplateProps } from './PartnerTemplate';

export function DynamicPartnerPublicTemplate(props: DynamicPartnerPublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <PartnerTemplate {...props} partner={context.doc} />;
}

export type DynamicPartnerPublicTemplateProps = Omit<PartnerTemplateProps, 'partner'>;

export default DynamicPartnerPublicTemplate;
