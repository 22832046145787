import loadable from '@loadable/component';

export default {
    all_in_one: loadable(() => import('./AllInOneTemplate')),
    basic: loadable(() => import('./BasicTemplate')),
    central_all_in_one: loadable(() => import('./CentralAllInOneTemplate')),
    central_box: loadable(() => import('./CentralBoxTemplate')),
    dynamic_sellergroup_public: loadable(() => import('./DynamicSellergroupPublicTemplate')),
    dynamic_seller_public: loadable(() => import('./DynamicSellerPublicTemplate')),
    dynamic_godfather_public: loadable(() => import('./DynamicGodfatherPublicTemplate')),
    dynamic_sponsoring_public: loadable(() => import('./DynamicSponsoringPublicTemplate')),
    dynamic_sponsor_public: loadable(() => import('./DynamicSponsorPublicTemplate')),
    dynamic_donee_public: loadable(() => import('./DynamicDoneePublicTemplate')),
    dynamic_game_public: loadable(() => import('./DynamicGamePublicTemplate')),
    dynamic_organization_public: loadable(() => import('./DynamicOrganizationPublicTemplate')),
    dynamic_partner_public: loadable(() => import('./DynamicPartnerPublicTemplate')),
    dynamic_campaign_public: loadable(() => import('./DynamicCampaignPublicTemplate')),
    dynamic_tenant_public: loadable(() => import('./DynamicTenantPublicTemplate')),
    left_box: loadable(() => import('./LeftBoxTemplate')),
    sellergroup: loadable(() => import('./SellergroupTemplate')),
    seller: loadable(() => import('./SellerTemplate')),
    godfather: loadable(() => import('./GodfatherTemplate')),
    sponsoring: loadable(() => import('./SponsoringTemplate')),
    sponsor: loadable(() => import('./SponsorTemplate')),
    donee: loadable(() => import('./DoneeTemplate')),
    game: loadable(() => import('./GameTemplate')),
    organization: loadable(() => import('./OrganizationTemplate')),
    partner: loadable(() => import('./PartnerTemplate')),
    campaign: loadable(() => import('./CampaignTemplate')),
    tenant: loadable(() => import('./TenantTemplate')),
    right_box: loadable(() => import('./RightBoxTemplate')),
    type: loadable(() => import('./TypeTemplate')),
    unknown: loadable(() => import('./UnknownTemplate')),
    default: loadable(() => import('./UnknownTemplate')),
};
