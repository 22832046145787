import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function SellerTemplate({ seller, page = 'seller_public', ...props }: SellerTemplateProps) {
    return <TypeTemplate type={'seller'} page={page.replace(/^seller_/, '')} doc={seller} {...props} />;
}

export interface SellerTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    seller?: any;
    game?: any;
}

export default SellerTemplate;
