import clsx from 'clsx';
import { TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import QRCodeDownload from '../../molecules/QRCodeDownload';

export function TypeQrcode({ className, doc, type }: TypeQrcodeProps) {
    if (!doc?.publicPageShortUrl) return null;

    return (
        <div className={clsx('space-y-8 sm:space-y-4', className)}>
            <QRCodeDownload
                qrCodeClassName={'flex-1 w-full'}
                target={doc.publicPageShortUrl}
                style={doc?.tenantCode}
                customLogo={doc?.qrcodeLogoImage?.url}
                withButton
            />
        </div>
    );
}

export interface TypeQrcodeProps extends TypeProps, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default TypeQrcode;
