import { WithClassName } from '../../withs';
import Footer from '../../organisms/Footer';

export function TenantFooter({ className, tenant }: TenantFooterProps) {
    const logoImage = !!tenant.logoImage && !!tenant.logoImage.url ? tenant.logoImage : undefined;

    return (
        <Footer
            logo={logoImage}
            address={tenant.city && `${tenant.zipCode} ${tenant.city}`}
            className={className}
            email={tenant.publicEmail}
            fullName={tenant.fullName || tenant.legalName}
            name={tenant.name}
            nin={tenant.nin}
            phone={tenant.publicPhone}
            street={tenant.street}
        />
    );
}

export interface TenantFooterProps extends WithClassName {
    tenant?: any;
}

export default TenantFooter;
