import Text, { TextProps } from '../atoms/Text';
import useDocAttribute from '../hooks/useDocAttribute';

export function AttributeMessage({ doc, text: forcedText, attribute, ...props }: AttributeMessageProps) {
    const text = useDocAttribute(doc, attribute, forcedText);
    return <Text light {...props} className={'text-center sm:text-justify w-full mb-4'} value={text} />;
}

export interface AttributeMessageProps extends TextProps {
    doc?: any;
    text?: string;
    attribute?: string | string[];
}

// noinspection JSUnusedGlobalSymbols
export default AttributeMessage;
