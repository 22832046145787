import { forwardRef } from 'react';
import clsx from 'clsx';
import { WithClassName, WithDisabled, WithLabel, WithLoading, WithNativeOnClick } from '../withs';
import Spinner2 from './Spinner2';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

const styles = {
    contained_primary: 'font-medium text-white bg-go-primary hover:bg-go-secondary hover:shadow-md cursor-pointer ',
    contained_secondary: 'font-medium text-white bg-go-secondary hover:bg-go-primary hover:shadow-md cursor-pointer ',
    contained_disabled: 'font-medium text-gray-500 bg-gray-100',
    outlined_primary:
        'font-medium bg-transparent border-2 border-go-primary text-go-primary hover:text-white hover:bg-go-primary hover:shadow-md cursor-pointer ',
    outlined_secondary:
        'font-medium bg-transparent border-2 border-go-secondary text-go-secondary hover:text-white hover:bg-go-secondary hover:shadow-md cursor-pointer ',
    outlined_disabled: 'font-medium text-black bg-gray-200',
    filled_primary: 'text-go-primary cursor-pointer underline',
    filled_secondary: 'text-go-secondary cursor-pointer underline',
    filled_disabled: 'text-black bg-gray-200',
};

export const MainButton = forwardRef(
    (
        {
            className,
            color = 'primary',
            disabled = false,
            label,
            loading = false,
            onClick,
            variant = 'contained',
        }: MainButtonProps,
        ref: any,
    ) => {
        const style = `${variant}_${disabled ? 'disabled' : color}`;
        const { blockShapeClass } = useStyling();

        return (
            <button
                disabled={loading || disabled}
                ref={ref}
                onClick={onClick}
                className={clsx(
                    'font-go-button flex justify-center items-center w-full sm:w-auto sm:min-w-[10rem] px-4 py-2 box-border',
                    styles[style],
                    blockShapeClass,
                    className,
                )}
            >
                {loading && <Spinner2 size={'sm'} color={'primary'} />}
                {!loading && (label || '')}
            </button>
        );
    },
);

MainButton.displayName = 'MainButton';

export interface MainButtonProps extends WithClassName, WithDisabled, WithLabel, WithNativeOnClick, WithLoading {
    variant?: 'contained' | 'outlined' | 'filled';
    color?: 'primary' | 'secondary';
}

// noinspection JSUnusedGlobalSymbols
export default MainButton;
