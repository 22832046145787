import useTypeTranslation from '../../../hooks/useTypeTranslation';
import TypeListing, { TypeListingProps } from '../../../organisms/type/TypeListing';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import ListKeyfigureItem from '../../../molecules/keyfigures/ListKeyfigureItem';
import useGameKeyfigures from '@gotombola/react-game/lib/hooks/useGameKeyfigures';
import { useMemo } from 'react';

const listProps = {
    defaultMsVariant: 'basic',
    defaultCols: 0,
};

export function ListLabelsGameModule({ title: forcedTitle, ...rest }: ListLabelsGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', props.game);

    const title = useMemo(() => forcedTitle || t('game_keyfigures_label'), [forcedTitle, t]);
    const keyfigures = useGameKeyfigures(props.game);

    if (!keyfigures?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeListing
                title={title}
                {...props}
                doc={props.game}
                type={'game'}
                items={keyfigures}
                itemType={'keyfigure'}
                itemComponent={ListKeyfigureItem}
                listProps={listProps}
            />
        </ModuleContainer>
    );
}

export interface ListLabelsGameModuleProps extends Omit<TypeListingProps, 'type'> {
    game?: any;
}

export default ListLabelsGameModule;
