import CallToAction from '../../../organisms/CallToAction';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import useTypeUrl from '../../../hooks/useTypeUrl';
import useQueryString from '../../../hooks/useQueryString';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { WithButton } from '../../../withs';
import formatRawAmount from '@gotombola/amount/lib/utils/formatRawAmount';

export function StandardCallToAction3GameModule({
    game,
    buttonLabel,
    buttonTarget,
    ...rest
}: StandardCallToAction3GameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const qs = useQueryString().toString();
    const buyUrl = useTypeUrl('game', game, 'buy', qs);

    if (!game?.rankedBunches?.items?.length || 'RUNNING' !== game.status) return null;

    return (
        <ModuleContainer {...zprops}>
            <CallToAction
                {...props}
                className={'hidden sm:flex'}
                target={buttonTarget || buyUrl}
                label={
                    buttonLabel ||
                    t('game_participate_price', {
                        price: formatRawAmount(game.ticketPriceAmount, game.ticketPriceCurrency),
                    })
                }
            />
        </ModuleContainer>
    );
}

export interface StandardCallToAction3GameModuleProps extends WithButton {
    game: any;
}

export default StandardCallToAction3GameModule;
