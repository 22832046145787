import clsx from 'clsx';
import StaticLogo from './StaticLogo';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import String from './String';
import { WithClassName } from '../withs';
export function PoweredByTenant({ className }: PoweredByTenantProps) {
    const { t } = useTrans();

    return (
        <div
            className={clsx(
                'font-extralight text-center text-[0.625rem] mr-4 hidden sm:flex sm:flex-col gap-y-1 min-w-[5rem]',
                className,
            )}
        >
            <String basic>{t('tenant_powered_by')}</String>
            <StaticLogo type={'gotombola-small-2'} className={'max-w-[16rem]'} />
        </div>
    );
}

export type PoweredByTenantProps = WithClassName;

export default PoweredByTenant;
