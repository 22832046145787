import GameInfos from '../../../organisms/GameInfos';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';

export function StandardInfosSellergroupModule({ sellergroup, game, ...rest }: StandardInfosSellergroupModuleProps) {
    game = ((game ? { game } : useGameContext()) || {}).game;
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <GameInfos
                {...props}
                game={game}
                publicPageShortUrl={sellergroup.publicPageShortUrl}
                secondLogo={sellergroup?.logoImage}
            />
        </ModuleContainer>
    );
}

export interface StandardInfosSellergroupModuleProps {
    sellergroup: any;
    game?: any;
}

export default StandardInfosSellergroupModule;
