import useAdZonesContext from './useAdZonesContext';
import useAdZoneDefinitionFromContext from './useAdZoneDefinitionFromContext';
import { useCallback } from 'react';

const defaultParams = {};

export function useAdZoneComponent(id: string | undefined, params: any = defaultParams) {
    const adZones = useAdZonesContext();
    const def = useAdZoneDefinitionFromContext(id || '', params);

    const Comp = (adZones || {})[(id || '').replace(/-/g, '_').toLowerCase()] || undefined;

    const FinalComp = useCallback((props: any) => <Comp id={id || ''} {...def} {...props} />, [Comp, id, def]);

    if (!Comp || !def || !def?.visible) return undefined;

    return FinalComp;
}

export default useAdZoneComponent;
