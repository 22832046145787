import { useMemo } from 'react';
import useTypeTranslation from '../hooks/useTypeTranslation';
import FeaturedSection from '../molecules/FeaturedSection';
import { WithDescription, WithOverline, WithTitle } from '../withs';
import { image } from '../types';

export function GameBeneficiaries({
    game,
    title,
    titleStyling,
    overline,
    description,
    donation,
    website,
    mainImage,
}: GameBeneficiariesProps) {
    const { t } = useTypeTranslation('game', game);
    const beneficiary = game.beneficiaries.items[0];
    mainImage = mainImage || beneficiary.mainImage;
    description = description || beneficiary.description;
    const defaultWebsite = useMemo(() => {
        return {
            url: beneficiary.websiteUrl,
            name: beneficiary.name,
            logoImage: beneficiary.logoImage,
        };
    }, [beneficiary.websiteUrl, beneficiary.name, beneficiary.logoImage]);
    website = website || defaultWebsite;
    const defaultDonation = useMemo(() => {
        return {
            url: beneficiary.donationUrl,
            label: t('game_beneficiary_donation_label'),
            buttonLabel: t('game_beneficiary_donation_button_label'),
        };
    }, [beneficiary.donationUrl, t]);
    donation = donation || defaultDonation;

    return (
        <FeaturedSection
            title={title || t('game_beneficiary_label', { beneficiaryName: beneficiary.name })}
            titleStyling={titleStyling}
            overline={
                overline ||
                t('game_beneficiary_overline', {
                    plannedDonationRatio: beneficiary.plannedDonationRatio
                        ? `${beneficiary.plannedDonationRatio}%`
                        : t('game_beneficiary_default_overline'),
                })
            }
            description={description}
            donation={donation}
            website={website}
            mainImage={mainImage as any}
        />
    );
}

export interface GameBeneficiariesProps extends WithTitle, WithOverline, WithDescription {
    game: any;
    donation?: any;
    website?: any;
    mainImage?: image;
    titleStyling?: string;
}

export default GameBeneficiaries;
