export function parseSerializedData(value: string | any | undefined): any | undefined {
    if (!value || 'string' !== typeof value) return undefined;
    try {
        return JSON.parse(value);
    } catch (e: any) {
        return undefined;
    }
}

export default parseSerializedData;
