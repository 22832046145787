import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { WithClassName, WithId, WithIndex, WithName, WithQuantity } from '../withs';
import StaticIcon from './StaticIcon';
import TextField from './fields/TextField';
import NumberField from './fields/NumberField';
import getRankSizeCategory from '../utils/getRankSizeCategory';

const sizes = {
    'very-very-long': 'w-fit text-xs px-2',
    'very-long': 'w-20 text-sm px-2',
    long: 'w-14 text-sm px-2',
    'not-empty': 'w-10',
    empty: 'w-10',
};

export function PrizeItem({
    className,
    id,
    index,
    name,
    quantity,
    rank,
    removeItem,
    setItem,
    ...props
}: PrizesItemProps) {
    quantity = quantity || 0;
    const handleAmountChange = useCallback(
        (event: any) => setItem({ id, name, quantity: parseInt(event.target.value) }),
        [setItem, name, id],
    );
    const handleNameChange = useCallback(
        (event: any) => setItem({ id, name: event.target.value, quantity }),
        [setItem, quantity, id],
    );
    const size = useMemo(() => getRankSizeCategory(rank), [rank]);

    return (
        <div className={clsx('flex px-4 gap-x-2', className)}>
            <div
                className={clsx(
                    'rounded-full flex items-center justify-center h-10 bg-gray-200 text-blue-600',
                    sizes[size] || sizes['not-empty'],
                )}
                {...props}
            >
                {rank}
            </div>
            <div className={'flex flex-1'}>
                <TextField
                    className={'flex-1'}
                    defaultValue={name}
                    name={'prize-' + index}
                    onChange={handleNameChange}
                />
            </div>
            <div className={'flex flex-row space-x-2'}>
                <NumberField defaultValue={quantity} onChange={handleAmountChange} min={1} className={'w-14'} />
                <button type={'button'} onClick={removeItem as any}>
                    <StaticIcon type={'delete'} />
                </button>
            </div>
        </div>
    );
}

export interface PrizesItemProps
    extends WithClassName,
        Required<WithId>,
        Required<WithIndex>,
        Required<WithName>,
        Required<WithQuantity> {
    rank: string;
    setItem: Function;
    removeItem: Function;
}

export default PrizeItem;
