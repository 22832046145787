import { useMemo } from 'react';
import clsx from 'clsx';
import cleanText from '../utils/cleanText';
import buildStyling from '@gotombola/react-modules-engine/lib/utils/buildStyling';
import useStylingDsn from '@gotombola/react-modules-engine/lib/hooks/useStylingDsn';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function useStyledText(text: any, styling: string | undefined, defaultText?: any, stylingPrefix = 'text') {
    const s = useStylingDsn(styling);
    const t = useMemo(
        () =>
            !text
                ? 'string' === typeof defaultText
                    ? cleanText(defaultText)
                    : defaultText
                : 'string' === typeof text
                ? cleanText(text)
                : text,
        [text, defaultText],
    );
    const ss = useStyling();
    const globalTextCaseClass = ss[`${stylingPrefix}CaseClass`];
    const globalTextDecorationClass = ss[`${stylingPrefix}DecorationClass`];
    const globalTextOverflowClass = ss[`${stylingPrefix}OverflowClass`];
    const globalTextSmoothingClass = ss[`${stylingPrefix}SmoothingClass`];
    const globalTextStyleClass = ss[`${stylingPrefix}StyleClass`];
    const globalTextSpacingClass = ss[`${stylingPrefix}SpacingClass`];

    return useMemo(() => {
        const {
            textCaseClass,
            textDecorationClass,
            textOverflowClass,
            textSmoothingClass,
            textStyleClass,
            textSpacingClass,
        } = buildStyling(s);
        const className = clsx(
            textCaseClass || globalTextCaseClass,
            textDecorationClass || globalTextDecorationClass,
            textOverflowClass || globalTextOverflowClass,
            textSmoothingClass || globalTextSmoothingClass,
            textStyleClass || globalTextStyleClass,
            textSpacingClass || globalTextSpacingClass,
        );
        return [
            t,
            className,
            globalTextCaseClass,
            globalTextDecorationClass,
            globalTextOverflowClass,
            globalTextSmoothingClass,
            globalTextStyleClass,
            globalTextSpacingClass,
        ];
    }, [t, s]);
}

export default useStyledText;
