import { group_definition, group_zones } from '../types';
import { WithChildren } from '../withs';
import GroupInstances from '@gotombola/react-modules-engine/lib/organisms/GroupInstances';
import useGroupZones from '@gotombola/react-modules-engine/lib/hooks/useGroupZones';

const defaultTemplateModules = {};
const defaultModuleProps = {};
const defaultGroups = [];

const zones = {
    main: 'main',
};

const zoneFn = (g: string, s: group_zones) => (s.main?.length ? 'afters' : 'befores');

export function FunnelTemplateLayout({
    children,
    groups = defaultGroups,
    moduleProps = defaultModuleProps,
    templateModules = defaultTemplateModules,
}: FunnelTemplateLayoutProps) {
    const { befores, main, afters } = useGroupZones(groups, zones, zoneFn);

    return (
        <div className={'w-full'}>
            <GroupInstances items={befores} templateModules={templateModules} moduleProps={moduleProps} />
            <div className={'w-full flex flex-col items-center'}>
                <div className={'flex flex-col items-center w-full px-4 sm:pb-4 mb-24 sm:w-2/3 md:w-1/2 lg:w-1/3'}>
                    <GroupInstances
                        items={main}
                        templateModules={templateModules}
                        moduleProps={moduleProps}
                        paddingMode={'none'}
                    />
                    {children || ''}
                </div>
            </div>
            <GroupInstances items={afters} templateModules={templateModules} moduleProps={moduleProps} />
        </div>
    );
}

export interface FunnelTemplateLayoutProps extends WithChildren {
    namedGroups?: Record<string, group_definition>;
    groups?: group_definition[];
    templateModules?: any;
    moduleProps?: any;
}

export default FunnelTemplateLayout;
