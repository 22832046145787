import ModuleContainer from '../../../organisms/ModuleContainer';
import { WithZoneCustomization } from '../../../withs';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import CommonContent from '../../../organisms/CommonContent';

export function StandardContentCommonModule(rest: StandardContentCommonModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <CommonContent {...props} />
        </ModuleContainer>
    );
}

export type StandardContentCommonModuleProps = WithZoneCustomization;

export default StandardContentCommonModule;
