import { WithChildren, WithInverted, WithTitle, WithZoneCustomization } from '../withs';
import TextBlock from '../blocks/TextBlock';
import ErrorBoundary from '@gotombola/react-error-boundary';
import ZoneContainer from './ZoneContainer';
import useModuleContainerInfos from '@gotombola/react-modules-engine/lib/hooks/useModuleContainerInfos';
import { AlreadyInContainerContextProvider } from '@gotombola/react-modules-engine/lib/contexts/AlreadyInContainerContext';
import useDesignerContext from '@gotombola/react-modules-engine/lib/hooks/useDesignerContext';
import { useMemo } from 'react';
import clsx from 'clsx';
import DefaultFallbackComponent from '../misc/DefaultFallbackComponent';
import DebugPanel from '@gotombola/react-modules-engine/lib/molecules/DebugPanel';

export function ModuleContainer({ children, section = true, ...rest }: ModuleContainerProps) {
    const { alreadyInContainer, className, type, inverted, border, title, props } = useModuleContainerInfos(rest);

    const designerContext = useDesignerContext();
    const moduleClassName = useMemo(
        () => (designerContext.debug ? 'hover:cursor-pointer hover:bg-cyan-300' : undefined),
        [designerContext.debug],
    );
    let content = children || null;

    !alreadyInContainer &&
        (content = <AlreadyInContainerContextProvider value={true}>{content}</AlreadyInContainerContextProvider>);

    border &&
        (content = (
            <TextBlock border={border} title={title} inverted={inverted}>
                <ErrorBoundary fallbackComponent={DefaultFallbackComponent} type={'module-text-block'} subtype={type}>
                    {content}
                </ErrorBoundary>
            </TextBlock>
        ));

    designerContext.debug &&
        (content = (
            <>
                <DebugPanel kind={'moduleInstance'} type={type} />
                {content}
            </>
        ));

    if (!alreadyInContainer && section)
        return (
            <ZoneContainer
                {...props}
                className={clsx(designerContext.debug && 'relative group', props.className, moduleClassName)}
            >
                <section className={className}>
                    <ErrorBoundary fallbackComponent={DefaultFallbackComponent} type={'module-section'} subtype={type}>
                        {content}
                    </ErrorBoundary>
                </section>
            </ZoneContainer>
        );
    if (!alreadyInContainer)
        return (
            <ZoneContainer
                {...props}
                className={clsx(designerContext.debug && 'relative group', props.className, className, moduleClassName)}
            >
                <ErrorBoundary fallbackComponent={DefaultFallbackComponent} type={'module-div'} subtype={type}>
                    {content}
                </ErrorBoundary>
            </ZoneContainer>
        );

    return (
        <ErrorBoundary fallbackComponent={DefaultFallbackComponent} type={'module-div-sub'} subtype={type}>
            <div className={clsx('w-full', designerContext.debug && 'relative group', moduleClassName)}>{content}</div>
        </ErrorBoundary>
    );
}

export interface ModuleContainerProps extends WithChildren, WithInverted, WithTitle, WithZoneCustomization {
    section?: boolean;
    padding?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    border?: boolean;
}

export default ModuleContainer;
