import SponsorFooter from '../../../molecules/footers/SponsorFooter';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardFooterSponsorModule({ sponsor, ...rest }: StandardFooterSponsorModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <SponsorFooter {...props} sponsor={sponsor} />
        </ModuleContainer>
    );
}

export interface StandardFooterSponsorModuleProps {
    sponsor: any;
}

export default StandardFooterSponsorModule;
