import { useMemo } from 'react';
import clsx from 'clsx';
import VideoModule from '../modules/VideoModule';
import Img from '../atoms/Img';
import Text from '../atoms/Text';
import { WithImage, WithIndex, WithSubtitle, WithTitle } from '../withs';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function GodfatherBlock({ image, index, subtitle, title, videoUrl }: GodfatherBlockProps) {
    const config = useMemo(() => ({ type: 'player', playing: index === 0 }), [index]);
    const { blockShapeClass } = useStyling();

    return (
        <div className={'flex flex-col flex-1 justify-center'}>
            {((!!image && !!image.url) || !!videoUrl) && (
                <div className={'flex'}>
                    {!videoUrl && !!image?.url && (
                        <Img
                            className={clsx('h-60 w-40 object-cover', blockShapeClass)}
                            url={image.url}
                            maxSize={400}
                            alt={
                                ('string' === typeof title ? title : undefined) ||
                                ('string' === typeof subtitle ? subtitle : undefined) ||
                                'godfather'
                            }
                        />
                    )}
                    {!!videoUrl && <VideoModule url={videoUrl} config={config} className={'h-60'} />}
                </div>
            )}
            <Text bold center className={'text-lg'} value={title} />
            <Text center light className={'text-sm'} value={subtitle} />
        </div>
    );
}

export interface GodfatherBlockProps extends WithImage, WithIndex, WithSubtitle, WithTitle {
    videoUrl?: string;
}

// noinspection JSUnusedGlobalSymbols
export default GodfatherBlock;
