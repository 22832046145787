import Helmet from 'react-helmet';
import useApplicationContext from '@gotombola/react-contexts/lib/hooks/useApplicationContext';
import { WithTitle } from '../withs';

const defaultFavicon = 'https://statics.gotombola.co/tenants/gotombola/images/favicons/gotombola/favicon-32x32.png';
const defaultAppleTouchIcon =
    'https://statics.gotombola.co/tenants/gotombola/images/favicons/gotombola/apple-touch-icon.png';

export function StaticPageHead({
    appleTouchIcon = defaultAppleTouchIcon,
    favicon = defaultFavicon,
    lang = undefined,
    noindex = false,
    title = undefined,
}: StaticPageHeadProps) {
    const { manifestPath } = useApplicationContext();

    return (
        <Helmet>
            {!!title && <title>{title}</title>}
            <link rel={'icon'} href={favicon} />
            <link rel='apple-touch-icon' href={appleTouchIcon} />
            {!!manifestPath && <link rel={'manifest'} href={manifestPath} />}
            <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
            <meta charSet='utf-8' />
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            {!!noindex && <meta name='robots' content='noindex' />}
            <html {...(lang ? { lang } : {})} className={'font-go-global'} />
        </Helmet>
    );
}

export interface StaticPageHeadProps extends WithTitle<string> {
    lang?: string;
    favicon?: string;
    appleTouchIcon?: string;
    noindex?: boolean;
}

export default StaticPageHead;
