import clsx from 'clsx';
import { WithChildren, WithHelper, WithNativeOnClick } from '../withs';
import { flag } from '@gotombola/types';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

const colors =
    'border border-go-choice-secondary-light bg-go-choice-primary text-go-choice-secondary hover:text-go-choice-primary hover:bg-go-choice-secondary';
const invertedColors =
    'border border-go-choice-primary-light bg-go-choice-secondary text-go-choice-primary hover:text-go-choice-secondary hover:bg-go-choice-primary';

export function MenuChoice({ children, helper, href, onClick, checked, blank = false }: MenuChoiceProps) {
    const { itemShapeClass } = useStyling();
    return (
        <>
            {!!helper && <div className={'w-full text-center'}>{helper || ''}</div>}
            <a
                href={href}
                onClick={onClick}
                className={clsx(
                    'group cursor-pointer p-2 flex w-full font-bold border hover:shadow-sm items-center',
                    checked ? invertedColors : colors,
                    itemShapeClass,
                )}
                target={!!blank ? '_blank' : undefined}
                rel={!!blank ? 'noreferrer' : undefined}
            >
                {children || ''}
            </a>
        </>
    );
}

export interface MenuChoiceProps extends WithChildren, WithHelper, WithNativeOnClick {
    href?: string;
    checked?: flag;
    blank?: flag;
}

// noinspection JSUnusedGlobalSymbols
export default MenuChoice;
