import clsx from 'clsx';
import { WithButtons, WithClassName, WithOverline, WithText, WithTitle } from '../../withs';
import Buttons from '../../molecules/Buttons';
import useButtonsProps from '../../hooks/useButtonsProps';
import Text from '../../atoms/Text';

const titleSizes = {
    xs: 'text-lg',
    sm: 'text-xl',
    md: 'text-2xl',
    lg: 'text-4xl',
    xl: 'text-6xl',
};

export function RightHeadingModule({
    className,
    overline,
    text,
    title,
    titleSize = 'md',
    ...rest
}: RightHeadingModuleProps) {
    const [bprops] = useButtonsProps(rest);

    return !!title || !!text ? (
        <div className={clsx('flex flex-col space-y-4', className)}>
            <div className={'w-full mb-4'}>
                {!!overline && (
                    <div className={'text-sm text-right text-gray-300 uppercase mb-2'}>
                        <Text>{overline}</Text>
                    </div>
                )}
                {!!title && (
                    <div className={clsx('text-2xl text-right text-blue-500 font-bold mb-4', titleSizes[titleSize])}>
                        <Text>{title}</Text>
                    </div>
                )}
                {!!text && (
                    <div className={'text-md text-justify text-gray-600 py-4'}>
                        <Text>{text}</Text>
                    </div>
                )}
                <Buttons {...bprops} />
            </div>
        </div>
    ) : null;
}

export interface RightHeadingModuleProps extends WithClassName, WithOverline, WithText, WithTitle, WithButtons {
    titleSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default RightHeadingModule;
