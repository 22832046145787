import { createContext } from 'react';
import { actions_context_value } from '../types';
import createDefaultActionsContextValue from '../utils/createDefaultActionsContextValue';

export const ActionsContext = createContext<actions_context_value>(createDefaultActionsContextValue());
// noinspection JSUnusedGlobalSymbols
export const ActionsContextProvider = ActionsContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const ActionsContextConsumer = ActionsContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default ActionsContext;
