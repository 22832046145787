import clsx from 'clsx';
import useTenant from '@gotombola/react-contexts-type/lib/hooks/useTenant';
import StaticImg from './StaticImg';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { WithClassName, WithUrl } from '../withs';
import ClickableContent from './ClickableContent';

export function BoxIcon({ className, format = 'png', icon, url, flat = false }: BoxIconProps) {
    const tenant = useTenant();
    const { thumbnail0SizeClass, itemShapeClass } = useStyling();

    return (
        <ClickableContent url={url}>
            <div
                className={clsx(
                    !flat && thumbnail0SizeClass,
                    itemShapeClass,
                    'overflow-hidden flex justify-center items-center',
                    url && 'cursor-pointer',
                    className,
                )}
            >
                <StaticImg src={`tenants/${tenant}/images/logos/${icon}.${format}`} alt={icon} />
            </div>
        </ClickableContent>
    );
}

export interface BoxIconProps extends WithClassName, WithUrl {
    icon: string;
    format?: string;
    flat?: boolean;
}

// noinspection JSUnusedGlobalSymbols
export default BoxIcon;
