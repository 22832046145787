import { useMemo } from 'react';
import useQueryString from './useQueryString';

export function useFirstUrl(...urls: (string | undefined)[]) {
    const qs = useQueryString();

    return useMemo(() => {
        let u = urls?.find((x) => !!x);
        if (!u) u = '/';

        const qss = qs.toString();

        if (!qss) return u;

        return `${u}${-1 < u.indexOf('?') ? '&' : '?'}${qss}`;
    }, [...urls, qs]);
}

export default useFirstUrl;
