import clsx from 'clsx';
import { WithChildren, WithClassName } from '../withs';
import { useMemo } from 'react';
import useEditModeContext from '@gotombola/react-contexts/lib/hooks/useEditModeContext';

const variants = {
    default: {
        containerClass: 'sm:px-24',
        contentClass: '',
    },
    corporate: {
        containerClass: 'sm:24 md:px-32 lg:px-48',
        contentClass: '',
    },
};

export function Header({ className, children, variant = 'default' }: HeaderProps) {
    const v = useMemo(() => variants[variant || 'default'] || variants['default'], [variant]);
    const {
        enabled: editModeEnabled,
        right: editModeRight,
        left: editModeLeft,
        bottom: editModeBottom,
        top: editModeTop,
    } = useEditModeContext();

    const style = useMemo(
        () =>
            editModeEnabled
                ? {
                      left: editModeLeft,
                      right: editModeRight,
                      top: editModeTop,
                      bottom: editModeBottom,
                  }
                : undefined,
        [editModeLeft, editModeRight, editModeTop, editModeBottom],
    );

    return (
        <>
            <div
                className={clsx(
                    'h-16 md:h-[4.75rem] text-go-nav-secondary flex items-center justify-between py-2 fixed bg-go-nav-primary shadow-md z-20',
                    !editModeEnabled && 'w-full',
                    v.containerClass,
                    className,
                )}
                style={style}
            >
                {children}
            </div>
            <div className={'w-full h-16 md:h-[4.75rem]'} />
        </>
    );
}

export interface HeaderProps extends WithClassName, WithChildren {
    variant?: string;
}

// noinspection JSUnusedGlobalSymbols
export default Header;
