import {
    WithAutoFocus,
    WithClassName,
    WithComponent,
    WithDefaultValue,
    WithDisabled,
    WithError,
    WithHelper,
    WithName,
    WithNativeOnChange,
    WithNativePlaceholder,
    WithRequired,
    WithRows,
    WithTitle,
    WithType,
    WithValue,
} from '../../withs';
import Text from '../Text';

export function InputField({
    className,
    component: Component,
    error,
    helper,
    inputProps,
    prefix,
    title,
    nolabel = false,
    nohelper = false,
    ...props
}: InputFieldProps) {
    return (
        <div className={className}>
            {!nolabel && !!title && (
                <div>
                    <Text inline>{title}</Text>
                    {!!props.required && (
                        <Text inline className={'text-red-500'}>
                            *
                        </Text>
                    )}
                </div>
            )}
            <div className={'w-full flex items-center'}>
                {!!prefix && <span className={'mr-2'}>{prefix}</span>}
                <Component {...(inputProps || {})} {...props} />
            </div>
            {error &&
                error.data &&
                error.data.fieldErrors &&
                error.data.fieldErrors[props.name || ''] &&
                error.data.fieldErrors[props.name || ''].map((message: string, index: number) => {
                    return (
                        <Text className={'text-red-500'} key={`error-${props.name}-${index}`}>
                            {message}
                        </Text>
                    );
                })}
            {!nohelper && !!helper && <Text className={'text-gray-500'}>{helper}</Text>}
        </div>
    );
}

export interface BaseInputFieldProps
    extends WithAutoFocus,
        WithClassName,
        WithDefaultValue,
        WithDisabled,
        WithError<any>,
        WithHelper,
        WithName,
        WithNativeOnChange,
        WithNativePlaceholder,
        WithRequired,
        WithRows,
        WithTitle,
        WithType,
        WithValue {
    prefix?: string;
    pattern?: string;
    nolabel?: boolean;
    nohelper?: boolean;
}

export interface InputFieldProps extends BaseInputFieldProps, Required<WithComponent> {
    inputProps?: Record<string, any>;
}

export default InputField;
