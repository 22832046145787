import { WithButton, WithMessage, WithText, WithTitle } from '../withs';
import useTypeTranslation from '../hooks/useTypeTranslation';
import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';
import Button from '../atoms/Button';
import Text from '../atoms/Text';

export function CollectModeItem({ title, text, text2, message, buttonLabel, buttonTarget }: CollectModeItemProps) {
    const { game } = useGameContext();
    const { t } = useTypeTranslation('game', game);

    return (
        <div className={'space-y-2'}>
            {!!title && <Text bold>{title}</Text>}
            {(!!text || !!text2) && (
                <>
                    <Text light>{t('presult_page_collect_mode_contact')}</Text>
                    {!!text && <Text light>{text}</Text>}
                    {!!text2 && <Text light>{text2}</Text>}
                </>
            )}
            {!!message && <Text className={'py-4'}>{message}</Text>}
            {!!buttonLabel && buttonTarget && (
                <div>
                    <div className={'mt-6'}>
                        <Button className={'text-sm p-1'} label={buttonLabel} target={buttonTarget} />
                    </div>
                </div>
            )}
        </div>
    );
}

export interface CollectModeItemProps extends WithMessage, WithButton, WithText, WithTitle {
    text2?: string;
}

// noinspection JSUnusedGlobalSymbols
export default CollectModeItem;
