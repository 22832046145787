import loadable from '@loadable/component';
import { DoneeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./footer/StandardFooterDoneeModule')),
};

export function FooterDoneeModule(props: FooterDoneeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type FooterDoneeModuleProps = DoneeModuleProps;

export default FooterDoneeModule;
