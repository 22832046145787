import { createContext } from 'react';
import { seller_context_value } from '../types';
import createDefaultSellerContextValue from '../utils/createDefaultSellerContextValue';

export const SellerContext = createContext<seller_context_value>(createDefaultSellerContextValue());
// noinspection JSUnusedGlobalSymbols
export const SellerContextProvider = SellerContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const SellerContextConsumer = SellerContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default SellerContext;
