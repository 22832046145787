import clsx from 'clsx';
import { WithAlt, WithNativeOnClick, WithUrl } from '../withs';
import Img from './Img';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

const sizes = {
    sm: 'w-40 h-32',
    md: 'w-60 h-48',
    lg: 'w-80 h-60',
};

const maxSizes = {
    sm: {
        maxSize: 400,
        maxSizeTablet: 400,
        maxSizeMobile: 250,
    },
    md: {
        maxSize: 500,
        maxSizeTablet: 500,
        maxSizeMobile: 300,
    },
    lg: {
        maxSize: 700,
        maxSizeTablet: 700,
        maxSizeMobile: 300,
    },
};

export function Thumbnail({ alt, onClick, size = 'md', url }: ThumbnailProps) {
    const { mediaShapeClass } = useStyling();

    return (
        <Img
            className={clsx(
                'flex-1 box-content border-2 border-transparent object-cover hover:cursor-pointer hover:border-2 hover:border-go-primary',
                sizes[size || ''] || sizes['md'],
                mediaShapeClass,
            )}
            url={url}
            {...(maxSizes[size || ''] || maxSizes['md'])}
            alt={alt || 'thumbnail'}
            onClick={onClick as any}
        />
    );
}

export interface ThumbnailProps extends Required<WithAlt>, WithNativeOnClick, Required<WithUrl> {
    size?: string;
}

// noinspection JSUnusedGlobalSymbols
export default Thumbnail;
