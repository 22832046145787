import { WithImage, WithIndex, WithText } from '../withs';
import Img from '../atoms/Img';
import String from '../atoms/String';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import clsx from 'clsx';

export function Step({ index, image, text }: StepProps) {
    const { mediaShapeClass } = useStyling();

    return (
        <div className={'flex flex-col sm:flex-row items-center gap-4 sm:gap-10'}>
            {!!image?.url && (
                <div className={'relative'}>
                    <Img
                        {...image}
                        className={clsx('object-cover max-w-full sm:w-[500px]', mediaShapeClass)}
                        alt={image.alt || 'image item'}
                        maxSize={800}
                    />
                    <div
                        className={
                            'font-go-text sm:hidden absolute left-2 bottom-2 text-center font-extrabold text-6xl text-go-secondary'
                        }
                    >
                        {index + 1}
                    </div>
                </div>
            )}
            <div className={'flex-1 sm:space-y-4'}>
                <div className={'font-go-text hidden sm:block text-center font-extrabold text-6xl text-go-secondary'}>
                    {index + 1}
                </div>
                {!!text && <String className={'font-go-text text-xl font-light'}>{text}</String>}
            </div>
        </div>
    );
}

export interface StepProps extends WithImage, Required<WithIndex>, WithText {}

// noinspection JSUnusedGlobalSymbols
export default Step;
