import loadable from '@loadable/component';
import { ModuleProps } from '../types';
import ModuleMode from '../organisms/ModuleMode';

const modes = {
    large: loadable(() => import('./images/LargeImageModule')),
    medium: loadable(() => import('./images/MediumImageModule')),
};

export function ImageModule(props: ImageModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'large'} />;
}

export type ImageModuleProps = ModuleProps;

export default ImageModule;
