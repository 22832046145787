import { secretize as originalSecretize, unsecretize as originalUnsecretize } from '@genstackio/secretize';
import base64 from 'base64url';

export function secretize(data: string) {
    return originalSecretize(data, 'x3jZq6tJqx3jyU6', { helpers: { x3j: base64.encode } });
}

export function unsecretize(data: string) {
    return originalUnsecretize(data, 'x3jZq6tJqx3jyU6', { helpers: { j3x: base64.decode } });
}

export default secretize;
