import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import { GameModuleProps } from '@gotombola/react-types';
import FilesDownloader from '../../../organisms/FilesDownloader';

export function StandardFilesGameModule({ ...rest }: StandardFilesGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    if (!props.game?.generatedDocuments?.items?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <FilesDownloader {...props} items={props.game?.generatedDocuments?.items} />
        </ModuleContainer>
    );
}

export type StandardFilesGameModuleProps = GameModuleProps;

export default StandardFilesGameModule;
