import clsx from 'clsx';
import { WithClassName, WithImage, WithOverline, WithText, WithTitle } from '../withs';
import Img from './Img';
import RichText from '../atoms/RichText';
import StaticIcon from './StaticIcon';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function GalleryItem({ className, contentClassName, icon, image, overline, text, title }: GalleryItemProps) {
    if (!image?.url && !title && !text) return null;
    const { blockShapeClass } = useStyling();

    return (
        <div className={clsx('flex bg-gray-50 border', blockShapeClass, className)}>
            {!!image?.url && (
                <div className={clsx('flex w-full', (!!title || !!text) && 'md:3/6 xl:w-1/3 sm:w-1/3')}>
                    <Img {...image} className={'object-cover'} alt={image.alt || 'image item'} />
                </div>
            )}
            {(!!overline || !!title || !!text) && (
                <div
                    className={clsx(
                        'flex flex-col justify-center flex-1 md:3/6 xl:w-2/3 sm:w-2/3 p-8',
                        contentClassName,
                    )}
                >
                    {!!icon && <StaticIcon type={icon} className={'w-10 mb-4 mx-auto'} />}
                    {!!overline && (
                        <div className={'font-go-text text-sm text-go-title uppercase mb-2'}>
                            <RichText value={overline} />
                        </div>
                    )}
                    {!!title && (
                        <div className={'text-2xl text-go-title font-go-heading leading-none font-bold mb-4'}>
                            <RichText value={title} />
                        </div>
                    )}
                    {!!text && (
                        <div className={'font-go-text text-md text-justify font-light text-gray-600'}>
                            <RichText value={text} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export interface GalleryItemProps extends WithClassName, WithImage, WithOverline, WithText, WithTitle {
    icon?: string;
    contentClassName?: string;
}

// noinspection JSUnusedGlobalSymbols
export default GalleryItem;
