import { effect } from '../types';
import { WithItems } from '../withs';
import Effect from './Effect';
import Items from '../atoms/Items';

export function Effects({ items }: EffectsProps) {
    return <Items items={items} component={Effect} />;
}

export type EffectsProps = WithItems<effect>;

// noinspection JSUnusedGlobalSymbols
export default Effects;
