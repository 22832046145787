import Content from '@gotombola/react-modules-engine/lib/organisms/Content';
import { useMemo } from 'react';

function buildFlex({ flex = 1 }: { flex?: number } = {}) {
    return `${flex} ${flex} 0%`;
}

export function RowCellContent({ content, ...props }: RowCellContentProps) {
    const styles = useMemo(() => ({ flex: buildFlex(content) }), [content]);

    return (
        <div className={'w-full flex'} style={styles}>
            <Content content={content} {...props} />
        </div>
    );
}

export interface RowCellContentProps {
    content?: any;
    index: number;
    total: number;
    section?: boolean;
    padding?: any;
}

// noinspection JSUnusedGlobalSymbols
export default RowCellContent;
