import { useDynamicPageContext } from '@gotombola/react-contexts/lib/hooks/useDynamicPageContext';
import DoneeTemplate, { DoneeTemplateProps } from './DoneeTemplate';

export function DynamicDoneePublicTemplate(props: DynamicDoneePublicTemplateProps) {
    const { context } = useDynamicPageContext();

    return <DoneeTemplate {...props} donee={context.doc} />;
}

export type DynamicDoneePublicTemplateProps = Omit<DoneeTemplateProps, 'donee'>;

export default DynamicDoneePublicTemplate;
