import { WithClassName } from '../../withs';
import useTypeUrl from '../../hooks/useTypeUrl';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import String from '../../atoms/String';
import BaseFooter from '../../organisms/BaseFooter';
import Img from '../../atoms/Img';
import Button from '../../atoms/Button';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function PartnerFooter({ className, partner }: PartnerFooterProps) {
    const logoImage = !!partner.logoImage && !!partner.logoImage.url ? partner.logoImage : undefined;
    const registerUrl = useTypeUrl('partner', partner, 'register');
    const { t } = useTypeTranslation('partner', partner);
    const { logoShapeClass } = useStyling();
    const needed = [
        partner.fullName,
        partner.legalName,
        partner.publicPhone,
        partner.publicEmail,
        partner.street,
        partner.city,
        partner.nin,
    ].find((x) => !!x);
    const secondaryChildren = needed && (
        <div className={'flex flex-col text-center break-words leading-relaxed justify-center items-start'}>
            {(!!partner.fullName || !!partner.legalName) && (
                <String className={'font-semibold'}>{partner.fullName || partner.legalName}</String>
            )}
            {!!partner.publicPhone && (
                <span>
                    <a href={`tel:${partner.publicPhone}`}>
                        <String basic className={'font-light'}>
                            {partner.publicPhone}
                        </String>
                    </a>
                </span>
            )}
            {!!partner.publicEmail && (
                <span>
                    <a href={`mailto:${partner.publicEmail}`}>
                        <String basic className={'font-light'}>
                            {partner.publicEmail}
                        </String>
                    </a>
                </span>
            )}
            {!!partner.street && <String className={'font-light'}>{partner.street}</String>}
            {!!partner.city && <String className={'font-light'}>{`${partner.zipCode} ${partner.city}`}</String>}
            {!!partner.nin && <String className={'text-base'}>{t('label_nin', { nin: partner.nin })}</String>}
        </div>
    );

    return (
        <BaseFooter className={className} secondaryChildren={secondaryChildren} grow={false} contentClassName={'px-32'}>
            <div
                className={
                    'flex flex-col lg:flex-row lg:space-x-6 space-y-4 sm:space-y-0 items-center lg:justify-start'
                }
            >
                {!!logoImage && (
                    <div className={'flex justify-center'}>
                        <div
                            className={clsx(
                                'p-4 w-40 h-40 bg-go-logo overflow-hidden flex justify-center items-center',
                                logoShapeClass,
                            )}
                        >
                            <Img
                                className={'bg-go-logo w-full object-contain'}
                                url={logoImage.url}
                                alt={logoImage.alt || 'logo'}
                                maxSize={200}
                            />
                        </div>
                    </div>
                )}
                <div className={'pt-4 lg:pt-0 flex flex-col items-center lg:items-start gap-y-2'}>
                    <div className={'text-2xl font-medium tracking-tight'}>
                        <String basic>{partner.name}</String>
                    </div>
                    {!!partner.presentation && (
                        <div className={'text-center lg:text-left text-sm font-light tracking-tight'}>
                            <String basic>{partner.presentation}</String>
                        </div>
                    )}
                    <Button className={'hidden sm:inline-block'} target={registerUrl} label={t('nav_register_label')} />
                </div>
            </div>
        </BaseFooter>
    );
}

export interface PartnerFooterProps extends WithClassName {
    partner?: any;
}

export default PartnerFooter;
