import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    logos: loadable(() => import('./sponsors/LogosSponsorsTypeModule')),
    lightlogos: loadable(() => import('./sponsors/LightLogosSponsorsTypeModule')),
    list: loadable(() => import('./sponsors/ListSponsorsTypeModule')),
};

export function SponsorsTypeModule(props: SponsorsTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'logos'} />;
}

export type SponsorsTypeModuleProps = TypeModuleProps;

export default SponsorsTypeModule;
