import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import ModuleContainer from '../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';
import Text from '../../atoms/Text';

function Component({ game, ...rest }: any) {
    const [zprops] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);

    return (
        <ModuleContainer {...zprops}>
            <div className={'w-full space-y-2'}>
                <Text bold className={'sm:text-xl'}>
                    {t('game_legal_page_website_provider')}
                </Text>
                <div className={'flex flex-col gap-2'}>
                    {!!game.serviceProviderName && (
                        <Text inline relaxed className={'sm:text-lg'}>
                            {game.serviceProviderName}
                        </Text>
                    )}
                    {!!game.serviceProviderStreet && (
                        <Text inline relaxed className={'sm:text-lg'}>
                            {game.serviceProviderStreet}
                        </Text>
                    )}
                    <Text inline relaxed className={'sm:text-lg'}>
                        {game.serviceProviderZipCode} {game.serviceProviderCity} {game.serviceProviderCountry}
                    </Text>
                    {!!game.serviceProviderTvaCode && (
                        <Text inline relaxed className={'sm:text-lg'}>
                            {game.serviceProviderTvaCode}
                        </Text>
                    )}
                    {!!game.serviceProviderSirenCode && (
                        <Text inline relaxed className={'sm:text-lg'}>
                            {game.serviceProviderSirenCode}
                        </Text>
                    )}
                </div>
            </div>
        </ModuleContainer>
    );
}

export function LegalWebsiteProviderGameModule(props: LegalWebsiteProviderGameModuleProps) {
    return <ModuleMode {...props} component={Component} />;
}

export type LegalWebsiteProviderGameModuleProps = GameModuleProps;

export default LegalWebsiteProviderGameModule;
