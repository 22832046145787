import TypeTemplate, { TypeTemplateProps } from './TypeTemplate';

export function GodfatherTemplate({ godfather, page = 'godfather_public', ...props }: GodfatherTemplateProps) {
    return <TypeTemplate type={'godfather'} page={page.replace(/^godfather_/, '')} doc={godfather} {...props} />;
}

export interface GodfatherTemplateProps extends Omit<TypeTemplateProps, 'type' | 'doc' | 'page'> {
    page?: string;
    godfather?: any;
    game?: any;
}

export default GodfatherTemplate;
