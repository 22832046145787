import { useMemo } from 'react';
import { WithClassName } from '../../withs';
import TopMenuItem from '../../atoms/TopMenuItem';
import feat from '../../utils/feat';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import GameLocaleSwitcher from '../GameLocaleSwitcher';
import useTypeUrl from '../../hooks/useTypeUrl';
import useQueryString from '../../hooks/useQueryString';
import Header from '../../organisms/Header';
import clsx from 'clsx';
import Img from '../../atoms/Img';
import String from '../../atoms/String';
import AdZone from '@gotombola/react-ad/lib/organisms/AdZone';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import useGameHeaderLogo from '@gotombola/react-game/lib/hooks/useGameHeaderLogo';
import { image } from '@gotombola/types/lib/types/base';

export function GameHeader({
    className,
    game,
    logo,
    light = false,
    locale,
    extraBuyQs,
    buyButtonLabel,
    ticketsButtonLabel,
    resultsButtonLabel,
    termsButtonLabel,
}: GameHeaderProps) {
    const { t } = useTypeTranslation('game', game);
    const isRunning = 'RUNNING' === game.status;
    const resultsPublished = 'PUBLISHED' === game.resultsPublishingStatus;
    const { logoShapeClass } = useStyling();
    const logoImage = useGameHeaderLogo(game, logo);
    const localeVersions = useMemo(() => game?.gameVersions?.filter((x: any) => x.type === 'locale') || [], [game]);
    const hasVersions = !!localeVersions && !!localeVersions.length;
    const qs = useQueryString().toString();
    const buyUrl = useTypeUrl('game', game, 'buy', !!extraBuyQs ? `${qs}&${extraBuyQs}` : qs);
    const resultsUrl = useTypeUrl('game', game, 'results');
    const termsUrl = useTypeUrl('game', game, 'terms-and-conditions', undefined, undefined, 'terms');
    const ticketsUrl = useTypeUrl('game', game, 'tickets');

    const name = game.fullName || game['organizationShortName'] || game.organizationName;

    return (
        <Header className={className}>
            <div className={'flex w-full items-center justify-center'}>
                {!!logoImage && (
                    <Img
                        className={clsx('hidden md:block h-11 flex mr-4', logoShapeClass)}
                        url={logoImage.url}
                        alt={logoImage.alt || 'logo'}
                        maxSize={250}
                    />
                )}
                <div
                    className={clsx(
                        hasVersions ? 'ml-4 text-left sm:ml-0' : 'text-center',
                        'sm:text-left flex-1 py-4 font-bold text-xl truncate',
                    )}
                >
                    {!!game.name && (
                        <span className={'font-go-text hidden lg:inline-block'}>
                            <String basic>{game.name}</String>&nbsp;-&nbsp;
                        </span>
                    )}
                    {!!name && (
                        <span className={'font-go-text font-bold lg:font-normal'}>
                            <String basic>{name}</String>
                        </span>
                    )}
                </div>
            </div>
            {!!game.code && (hasVersions || !light) && (
                <div className={'flex w-auto items-center justify-end space-x-2'}>
                    <AdZone id={'GP-1-1'} />
                    <div className={'hidden sm:flex sm:flex-1 items-center justify-end space-x-2'}>
                        {!light && feat(game, 'terms') && (
                            <TopMenuItem
                                target={termsUrl}
                                label={termsButtonLabel || t('game_nav_buttons_terms_and_conditions_label')}
                                className={'hidden md:block'}
                            />
                        )}
                        {!light && feat(game, 'tickets') && (
                            <TopMenuItem
                                target={ticketsUrl}
                                label={ticketsButtonLabel || t('game_nav_buttons_tickets_label')}
                                className={'hidden lg:block'}
                            />
                        )}
                        {!light && isRunning && (
                            <TopMenuItem
                                important
                                target={buyUrl}
                                label={buyButtonLabel || t('game_participate_price')}
                                className={'hidden sm:block'}
                            />
                        )}
                        {!light && resultsPublished && (
                            <TopMenuItem
                                important
                                target={resultsUrl}
                                label={resultsButtonLabel || t('game_results_page')}
                                className={'hidden sm:block'}
                            />
                        )}
                    </div>
                    {hasVersions && (
                        <div className={'w-auto pr-4 sm:pr-0'}>
                            <GameLocaleSwitcher game={game} locale={locale} />
                        </div>
                    )}
                </div>
            )}
        </Header>
    );
}

export interface GameHeaderProps extends WithClassName {
    game?: any;
    logo?: image;
    light?: boolean;
    locale?: string;
    extraBuyQs?: string;
    buyButtonLabel?: string;
    ticketsButtonLabel?: string;
    resultsButtonLabel?: string;
    termsButtonLabel?: string;
}

// noinspection JSUnusedGlobalSymbols
export default GameHeader;
