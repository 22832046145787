import SponsorHeader from '../../../molecules/headers/SponsorHeader';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardHeaderSponsorModule({ sponsor, ...rest }: StandardHeaderSponsorModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <SponsorHeader {...props} sponsor={sponsor} />
        </ModuleContainer>
    );
}

export interface StandardHeaderSponsorModuleProps {
    sponsor: any;
}

export default StandardHeaderSponsorModule;
