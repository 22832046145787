import TenantHeader from '../../../molecules/headers/TenantHeader';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardHeaderTenantModule({ tenant, ...rest }: StandardHeaderTenantModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <TenantHeader {...props} tenant={tenant} />
        </ModuleContainer>
    );
}

export interface StandardHeaderTenantModuleProps {
    tenant: any;
}

export default StandardHeaderTenantModule;
