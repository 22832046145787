import { useMemo } from 'react';
import useTypeVideos from '../../../hooks/useTypeVideos';
import VideoThumbnail from '../../../atoms/VideoThumbnail';
import TypeImages, { TypeImagesProps } from '../../../organisms/type/TypeImages';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any, type: string) => !!doc[`${(type || '').slice(0, 1)}videos`]?.items?.length;

export function ThumbnailsVideosTypeModule({
    hsize = 'full',
    size,
    sort = undefined,
    tag = undefined,
    typeType,
    ...rest
}: ThumbnailsVideosTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    const videos = useTypeVideos(type, doc, sort, tag);
    const itemProps = useMemo(() => ({ size, hsize }), [size, hsize]);

    if (!videos?.length) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeImages
                {...props}
                type={type}
                doc={doc}
                items={videos}
                component={VideoThumbnail}
                itemProps={itemProps}
            />
        </ModuleContainer>
    );
}

export interface ThumbnailsVideosTypeModuleProps extends Omit<TypeImagesProps, 'type'> {
    typeType: string;
    size?: string;
    sort?: string;
    tag?: string;
    hsize?: string;
}

export default ThumbnailsVideosTypeModule;
