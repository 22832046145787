import { WithClassName, WithDisabled, WithLabel, WithLoading } from '../../withs';
import Spinner2 from '../Spinner2';

export function SubmitButton({ className, label, loading = false, ...props }: SubmitButtonProps) {
    return (
        <button type={'submit'} {...props} className={className}>
            {loading && <Spinner2 size={'sm'} />}
            {!loading && (label || '')}
        </button>
    );
}

export interface SubmitButtonProps extends WithClassName, WithDisabled, Required<WithLabel>, WithLoading {}

// noinspection JSUnusedGlobalSymbols
export default SubmitButton;
