import clsx from 'clsx';
import { WithClassName, WithItems } from '../withs';
import Items from '../atoms/Items';
import SocialSmallIcon from '../atoms/SocialSmallIcon';

export function LightSocialNetworks({ className, items = [] }: LightSocialNetworksProps) {
    if (!items?.length) return null;

    return (
        <div className={clsx('flex gap-3', className)}>
            <Items items={items} component={SocialSmallIcon} className={'w-12 h-12'} />
        </div>
    );
}

export interface LightSocialNetworksProps extends WithClassName, WithItems {}

// noinspection JSUnusedGlobalSymbols
export default LightSocialNetworks;
