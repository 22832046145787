import { useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useAction from '@gotombola/react-contexts/lib/hooks/useAction';

export function useFormAction<T = any, R = any>(name: string, defaultValues?: T | (() => T), vars?: any) {
    const [actionState, setActionState] = useState<{ called: boolean; loading: boolean; data: any; error: any }>({
        called: false,
        loading: false,
        data: undefined,
        error: undefined,
    });
    const {
        register,
        handleSubmit,
        watch,
        formState: { isSubmitting, errors },
        setError,
        control,
        clearErrors,
    } = useForm<T>({
        // @ts-ignore
        ...(defaultValues ? ('function' === typeof defaultValues ? defaultValues() : defaultValues) : {}),
    });
    const action = useAction(name);
    const context = useMemo(() => ({ ...vars, name }), [vars, name]);
    const onSubmit: SubmitHandler<T> = useCallback(
        (data: any) => {
            setActionState({ ...actionState, error: undefined, data: undefined, loading: true, called: true });
            let p: any;
            let error: any;
            try {
                p = action(data, context);
            } catch (e: any) {
                error = e;
            }
            const onCompleted = (data) => {
                setActionState({ ...actionState, error: undefined, data, loading: false });
            };
            const onError = (e: any) => {
                setActionState({ ...actionState, data: undefined, loading: false, error: e, called: true });
                setError('globalError' as any, e);
            };

            if (error) onError(error);
            else {
                if (p?.then) {
                    p.then(onCompleted);
                    p.catch(onError);
                } else {
                    try {
                        onCompleted(p);
                    } catch (e: any) {
                        onError(e);
                    }
                }
            }
        },
        [action, context, setActionState, actionState, setError],
    );

    const submit = useCallback(
        (...args: any[]) => {
            clearErrors('globalError' as any);
            return handleSubmit(onSubmit)(...args);
        },
        [clearErrors, handleSubmit, onSubmit],
    );

    return useMemo(
        () => ({
            submit,
            register,
            watch,
            isSubmitting,
            errors,
            control,
            clearErrors,
            setError,
            data: actionState.data,
            loading: actionState.loading,
            called: actionState.called,
        }),
        [
            clearErrors,
            setError,
            submit,
            register,
            watch,
            errors,
            isSubmitting,
            control,
            actionState.called,
            actionState.loading,
            actionState.data,
        ],
    );
}

export default useFormAction;
