import GameFooter from '../../../molecules/footers/GameFooter';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardFooterGameModule({ game, ...rest }: StandardFooterGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <GameFooter {...props} game={game} />
        </ModuleContainer>
    );
}

export interface StandardFooterGameModuleProps {
    game: any;
}

export default StandardFooterGameModule;
