import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from '@tsparticles/engine';

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        particles: {
            number: {
                value: 80,
                density: {
                    enable: true,
                    area: 800,
                } as any,
            },
            reduceDuplicates: true,
            rotate: {
                animation: {
                    enable: true,
                    speed: { min: 10, max: 15 },
                },
            },
            shape: {
                type: ['spades', 'hearts', 'diamonds', 'clubs'],
                options: {
                    spades: {
                        particles: {
                            color: {
                                value: '#000000',
                            },
                        },
                    },
                    hearts: {
                        particles: {
                            color: {
                                value: '#ff0000',
                            },
                        },
                    },
                    diamonds: {
                        particles: {
                            color: {
                                value: '#ff0000',
                            },
                        },
                    },
                    clubs: {
                        particles: {
                            color: {
                                value: '#000000',
                            },
                        },
                    },
                },
            },
            opacity: {
                value: 1,
            },
            size: {
                value: 30,
            },
            move: {
                enable: true,
                speed: 2,
                direction: 'none',
                random: false,
                straight: false,
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: 'repulse',
                },
                onClick: {
                    enable: true,
                    mode: 'push',
                },
            },
            modes: {
                repulse: {
                    distance: 200,
                },
                push: {
                    quantity: 4,
                },
            },
        },
    };
};
