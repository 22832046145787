import { WithAny, WithComponent, WithItemPropsFn, WithItems } from '../withs';

export function Items({ component: Component, itemPropsFn, items = [], ...props }: ItemsProps) {
    if (!items || !items.length) return null;

    return (
        <>
            {items.map((item: any, index: number) => (
                <Component
                    key={index}
                    index={item?.id || index}
                    indexCounter={index}
                    itemsCount={items?.length}
                    {...item}
                    {...props}
                    {...((itemPropsFn ? itemPropsFn(item, index) : undefined) || {})}
                />
            ))}
        </>
    );
}

export interface ItemsProps extends WithAny, Required<WithComponent>, WithItemPropsFn, WithItems {}

export default Items;
