import { TypeProps } from '../../types';
import { WithClassName } from '../../withs';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import Section from '../../molecules/Section';
import Avatar from '../../molecules/Avatar';
import VideoModule from '../../modules/VideoModule';
import Img from '../../atoms/Img';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import clsx from 'clsx';
import Text from '../../atoms/Text';

const config = { type: 'player', playing: false };

export function TypeLocationPresentation({ className, doc, type }: TypeLocationPresentationProps) {
    const { t } = useTypeTranslation(type, doc);
    const { mediaShapeClass } = useStyling();
    const logoImage = doc.locationLogoImage;
    const mainImage = doc.locationMainImage;
    const websiteUrl = doc.locationWebsiteUrl;
    const name = doc.locationName;
    const presentation = doc.locationPresentation;
    const description = doc.locationDescription;
    const address = [
        doc.locationStreet,
        doc.locationComplement,
        doc.locationZipCode,
        doc.locationCity,
        doc.locationCountry,
    ]
        .filter((x) => !!x)
        .join(' - ');
    const videoUrl = doc.locationMainVideoUrl;

    return (
        <Section className={className} title={t(`${type}_location_label`)}>
            <div className='container mx-auto flex px-4 lg:flex-row py-2 flex-col items-center'>
                {((!!mainImage && !!mainImage.url) || !!videoUrl) && (
                    <div className='flex-col mt-4 sm:mt-0 lg:w-1/3 lg:w-1/2 w-5/6 flex justify-center items-center'>
                        {!videoUrl && !!mainImage && !!mainImage.url && (
                            <Img
                                className={clsx('flex-shrink-0 object-contain', mediaShapeClass)}
                                url={mainImage.url}
                                alt={mainImage.alt || 'location'}
                            />
                        )}
                        {!!videoUrl && <VideoModule url={videoUrl} config={config} />}
                    </div>
                )}
                <div className='w-full lg:pl-8 space-y-4 flex flex-col items-center mt-4 sm:mt-2 md:mt-2 lg:mt-0 lg:items-start lg:text-left lg:mb-0 '>
                    {!!name && (
                        <div className='font-go-text sm:text-3xl text-3xl mb-4 font-medium text-gray-900'>{name}</div>
                    )}
                    {!!presentation && (
                        <Text light className={'text-lg'}>
                            {presentation}
                        </Text>
                    )}
                    {!!description && (
                        <Text light className={'text-md'}>
                            {description}
                        </Text>
                    )}
                    <Avatar logoImage={logoImage} websiteUrl={websiteUrl} name={address} logoClassName={'border'} />
                </div>
            </div>
        </Section>
    );
}

export interface TypeLocationPresentationProps extends TypeProps, WithClassName {}

// noinspection JSUnusedGlobalSymbols
export default TypeLocationPresentation;
