import SingleLineTextInput from './inputs/SingleLineTextInput';
import MultiLineTextInput from './inputs/MultiLineTextInput';
import InputField, { InputFieldProps } from './InputField';

export function TextField(props: TextFieldProps) {
    return <InputField {...props} component={props.rows ? MultiLineTextInput : SingleLineTextInput} />;
}

export type TextFieldProps = Omit<InputFieldProps, 'component'>;

export default TextField;
