import { loadFull } from 'tsparticles';
import {
    Engine,
    /*
    type CustomEventArgs,
    type Particle,
     */
    type IRangeValue,
    type RangeValue,
    setRangeValue,
    type RecursivePartial,
    stringToRgb,
    type IParticlesOptions,
    rgbToHsl,
    type ISourceOptions,
} from '@tsparticles/engine';

/*
const explodeSoundCheck = (args: CustomEventArgs): boolean => {
    const data = args.data as { particle: Particle };

    return data.particle.shape === 'line';
};
 */

const fixRange = (value: IRangeValue, min: number, max: number): RangeValue => {
    const minValue = 0,
        diffSMax = value.max > max ? value.max - max : minValue;

    let res = setRangeValue(value);

    if (diffSMax) {
        res = setRangeValue(value.min - diffSMax, max);
    }

    const diffSMin = value.min < min ? value.min : minValue;

    if (diffSMin) {
        res = setRangeValue(minValue, value.max + diffSMin);
    }

    return res;
};

const fireworksOptions: RecursivePartial<IParticlesOptions>[] = ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93']
    .map((color) => {
        const rgb = stringToRgb(color);

        if (!rgb) {
            return undefined;
        }

        const hsl = rgbToHsl(rgb),
            sOffset = 30,
            lOffset = 30,
            sBounds: IRangeValue = {
                min: 0,
                max: 100,
            },
            lBounds: IRangeValue = {
                min: 0,
                max: 100,
            },
            sRange = fixRange({ min: hsl.s - sOffset, max: hsl.s + sOffset }, sBounds.min, sBounds.max),
            lRange = fixRange({ min: hsl.l - lOffset, max: hsl.l + lOffset }, lBounds.min, lBounds.max);

        return {
            color: {
                value: {
                    h: hsl.h,
                    s: sRange,
                    l: lRange,
                },
            },
            stroke: {
                width: 0,
            },
            number: {
                value: 0,
            },
            opacity: {
                value: {
                    min: 0.1,
                    max: 1,
                },
                animation: {
                    enable: true,
                    speed: 0.7,
                    sync: false,
                    startValue: 'max',
                    destroy: 'min',
                },
            },
            shape: {
                type: 'circle',
            },
            size: {
                value: { min: 1, max: 2 },
                animation: {
                    enable: true,
                    speed: 5,
                    count: 1,
                    sync: false,
                    startValue: 'min',
                    destroy: 'none',
                },
            },
            life: {
                count: 1,
                duration: {
                    value: {
                        min: 1,
                        max: 2,
                    },
                },
            },
            move: {
                decay: { min: 0.075, max: 0.1 },
                enable: true,
                gravity: {
                    enable: true,
                    inverse: false,
                    acceleration: 5,
                },
                speed: { min: 5, max: 15 },
                direction: 'none',
                outModes: 'destroy',
            },
        } as RecursivePartial<IParticlesOptions>;
    })
    .filter((t) => t !== undefined) as RecursivePartial<IParticlesOptions>[];

export default async (engine: Engine): Promise<ISourceOptions> => {
    await loadFull(engine);
    return {
        detectRetina: true,
        fpsLimit: 120,
        fullScreen: {
            enable: true,
            zIndex: 30,
        },
        emitters: {
            direction: 'top',
            life: {
                count: 0,
                duration: 0.1,
                delay: 0.1,
            },
            rate: {
                delay: 0.05,
                quantity: 1,
            },
            size: {
                width: 100,
                height: 0,
            },
            position: {
                y: 100,
                x: 50,
            },
        },
        particles: {
            number: {
                value: 0,
            },
            destroy: {
                mode: 'split',
                bounds: {
                    top: { min: 10, max: 30 },
                },
                split: {
                    sizeOffset: false,
                    count: 1,
                    factor: {
                        value: 0.333333,
                    },
                    rate: {
                        value: { min: 75, max: 150 },
                    },
                    particles: fireworksOptions,
                },
            },
            life: {
                count: 1,
            },
            shape: {
                type: 'line',
            },
            size: {
                value: {
                    min: 1,
                    max: 100,
                },
                animation: {
                    enable: true,
                    sync: true,
                    speed: 90,
                    startValue: 'max',
                    destroy: 'min',
                },
            },
            stroke: {
                color: {
                    value: '#ffffff',
                },
                width: 1,
            },
            rotate: {
                path: true,
            },
            move: {
                enable: true,
                gravity: {
                    acceleration: 15,
                    enable: true,
                    inverse: true,
                    maxSpeed: 100,
                },
                speed: {
                    min: 10,
                    max: 20,
                },
                outModes: {
                    default: 'destroy',
                    top: 'none',
                },
                trail: {
                    fill: {
                        color: '#000',
                    },
                    enable: false,
                    length: 10,
                },
            },
        },
        /*
        sounds: {
            enable: true,
            events: [
                {
                    event: 'particleRemoved',
                    filter: explodeSoundCheck,
                    audio: [
                        'https://particles.js.org/audio/explosion0.mp3',
                        'https://particles.js.org/audio/explosion1.mp3',
                        'https://particles.js.org/audio/explosion2.mp3',
                    ],
                },
            ],
            volume: 50,
        },
         */
    };
};
