import clsx from 'clsx';
import { WithChildren, WithClassName } from '../withs';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function TableRow({ children, className }: TableRowProps) {
    const { tableShapeClass } = useStyling();

    return (
        <div
            className={clsx(
                'md:flex flex-wrap w-full justify-between md:space-x-4 md:border-0 border border-gray-200 p-2',
                className,
                tableShapeClass,
            )}
        >
            {children || ''}
        </div>
    );
}

export interface TableRowProps extends WithChildren, WithClassName {}
export default TableRow;
