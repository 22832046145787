import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import ContentHeadingPushModule from '../../../organisms/ContentHeadingPushModule';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import useTypeUrl from '../../../hooks/useTypeUrl';

export function VideoServicePushPartnerModule({ partner, ...rest }: VideoServicePushPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('partner', partner);
    const registerUrl = useTypeUrl('partner', partner, 'register');

    return (
        <ModuleContainer {...zprops}>
            <ContentHeadingPushModule
                right
                videoUrl={partner.mainVideoUrl}
                overline={partner.overline}
                title={t('big_title')}
                text={partner.presentation}
                subtext={partner.description}
                buttonLabel={t('nav_register_label')}
                buttonTarget={registerUrl}
                button2Label={t('more_info_button_label', { name: partner.name })}
                button2Target={partner.websiteUrl}
                nopadding
                buttonsClassName={'hidden sm:flex'}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface VideoServicePushPartnerModuleProps {
    partner: any;
}

export default VideoServicePushPartnerModule;
