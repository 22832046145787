import GameHeader from '../../../molecules/headers/GameHeader';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardHeaderGameModule({ game, ...rest }: StandardHeaderGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer padding={'none'} {...zprops}>
            <GameHeader {...props} game={game} />
        </ModuleContainer>
    );
}

export interface StandardHeaderGameModuleProps {
    game: any;
}

export default StandardHeaderGameModule;
