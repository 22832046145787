import clsx from 'clsx';
import { WithClassName } from '../withs';

const sizes = {
    sm: 'w-4 h-4',
    md: 'w-8 h-8',
    lg: 'w-12 h-12',
};

const colors = {
    primary: 'border-go-primary',
    secondary: 'border-go-secondary',
    clear: 'border-white',
};

export function Spinner2({ className, color = 'primary', size = 'md' }: Spinner2Props) {
    return (
        <div
            className={clsx(
                'animate-spin inline-block border-5 border-b border-r rounded-full',
                sizes[size],
                colors[color],
                className,
            )}
        />
    );
}

export interface Spinner2Props extends WithClassName {
    size?: 'sm' | 'md' | 'lg';
    color?: 'primary' | 'secondary' | 'clear';
}

export default Spinner2;
