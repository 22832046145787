import clsx from 'clsx';
import { WithChildren, WithClassName } from '../withs';
import { ReactNode } from 'react';
import { class_name } from '../types';

export function BaseFooter({
    className,
    contentClassName,
    children,
    grow = true,
    secondaryChildren,
    divider = true,
}: BaseFooterProps) {
    return (
        <div className={clsx('w-full', className)}>
            <div
                className={clsx(
                    'flex flex-col bg-go-footer-primary text-go-footer-secondary lg:flex-row justify-between',
                    divider && 'lg:divide-x divide-black/5',
                    contentClassName,
                )}
            >
                <div className={'flex-1 p-8 sm:p-16 flex flex-col justify-center'}>{children}</div>
                {!!secondaryChildren && (
                    <>
                        {divider && <hr className={'border-b lg:border-none border-black/10'} />}
                        <div className={clsx('p-8 sm:p-16 flex flex-col justify-center', grow && 'flex-1')}>
                            {secondaryChildren}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export interface BaseFooterProps extends WithClassName, WithChildren {
    grow?: boolean;
    secondaryChildren?: ReactNode;
    contentClassName?: class_name;
    divider?: boolean;
}

// noinspection JSUnusedGlobalSymbols
export default BaseFooter;
