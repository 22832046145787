import TextInput from '../atoms/inputs/TextInput';

export function TextDataField({ placeholder, allData, ...props }: TextDataFieldProps) {
    return <TextInput {...props} placeholder={placeholder || ''} />;
}

export interface TextDataFieldProps {
    name: string;
    onChange: Function;
    value: any;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    allData?: any;
    register: Function;
    control: Function;
}

// noinspection JSUnusedGlobalSymbols
export default TextDataField;
