import clsx from 'clsx';
import { WithClassName, WithImage, WithSubtitle, WithText, WithTitle } from '../withs';
import Img from '../atoms/Img';
import String from '../atoms/String';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function BlockQuote({ className, image, subtitle, title, text }: BlockQuoteProps) {
    if (!text && !title) return null;
    const { blockShapeClass } = useStyling();

    return (
        <div
            className={clsx(
                'bg-go-panel-primary flex flex-col md:flex-row items-center space-y-6 md:space-x-6 md:space-y-0 px-8 py-6',
                blockShapeClass,
                className,
            )}
        >
            {!!image && image.url && (
                <Img
                    className={'rounded-full max-w-[160px] mx-4'}
                    alt={image.alt || 'quote'}
                    {...image}
                    maxSize={400}
                />
            )}
            {!!text && (
                <div className={'flex flex-col justify-between gap-y-4'}>
                    <blockquote className={'font-go-text text-md font-normal text-gray-600 italic'}>
                        <String basic>{`« ${text} »`}</String>
                    </blockquote>
                    {!!title && (
                        <div className={'flex items-center'}>
                            <String className={'font-go-text text-go-primary'}>{`**${title}**`}</String>
                            {!!subtitle && <String className={'font-go-text'}>{`, ${subtitle}`}</String>}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export interface BlockQuoteProps extends WithClassName, WithImage, WithSubtitle, WithTitle, WithText {}

// noinspection JSUnusedGlobalSymbols
export default BlockQuote;
