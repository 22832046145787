import useTenantContext from '@gotombola/react-contexts-type/lib/hooks/useTenantContext';
import { WithAlt, WithClassName } from '../withs';
import Img from './Img';

export function StaticImg({
    alt,
    className,
    mode = undefined,
    object = undefined,
    overlay = false,
    src,
}: StaticImgProps) {
    const { staticsDns } = useTenantContext().tenant || {};
    const path = `https://${staticsDns}/${src}`;
    const type = (path.slice(-4) || '').toLowerCase() === '.svg' ? 'image/svg+xml' : undefined;
    mode = mode || (object ? 'object' : undefined) || 'image';
    let content: any = null;

    switch (mode) {
        case 'object':
            content = (
                <object {...(type ? { type } : {})} className={className} data={path}>
                    <Img className={className} url={path} alt={alt} />
                </object>
            );
            break;
        case 'object-nofb':
            content = <object {...(type ? { type } : {})} className={className} data={path} />;
            break;
        default:
        case 'image':
            content = <Img className={className} url={path} alt={alt} />;
            break;
    }

    overlay &&
        (content = (
            <div className={'relative'}>
                {content}
                <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }} />
            </div>
        ));

    return content;
}

export interface StaticImgProps extends Required<WithAlt>, WithClassName {
    src: string;
    mode?: 'image' | 'object' | 'object-nofb';
    object?: boolean;
    overlay?: boolean;
}

export default StaticImg;
