import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./presentation/StandardPresentationGameModule')),
};

export function PresentationGameModule(props: PresentationGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type PresentationGameModuleProps = GameModuleProps;

export default PresentationGameModule;
