import clsx from 'clsx';
import {
    WithAutoFocus,
    WithClassName,
    WithDefaultValue,
    WithDisabled,
    WithName,
    WithNativeOnChange,
    WithNativePlaceholder,
    WithRequired,
    WithRows,
    WithValue,
} from '../../../withs';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

function MultiLineTextInput({ name, required, rows = 4, className, ...props }: MultiLineTextInputProps) {
    const { blockShapeClass } = useStyling();

    return (
        <textarea
            {...props}
            rows={rows}
            className={clsx(
                'font-go-text p-3 border w-full',
                required ? 'border-gray-500' : 'border-gray-300',
                blockShapeClass,
                className,
            )}
            name={name}
            required={required}
        />
    );
}

export interface MultiLineTextInputProps
    extends WithAutoFocus,
        WithClassName,
        WithDefaultValue,
        WithDisabled,
        WithName,
        WithNativeOnChange,
        WithNativePlaceholder,
        WithRequired,
        WithRows,
        WithValue {
    prefix?: string;
    pattern?: string;
}

export default MultiLineTextInput;
