import clsx from 'clsx';
import { image } from '../types';
import { WithClassName, WithName, WithOverline } from '../withs';
import Img from '../atoms/Img';
import String from '../atoms/String';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import shortenurl from '@genstackio/shortenurl';

export function Avatar({ className, logoClassName, logoImage, name, overline, websiteUrl }: AvatarProps) {
    const { logoShapeClass } = useStyling();

    return (
        <div
            className={clsx(
                'flex-1 w-full flex flex-col sm:flex-row justify-center items-baseline sm:items-center pr-4',
                logoShapeClass,
                className,
            )}
        >
            {!!logoImage && !!logoImage['url'] && (
                <Img
                    className={clsx(
                        'w-16 h-16 object-contain object-center flex-shrink-0 bg-gray-200 mr-4 box-content',
                        logoClassName,
                        logoShapeClass,
                    )}
                    url={logoImage.url}
                    maxSize={300}
                    alt={logoImage.alt || 'avatar'}
                />
            )}
            <div className='flex-grow'>
                {!!name && <String className='font-go-text text-gray-900 font-medium'>{name}</String>}
                {!!overline && <String className='font-go-text text-gray-500 text-sm font-light'>{overline}</String>}
                {!!websiteUrl && (
                    <a className='font-go-text text-gray-500 hover:text-go-title' href={websiteUrl}>
                        {shortenurl(websiteUrl, true) as string}
                    </a>
                )}
            </div>
        </div>
    );
}

export interface AvatarProps extends WithClassName, WithName, WithOverline {
    logoClassName?: string;
    logoImage?: image;
    websiteUrl?: string;
}

export default Avatar;
