import { formatRawAmount } from '@gotombola/amount';
import { WithTitle } from '@gotombola/react-types';
import TypeListing from './TypeListing';
import { useCallback, useMemo, useState } from 'react';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';
import LeaderboardRow from '../../molecules/LeaderboardRow';
import Text from '../../atoms/Text';

const listProps = {
    defaultMsVariant: 'basic',
    space: 'xs',
};
const filters = {
    default: (property) => (x) => x[property] > 0,
    full: () => () => true,
};
const sorts = {
    default: (property) => (a, b) => a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0,
    descending: (property) => (a, b) => a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0,
    ascending: (property) => (a, b) => a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0,
};
const units = {
    default: (v, x, { game }) => [game?.ticketPriceCurrency || 'EUR', formatRawAmount],
    currency: (v, x, { game }) => [game?.ticketPriceCurrency || 'EUR', formatRawAmount],
    ticket: (v, x, { t }) => [t(`leaderboard_game_unit_ticket${v > 1 ? '_other' : '_one'}`), undefined],
    sale: (v, x, { t }) => [t(`leaderboard_game_unit_sale${v > 1 ? '_other' : '_one'}`), undefined],
    promise: (v, x, { t }) => [t(`leaderboard_game_unit_promise${v > 1 ? '_other' : '_one'}`), undefined],
    donation: (v, x, { t }) => [t(`leaderboard_game_unit_donation${v > 1 ? '_other' : '_one'}`), undefined],
    none: () => ['', undefined],
};
const defaultItems = [];

export function Ranking({
    defaultFilter = 'default',
    defaultSort = 'default',
    unit = 'ticket',
    game,
    typeType,
    property = 'statPaidTickets',
    title: forcedTitle,
    items: rawItems = defaultItems,
    computeTitle,
    ...rest
}: RankingProps) {
    const [_, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const [filter, setFilter] = useState(defaultFilter);
    const [sort] = useState(defaultSort);
    const onFullClick = useCallback(() => {
        setFilter('full');
    }, [setFilter]);
    const onResetFilterClick = useCallback(() => {
        setFilter(defaultFilter);
    }, [setFilter, defaultFilter]);
    const filterFn = (filters[filter || 'default'] || filters['default'])(property);
    const sortFn = (sorts[sort || 'default'] || sorts['default'])(property);
    const sortedItems = useMemo(() => {
        const its = rawItems.filter(filterFn);
        its.sort(sortFn);
        return its;
    }, [rawItems, filterFn, sortFn]);
    const unitFn = units[unit || 'default'] || units['default'];
    const mainImage = game?.logoImage?.url
        ? game.logoImage
        : game.organizationLogoImage?.url
        ? game.organizationLogoImage
        : undefined;
    const { items, hasImage } = useMemo(() => {
        const needImage = sortedItems.reduce((acc, x) => {
            return acc || !!x?.logoImage?.url;
        }, false);
        return sortedItems.reduce(
            (acc, x, i) => {
                const [unitValue, formatValue] = unitFn(x[property], x, { game, t });
                return {
                    lastIndex: x[property] === acc.lastValue ? acc.lastIndex : i + 1,
                    lastValue: x[property],
                    hasImage:
                        acc.hasImage || !!x?.logoImage?.url || (x?.main && needImage ? !!mainImage?.url : acc.hasImage),
                    items: [
                        ...acc.items,
                        {
                            index: x[property] === acc.lastValue ? acc.lastIndex : i + 1,
                            title: !!computeTitle ? computeTitle(x) : x.name,
                            overline: x.country,
                            value: x[property] || 0,
                            unit: unitValue,
                            format: formatValue,
                            image: x?.logoImage?.url ? x?.logoImage : x?.main && needImage ? mainImage : undefined,
                        },
                    ],
                };
            },
            { lastIndex: 0, lastValue: 0, items: [], hasImage: false },
        );
    }, [sortedItems, unit]);

    const title = forcedTitle || t('leaderboard_game_title');
    const itemProps = useMemo(() => ({ withImage: hasImage }), [hasImage]);
    if (!items?.length) return null;

    const areAllSelected = rawItems?.length === items?.length;

    return (
        <TypeListing
            {...props}
            items={items}
            withRank={false}
            type={'game'}
            doc={game}
            itemType={'leaderboard'}
            itemComponent={LeaderboardRow}
            nobg
            title={title}
            listProps={listProps}
            itemProps={itemProps}
        >
            <div className={'text-center'}>
                {'full' !== filter && !areAllSelected && (
                    <Text inline extralight onClick={onFullClick}>
                        {t('leaderboard_game_full_list')}
                    </Text>
                )}
                {'full' === filter && defaultFilter !== filter && (
                    <Text inline extralight onClick={onResetFilterClick}>
                        {t('leaderboard_game_filtered_list')}
                    </Text>
                )}
            </div>
        </TypeListing>
    );
}

export interface RankingProps extends WithTitle {
    game: any;
    typeType: string;
    defaultFilter?: string;
    unit?: string;
    defaultSort?: string;
    property?: string;
    items?: any[];
    computeTitle?: (x: any) => string;
}

export default Ranking;
