import { useMemo } from 'react';
import { public_page_definition } from '../types';
import buildDefaultConfigPropertiesFromDoc from '../utils/buildDefaultConfigPropertiesFromDoc';

const defaultDefs: any = {};
const defaultDefaults: any = {};

export function usePublicPageDefinition(
    def: public_page_definition | undefined,
    type: string,
    key: string | undefined = undefined,
    defs: any = defaultDefs,
    defaults: any = defaultDefaults,
    doc: any = undefined,
) {
    return useMemo(() => {
        const zz = buildDefaultConfigPropertiesFromDoc(doc);
        if (def && Object.keys(def).length) return { ...defaults, ...zz, ...def };
        const dd = defs[type || ''] || defs['default'] || {};
        return {
            ...defaults,
            ...(dd['common'] || {}),
            ...(dd[key || ''] || dd['default'] || {}),
            ...zz,
            ...(def || {}),
        };
    }, [defs, def, type, key, defaults, doc]);
}

export default usePublicPageDefinition;
