const ownerTypeMap = {
    sms: 'par SMS',
    email: 'par email',
    default: '',
};

export function getOwnerType(owner: string | undefined | null) {
    let x = 'unknown';
    if (!!owner && null !== owner) {
        x = owner.includes('@') ? 'email' : owner.match(/[0-9\s.+()]+/g) ? 'sms' : 'unknown';
    }
    return ownerTypeMap[x || ''] || ownerTypeMap['default'];
}

export default getOwnerType;
