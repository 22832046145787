import { createContext } from 'react';
import { sponsor_context_value } from '../types';
import createDefaultSponsorContextValue from '../utils/createDefaultSponsorContextValue';

export const SponsorContext = createContext<sponsor_context_value>(createDefaultSponsorContextValue());
// noinspection JSUnusedGlobalSymbols
export const SponsorContextProvider = SponsorContext.Provider;
// noinspection JSUnusedGlobalSymbols
export const SponsorContextConsumer = SponsorContext.Consumer;

// noinspection JSUnusedGlobalSymbols
export default SponsorContext;
