import TypeQuote, { TypeQuoteProps } from '../../../organisms/type/TypeQuote';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any) => !!doc?.mainQuote;

export function StandardQuoteTypeModule({ typeType, ...rest }: StandardQuoteTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');
    if (!doc) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeQuote {...props} type={type} doc={doc} />
        </ModuleContainer>
    );
}

export interface StandardQuoteTypeModuleProps extends Omit<TypeQuoteProps, 'type'> {
    typeType: string;
}

export default StandardQuoteTypeModule;
