import loadable from '@loadable/component';
import { DoneeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./presentation/StandardPresentationDoneeModule')),
};

export function PresentationDoneeModule(props: PresentationDoneeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type PresentationDoneeModuleProps = DoneeModuleProps;

export default PresentationDoneeModule;
