import {
    WithAutoFocus,
    WithDefaultValue,
    WithDisabled,
    WithError,
    WithName,
    WithNativeOnChange,
    WithRequired,
    WithTitle,
} from '../../withs';
import TextField from './TextField';

export function DateField(props: DateFieldProps) {
    return <TextField type={'date'} {...props} />;
}

export interface DateFieldProps
    extends WithAutoFocus,
        WithDefaultValue<number>,
        WithDisabled,
        WithError,
        WithNativeOnChange,
        WithName,
        WithRequired,
        WithTitle {}

export default DateField;
