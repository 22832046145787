import GenericLocaleSwitcher, { GenericLocaleSwitcherProps } from './GenericLocaleSwitcher';

export function GameLocaleSwitcher({ game, ...props }: GameLocaleSwitcherProps) {
    return <GenericLocaleSwitcher item={game} versionKey={'gameVersions'} {...props} />;
}

export interface GameLocaleSwitcherProps extends Omit<GenericLocaleSwitcherProps, 'item' | 'versionKey'> {
    game: any;
}

export default GameLocaleSwitcher;
