import CenteredHeadingModule from '../modules/headings/CenteredHeadingModule';
import useTypeUrl from '../hooks/useTypeUrl';
import useTypeTranslation from '../hooks/useTypeTranslation';
import { WithButton, WithSubtitle, WithText, WithTitle } from '@gotombola/react-types/lib/withs';

export function PartnerPricing({ partner, title, subtitle, text, buttonLabel, buttonTarget }: PartnerPricingProps) {
    const { t } = useTypeTranslation('partner', partner);
    const registerUrl = useTypeUrl('partner', partner, 'register');

    return (
        <CenteredHeadingModule
            title={title || t('pricing_title')}
            subtitle={subtitle || t('pricing_subtitle')}
            text={text || t('pricing_text')}
            buttonLabel={buttonLabel || t('nav_register_label')}
            buttonTarget={buttonTarget || registerUrl}
        />
    );
}

export interface PartnerPricingProps extends WithTitle, WithSubtitle, WithText, WithButton {
    partner?: any;
}

// noinspection JSUnusedGlobalSymbols
export default PartnerPricing;
