import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./serviceContact/StandardServiceContactPartnerModule')),
};

export function ServiceContactPartnerModule(props: ServiceContactPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ServiceContactPartnerModuleProps = PartnerModuleProps;

export default ServiceContactPartnerModule;
