import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    introduction: loadable(() => import('./registerForm/IntroductionRegisterFormPartnerModule')),
    website: loadable(() => import('./registerForm/WebsiteRegisterFormPartnerModule')),
};

export function RegisterFormPartnerModule(props: RegisterFormPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'introduction'} />;
}

export type RegisterFormPartnerModuleProps = PartnerModuleProps;

export default RegisterFormPartnerModule;
