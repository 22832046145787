import { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterContextProvider } from '@gotombola/react-contexts/lib/contexts/RouterContext';
import { ApplicationContextProvider } from '@gotombola/react-contexts/lib/contexts/ApplicationContext';
import buildRoutes, { routes_manager } from '@gotombola/routes-utils';
import { WithOptions } from '../withs';
import buildUrlFactory from '../utils/buildUrlFactory';
import useQueryString from '../hooks/useQueryString';
import { storage } from '@gotombola/react-contexts/lib/types';
import AppProvider from './AppProvider';
import buildStorage from '../utils/buildStorage';
import useBuiltUserContextValue from '../hooks/useBuiltUserContextValue';

const storage: storage | undefined = buildStorage();

export function App({ applicationContext, data, mode, options, routes, prefix = 'gotb' }: AppProps) {
    if ('function' === typeof routes) routes = routes({ data, front: 'undefined' !== typeof window }, mode, options);
    const realRoutes = buildRoutes(routes, { data, front: 'undefined' !== typeof window }, options, mode);
    routes = realRoutes as any[];
    const qs = useQueryString();
    const buildUrl = useMemo(() => buildUrlFactory(routes as any[]), [routes]);
    let content = (
        <Switch>
            {((routes as any[]) || []).map((route: any, i: number) => (
                <Route
                    key={i}
                    {...route}
                    {...(data
                        ? {
                              render: (props) => <route.component {...props} staticContext={data} />,
                              component: undefined,
                          }
                        : {})}
                />
            ))}
        </Switch>
    );

    const designer = useMemo(() => ({ debug: qs.has('designer:debug') }), [qs]);

    const routerContextProviderValue = useMemo(() => {
        if (!buildUrl) return undefined;
        return { buildUrl };
    }, [buildUrl]);

    const applicationContextProviderValue = useMemo(() => {
        if (!applicationContext) return undefined;
        return { ...applicationContext };
    }, [applicationContext]);

    routerContextProviderValue &&
        (content = <RouterContextProvider value={routerContextProviderValue}>{content}</RouterContextProvider>);

    applicationContextProviderValue &&
        (content = (
            <ApplicationContextProvider value={applicationContextProviderValue}>{content}</ApplicationContextProvider>
        ));

    const userContextProviderValue = useBuiltUserContextValue({ prefix, storage });

    return (
        <AppProvider storage={storage} user={userContextProviderValue} designer={designer}>
            {content}
        </AppProvider>
    );
}

export interface AppProps extends WithOptions {
    data?: any;
    routes?: routes_manager;
    mode?: string;
    applicationContext?: any;
    prefix?: string;
}

// noinspection JSUnusedGlobalSymbols
export default App;
