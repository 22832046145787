import clsx from 'clsx';
import Img from './Img';
import RichText from './RichText';
import useStyledText from '../hooks/useStyledText';
import { flag } from '@gotombola/types/lib/types/base';
import { WithClassName, WithTitle } from '@gotombola/react-types/lib/withs';
import { responsive_image } from '../types';

const sizes = {
    text: 'text-md',
    title: 'text-3xl',
};

export function SectionTitle({
    className,
    bold = true,
    center = true,
    title: rawTitle,
    titleStyling,
    basic = false,
    level = 2,
    size = 'title',
    nocolor = false,
    leftImage,
    rightImage,
    topImage,
    bottomImage,
    aboveImage,
    belowImage,
}: SectionTitleProps) {
    const [title, titleClassName] = useStyledText(rawTitle, titleStyling, undefined, 'sectionTitle');
    if (!title) return null;
    let content = (
        <RichText
            className={clsx(
                'font-go-heading',
                !nocolor && 'text-go-title',
                sizes[size],
                center && 'text-center',
                bold && 'font-bold',
                className,
                titleClassName,
            )}
            value={title}
            basic={basic}
        />
    );
    switch (level) {
        case 1:
            content = <h1>{content}</h1>;
            break;
        default:
        case 2:
            content = <h2>{content}</h2>;
            break;
        case 3:
            content = <h3>{content}</h3>;
            break;
        case 4:
            content = <h4>{content}</h4>;
            break;
    }
    if (
        !!leftImage?.url ||
        !!leftImage?.mobile?.url ||
        !!leftImage?.tablet?.url ||
        !!rightImage?.url ||
        !!rightImage?.mobile?.url ||
        !!rightImage?.tablet?.url
    ) {
        content = (
            <div className={'flex items-center'}>
                <div className={'w-2/12'}>
                    <Img
                        className={clsx('object-contain w-full max-h-[125px]', className)}
                        url={leftImage?.url || ''}
                        {...(leftImage?.mobile?.url ? { mobile: leftImage.mobile } : {})}
                        {...(leftImage?.tablet?.url ? { tablet: leftImage.tablet } : {})}
                        maxSize={300}
                        alt={leftImage?.alt || 'banner item'}
                    />
                </div>
                <div className={'flex justify-center items-center flex-1 px-2'}>{content}</div>
                <div className={'w-2/12'}>
                    <Img
                        className={clsx('object-contain w-full max-h-[125px]', className)}
                        url={rightImage?.url || ''}
                        {...(rightImage?.mobile?.url ? { mobile: rightImage.mobile } : {})}
                        {...(rightImage?.tablet?.url ? { tablet: rightImage.tablet } : {})}
                        maxSize={300}
                        alt={rightImage?.alt || 'banner item'}
                    />
                </div>
            </div>
        );
    }
    if (
        !!topImage?.url ||
        !!topImage?.mobile?.url ||
        !!topImage?.tablet?.url ||
        !!bottomImage?.url ||
        !!bottomImage?.mobile?.url ||
        !!bottomImage?.tablet?.url
    ) {
        content = (
            <div className={'flex flex-col gap-4'}>
                <Img
                    className={'object-cover w-full max-h-[125px]'}
                    url={topImage?.url || ''}
                    {...(topImage?.mobile?.url ? { mobile: topImage.mobile } : {})}
                    {...(topImage?.tablet?.url ? { tablet: topImage.tablet } : {})}
                    maxSize={2048}
                    alt={topImage?.alt || 'banner item'}
                />
                <div className={'flex justify-center items-center flex-1 py-2'}>{content}</div>
                <Img
                    className={'object-cover w-full max-h-[125px]'}
                    url={bottomImage?.url || ''}
                    {...(bottomImage?.mobile?.url ? { mobile: bottomImage.mobile } : {})}
                    {...(bottomImage?.tablet?.url ? { tablet: bottomImage.tablet } : {})}
                    maxSize={2048}
                    alt={bottomImage?.alt || 'banner item'}
                />
            </div>
        );
    }
    if (
        !!aboveImage?.url ||
        !!aboveImage?.mobile?.url ||
        !!aboveImage?.tablet?.url ||
        !!belowImage?.url ||
        !!belowImage?.mobile?.url ||
        !!belowImage?.tablet?.url
    ) {
        content = (
            <div className={clsx('relative flex items-center justify-center w-full max-h-[300px]', className)}>
                <Img
                    className={'absolute inset-0 object-cover w-full z-20'}
                    url={aboveImage?.url || ''}
                    {...(aboveImage?.mobile?.url ? { mobile: aboveImage.mobile } : {})}
                    {...(aboveImage?.tablet?.url ? { tablet: aboveImage.tablet } : {})}
                    maxSize={2048}
                    alt={aboveImage?.alt || 'banner item'}
                />
                <div className={'absolute inset-0 w-full z-10 my-auto'}>{content}</div>
                <Img
                    className={'absolute inset-0 object-cover w-full z-0'}
                    url={belowImage?.url || ''}
                    {...(belowImage?.mobile?.url ? { mobile: belowImage.mobile } : {})}
                    {...(belowImage?.tablet?.url ? { tablet: belowImage.tablet } : {})}
                    maxSize={2048}
                    alt={belowImage?.alt || 'banner item'}
                />
            </div>
        );
    }
    return content;
}

export interface SectionTitleProps extends WithClassName, WithTitle {
    titleStyling?: string;
    basic?: flag;
    bold?: flag;
    center?: flag;
    nocolor?: flag;
    level?: number;
    size?: string;
    leftImage?: responsive_image;
    rightImage?: responsive_image;
    topImage?: responsive_image;
    bottomImage?: responsive_image;
    aboveImage?: responsive_image;
    belowImage?: responsive_image;
}

export default SectionTitle;
