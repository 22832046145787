import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./servicePush/StandardServicePushPartnerModule')),
    image: loadable(() => import('./servicePush/ImageServicePushPartnerModule')),
    video: loadable(() => import('./servicePush/VideoServicePushPartnerModule')),
};

export function ServicePushPartnerModule(props: ServicePushPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type ServicePushPartnerModuleProps = PartnerModuleProps;

export default ServicePushPartnerModule;
