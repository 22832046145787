import { useMemo } from 'react';
import loadable from '@loadable/component';
import { WithAny, WithType } from '../withs';

export function Effect({ type, ...props }: EffectProps) {
    const Comp = useMemo(() => {
        if (!type) return undefined;
        const [realType, opts = undefined] = type.split(':');
        const optsProps = opts ? { type: opts } : {};
        const name = `${realType.slice(0, 1).toUpperCase()}${realType.slice(1)}Effect`;
        const Cp = loadable(() => import(`../effects/${name}`));
        // eslint-disable-next-line react/display-name
        return (props: any) => <Cp {...optsProps} {...props} />;
    }, [type]);

    return Comp ? <Comp {...props} /> : null;
}

export interface EffectProps extends WithAny, WithType {}

export default Effect;
