import loadable from '@loadable/component';
import { TypeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    player: loadable(() => import('./video/PlayerVideoTypeModule')),
};

export function VideoTypeModule(props: VideoTypeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'player'} />;
}

export type VideoTypeModuleProps = TypeModuleProps;

export default VideoTypeModule;
