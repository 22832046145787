import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./keyInfos/StandardKeyInfosGameModule')),
};

export function KeyInfosGameModule(props: KeyInfosGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type KeyInfosGameModuleProps = GameModuleProps;

export default KeyInfosGameModule;
