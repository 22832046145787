import Img from './Img';
import { WithAlt, WithNativeOnClick, WithUrl } from '../withs';
import clsx from 'clsx';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

export function SponsoringImage({ alt, onClick, url }: SponsoringImageProps) {
    const { logoShapeClass } = useStyling();

    return (
        <div
            className={clsx(
                'bg-white w-36 h-20 flex justify-center items-center border-2 border-gray-200 hover:cursor-pointer hover:border-2 hover:border-go-primary',
                logoShapeClass,
            )}
        >
            <Img className={'h-14 object-contain'} url={url} alt={alt || 'sponsoring'} onClick={onClick as any} />
        </div>
    );
}

export interface SponsoringImageProps extends Required<WithAlt>, WithNativeOnClick, Required<WithUrl> {}

// noinspection JSUnusedGlobalSymbols
export default SponsoringImage;
