import { useEffect, useMemo, useState } from 'react';
import { image } from '../types';
import Img, { ImgProps } from './Img';

const defaultExtraImages: any[] = [];
export function AnimatedImage({
    speed = 1,
    mainImage,
    extraImages = defaultExtraImages,
    ...props
}: AnimatedImageProps) {
    const images = useMemo(() => [mainImage, ...extraImages].filter((x: any) => !!x?.url), [mainImage, extraImages]);
    const [current, setCurrent] = useState(0);
    const v = useMemo(() => {
        const vv = Math.round(10000 / speed);
        return vv >= 500 ? vv : 1000;
    }, [speed]);
    useEffect(() => {
        if (images.length < 2) return;
        const interval = setTimeout(() => {
            setCurrent((current + 1) % images.length);
        }, v);
        return () => clearInterval(interval);
    }, [v, images, setCurrent, current]);
    const image = images[current || 0];
    return image?.url ? <Img {...props} url={image.url} /> : null;
}

export interface AnimatedImageProps extends Omit<ImgProps, 'url'> {
    mainImage: image;
    extraImages?: image[];
    speed?: number;
}

// noinspection JSUnusedGlobalSymbols
export default AnimatedImage;
