import { useMemo } from 'react';
import useBuildUrlFor from './useBuildUrlFor';
import { camelcase } from '@ohoareau/string';

export function useTypeUrl(type: string, doc: any, target: string, qs?: any, extraVars?: any, aliasName?: string) {
    aliasName = (aliasName || target || '').replace(/-/g, '_');
    const aliasValue = doc?.[`custom${camelcase(aliasName)}PageUrl`] || undefined;
    const officialValue = doc?.[`${aliasName}PageUrl`] || undefined;
    const buildUrlForDoc = useBuildUrlFor(type, doc);
    return useMemo(
        () => aliasValue || officialValue || buildUrlForDoc(target, qs, extraVars),
        [target, qs, extraVars, buildUrlForDoc, aliasValue],
    );
}

// noinspection JSUnusedGlobalSymbols
export default useTypeUrl;
