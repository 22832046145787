import computeCustomPacks from './computeCustomPacks';
import getDefaultDefaultCustomPacks from './getDefaultDefaultCustomPacks';

export function getFinalPacks(
    doc: { features?: string; customPacks?: string },
    forcedDefaultCustomPacks: string | undefined = undefined,
) {
    return computeCustomPacks(
        doc.features,
        doc.customPacks,
        forcedDefaultCustomPacks || getDefaultDefaultCustomPacks(),
    );
}

export default getFinalPacks;
