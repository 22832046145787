/**
 * @var customPacks Only used when not limited to 1 ticket per sale (max_1_ticket_per_sale)
 */
export function computeCustomPacks(
    features: string | undefined,
    customPacks: string | undefined,
    defaultValue: string,
) {
    if (features?.includes('max_1_ticket_per_sale')) {
        return '1';
    }
    return customPacks || defaultValue;
}

export default computeCustomPacks;
