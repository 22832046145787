import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypePresentation from '../../../organisms/type/TypePresentation';

export function StandardPresentationSellergroupModule({
    sellergroup,
    game,
    ...rest
}: StandardPresentationSellergroupModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <TypePresentation {...props} type={'sellergroup'} doc={sellergroup} />
        </ModuleContainer>
    );
}

export interface StandardPresentationSellergroupModuleProps {
    sellergroup: any;
    game?: any;
}

export default StandardPresentationSellergroupModule;
