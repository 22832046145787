import SnowStorm from 'react-snowstorm';
import { useEffect, useState } from 'react';

// noinspection JSUnusedLocalSymbols
export function SnowstormEffect(props: SnowstormEffectProps) {
    const [displayed, setDisplayed] = useState(false);

    useEffect(() => {
        setDisplayed(true);
    }, [setDisplayed]);

    return displayed ? <SnowStorm excludeMobile={false} /> : null;
}

export type SnowstormEffectProps = Record<string, never>;

// noinspection JSUnusedGlobalSymbols
export default SnowstormEffect;
