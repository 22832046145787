import { useMemo } from 'react';

export function useNthGodfather(doc: any, index = 1) {
    return useMemo(() => {
        const prefix = `godfather${index <= 1 ? '' : `${index}`}`;
        const x = {
            index: index - 1,
            title: doc[`${prefix}Title`],
            subtitle: doc[`${prefix}Subtitle`],
            image: doc[`${prefix}Image`],
            videoUrl: doc[`${prefix}VideoUrl`],
        };
        return x.title ? x : undefined;
    }, [doc, index]);
}

// noinspection JSUnusedGlobalSymbols
export default useNthGodfather;
