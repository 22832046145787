import { useMemo } from 'react';
import { WithAny } from '../withs';
import OneSignalPushNotification from './push-notifications/OneSignalPushNotification';
import GotbPushNotification from './push-notifications/GotbPushNotification';

export function PushNotification(props: PushNotificationProps) {
    const { swPath } = props;
    const cfg = useMemo(() => {
        if (!props.type || !props.type.modes) return undefined;
        const cfgFn = props.type.modes[props.mode || ''];
        return cfgFn ? cfgFn(props) : undefined;
    }, [props]);

    switch (cfg?.provider) {
        case 'onesignal':
            return <OneSignalPushNotification cfg={cfg} swPath={swPath} />;
        default:
            return <GotbPushNotification cfg={cfg} swPath={swPath} />;
    }
}

export interface PushNotificationProps extends WithAny {
    type?: any;
    mode?: string;
    swPath?: string;
}

// noinspection JSUnusedGlobalSymbols
export default PushNotification;
