import useQueryString from './useQueryString';
import { toByteArray } from 'base64-js';

export function useBase64QueryStringParam(name: string): string {
    const query = useQueryString();
    let value = query.get(name) || '';
    try {
        value && value.length && (value = String.fromCharCode(...toByteArray(value)));
    } catch (e) {
        console.error(e);
        value = '';
    }
    return value;
}

// noinspection JSUnusedGlobalSymbols
export default useBase64QueryStringParam;
