import fromMods from './fromMods';

export function buildAppsCommonConfigs(defaultApp: string, apps: any, commonConfig: any = {}) {
    const c = Object.entries(apps).reduce(
        (acc, [name, app]: [string, any]) => {
            const mods = app.mods || (app.mounts || []).map((x: any) => x.mod).reverse();
            acc.sitemaps[name] = app.sitemaps || { root: { items: [{ uri: '/' }] } };
            acc.configs[name] = { ...commonConfig, ...(app.config || {}) };
            acc.modules[name] = app.modules || fromMods(mods, 'modules');
            acc.publicPages[name] = app.publicPages || fromMods(mods, 'publicPages');
            acc.templates[name] = app.templates || fromMods(mods, 'templates');
            acc.templateDefinitions[name] = app.templateDefinitions || fromMods(mods, 'templateDefinitions');
            acc.workflows[name] = app.workflows || fromMods(mods, 'workflows');
            acc.actions[name] = app.actions || fromMods(mods, 'actions');
            acc.pixels[name] = app.pixels || {
                app: `${acc.configs[name].service}_${acc.configs[name].mode}`,
                pages: fromMods(mods, 'pixel'),
            };
            acc.pushs[name] = app.pushs || {
                app: `${acc.configs[name].service}_${acc.configs[name].mode}`,
                pages: fromMods(mods, 'push'),
            };
            acc.mods[name] = mods;
            acc.mounts[name] = app.mounts || [];
            return acc;
        },
        {
            configs: {},
            mounts: {},
            modules: {},
            publicPages: {},
            pixels: {},
            pushs: {},
            templates: {},
            templateDefinitions: {},
            mods: {},
            workflows: {},
            actions: {},
            sitemaps: {},
        },
    );

    return Object.keys(c).reduce((acc, k) => {
        acc[k].default = acc[k][defaultApp];
        return acc;
    }, c);
}

// noinspection JSUnusedGlobalSymbols
export default buildAppsCommonConfigs;
