import { dynamic_page_context_value } from '../types';

export function createDefaultDynamicPageContextValue(): dynamic_page_context_value {
    return {
        step: 'initial',
        data: {},
        set: () => {
            /* empty */
        },
        updateData: () => {
            /* empty */
        },
        setStep: () => {
            /* empty */
        },
        context: {},
    };
}

export default createDefaultDynamicPageContextValue;
