import clsx from 'clsx';
import String from '../atoms/String';
import { WithButton } from '../withs';

export function MobileFixedButton({ buttonLabel, buttonTarget }: MobileFixedButtonProps) {
    return (
        <>
            <div className={'sm:hidden h-16'} />
            <a
                href={buttonTarget}
                className={clsx(
                    'font-go-button sm:hidden text-white font-semibold text-3xl text-center p-4 z-30 fixed bottom-0 left-0 right-0 cursor-pointer bg-go-primary hover:bg-go-secondary',
                )}
            >
                <String basic>{buttonLabel}</String>
            </a>
        </>
    );
}

export type MobileFixedButtonProps = WithButton<string>;

export default MobileFixedButton;
