import { useEffect, useState } from 'react';
import Snowfall from 'react-snowfall';

// noinspection JSUnusedLocalSymbols
export function SnowfallEffect(props: SnowfallEffectProps) {
    const [displayed, setDisplayed] = useState(false);

    useEffect(() => {
        setDisplayed(true);
    }, [setDisplayed]);

    return displayed ? <Snowfall /> : null;
}

export type SnowfallEffectProps = Record<string, never>;

// noinspection JSUnusedGlobalSymbols
export default SnowfallEffect;
