import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import ModuleContainer from '../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../hooks/useZoneCustomizationProps';
import Text from '../../atoms/Text';

function Component({ game, ...rest }: any) {
    const [zprops] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const email = game.serviceProviderEmail;
    const phone = game.serviceProviderPhone;
    const street = game.serviceProviderStreet;
    const zipCode = game.serviceProviderZipCode;
    const country = game.serviceProviderZipCountry;

    return (
        <ModuleContainer {...zprops}>
            <div className={'w-full space-y-2'}>
                <Text bold className={'sm:text-xl'}>
                    {t('game_legal_page_contact_us')}
                </Text>
                <div className={'flex flex-col gap-2'}>
                    <Text inline relaxed className={'sm:text-lg'}>
                        {t('game_legal_page_contact_us_text')}
                    </Text>
                    <Text inline relaxed className={'sm:text-lg'}>
                        {t('game_legal_page_support_mailbox_contact', {
                            street: street,
                            zipCode: zipCode,
                            country: country,
                        })}
                    </Text>
                    <Text inline relaxed className={'sm:text-lg'}>
                        {t('game_legal_page_support_email_contact', { email: email })}
                    </Text>
                    <Text inline relaxed className={'sm:text-lg'}>
                        {t('game_legal_page_support_phone_contact', { phone: phone })}
                    </Text>
                </div>
            </div>
        </ModuleContainer>
    );
}

export function LegalContactUsGameModule(props: LegalContactUsGameModuleProps) {
    return <ModuleMode {...props} component={Component} />;
}

export type LegalContactUsGameModuleProps = GameModuleProps;

export default LegalContactUsGameModule;
