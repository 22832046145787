import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';

export function OptionsDataField({ onChange, value, optionUnmarshall }: OptionsDataFieldProps) {
    const { t } = useTrans();
    const selectedValues = useMemo(() => {
        let vals = [...value];
        vals = vals.map((x) => ({ ...x, label: x.label ? t(x.label) : x.label }));
        vals.sort((a: any, b: any) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
        return vals;
    }, [value, t]);

    const handleClick = useCallback(
        (v: any) => (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            onChange &&
                onChange({
                    target: {
                        value: optionUnmarshall(
                            v,
                            !(value.find((x: any) => x.value === v) || { enabled: false, value: v }).enabled,
                            value,
                        ),
                    },
                });
        },
        [onChange, optionUnmarshall, value],
    );

    return (
        <div className={'flex flex-wrap gap-2'}>
            {selectedValues.map(({ value, label, enabled }: any, index: number) => (
                <div
                    key={index}
                    className={clsx(
                        'font-normal text-md p-2 flex items-center w-full border-4 rounded-sm hover:border-go-primary cursor-pointer',
                        enabled
                            ? 'border-go-primary bg-go-primary text-white'
                            : 'line-through bg-gray-100 border-transparent',
                    )}
                    onClick={handleClick(value)}
                >
                    {label}
                </div>
            ))}
        </div>
    );
}

export interface OptionsDataFieldProps {
    name: string;
    onChange: Function;
    value: any;
    placeholder?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    optionUnmarshall: Function;
    allData?: any;
    register: Function;
    control: Function;
}

// noinspection JSUnusedGlobalSymbols
export default OptionsDataField;
