import useUser from '@gotombola/react-contexts/lib/hooks/useUser';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import { WithButtonLabel, WithTitle } from '@gotombola/react-types/lib/withs';

// noinspection JSUnusedLocalSymbols
export function ThemingEditingPanel({ title, buttonLabel, doc, setDoc, onSubmit }: ThemingEditingPanelProps) {
    const { t } = useTrans();
    const [user] = useUser();
    const userName = user?.email || undefined;

    return (
        <form onSubmit={onSubmit as any}>
            <div
                className={
                    'z-50 h-screen fixed w-auto right-0 top-0 bg-white border-l border-black overflow-y-scroll flex flex-col shadow-2xl'
                }
            >
                <div className={'sticky top-0 left-0 right-0 h-auto flex flex-col w-full text-white'}>
                    <p className={'w-full font-bold text-xl py-4 text-center font-normal bg-black'}>
                        {title || t('theming_provider_panel_title')}
                    </p>
                    {!!userName && (
                        <p
                            className={
                                'w-full font-bold text-sm py-2 text-center font-light bg-gray-800 border-t-[0.5px] border-white'
                            }
                        >
                            {userName}
                        </p>
                    )}
                </div>
                <div className={'flex-1 flex w-full'}>
                    <div className={'w-screen sm:w-[475px]'}>...</div>
                </div>
                <div className={'sticky bottom-0 left-0 right-0 h-auto flex w-full border-t border-black'}>
                    <button
                        className='bottom-0 w-full bg-go-secondary text-white font-bold text-xl py-4 font-normal hover:bg-go-primary'
                        type={'submit'}
                    >
                        {buttonLabel || t('theming_provider_button_save_label')}
                    </button>
                </div>
            </div>
        </form>
    );
}

export interface ThemingEditingPanelProps extends WithTitle, WithButtonLabel {
    formComponent: any;
    doc: any;
    setDoc: Function;
    onSubmit: Function;
}

export default ThemingEditingPanel;
