import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import useTypeUrl from '../../../hooks/useTypeUrl';
import CenteredHeadingModule from '../../../modules/headings/CenteredHeadingModule';

export function StandardServiceCallToActionPartnerModule({
    partner,
    ...rest
}: StandardServiceCallToActionPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    const { t } = useTypeTranslation('partner', partner);
    const registerUrl = useTypeUrl('partner', partner, 'register');

    return (
        <ModuleContainer {...zprops}>
            <CenteredHeadingModule
                title={t(partner?.main ? 'calltoaction_title_main' : 'calltoaction_title', { name: partner?.name })}
                text={t(partner?.main ? 'calltoaction_subtitle_main' : 'calltoaction_subtitle', {
                    name: partner?.name,
                })}
                buttonLabel={t('nav_register_label')}
                buttonTarget={registerUrl}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface StandardServiceCallToActionPartnerModuleProps {
    partner: any;
}

export default StandardServiceCallToActionPartnerModule;
