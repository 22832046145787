import { useMemo } from 'react';
import clsx from 'clsx';
import { dynamic_page_body_item_config } from '../types';
import { WithIndex } from '../withs';
import useModule from '../hooks/useModule';
import prepareModuleConfig from '../utils/prepareModuleConfig';

const defaultConfig = {};

export function DynamicPageBodyItem({ config = defaultConfig }: DynamicPageBodyItemProps) {
    const preparedConfig = useMemo(() => prepareModuleConfig(config.config || {}), [config.config]);
    const Component = useModule(config.type, preparedConfig);
    const visibility = ((config || {}).config || {}).visibility;
    const forcedClassNames = ((config || {}).config || {}).styles;
    const forcedTheme = ((config || {}).config || {}).theme;
    const classNames = clsx(
        'hidden' === visibility && 'hidden',
        'desktop' === visibility && 'hidden lg:block',
        'tablet' === visibility && 'hidden sm:block lg:hidden',
        'mobile' === visibility && 'sm:hidden',
        'desktop+tablet' === visibility && 'hidden sm:block',
        'tablet+mobile' === visibility && 'lg:hidden',
        'desktop+mobile' === visibility && 'sm:hidden lg:block',
        forcedClassNames,
        forcedTheme && 'default' !== forcedTheme && `theme-${forcedTheme}`,
    );

    return <Component config={preparedConfig} type={config.type} className={classNames} />;
}

export interface DynamicPageBodyItemProps extends WithIndex<any> {
    config: dynamic_page_body_item_config;
}

export default DynamicPageBodyItem;
