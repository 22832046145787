import MultiLineTextInput from './inputs/MultiLineTextInput';
import InputField, { InputFieldProps } from './InputField';

export function TextareaField(props: TextareaFieldProps) {
    return <InputField {...props} component={MultiLineTextInput} />;
}

export type TextareaFieldProps = Omit<InputFieldProps, 'component'>;

export default TextareaField;
