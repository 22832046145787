import { WithButtons, WithClassName, WithItems, WithOverline, WithText, WithTitle } from '../withs';
import CenteredHeadingModule from '../modules/headings/CenteredHeadingModule';
import Items from '../atoms/Items';
import Screenshot from '../atoms/Screenshot';

export function Screenshots({ className, items = [], ...props }: ScreenshotsProps) {
    return (
        <div className={className}>
            <CenteredHeadingModule {...props} />
            <div className={'flex flex-wrap sm:flex-nowrap items-center justify-center flex-row gap-4 pb-10'}>
                <Items items={items} component={Screenshot} />
            </div>
        </div>
    );
}

export interface ScreenshotsProps extends WithClassName, WithItems, WithOverline, WithText, WithTitle, WithButtons {}

// noinspection JSUnusedGlobalSymbols
export default Screenshots;
