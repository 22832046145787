import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import TypeFunnelStep from '../../../organisms/TypeFunnelStep';
import Input from '../../../atoms/Input';
import useTypeFormStep from '../../../hooks/useTypeFormStep';

export function OrganizationRegistrationFormPartnerModule({
    partner,
    registration,
    ...rest
}: OrganizationRegistrationFormPartnerModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t, stepProps, inputProps, register } = useTypeFormStep('partner', partner, 'registration', 'organization', {
        registration,
    });

    return (
        <ModuleContainer {...zprops}>
            <TypeFunnelStep {...stepProps} {...props}>
                <Input
                    {...register('name', { required: true })}
                    placeholder={t('field_organizationname_placeholder')}
                    autoFocus
                    required
                    {...inputProps}
                />
                <Input {...register('zipCode')} placeholder={t('field_zipcode_placeholder')} {...inputProps} />
            </TypeFunnelStep>
        </ModuleContainer>
    );
}

export interface OrganizationRegistrationFormPartnerModuleProps {
    partner: any;
    registration: any;
}

export default OrganizationRegistrationFormPartnerModule;
