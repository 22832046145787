import GameInfos from '../../../organisms/GameInfos';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';

export function StandardInfosGameModule({ game, ...rest }: StandardInfosGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);

    return (
        <ModuleContainer {...zprops}>
            <GameInfos {...props} game={game} />
        </ModuleContainer>
    );
}

export interface StandardInfosGameModuleProps {
    game: any;
}

export default StandardInfosGameModule;
