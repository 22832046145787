import { useCallback, useMemo, useState } from 'react';
import useQueryString from '../hooks/useQueryString';
import clsx from 'clsx';
import { WithButtonLabel, WithTitle } from '@gotombola/react-types/lib/withs';
import { EditModeContextProvider } from '@gotombola/react-contexts/lib/contexts/EditModeContext';
import { edit_provider_view } from '../types';
import { FormProvider, useForm } from 'react-hook-form';
import buildCssFromDoc, { colorVars } from '../utils/buildCssFromDoc';
import EditEditingPanel from './EditEditingPanel';
import ThemingEditingPanel from './ThemingEditingPanel';

function mergeDocAndForcedDoc(doc: any, forcedDoc: any) {
    if (!doc) {
        if (!forcedDoc) return {};
        return forcedDoc;
    }
    if (!forcedDoc) {
        return doc;
    }
    if (!doc.__css) return { ...doc, ...forcedDoc };
    if (!forcedDoc.__css) return { ...doc, ...forcedDoc };
    return {
        ...doc,
        ...forcedDoc,
        __css: { ...doc.__css, ...forcedDoc.__css },
    };
}
function buildForcedCss(qs: URLSearchParams): Record<string, string> | undefined {
    const css: Record<string, string> = {};
    Object.keys(colorVars).reduce((acc, x) => {
        if (qs.has(x)) {
            const v = qs.get(x);
            v && (acc[x] = v);
        }
        return acc;
    }, css);

    if (!Object.keys(css).length) return undefined;
    return css;
}

export function EditProvider<T = any>({ onSubmit, data, children, view: forcedView, ...props }: EditProviderProps<T>) {
    const [doc, setDoc] = useState(data);

    const methods = useForm();
    const { register, handleSubmit, watch, control } = methods;

    const qs = useQueryString();
    const isEdit = qs.has('edit');
    const isTheming = qs.has('theming');
    const forcedCss = useMemo(() => buildForcedCss(qs), [qs]);
    const [view, setView] = useState<'display' | 'edit' | 'theming'>(
        forcedView || (isEdit ? 'edit' : isTheming ? 'theming' : 'display'),
    );

    const realSubmit = useCallback(
        (data: any) => {
            console.log(data);
            onSubmit && onSubmit(data);
            setView('display');
        },
        [onSubmit, setView],
    );

    const editModeProviderValue = useMemo(
        () => ({
            view,
            enabled: view === 'edit' || view === 'theming',
            left: 0,
            right: view === 'edit' || view === 'theming' ? 475 : 0,
            top: 0,
            bottom: 0,
            register,
            watch,
            control,
        }),
        [view, register, watch],
    );

    const isEditing = editModeProviderValue.enabled;
    const style = useMemo(
        () =>
            isEditing
                ? {
                      left: 0,
                      right: 475,
                      top: 0,
                      bottom: 0,
                  }
                : undefined,
        [isEditing],
    );

    watch<any>((data: any) => {
        setDoc({ ...doc, ...data });
    });

    const builtCss = useMemo(
        () => buildCssFromDoc(mergeDocAndForcedDoc(doc, forcedCss ? { __css: forcedCss } : undefined)),
        [doc, forcedCss],
    );

    return (
        <EditModeContextProvider value={editModeProviderValue}>
            <FormProvider {...methods}>
                {!!builtCss && <style>{builtCss}</style>}
                <div className={'w-full flex sm:flex-row flex-col-reverse'}>
                    <div
                        className={clsx(isEditing && 'hidden sm:block sm:absolute', !isEditing && 'flex-1 w-full')}
                        style={style}
                    >
                        {children(doc)}
                    </div>
                    {isEditing && isEdit && !!doc && (
                        <EditEditingPanel {...props} doc={doc} setDoc={setDoc} onSubmit={handleSubmit(realSubmit)} />
                    )}
                    {isEditing && isTheming && !!doc && (
                        <ThemingEditingPanel {...props} doc={doc} setDoc={setDoc} onSubmit={handleSubmit(realSubmit)} />
                    )}
                </div>
            </FormProvider>
        </EditModeContextProvider>
    );
}

export interface EditProviderProps<T = any> extends WithTitle, WithButtonLabel {
    children: (doc: T) => any;
    data: T;
    formComponent: any;
    onSubmit?: Function;
    view?: edit_provider_view;
}

// noinspection JSUnusedGlobalSymbols
export default EditProvider;
