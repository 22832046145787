import TopMenuItem from '../../TopMenuItem';
import useTypeUrl from '../../../hooks/useTypeUrl';
import useTypeTranslation from '../../../hooks/useTypeTranslation';

export function PartnerHeaderMainButton({ partner }: PartnerHeaderMainButtonProps) {
    const { t } = useTypeTranslation('partner', partner);
    const registerUrl = useTypeUrl('partner', partner, 'register');

    return <TopMenuItem target={registerUrl} label={t('nav_register_label')} className={'hidden lg:block'} important />;
}

export interface PartnerHeaderMainButtonProps {
    partner: any;
}

export default PartnerHeaderMainButton;
