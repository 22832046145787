import { MouseEventHandler, useMemo } from 'react';
import clsx from 'clsx';
import Img from '../../atoms/Img';
import Button from '../../atoms/Button';
import AnimatedImage from '../../atoms/AnimatedImage';
import useTrans from '@gotombola/react-i18n/lib/hooks/useTrans';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { image, WithName, WithOnClick, WithOverline, WithQuantity } from '@gotombola/react-types';

export function GalleryBunchItem({
    mainImage,
    name,
    quantity,
    onClick,
    rank,
    sponsorings,
    image1Image,
    image2Image,
    image3Image,
    image4Image,
    image5Image,
    image6Image,
    image7Image,
    image8Image,
    image9Image,
}: GalleryBunchItemProps) {
    const { t } = useTrans();
    const { blockShapeClass, blockBorderClass, logoShapeClass } = useStyling();
    const rankString = `${rank}${quantity! > 1 ? `-${rank! + (quantity! - 1)}` : ''}`;
    const hasImage = !!mainImage?.url;
    const sponsoringsImages =
        !!sponsorings?.length && !!sponsorings?.filter((x) => !!x?.mainImage?.url || !!x?.logoImage?.url);
    const extraImages = useMemo(
        () =>
            [
                image1Image,
                image2Image,
                image3Image,
                image4Image,
                image5Image,
                image6Image,
                image7Image,
                image8Image,
                image9Image,
            ].filter((x: any) => !!x?.url) as any[],
        [
            image1Image,
            image2Image,
            image3Image,
            image4Image,
            image5Image,
            image6Image,
            image7Image,
            image8Image,
            image9Image,
        ],
    );

    return (
        <div
            className={clsx(
                'shadow group relative hover:cursor-pointer overflow-hidden',
                !hasImage && 'bg-go-secondary',
                blockBorderClass,
                blockShapeClass,
                'h-[542px]',
            )}
            onClick={onClick as MouseEventHandler<HTMLDivElement>}
        >
            <div
                className={clsx(
                    'font-go-text absolute top-2 right-2 bg-opacity-95 bg-go-primary px-2 py-1 text-sm font-light text-white',
                    blockShapeClass,
                )}
            >
                {t('game_listing_rank_position_item', { rankString })}
            </div>
            {hasImage && (
                <AnimatedImage
                    className={clsx(
                        'transition group-hover:scale-105 object-cover w-full h-[350px] mb-48',
                        blockShapeClass,
                    )}
                    mainImage={mainImage}
                    maxSize={650}
                    alt={mainImage?.alt || 'image item'}
                    extraImages={extraImages}
                />
            )}
            <div
                className={clsx(
                    'absolute bottom-0 inset-x-0 bg-white group-hover:bg-go-choice-primary min-h-[14rem] w-full flex flex-col justify-center items-center px-8 pb-4',
                    blockShapeClass,
                    'border-t',
                )}
            >
                {!!sponsorings?.length && (
                    <div className={'flex flex-col items-center gap-2 p-2'}>
                        <div className={'flex flex-wrap justify-center items-center gap-x-2'}>
                            {sponsoringsImages &&
                                sponsorings.map(({ mainImage, logoImage }, i) => (
                                    <Img
                                        key={i}
                                        className={clsx(
                                            'w-16 h-16 object-contain object-center flex-shrink-0 box-content',
                                            logoShapeClass,
                                        )}
                                        url={logoImage?.url || mainImage?.url || ''}
                                        maxSize={300}
                                        alt={logoImage?.alt || mainImage?.alt || 'avatar'}
                                    />
                                ))}
                        </div>
                        <div className={'font-go-text text-sm text-go-text font-light text-center'}>
                            {t(
                                [`game_sponsoring_overline_${String(sponsorings.length)}`, `game_sponsoring_overline`],
                                sponsorings.reduce(
                                    (acc, s, index) =>
                                        Object.assign(acc, { [`name${index > 0 ? String(index + 1) : ''}`]: s?.name }),
                                    {} as any,
                                ),
                            )}
                        </div>
                    </div>
                )}
                {!!name && (
                    <div
                        className={
                            'font-go-text font-light text-go-text flex-1 flex items-center my-2 overflow-hidden text-center'
                        }
                    >
                        {name}
                    </div>
                )}
                <Button
                    variant={'secondary'}
                    label={t('more_details_button_label')}
                    className={'text-sm mt-2'}
                    target={onClick}
                />
            </div>
        </div>
    );
}

export interface GalleryBunchItemProps extends WithName, WithOverline, WithQuantity, WithOnClick {
    mainImage?: image;
    rank?: number;
    sponsorings?: {
        websiteUrl?: string;
        description?: string;
        name?: string;
        logoImage?: { url: string; alt: string };
        mainImage?: { url: string; alt: string };
    }[];
    image1Image?: { url: string; alt: string };
    image2Image?: { url: string; alt: string };
    image3Image?: { url: string; alt: string };
    image4Image?: { url: string; alt: string };
    image5Image?: { url: string; alt: string };
    image6Image?: { url: string; alt: string };
    image7Image?: { url: string; alt: string };
    image8Image?: { url: string; alt: string };
    image9Image?: { url: string; alt: string };
}

export default GalleryBunchItem;
