import { WithChildren, WithNoindex, WithTitle } from '../withs';
import StaticPageHead from '../organisms/StaticPageHead';

export function StaticPage({ children, noindex = false, title = undefined }: StaticPageProps) {
    return (
        <>
            <StaticPageHead title={title} noindex={noindex} />
            {children || ''}
        </>
    );
}

export interface StaticPageProps extends WithChildren, WithNoindex, WithTitle<string> {}

export default StaticPage;
