import TypeSocialNetworks, { TypeSocialNetworksProps } from '../../../organisms/type/TypeSocialNetworks';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useFirstTypeInHierarchyMatching from '../../../hooks/useFirstTypeInHierarchyMatching';

const matcher = (doc: any) => !!doc;

export function StandardShareTypeModule({ typeType, ...rest }: StandardShareTypeModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const [type, doc] = useFirstTypeInHierarchyMatching(typeType, rest, matcher, 'basic');

    if (!doc) return null;

    return (
        <ModuleContainer {...zprops}>
            <TypeSocialNetworks {...props} type={type} doc={doc} />
        </ModuleContainer>
    );
}

export interface StandardShareTypeModuleProps extends Omit<TypeSocialNetworksProps, 'type'> {
    typeType: string;
}

export default StandardShareTypeModule;
