import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import TableRow from '../molecules/TableRow';
import Cell from '../molecules/Cell';
import useTypeTranslation from '../hooks/useTypeTranslation';
import isPrizeToKeepForWinner from '../utils/isPrizeToKeepForWinner';
import censureContent from '@gotombola/censure/lib/utils/censureContent';
import String from '../atoms/String';

const styles = {
    contained_primary: 'bg-go-primary text-white',
    contained_secondary: 'bg-go-secondary text-white',
    outlined_primary: 'border-go-primary text-go-primary',
    outlined_secondary: 'border-go-secondary text-go-secondary',
    children_light_primary: 'border border-go-primary text-go-primary sm:border-none bg-white sm:bg-none',
    children_light_secondary: 'border border-go-secondary text-go-secondary sm:border-none bg-white sm:bg-none',
    children_full_primary: 'bg-go-primary text-white',
    children_full_secondary: 'bg-go-secondary text-white',
};

export function GameDrawResults({
    draw,
    from,
    game,
    light = false,
    obfuscate = true,
    searchBar = true,
    searchFocus = true,
    to,
}: GameDrawResultsProps) {
    const { t } = useTypeTranslation('game', game);
    const [search, setSearch] = useState('');
    const allItems = ((draw || {}).winners || {}).items || [];
    const filteredItems = useMemo(() => {
        if (!!search) {
            return allItems.filter((item: any) => isPrizeToKeepForWinner(search, item));
        }
        return allItems;
    }, [search, draw]);
    from = from || 0;
    to = undefined === to || -1 === to ? filteredItems.length : to;
    const items = useMemo(() => filteredItems.slice(from!, to!), [filteredItems, from, to]);
    const handleChange = useCallback(
        (e) => {
            setSearch(e.target.value);
        },
        [setSearch],
    );
    const handleClear = useCallback(() => {
        setSearch('');
    }, [setSearch]);
    const vip = game.customBigPriceCount || 3;

    return (
        <>
            {searchBar && (
                <div className={'flex flex-col items-center'}>
                    <div className={'font-go-text tracking-tight mb-2 font-light sm:text-xl'}>
                        {t('game_results_page_search_bar_text')}
                    </div>
                    <div className={'relative w-full sm:w-96 text-center'}>
                        {search !== '' && (
                            <button
                                className={
                                    'font-go-button absolute right-2 mt-1 text-gray-600 bottom-1/2 text-xs font-light p-1 rounded-lg bg-gray-100 hover:bg-gray-200'
                                }
                                value={search}
                                onClick={handleClear}
                            >
                                {t('game_results_page_reset_button_label')}
                            </button>
                        )}
                        <input
                            type={'text'}
                            value={search}
                            onChange={handleChange}
                            className={'font-go-text px-4 py-2 border rounded-lg h-10 w-full mb-6'}
                            autoFocus={searchFocus}
                        />
                    </div>
                </div>
            )}
            {!!search && !items.length && (
                <div className={'font-go-text'}>{t('game_results_page_no_tickets_for_search')}</div>
            )}
            <div className={'w-full'}>
                {!!items.length && (
                    <div className={'hidden md:flex px-4'}>
                        <div className={'font-go-text w-28 text-xs text-center pr-4'}>
                            {t('game_results_page_rank_column_label')}
                        </div>
                        <div className={'font-go-text w-32 text-xs text-center pr-4'}>
                            {t('game_results_page_ticket_column_label')}
                        </div>
                        <div className={'font-go-text pl-4 w-72 lg:w-[22rem] text-xs'}>
                            {t('game_results_page_identity_column_label')}
                        </div>
                        <div className={'font-go-text pl-4 flex-1 text-xs'}>
                            {t('game_results_page_bunch_column_label')}
                        </div>
                    </div>
                )}
                <div className={'w-full'}>
                    {items.map((item: any, index: number) => {
                        const {
                            id,
                            rank,
                            ticketCustomCode,
                            ticketCode,
                            bunchName,
                            email,
                            phone,
                            lastName,
                            firstName,
                            ticketSellerOnly = false,
                        } = item;
                        const isVip = rank <= vip;
                        // noinspection PointlessBooleanExpressionJS
                        const extra = !!ticketSellerOnly && (
                            <String className={'font-go-text w-full text-xs font-light ml-4 sm:ml-0 text-go-primary'}>
                                {t('game_results_page_seller_only_label')}
                            </String>
                        );
                        return (
                            <TableRow key={id || index} className={'mt-6 md:mt-0 px-4 flex relative w-full'}>
                                <Cell
                                    emphasize={isVip}
                                    className={'w-full sm:w-auto block py-3 sm:p-4'}
                                    childrenClassName={clsx(
                                        'font-go-text h-full pr-2 sm:pr-0 w-8 sm:w-16 flex justify-center items-center text-xl tracking-tighter rounded-full',
                                        isVip && 'font-bold',
                                    )}
                                >
                                    {rank}
                                </Cell>
                                <Cell
                                    border={light}
                                    emphasize={light && isVip}
                                    childrenClassName={clsx(
                                        'font-go-text md:h-full w-28 md:w-full flex items-center justify-center absolute md:static -top-3 right-4 capitalize mx-auto p-1 md:p-3 text-md font-bold rounded-lg',
                                        light
                                            ? styles[`children_light_${ticketCustomCode ? 'primary' : 'secondary'}`]
                                            : styles[`children_full_${ticketCustomCode ? 'primary' : 'secondary'}`],
                                    )}
                                    className={clsx(
                                        'w-0 md:w-28',
                                        styles[
                                            `${light ? 'outlined' : 'contained'}_${
                                                ticketCustomCode ? 'primary' : 'secondary'
                                            }`
                                        ],
                                    )}
                                >
                                    {ticketCustomCode || ticketCode}
                                </Cell>
                                {!!lastName && 'INCONNU' !== lastName && (
                                    <Cell
                                        emphasize={isVip}
                                        childrenClassName={'w-full flex flex-wrap items-center md:px-2 lg:px-4'}
                                        className={clsx(
                                            'w-32 lg:w-40',
                                            !firstName && 'w-[17rem] lg:w-[21rem]',
                                            isVip && 'font-bold',
                                        )}
                                    >
                                        <String className={'font-go-text text-md uppercase break-words'} basic>
                                            {lastName}
                                        </String>
                                        {extra}
                                    </Cell>
                                )}
                                {!!firstName && 'INCONNU' !== firstName && (
                                    <Cell
                                        emphasize={isVip}
                                        childrenClassName={'w-full flex flex-wrap items-center md:px-2 lg:px-4'}
                                        className={clsx(
                                            'w-32 lg:w-40',
                                            !lastName && 'w-[17rem] lg:w-[21rem]',
                                            isVip && 'font-bold',
                                        )}
                                    >
                                        <String className={'font-go-text text-md capitalize break-words'} basic>
                                            {firstName}
                                        </String>
                                        {extra}
                                    </Cell>
                                )}
                                {(!lastName || lastName === 'INCONNU') && (!firstName || firstName === 'INCONNU') && (
                                    <Cell
                                        emphasize={isVip}
                                        childrenClassName={'w-full flex flex-wrap items-center pl-1 md:px-2 lg:px-4'}
                                        className={clsx('w-[17rem] lg:w-[21rem]', isVip && 'font-bold')}
                                    >
                                        <String className={'font-go-text text-md'} basic>
                                            {censureContent(email || phone, email ? 'email' : 'phone', obfuscate)}
                                        </String>
                                        {extra}
                                    </Cell>
                                )}
                                <Cell
                                    emphasize={isVip}
                                    childrenClassName={'flex flex-col flex-1 md:py-1 md:px-2 lg:px-4'}
                                    className={'mt-2 md:mt-0 w-full flex sm:flex-1'}
                                >
                                    <String className={'font-go-text text-md text-justify md:text-left font-light'}>
                                        {bunchName}
                                    </String>
                                </Cell>
                            </TableRow>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export interface GameDrawResultsProps {
    game: any;
    draw?: any;
    from?: number;
    to?: number;
    searchBar?: boolean;
    searchFocus?: boolean;
    light?: boolean;
    obfuscate?: boolean;
}

// noinspection JSUnusedGlobalSymbols
export default GameDrawResults;
