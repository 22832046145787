import clsx from 'clsx';
import { WithClassName, WithUrl } from '../withs';
import StaticLogo, { StaticLogoProps } from './StaticLogo';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

const sizes = {
    sm: {
        container: 'w-10 h-10',
        logo: 'h-[60%]',
    },
    md: {
        container: 'w-14 h-14',
        logo: 'h-[50%]',
    },
    lg: {
        container: 'w-20 h-20',
        logo: 'h-[60%]',
    },
};

export function SocialIcon({ alt, className, icon, url, size = 'md', ...props }: SocialIconProps) {
    const { blockShapeClass } = useStyling();

    return (
        <a href={url}>
            <div
                className={clsx(
                    'bg-gray-200 hover:bg-go-primary cursor-pointer flex justify-center items-center hover:shadow-sm',
                    sizes[size || '']?.container || sizes['md'].container,
                    url && 'cursor-pointer',
                    blockShapeClass,
                    className,
                )}
            >
                <StaticLogo
                    className={sizes[size || '']?.logo || sizes['md'].logo}
                    type={icon}
                    alt={alt || icon}
                    {...props}
                />
            </div>
        </a>
    );
}

export interface SocialIconProps extends WithClassName, Omit<StaticLogoProps, 'type'>, WithUrl {
    icon: string;
    size?: 'sm' | 'md' | 'lg';
}

export default SocialIcon;
