import { useMemo } from 'react';
import { TypeProps } from '../../types';
import IconsBlock from '../IconsBlock';
import useTypeShares from '../../hooks/useTypeShares';
import hasFeature from '../../utils/hasFeature';
import { WithComponent, WithTitle } from '../../withs';

export function TypeSocialNetworks({
    doc,
    type,
    component: Component = IconsBlock,
    msVariant,
    ...props
}: TypeSocialNetworksProps) {
    const shares = useTypeShares(type, doc);

    // enabled by default, disablable
    const needFacebook = !hasFeature(doc, 'disable_social_networks_share_facebook');
    const needTwitter = !hasFeature(doc, 'disable_social_networks_share_twitter');

    // disabled by default, enablable
    const needInstagram = hasFeature(doc, 'social_networks_share_instagram');
    const needLinkedin = hasFeature(doc, 'social_networks_share_linkedin');
    const needWhatsapp = hasFeature(doc, 'social_networks_share_whatsapp');
    const needPinterest = hasFeature(doc, 'social_networks_share_pinterest');
    const needTelegram = hasFeature(doc, 'social_networks_share_telegram');
    const needEmail = hasFeature(doc, 'social_networks_share_email');
    const needSms = hasFeature(doc, 'social_networks_share_sms');

    const items = useMemo(() => {
        const items: any[] = [];
        needEmail &&
            shares.emailShareUrl &&
            items.push({
                url: shares.emailShareUrl,
                icon: 'email-2',
                className: 'bg-gray-500',
                flatIcon: 'email-black',
            });
        needSms &&
            shares.smsShareUrl &&
            items.push({ url: shares.smsShareUrl, icon: 'sms-2', className: 'bg-gray-500', flatIcon: 'sms-black' });
        needFacebook &&
            shares.facebookShareUrl &&
            items.push({
                url: shares.facebookShareUrl,
                icon: 'facebook-2',
                className: 'bg-blue-500',
                flatIcon: 'facebook-black',
            });
        needTwitter &&
            shares.twitterShareUrl &&
            items.push({
                url: shares.twitterShareUrl,
                icon: 'x-3',
                className: 'bg-black',
                flatIcon: 'twitter-black',
            });
        needInstagram &&
            (shares.instagramShareUrl || doc.instagramUrl) &&
            items.push({
                url: shares.instagramShareUrl || doc.instagramUrl,
                icon: 'instagram-2',
                className: 'bg-fuchsia-700',
                flatIcon: 'instagram-black',
            });
        needLinkedin &&
            shares.linkedinShareUrl &&
            items.push({
                url: shares.linkedinShareUrl,
                icon: 'linkedin-2',
                className: 'bg-sky-600',
                flatIcon: 'linkedin-black',
            });
        needWhatsapp &&
            shares.whatsappShareUrl &&
            items.push({
                url: shares.whatsappShareUrl,
                icon: 'whatsapp-2',
                className: 'bg-green-600',
                flatIcon: 'whatsapp-black',
            });
        needPinterest &&
            shares.pinterestShareUrl &&
            items.push({
                url: shares.pinterestShareUrl,
                icon: 'pinterest-2',
                className: 'bg-red-600',
                flatIcon: 'pinterest-black',
            });
        needTelegram &&
            shares.telegramShareUrl &&
            items.push({
                url: shares.telegramShareUrl,
                icon: 'telegram-2',
                className: 'bg-gray-600',
                flatIcon: 'telegram-black',
            });
        return items;
    }, [
        shares.facebookShareUrl,
        shares.twitterShareUrl,
        shares.instagramShareUrl,
        shares.linkedinShareUrl,
        shares.whatsappShareUrl,
        shares.pinterestShareUrl,
        shares.telegramShareUrl,
        shares.emailShareUrl,
        shares.smsShareUrl,
        needFacebook,
        needTwitter,
        needInstagram,
        needLinkedin,
        needWhatsapp,
        needTelegram,
        needEmail,
        needSms,
        doc.instagramUrl,
    ]);

    return hasFeature(doc, 'disable_social_networks_share') ? null : (
        <Component {...props} items={items} variant={msVariant} defaultVariant={'basic'} />
    );
}

export interface TypeSocialNetworksProps extends TypeProps, WithComponent, WithTitle {
    msVariant?: string;
}

// noinspection JSUnusedGlobalSymbols
export default TypeSocialNetworks;
