import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import ReactPlayer from 'react-player';
import { WithClassName, WithImage, WithType, WithUrl } from '../../withs';
import StaticIcon from '../../atoms/StaticIcon';
import Img from '../../atoms/Img';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';

const hsizes = {
    custom: 'noclass',
    default: 'h-[16rem] sm:h-[24rem] md:h-[28rem] lg:h-[34rem]',
    full: 'h-full',
};

export function PlayerVideoModule({
    className,
    controls = true,
    hmargin,
    hsize,
    image,
    muted = false,
    playing = false,
    url,
}: PlayerVideoModuleProps) {
    const [showVideo, setShowVideo] = useState(!image);
    const toggleVideo = useCallback(() => {
        setShowVideo(!showVideo);
    }, [setShowVideo, showVideo]);
    url = !!url ? ('string' === typeof url ? url : url.url) : undefined;
    image = useMemo(() => {
        if (!image) return undefined;
        const { src, ...image2 } = image;
        return { url: src, ...image2 };
    }, [image]);

    const { mediaShapeClass } = useStyling();
    return (
        <div
            className={clsx(
                'w-full relative overflow-hidden',
                hmargin && 'my-2',
                !image && (hsizes[hsize || ''] || hsizes['default']),
                mediaShapeClass,
                className,
            )}
        >
            {!!image && <Img {...image} className={'object-cover'} alt={image.alt || 'video presentation image'} />}
            {!!image && (
                <button onClick={toggleVideo} type={'button'} className={'absolute inset-0 mx-auto'}>
                    <div className={'w-10 h-10 text-blue-500 hover:text-yellow-500'}>
                        <StaticIcon type={'play'} />
                    </div>
                </button>
            )}
            {showVideo && (
                <ReactPlayer
                    className={'absolute top-0'}
                    height={'100%'}
                    width={'100%'}
                    url={url}
                    muted={muted}
                    playing={playing}
                    controls={controls}
                    onEnded={image && toggleVideo}
                />
            )}
        </div>
    );
}

export interface PlayerVideoModuleProps extends WithClassName, WithImage<any>, WithType, Required<WithUrl<any>> {
    muted?: boolean;
    playing?: boolean;
    controls?: boolean;
    hsize?: string;
    hmargin?: boolean;
}

export default PlayerVideoModule;
