import { useEffect, useMemo, useState } from 'react';
import Confetti from 'react-confetti';

// noinspection JSUnusedLocalSymbols
export function ConfettiEffect(props: ConfettiEffectProps) {
    const [displayed, setDisplayed] = useState(false);
    const [width, height] = useMemo(
        () => (!displayed || 'undefined' === typeof window ? [0, 0] : [window?.innerWidth, window?.innerHeight]),
        [displayed],
    );

    useEffect(() => {
        setDisplayed(true);
    }, [setDisplayed]);

    return displayed ? <Confetti width={width} height={height} /> : null;
}

export type ConfettiEffectProps = Record<string, never>;

// noinspection JSUnusedGlobalSymbols
export default ConfettiEffect;
