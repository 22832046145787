import loadable from '@loadable/component';
import { PartnerModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    organization: loadable(() => import('./registrationForm/OrganizationRegistrationFormPartnerModule')),
    owner: loadable(() => import('./registrationForm/OwnerRegistrationFormPartnerModule')),
    game: loadable(() => import('./registrationForm/GameRegistrationFormPartnerModule')),
    price: loadable(() => import('./registrationForm/TicketPriceRegistrationFormPartnerModule')),
    summary: loadable(() => import('./registrationForm/SummaryRegistrationFormPartnerModule')),
};

export function RegistrationFormPartnerModule(props: RegistrationFormPartnerModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'introduction'} />;
}

export type RegistrationFormPartnerModuleProps = PartnerModuleProps;

export default RegistrationFormPartnerModule;
