import Loader from 'react-loader-spinner';
import { WithClassName } from '../../withs';

// noinspection JSUnusedLocalSymbols
export function DefaultSpinner(_: DefaultSpinnerProps) {
    return <Loader type={'TailSpin'} color={'#FFA026'} height={60} />;
}

export type DefaultSpinnerProps = WithClassName;

export default DefaultSpinner;
