import loadable from '@loadable/component';
import { DoneeModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./header/StandardHeaderDoneeModule')),
};

export function HeaderDoneeModule(props: HeaderDoneeModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type HeaderDoneeModuleProps = DoneeModuleProps;

export default HeaderDoneeModule;
