import clsx from 'clsx';
import { useMemo } from 'react';
import { WithClassName } from '@gotombola/react-types/lib/withs';
import MainLogo from '../atoms/MainLogo';

const defaultItems = [];

export function MainLogos({ className, items = defaultItems }: MainLogosProps) {
    const logos = useMemo(() => (items || []).filter((x) => !!x && !x?.hidden), [items]);

    return logos?.length ? (
        <div className={clsx('relative', className, logos.length > 1 && 'space-x-2 inline-block text-center')}>
            {logos.map((l: any, i: number) => (
                <div key={i} className={'inline-block w-32 h-32'}>
                    <MainLogo {...l} />
                </div>
            ))}
        </div>
    ) : null;
}

export interface MainLogosProps extends WithClassName {
    items?: any[];
}

export default MainLogos;
