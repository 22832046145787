import clsx from 'clsx';
import { WithChildren, WithClassName, WithUrl } from '../withs';

export function ClickableContent({ children, className, url, mode = undefined }: ClickableContentProps) {
    return url ? (
        <a href={url} target={mode} className={clsx('cursor-pointer', className)}>
            {children}
        </a>
    ) : (
        <>{children}</>
    );
}

export interface ClickableContentProps extends WithChildren, WithClassName, WithUrl {
    mode?: string;
}

// noinspection JSUnusedGlobalSymbols
export default ClickableContent;
